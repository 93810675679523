import { BootstrapURLKeys, MapOptions } from 'google-map-react';

export const MapConfigKeys: BootstrapURLKeys = {
  key: 'AIzaSyDQEf22rhw5KU4I6EokkXhyI_feTYKWZPY',
  libraries: ['drawing', 'geometry'],
};

export const MapConfigOptions: MapOptions = {
  mapId: 'd771944a00f183a9',
};

export const MapConfigDefaultProps = {
  center: {
    lat: 37.5757693,
    lng: 126.9772881,
  },
  zoom: 13,
};
