import React, { useState } from 'react';
import styled from 'styled-components';
import { CtrRow } from '../style';

import { useDraggable } from '@dnd-kit/core';

function DraggableRow({
  children,
  id,
  onMouseEnter,
  onMouseLeave,
}: {
  children: React.ReactNode;
  id: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });

  return (
    <DraggableWrapper
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </DraggableWrapper>
  );
}

export default DraggableRow;

const DraggableWrapper = styled(CtrRow)`
  cursor: grabbing;
`;
