import React, { SetStateAction, useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useQuery, useMutation } from 'react-query';
import { getOrderDetail, getOrderList, getVehicleInfo, setOrderDetail } from 'api';
import { useForm, useFormState } from 'react-hook-form';

import { validateOrderStatus } from 'util/validateStatus';
import getDirtyValues from 'util/getDirtyValues';

import WorkTimePopup from 'components/Popup/WorkTime';
import DaumPostcodeEmbed from 'react-daum-postcode';
import DatePickerPopup from 'components/Popup/DatePicker';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';

import Modal from '.';
import Divider from 'components/Divider';

import { Tag } from 'components/Tag';
import { Input } from 'components/Input';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import {
  DescriptionTable,
  DescriptionTd,
  DescriptionTh,
  OrderDetailModalBody,
  OrderDetailModalFooter,
  OrderDetailModalHeader,
} from './style';

import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as SpinLoader } from 'constants/icon/spin_loader.svg';
import { ReactComponent as IcArrowUp } from 'constants/icon/ic_btn_arrow_up.svg';

import dateConverter from 'util/dateConverter';
import { genLabel } from './SingleOrderForm';
import _ from 'lodash';
import { SkillPicker } from 'components/Popup/SkillPicker';
import { IGetOrderListResponses } from 'constants/types';
import { useStore } from 'store';
import { Link } from 'react-router-dom';
import theme from 'constants/theme';
import strings from '../../util/Localization';
import { Swap } from 'components/Swap';
import { shipment_type } from 'constants/commons';

const OrderDetailModal = ({
  target,
  isOpen,
  setIsOpen,
  noModi,
  bd,
  ds,
  nomt,
  sx,
  style,
}: {
  target: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  noModi?: boolean;
  ds?: 'weak' | 'normal' | 'strong';
  bd?: boolean;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
  nomt?: boolean;
}) => {
  const { getOrderListProps } = useStore();
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const { refetch: refetchOrderList } = useQuery<IGetOrderListResponses>(
    ['getOrderList', getOrderListProps],
    () => getOrderList(getOrderListProps),
    {
      keepPreviousData: true,
      refetchInterval: 300000,
      refetchOnWindowFocus: false,

      enabled: shouldRefetch,
    }
  );

  const {
    data: targetDetails,
    isSuccess: targetDetailsIsSuccess,
    isLoading: targetDetailsIsLoading,
    refetch: refetchTargetDetails,
    // } = useQuery(['targetDetails', target], () => getOrderDetail({ target: 28253 }), {
  } = useQuery(['targetDetails', target], () => getOrderDetail({ target: target }), {
    onSuccess: data => {
      data.note = RenderDefaultNote(data.note || []);
      data.service_time = Math.floor(data.service_time / 60);
      data.received_date = dayjs(data.received_date).format('YYMMDD');
    },
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const {
    mutate: mutateSetOrderDetail,
    isSuccess: mutateSetOrderDetailIsSuccess,
    isError: mutateSetOrderDetailIsError,
  } = useMutation(setOrderDetail, {
    onSuccess: async () => {
      await refetchTargetDetails();
      setShouldRefetch(true);
    },
    onError: () => methods.reset(),
    onSettled: () => setIsModi(false),
  });

  const {
    data: vehicles,
    isSuccess: getVehicleListIsSuccess,
    refetch: refetchVehicle,
  } = useQuery(['vehicles'], getVehicleInfo, {});

  const domain = window.localStorage.getItem('serviceDomain');

  const [closeConfirmModalIsOpen, setCloseConfirmModalIsOpen] = useState<boolean>(false);

  const [canModifying, setCanModifying] = useState<boolean>(false);
  const [isModi, setIsModi] = useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = useState<boolean>(false);

  const [workTimePopupIsOpen, setWorkTimePopupIsOpen] = useState<boolean>(false);
  const [startTimePickerPopupIsOpen, setStartTimePickerPopupIsOpen] = useState<boolean>(false);
  const [endTimePickerPopupIsOpen, setEndTimePickerPopupIsOpen] = useState<boolean>(false);
  const [desiredDatePopupIsOpen, setDesiredDatePopupIsOpen] = useState<boolean>(false);
  const [receivedDatePopupIsOpen, setReceivedDatePopupIsOpen] = useState<boolean>(false);
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [skillSearchValue, setSkillSearchValue] = useState<string>('');

  const [modifyingFormIsFold, setModifyingFormIsFold] = useState<{
    consignee: boolean;
    task: boolean;
    product: boolean;
    notes: boolean;
  }>({
    consignee: false,
    task: false,
    product: false,
    notes: Boolean(targetDetails?.note?.at(0)) ? false : true,
  });

  const serviceTimeOnCahnge = useCallback(
    _.debounce(function (e) {
      if (e.target.value !== '' && /[0-9]/g.test(e.target.value))
        methods.setValue('serviceTime', e.target.value.replaceAll(/\D/g, '').concat('분'), {
          shouldDirty: true,
          shouldValidate: true,
        });
      else
        methods.setValue('serviceTime', '', {
          shouldDirty: true,
          shouldValidate: true,
        });
    }, 500),
    []
  );

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const { handleSubmit, control } = methods;
  const { dirtyFields } = useFormState({
    control,
  });

  useEffect(() => {
    setShouldRefetch(false);
    setSkillSearchValue('');
    setIsModi(false);
    methods.reset();

    if (shouldRefetch) {
      refetchOrderList();
    }
  }, [isOpen, methods, refetchOrderList, shouldRefetch]);

  useEffect(() => {
    methods.setValue(
      'receivedDate',
      targetDetails?.received_date && dateConverter(targetDetails.received_date, 'YYYY.MM.DD'),
      {
        shouldValidate: true,
      }
    );
    methods.setValue('consigneeName', targetDetails?.consignee_name, { shouldValidate: true });
    methods.setValue(
      'consigneePhone',
      targetDetails?.consignee_phone?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/(\-{1,2})$/g, ''),
      { shouldValidate: true }
    );
    methods.setValue('consigneeNote', targetDetails?.consignee_note, { shouldValidate: true });
    methods.setValue('address', targetDetails?.address, { shouldValidate: true });
    methods.setValue('detailAddress', targetDetails?.detail_address, { shouldValidate: true });

    methods.setValue(
      'desiredDate',
      targetDetails?.desired_date && dateConverter(targetDetails.desired_date, 'YYYY.MM.DD'),
      {
        shouldValidate: true,
      }
    );
    methods.setValue('desiredTimeStart', targetDetails?.desired_time_start, { shouldValidate: true });
    methods.setValue('desiredTimeEnd', targetDetails?.desired_time_end, { shouldValidate: true });
    methods.setValue('serviceTime', `${targetDetails?.service_time}분`, { shouldValidate: true });
    methods.setValue('skill', targetDetails?.skill, { shouldValidate: true });

    methods.setValue('product', targetDetails?.product, { shouldValidate: true });
    methods.setValue('note', targetDetails?.note, {
      shouldValidate: true,
    });

    methods.setValue('capacity', targetDetails?.capacity && targetDetails.capacity, {
      shouldValidate: true,
    });

    methods.setValue('driver_name', targetDetails?.route?.driverName, { shouldValidate: true });
    methods.setValue('completed_at', targetDetails?.completed_at, { shouldValidate: true });

    methods.setValue('shipmentType', targetDetails?.shipment_type, { shouldValidate: true });

    setSkillSearchValue(targetDetails?.skill || '');

    setCanModifying(targetDetails?.status === 'unassigned');
    setModifyingFormIsFold(prev => {
      return {
        ...prev,
        notes: Boolean(targetDetails?.note?.at(0)) ? false : true,
      };
    });
  }, [targetDetails, mutateSetOrderDetailIsError, isModi]);

  const [product, setProduct] = React.useState<Array<any>>([]);

  useEffect(() => {
    targetDetails && setProduct(targetDetails?.product);
  }, [targetDetails, targetDetailsIsSuccess, isOpen]);

  React.useEffect(() => {
    methods.setValue(`product[${product.length - 1}].quantity`, 1, {
      shouldValidate: true,
    });
  }, [product, methods]);

  if (targetDetailsIsSuccess) {
    const {
      order_id,
      received_date,
      consignee_name,
      consignee_phone,
      consignee_note,
      address,
      detail_address,
      skill,
      service_time,
      desired_date,
      desired_time_start,
      desired_time_end,
      note,
      status,
      route,
      capacity,
      completed_at,
      shipment_type: shipmentType,
    } = targetDetails;

    const { key: statusKey, name: statusName } = validateOrderStatus(
      status as 'unassigned' | 'scheduled' | 'processing' | 'arrived' | 'completed' | 'skipped' | 'canceled' | 'deleted'
    );

    const isModifying = () => {
      return Object.keys(dirtyFields).length !== 0;
    };

    const handlePostcodeComplete = (data: any) => {
      let address = data.address || data.jibunAddress;
      methods.setValue('address', address, { shouldValidate: true, shouldDirty: true });
      setAddressPopupIsOpen(false);
    };

    const ModiOnSubmit = (data: any) => {
      let res: any = getDirtyValues(dirtyFields, data);

      console.log(res);

      if (res?.consigneePhone) res.consigneePhone = res?.consigneePhone?.replace(/[^0-9]/g, '');
      if (res?.receivedDate) res.receivedDate = dateConverter(res.receivedDate, 'YYYY-MM-DD');
      if (res?.desiredDate) res.desiredDate = dateConverter(res.desiredDate, 'YYYY-MM-DD');
      if (res?.serviceTime) {
        res.serviceTime = parseInt(res.serviceTime.replace(/[^0-9]/g, '')) * 60;
      }
      if (res?.service_Time === '') res.service_Time = 0;
      if (res?.desiredTimeStart) {
        let key = res.desiredTimeStart?.split(' ')[0];
        let value = res.desiredTimeStart?.split(' ')[1];
        res.desiredTimeStart =
          key === '익일오전' ? `${parseInt(value.split(':')[0]) + 24}:${value.split(':')[1]}` : value;
      }
      if (res?.desiredTimeEnd) {
        let key = res.desiredTimeEnd?.split(' ')[0];
        let value = res.desiredTimeEnd?.split(' ')[1];
        res.desiredTimeEnd =
          key === '익일오전' ? `${parseInt(value.split(':')[0]) + 24}:${value.split(':')[1]}` : value;
      }

      if (
        (res.desiredTimeStart || methods.getValues('desiredTimeStart')) &&
        (res.desiredTimeEnd || methods.getValues('desiredTimeEnd'))
      ) {
        let start = dayjs(
          new Date(
            0,
            0,
            0,
            Number((res.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[0]),
            Number((res.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[1]),
            0
          )
        ).format('YYYY/MM/DD HH:mm');

        let end = dayjs(
          new Date(
            0,
            0,
            0,
            Number((res.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[0]),
            Number((res.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[1]),
            0
          )
        ).format('YYYY/MM/DD HH:mm');
        if (dayjs(start).isAfter(end)) {
          return methods.setError(
            'desiredTimeStart',
            {
              type: 'invalidDateRange',
              message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
            },
            { shouldFocus: true }
          );
        }
      }
      // RT-577/RT-617
      res?.product?.forEach((d: any) => {
        delete d.product_id;
      });

      if (!(Object.keys(res).length === 0)) {
        mutateSetOrderDetail({ target: target, ...res });
      }
      methods.reset({ dirtyFields: true });
      setIsModi(false);
    };

    return (
      <Modal
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        plain
        title={strings.모달_주문 + '상세 정보'}
        width={isModi ? 496 : 646}
        height={'80vh'}
        {...{ bd, ds, nomt, sx, style }}
      >
        <React.Fragment>
          <OrderDetailModalHeader>
            <Stack direction="row" justify="space-between">
              <Text styleName="subheadline2" color="RG03">
                {strings.주문 + ' 상세 정보'}
              </Text>
              <IconClose
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </Stack>

            <Stack name="info-area" direction="row" justify="space-between" align="center">
              {!isModi ? (
                <Stack direction="row" spacing={10} divider={<Divider vertical />} sx={{ height: '26px' }}>
                  <Stack spacing={8} direction="row" sx={{ width: 'max-content' }}>
                    <Text styleName="caption2" color="RG03">
                      {strings.주문상태}
                    </Text>
                    <Tag
                      status={statusKey as 'done' | 'moving' | 'todo' | 'cancel' | 'preparing' | 'purple'}
                      styleName="caption1"
                    >
                      {statusName}
                    </Tag>
                  </Stack>

                  <Stack spacing={8} direction="row" sx={{ width: 'max-content' }}>
                    <Text styleName="caption2" color="RG03">
                      주문 접수일
                    </Text>
                    <Text styleName="caption2" color="RG02">
                      {received_date}
                    </Text>
                  </Stack>

                  <Text styleName="caption2" color="RG04">
                    {strings.주문 + 'ID : ' + order_id}
                  </Text>
                </Stack>
              ) : (
                <Stack direction="row" justify="space-between" divider={<Divider vertical />} sx={{ height: '26px' }}>
                  <Stack spacing={8} direction="row" sx={{ width: 'max-content' }}>
                    <Text styleName="caption2" color="RG03">
                      {strings.주문상태}
                    </Text>
                    <Tag
                      status={statusKey as 'done' | 'moving' | 'todo' | 'cancel' | 'preparing' | 'purple'}
                      styleName="caption1"
                    >
                      {statusName}
                    </Tag>
                  </Stack>

                  <Text styleName="caption2" color="RG04">
                    {strings.주문 + 'ID : ' + order_id}
                  </Text>
                </Stack>
              )}
              {/* <Button
                styleName="caption1"
                variant="default"
                type="submit"
                color="RG00"
                width={124}
                height={32}
                onClick={handleSubmit(ModiOnSubmit)}
              >
                수정 완료
              </Button> */}
            </Stack>
          </OrderDetailModalHeader>
          <Divider color="RG06" />
          <OrderDetailModalBody useVerticalScroll>
            <Stack
              sx={{
                maxHeight: 686,
              }}
            >
              {!isModi ? (
                <Stack padding={24} spacing={24} name="content-area">
                  <DescriptionTable>
                    <caption>
                      <Stack align="start">
                        <Text styleName="body2" color="RG02">
                          고객 정보
                        </Text>
                      </Stack>
                    </caption>
                    <tbody>
                      <tr>
                        <DescriptionTh>{strings.고객명}</DescriptionTh>
                        <DescriptionTd>{consignee_name}</DescriptionTd>
                        <DescriptionTh>연락처</DescriptionTh>
                        <DescriptionTd>
                          {consignee_phone
                            ? consignee_phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1 - $2 - $3`)
                            : '-'}
                        </DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh>주소</DescriptionTh>
                        <DescriptionTd colSpan={3}>{address}</DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh>상세주소</DescriptionTh>
                        <DescriptionTd colSpan={3}>{detail_address ? detail_address : '-'}</DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh>{strings.고객전달사항}</DescriptionTh>
                        <DescriptionTd colSpan={3}>{consignee_note ? consignee_note : '없음'}</DescriptionTd>
                      </tr>
                    </tbody>
                  </DescriptionTable>
                  <DescriptionTable>
                    <caption>
                      <Stack align="start">
                        <Text styleName="body2" color="RG02">
                          {strings.주문정보}
                        </Text>
                      </Stack>
                    </caption>
                    <tbody>
                      {domain === 'roouty' ? (
                        <tr key={`$table-row-sum-product`}>
                          <DescriptionTh colSpan={150}>주문 총 용적량</DescriptionTh>
                          <DescriptionTd colSpan={150}>{capacity ? capacity : '-'}</DescriptionTd>
                          <DescriptionTh colSpan={80}>{strings.주문} 유형</DescriptionTh>
                          <DescriptionTd colSpan={220}>
                            {shipment_type.find(d => d.value === shipmentType)?.label}
                          </DescriptionTd>
                        </tr>
                      ) : (
                        <tr key={`$table-row-sum-product`}>
                          <DescriptionTh colSpan={150}>{strings.주문} 유형</DescriptionTh>
                          <DescriptionTd colSpan={450}>
                            {shipment_type.find(d => d.value === shipmentType)?.label}
                          </DescriptionTd>
                        </tr>
                      )}

                      {product.map((d, i) => {
                        if (domain === 'roouty')
                          return (
                            <React.Fragment key={`${i}-table-row-${d.name ?? 'product' + i}`}>
                              <tr>
                                <DescriptionTh colSpan={150}>{strings.상품명}</DescriptionTh>
                                <DescriptionTd colSpan={450} elipsis>
                                  {d.name ? d.name : '-'}
                                </DescriptionTd>
                              </tr>
                              <tr key={`${i}-table-row-${d.name ?? 'product' + i}`}>
                                <DescriptionTh colSpan={150}>{strings.상품코드}</DescriptionTh>
                                <DescriptionTd colSpan={150}>{d.code ? d.code : '-'}</DescriptionTd>
                                <DescriptionTh colSpan={150}>수량</DescriptionTh>
                                <DescriptionTd colSpan={150}>{d.quantity}</DescriptionTd>
                              </tr>
                            </React.Fragment>
                          );
                        else
                          return (
                            <React.Fragment>
                              <tr key={`${i}-table-row-${d.name ?? 'product' + i}`}>
                                <DescriptionTh colSpan={150}>{strings.상품명}</DescriptionTh>
                                <DescriptionTd colSpan={150}>{d.name ? d.name : '-'}</DescriptionTd>
                                <DescriptionTh colSpan={150}>{strings.상품코드}</DescriptionTh>
                                <DescriptionTd colSpan={150}>{d.code ? d.code : '-'}</DescriptionTd>
                              </tr>
                            </React.Fragment>
                          );
                      })}
                    </tbody>
                  </DescriptionTable>
                  <DescriptionTable>
                    <caption>
                      <Stack align="start">
                        <Text styleName="body2" color="RG02">
                          {strings.배차정보}
                        </Text>
                      </Stack>
                    </caption>
                    <tbody>
                      <tr>
                        <DescriptionTh>{strings.작업희망일}</DescriptionTh>
                        <DescriptionTd colSpan={3}>
                          {desired_date ? dateConverter(desired_date, 'YYYY.MM.DD') : '-'}
                        </DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh>{strings.희망시간이후}</DescriptionTh>
                        <DescriptionTd>{desired_time_start ? desired_time_start : '-'}</DescriptionTd>
                        <DescriptionTh>{strings.희망시간이전}</DescriptionTh>
                        <DescriptionTd>{desired_time_end ? desired_time_end : '-'}</DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh>{strings.작업소요시간분}</DescriptionTh>
                        <DescriptionTd>{service_time}분</DescriptionTd>
                        <DescriptionTh>{strings.특정차량지정}</DescriptionTh>
                        <DescriptionTd>{skill ? skill : '-'}</DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh>{strings.모달_드라이버}</DescriptionTh>
                        <DescriptionTd disabled>{route ? route.driverName : '-'}</DescriptionTd>
                        <DescriptionTh>{strings.완료일}</DescriptionTh>
                        <DescriptionTd disabled>
                          {completed_at ? dayjs(completed_at).format('YYYY.MM.DD') : '-'}
                        </DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh>{strings.배차} 상세 정보</DescriptionTh>
                        <DescriptionTd colSpan={3}>
                          {route ? route?.routeName + ' ' : ''}
                          <Link
                            // to={route ? `/manage/route/history/${route.routeId}` : ''}
                            // state={route && { driverId: route.driverId, orderId: order_id }}

                            to={route ? `/manage/control` : ''}
                            state={
                              route && {
                                driverId: route.driverId,
                                orderId: order_id,
                                routeId: route.routeId,
                                targetPerformedDate: route.performedDate,
                              }
                            }
                            style={{
                              color: '#406EE2',
                              textDecoration: 'underline',
                              cursor: !route ? 'not-allowed' : 'pointer',
                            }}
                          >
                            {'보러 가기'}
                          </Link>
                        </DescriptionTd>
                      </tr>
                    </tbody>
                  </DescriptionTable>
                  <DescriptionTable>
                    <caption>
                      <Stack align="start">
                        <Text styleName="body2" color="RG02">
                          기타 정보
                        </Text>
                      </Stack>
                    </caption>
                    <tbody>
                      {note?.map((d: string, i: any) => {
                        return (
                          <tr key={`note[${i}]`}>
                            <DescriptionTh>비고{i + 1}</DescriptionTh>
                            <DescriptionTd colSpan={3}>{d !== '' ? d : '-'}</DescriptionTd>
                          </tr>
                        );
                      })}
                    </tbody>
                  </DescriptionTable>
                </Stack>
              ) : (
                <Stack>
                  <Stack name="content-area">
                    <DetailModiForm title="고객 정보" isFold={modifyingFormIsFold.consignee}>
                      <Stack sx={{ position: 'relative' }}>
                        {receivedDatePopupIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setReceivedDatePopupIsOpen}
                            setValue={methods.setValue}
                            initDate={dayjs().toString()}
                            name={'receivedDate'}
                            format="YYYY.MM.DD"
                            top="80px"
                          />
                        )}
                        <Input
                          label={genLabel({ text: strings.주문접수일 })}
                          name="receivedDate"
                          placeholder={`${dayjs().format('YYYY.MM.DD')}`}
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          readOnly={true}
                          onClick={() => {
                            setReceivedDatePopupIsOpen(true);
                          }}
                          validation={{ required: { value: true, message: '해당 필드는 필수값입니다.' } }}
                          errors={methods.formState?.errors?.receivedDate}
                          hasError={methods.formState?.errors?.receivedDate}
                        />
                      </Stack>
                      <Stack direction="row" spacing={16}>
                        <Input
                          label={genLabel({ text: strings.고객명, isRequired: true })}
                          name="consigneeName"
                          placeholder="예시) 홍길동"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{ required: true }}
                          hasError={methods.formState?.errors?.consigneeName}
                        />
                        <Input
                          name="consigneePhone"
                          label={genLabel({ text: '연락처', isRequired: !(domain === 'survey') })}
                          placeholder="000 - 0000 - 0000"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{
                            required: !(domain === 'survey'),
                            pattern: {
                              value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
                              message: '휴대폰 형식에 맞춰주세요.',
                            },
                            maxLength: {
                              value: 13,
                              message: '휴대폰 형식에 맞춰주세요.',
                            },
                          }}
                          hasError={methods.formState?.errors?.consigneePhone}
                          errors={methods.formState?.errors?.consigneePhone}
                        />
                      </Stack>
                      <Input
                        name="address"
                        label={genLabel({ text: '주소', isRequired: true })}
                        placeholder="받는 분 주소를 입력하세요"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                        validation={{ required: true }}
                        readOnly={true}
                        onFocus={() => {
                          methods.clearErrors('address');
                          setAddressPopupIsOpen(true);
                        }}
                        onClick={() => {
                          setAddressPopupIsOpen(true);
                        }}
                        hasError={methods.formState?.errors?.address}
                      />
                      <Input
                        name="detailAddress"
                        label={genLabel({ text: '상세 주소' })}
                        placeholder="예시) 000동 000호"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                      />
                      <Input
                        name="consigneeNote"
                        label={genLabel({ text: strings.고객전달사항 })}
                        placeholder="예시) 문 앞에 전달"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                      />
                    </DetailModiForm>
                    <Divider color="RG06" />
                    <DetailModiForm title={strings.주문정보} isFold={modifyingFormIsFold.product}>
                      {domain === 'roouty' ? (
                        <Stack align="end">
                          <Stack>
                            <Stack direction="row" spacing={16} sx={{ padding: '0 0 24px' }}>
                              <Input
                                name="capacity"
                                label={genLabel({ text: '주문 총 용적량', isRequired: true })}
                                placeholder="1"
                                register={methods.register}
                                watch={methods.watch}
                                reset={methods.reset}
                                field
                                type="text"
                                height={40}
                                validation={{
                                  required: { value: true, message: '해당 필드는 필수값입니다.' },
                                  pattern: {
                                    value: /^[0-9]/,
                                    message: '숫자만 입력 가능합니다.',
                                  },
                                }}
                                sx={{ flex: 1 }}
                                errors={methods.formState?.errors?.capacity}
                                hasError={methods.formState?.errors?.capacity}
                              />
                              <Swap
                                name="shipmentType"
                                methods={methods}
                                label={genLabel({
                                  text: '주문 유형',
                                  subText: `${strings.주문} 1개당 배달  또는 수거만 설정 가능`,
                                })}
                                sx={{ flex: 2 }}
                                maxWidth={286}
                                defaultIndex={shipment_type.findIndex(d => d.value === shipmentType)}
                                options={shipment_type.filter(d => d.visibleType)}
                              />
                            </Stack>
                            <Divider color="RG06" style={{ width: `calc(100% + 48px)` }} />
                          </Stack>
                          <Stack divider={<Divider color="RG06" style={{ width: `calc(100% + 48px)` }} />}>
                            {product?.map((d, i) => {
                              return (
                                <Stack spacing={12} key={`${d.name}-${i}-product-setting`} sx={{ padding: '24px 0' }}>
                                  <Input
                                    name={`product[${i}].name`}
                                    label={genLabel({ text: strings.상품명 })}
                                    placeholder={strings.상품명 + '을 입력해주세요.'}
                                    register={methods.register}
                                    watch={methods.watch}
                                    reset={methods.reset}
                                    field
                                    height={40}
                                  />

                                  <Stack direction="row" spacing={16}>
                                    <Input
                                      name={`product[${i}].quantity`}
                                      label={genLabel({ text: '수량', isRequired: true })}
                                      placeholder="1"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      type="text"
                                      height={40}
                                      validation={{ required: true }}
                                      hasError={methods.formState?.errors?.quantity}
                                      sx={{ flex: 2 }}
                                    />
                                    <Input
                                      name={`product[${i}].code`}
                                      label={genLabel({ text: strings.상품코드 })}
                                      placeholder="예시) RT-123"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      height={40}
                                      sx={{ flex: 7 }}
                                    />
                                  </Stack>
                                </Stack>
                              );
                            })}
                          </Stack>
                          <Button
                            variant={'RTB'}
                            type={'button'}
                            styleName="caption2"
                            width={100}
                            height={26}
                            disabled={product.length >= 10}
                            onClick={() => setProduct(prev => [...prev].concat({ quantity: 1 }))}
                          >
                            + 아이템 추가
                          </Button>
                        </Stack>
                      ) : (
                        <Stack spacing={16} align="end">
                          {product?.map((d, i) => {
                            return (
                              <React.Fragment key={`${d.name}-${i}-product-setting`}>
                                <Stack direction="row" spacing={16} key={`${d.name}-${i}-product-setting`}>
                                  <Input
                                    name={`product[${i}].name`}
                                    label={genLabel({ text: strings.상품명 })}
                                    placeholder={strings.상품명 + '을 입력해주세요.'}
                                    register={methods.register}
                                    watch={methods.watch}
                                    reset={methods.reset}
                                    field
                                    height={40}
                                  />
                                  <Input
                                    name={`product[${i}].code`}
                                    label={genLabel({ text: strings.상품코드 })}
                                    placeholder="예시) RT-123"
                                    register={methods.register}
                                    watch={methods.watch}
                                    reset={methods.reset}
                                    field
                                    height={40}
                                  />
                                </Stack>
                              </React.Fragment>
                            );
                          })}
                        </Stack>
                      )}
                    </DetailModiForm>
                    <Divider color="RG06" />
                    <DetailModiForm title={strings.배차정보} isFold={modifyingFormIsFold.task}>
                      <Stack sx={{ position: 'relative', flex: 1 }}>
                        {desiredDatePopupIsOpen && (
                          <PopupYIndexFixer>
                            <DatePickerPopup
                              setIsOpen={setDesiredDatePopupIsOpen}
                              setValue={methods.setValue}
                              initDate={dayjs().toString()}
                              name={'desiredDate'}
                              banPast
                              top="80px"
                              format="YYYY.MM.DD"
                            />
                          </PopupYIndexFixer>
                        )}
                        <Input
                          name="desiredDate"
                          label={genLabel({ text: strings.작업희망일 })}
                          placeholder="YYYY.MM.DD"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          height={40}
                          readOnly={true}
                          onClick={() => {
                            setDesiredDatePopupIsOpen(true);
                          }}
                        />
                      </Stack>
                      <Stack spacing={6}>
                        <Stack spacing={16} direction={'row'}>
                          <Stack sx={{ position: 'relative', flex: 2 }}>
                            {startTimePickerPopupIsOpen && (
                              <PopupYIndexFixer>
                                <MiniTimePicker
                                  isOpen={startTimePickerPopupIsOpen}
                                  setIsOpen={setStartTimePickerPopupIsOpen}
                                  setValue={methods.setValue}
                                  name={'desiredTimeStart'}
                                  desired
                                />
                              </PopupYIndexFixer>
                            )}
                            <Input
                              label={genLabel({ text: strings.희망시간이후 })}
                              placeholder="00 : 00 (몇 시 부터)"
                              type="text"
                              name="desiredTimeStart"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              height={40}
                              readOnly={true}
                              onClick={() => {
                                setEndTimePickerPopupIsOpen(false);
                                setStartTimePickerPopupIsOpen(true);
                              }}
                              field
                              hasError={methods.formState.errors?.desiredTimeStart}
                            />
                          </Stack>
                          <Stack align="end" sx={{ position: 'relative', flex: 2 }}>
                            {endTimePickerPopupIsOpen && (
                              <PopupYIndexFixer>
                                <MiniTimePicker
                                  isOpen={endTimePickerPopupIsOpen}
                                  setIsOpen={setEndTimePickerPopupIsOpen}
                                  setValue={methods.setValue}
                                  name={'desiredTimeEnd'}
                                  rSide
                                  desired
                                />
                              </PopupYIndexFixer>
                            )}
                            <Input
                              label={genLabel({ text: strings.희망시간이전 })}
                              placeholder="00 : 00 (몇 시 까지)"
                              type="text"
                              name="desiredTimeEnd"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              height={40}
                              readOnly={true}
                              onClick={() => {
                                setStartTimePickerPopupIsOpen(false);
                                setEndTimePickerPopupIsOpen(true);
                              }}
                              field
                              hasError={methods.formState.errors?.desiredTimeStart}
                            />
                          </Stack>
                        </Stack>
                        {methods.formState.errors?.desiredTimeStart && (
                          <Text styleName="caption3" color="RC04">
                            {methods.formState.errors?.desiredTimeStart.message}
                          </Text>
                        )}
                      </Stack>

                      <Stack direction="row" spacing={16} align={'end'} sx={{ position: 'relative' }}>
                        <Input
                          name="serviceTime"
                          label={genLabel({ text: strings.작업소요시간분 })}
                          placeholder="숫자만 입력"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          height={40}
                          onChange={(e: any) => {
                            setWorkTimePopupIsOpen(false);
                            serviceTimeOnCahnge(e);
                          }}
                          onClick={() => {
                            setWorkTimePopupIsOpen(true);
                          }}
                        />
                        <Stack sx={{ position: 'relative' }}>
                          <Input
                            name="skill"
                            label={genLabel({ text: strings.특정차량지정 })}
                            placeholder={`${strings.특정차량} 지정`}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            height={40}
                            field
                            validation={{
                              validate: (res: any) =>
                                !Boolean(res) ||
                                (vehicles && vehicles.vehicleList.filter(d => d.licenseNumber === res).length > 0) ||
                                '존재하지 않는 차량입니다.',
                            }}
                            onChange={(e: any) => {
                              setSkillPopupIsOpen(true);
                              setSkillSearchValue(e.target.value);
                              methods.setValue('skill', e.target.value, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                            onClick={() => {
                              setSkillPopupIsOpen(true);
                            }}
                            hasError={methods.formState.errors?.skill}
                            // errors={methods.formState.errors?.skill}
                          />
                          {skillPopupIsOpen && (
                            <SkillPicker
                              setIsOpen={setSkillPopupIsOpen}
                              methods={methods}
                              name={'skill'}
                              searchValue={skillSearchValue}
                            />
                          )}
                        </Stack>

                        {workTimePopupIsOpen && (
                          <WorkTimePopup
                            setIsOpen={setWorkTimePopupIsOpen}
                            setValue={methods.setValue}
                            name={'serviceTime'}
                          />
                        )}
                      </Stack>
                      {methods.formState.errors?.skill && (
                        <Stack align="end">
                          <Text styleName="caption3" color="RC04">
                            {methods.formState.errors?.skill.message ?? ''}
                          </Text>
                        </Stack>
                      )}

                      <Stack direction="row" spacing={16}>
                        <Input
                          name="driver_name"
                          label={genLabel({ text: strings.모달_드라이버 })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                        <Input
                          name="completed_at"
                          label={genLabel({ text: strings.완료일 })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                      </Stack>

                      <Stack spacing={8} align="start">
                        <Text styleName="caption2" color="RG03">
                          {strings.배차} 상세 정보
                        </Text>
                        {route ? route?.routeName + ' ' : ''}
                        <Link
                          to={route ? `/manage/route/history/${route.routeId}` : ''}
                          state={route && { driverId: route.driverId, orderId: order_id }}
                          style={{
                            letterSpacing: '-0.02em',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '20px',
                            textAlign: 'start',

                            color: '#406EE2',
                            textDecoration: 'underline',
                            cursor: !route ? 'not-allowed' : 'pointer',

                            backgroundColor: theme.colors.RG07,
                            borderRadius: '8px',
                            border: `1px solid ${theme.colors.RG07}`,
                            padding: '10px',

                            width: '100%',
                            height: '40px',

                            boxSizing: 'border-box',
                          }}
                        >
                          보러 가기
                        </Link>
                      </Stack>
                    </DetailModiForm>
                    <Divider color="RG06" />
                    <DetailModiForm title="기타 정보" isFold={modifyingFormIsFold.notes}>
                      {methods.getValues('note')?.map((d: any, index: any) => {
                        return (
                          <Input
                            key={`note[${index}]`}
                            name={`note[${index}]`}
                            label={genLabel({ text: `비고${index + 1}` })}
                            placeholder={'-'}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            height={40}
                          />
                        );
                      })}
                    </DetailModiForm>
                    <Divider color="RG06" />
                  </Stack>

                  <Modal
                    isModalOpen={addressPopupIsOpen}
                    setIsModalOpen={setAddressPopupIsOpen}
                    title={'우편번호찾기'}
                    width={532}
                    ms={30}
                    padding={20}
                  >
                    <DaumPostcodeEmbed
                      onComplete={handlePostcodeComplete}
                      style={{ width: 'calc(100% + 40px)', height: '500px', marginBottom: '-20px' }}
                    />
                  </Modal>
                  <CloseConfirmModal
                    isOpen={closeConfirmModalIsOpen}
                    setIsOpen={setCloseConfirmModalIsOpen}
                    targetSetIsOpen={setIsOpen}
                    text={
                      <p>
                        수정 사항이 저장되지 않았습니다.
                        <br />
                        수정하기을 종료하시겠습니까?
                      </p>
                    }
                    ms={17}
                    RBT={'이어서 수정하기'}
                    LBT={'종료'}
                  />
                </Stack>
              )}
            </Stack>
          </OrderDetailModalBody>
          <Divider color="RG06" />
          <OrderDetailModalFooter>
            <Button
              variant="fourth"
              type="button"
              height={42}
              width={124}
              styleName="body2"
              color="RG04"
              onClick={() => {
                if (isModifying()) setCloseConfirmModalIsOpen(true);
                else setIsOpen(false);
              }}
            >
              닫기
            </Button>
            <Button
              variant="default"
              type="submit"
              height={42}
              width={222}
              styleName="body2"
              color="RG00"
              disabled={noModi || !canModifying}
              onClick={
                isModifying()
                  ? handleSubmit(ModiOnSubmit)
                  : () => {
                      setIsModi(prev => !prev);
                    }
              }
            >
              {!isModi ? '수정하기' : '수정완료'}
            </Button>
          </OrderDetailModalFooter>
        </React.Fragment>
      </Modal>
    );
  } else
    return (
      <Modal
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        title={strings.모달_주문 + '상세 정보'}
        ms={36}
        padding={24}
        width={isModi ? 978 : 646}
        height={'80vh'}
        bd={bd}
        sx={sx}
      >
        <Stack spacing={30}>
          <SpinLoader style={{ width: '44px', height: '44px' }} className="infinite_rotating" />
          <Text styleName="subheadline3" color="RG03">
            데이터를 불러오고 있습니다...
          </Text>
          <OrderDetailModalFooter>
            <Button
              variant="fourth"
              type="button"
              height={32}
              width={84}
              styleName="caption1"
              color="RG04"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              닫기
            </Button>
          </OrderDetailModalFooter>
        </Stack>
      </Modal>
    );
};

export default OrderDetailModal;

export const CloseConfirmModal = ({
  isOpen,
  setIsOpen,
  targetSetIsOpen,
  text,
  RBT,
  LBT,
  ms,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  targetSetIsOpen: React.Dispatch<SetStateAction<boolean>>;
  text: string | React.ReactNode;
  RBT: string;
  LBT: string;
  ms?: number;
}) => {
  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} width={486} padding={24} ms={ms ? ms : 5}>
      <Stack name="delete-vehicle-modal">
        <Stack name="content-area" spacing={16} sx={{ padding: '30px 0 40px' }}>
          <Text styleName="subheadline3" color="RG03">
            {text}
          </Text>
        </Stack>

        <Stack direction="row" spacing={16}>
          <Button
            variant={'second'}
            type={'button'}
            fullWidth
            sx={{ padding: '8px 10px' }}
            onClick={() => {
              setIsOpen(false);
              targetSetIsOpen(false);
            }}
          >
            <Text styleName="body2" color="RG04">
              {LBT ? LBT : '취소'}
            </Text>
          </Button>
          <Button
            variant={'default'}
            type={'button'}
            fullWidth
            sx={{ padding: '8px 10px' }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Text styleName="body2" color="RG00">
              {RBT}
            </Text>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export const DetailModiForm = ({
  title,
  spacing,
  children,
  isFold,
}: {
  title: string;
  spacing?: number;
  children: React.ReactNode;
  isFold?: boolean;
}) => {
  const [modifyingFormIsFold, setModifyingFormIsFold] = useState<boolean>(isFold ?? false);

  return (
    <Stack padding={24} align={'start'}>
      <Stack
        onClick={() => {
          setModifyingFormIsFold(prev => !prev);
        }}
        direction="row"
        justify="space-between"
        align="start"
        sx={{ cursor: 'pointer' }}
        // sx={{ cursor: typeof isFold === 'boolean' ? 'pointer' : 'auto' }}
      >
        <Text styleName="body2" color="RG03">
          {title}
        </Text>
        <IcArrowUp style={{ transform: modifyingFormIsFold ? 'rotate(1turn)' : 'rotate(0.5turn)' }} />
      </Stack>
      <Stack
        spacing={spacing ?? 16}
        sx={{
          marginTop: modifyingFormIsFold ? 0 : spacing ?? 24,
          maxHeight: modifyingFormIsFold ? '0px' : '100vh',
          minHeight: 'fit-content',
          transition: 'all .3s ease-out',
        }}
      >
        {!modifyingFormIsFold ? children : <div style={{ height: '300px', flex: 1 }} />}
      </Stack>
    </Stack>
  );
};

const RenderDefaultNote = (note: string[]) => {
  if (note && note.length < 5) {
    let dump = note;
    for (let index = note.length; index < 5; index++) {
      dump.push('');
    }

    return dump;
  } else return note;
};
