import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';

import * as S from './style';

import useMediaQuery from 'hooks/useMediaQuery';

import GradeSelector from 'util/GradeSelector';
import MyPagePopup from 'components/Popup/MyPagePopup';

import { Text } from '../Typography';
import { ReactComponent as Logo } from 'roouty_logo_white.svg';
import { ReactComponent as IcProfile } from 'constants/icon/ic_profile.svg';
import { ReactComponent as Ic_dropdown } from 'constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as Ic_Help } from 'constants/icon/ic_help.svg';

import { ReactComponent as Ic_notice } from 'constants/icon/ic_notice.svg';
import { ReactComponent as Ic_setting } from 'constants/icon/ic_setting.svg';

import strings from 'util/Localization';
import { useStore } from 'store';
import { commonUris } from 'constants/uris';

const GNB = () => {
  const location = useLocation();
  const { userGrade } = useStore();

  const GNBContent: {
    routes: Array<{ path: string; title: string; disabled?: boolean }>;
    iconRoutes: Array<{
      icon: any;
      path?: string;
      external?: boolean;
      target?: '_blank' | '_self' | '_parent' | '_top';
    }>;
  } = useMemo(() => {
    return {
      routes: [
        { path: 'manage/order', title: strings.주문관리 },
        { path: 'manage/route', title: strings.배차관리 },
        { path: 'manage/control', title: '실시간 관제' },
        { path: 'manage/report', title: '보고서' },
      ],
      iconRoutes: [
        { icon: Ic_notice },
        {
          icon: Ic_Help,
          external: true,
          target: '_blank',
          path: commonUris.FAQ,
        },
        {
          icon: Ic_setting,
          path: 'setting',
        },
      ],
    };
  }, []);
  const { routes, iconRoutes } = GNBContent;

  let navActiveStyle = {
    backgroundColor: 'rgba(118, 157, 255, 0.3)',

    padding: '4px 10px',
    borderRadius: '6px',
    minWidth: 'fit-content',
  };
  let navDefaultStyle = {
    padding: '4px 10px',
    borderRadius: '6px',
    minWidth: 'fit-content',
  };

  const handleDisabledNav = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    alert('해당 페이지는 준비중입니다.');
  };

  const matches = useMediaQuery('(max-width: 1024px)');
  const [myPagePopupIsOpen, setMyPagePopupIsOpen] = useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current?.contains(e.target) && !parentRef.current?.contains(e.target)) setMyPagePopupIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <>
      <S.Header>
        <S.LogoArea>
          <Link to={'/'}>
            <Logo />
          </Link>
        </S.LogoArea>
        <S.NavArea spacing={16} justify={'center'} direction="row" sx={{ textAlign: 'center' }}>
          {routes.map((d, i) => {
            return (
              <NavLink
                key={`gnb_${i}`}
                to={d.path}
                style={({ isActive }) => (isActive ? navActiveStyle : navDefaultStyle)}
                onClick={event => {
                  d.disabled && handleDisabledNav(event);
                }}
              >
                <Text styleName={'caption2'} color={'RG00'}>
                  {d.title}
                </Text>
              </NavLink>
            );
          })}
        </S.NavArea>
        <S.IConAreaWrapper>
          <S.IconArea spacing={20} align={'center'} direction="row" justify="end">
            {iconRoutes.map((d, i) =>
              d.path ? (
                d.external ? (
                  <a href={d.path} target={d.target} key={`link_${i}`}>
                    <d.icon
                      width={20}
                      height={20}
                      className={location.pathname.split('/')[1] === d.path ? 'isOnActive' : 'isInActive'}
                    />
                  </a>
                ) : (
                  <Link to={d.path} target={d.target} key={`link_${i}`}>
                    <d.icon
                      width={20}
                      height={20}
                      className={location.pathname.split('/')[1] === d.path ? 'isOnActive' : 'isInActive'}
                    />
                  </Link>
                )
              ) : (
                <d.icon key={`link_${i}`} style={{ cursor: 'not-allowed' }} />
              )
            )}
            <div ref={parentRef}>
              {matches ? (
                <IcProfile
                  width={20}
                  height={20}
                  className={myPagePopupIsOpen ? 'isOnActive' : 'isInActive'}
                  onClick={() => {
                    setMyPagePopupIsOpen(prev => !prev);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <S.ProfileArea
                  spacing={8}
                  direction={'row'}
                  isActive={myPagePopupIsOpen}
                  onClick={() => {
                    setMyPagePopupIsOpen(prev => !prev);
                  }}
                >
                  <S.ProfileIcon src={'https://via.placeholder.com/100'} />
                  <Text styleName={'caption3'} color={'RG00'}>
                    {userGrade < 2
                      ? window.localStorage.getItem('email')
                      : window.localStorage.getItem('name') || 'userid@wemeetmobility.com'}
                  </Text>
                  <Ic_dropdown />
                </S.ProfileArea>
              )}
            </div>
          </S.IconArea>
        </S.IConAreaWrapper>
      </S.Header>
      {myPagePopupIsOpen && <MyPagePopup {...{ setMyPagePopupIsOpen, ref: targetRef }} />}
      <S.PageContentWrapper style={{ position: 'relative' }}>
        <Outlet />

        {JSON.parse(process.env.REACT_APP_TB_ONLY as string) && <GradeSelector />}
      </S.PageContentWrapper>
    </>
  );
};

export default GNB;
