import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as S from './style';

export interface InputProps {
  name: string;
  label?: string | ReactNode;
  placeholder: string;

  validation?: object;
  sx?: React.CSSProperties;

  onClick?: () => void;

  height?: number;

  hasError?: boolean;
  errors: object;

  disabled?: boolean;
  rightArea?: string | React.ReactNode;
  field?: boolean;

  variant?: 'default' | 'second' | 'third' | 'fourth';
  xAxisPadding?: number;

  bottomLabel?: string | ReactNode;
}

export interface SelectProps {
  field?: boolean;
  height?: number;

  label?: string | React.ReactNode;
  rightArea?: string | React.ReactNode;
}

export const Input = ({
  reset,
  watch,
  validation,
  register,
  name,
  sx,
  label,
  rightArea,
  errors,
  bottomLabel,
  ...props
}: any): React.ReactElement => {
  return (
    <S.InputWrapper spacing={6} style={sx}>
      {(label || rightArea) && (
        <S.LabelArea {...{ rightArea, label }}>
          {label && <S.Label htmlFor={name}>{label}</S.Label>}
          {rightArea && <S.RightArea onClick={rightArea.rightFunc}>{rightArea.rightContent}</S.RightArea>}
        </S.LabelArea>
      )}
      <S.Input {...register(name, validation)} {...props} autoComplete="off" spellCheck="false" />

      {props?.hasError && errors?.message ? (
        <Text styleName="caption3" color="RC04">
          {errors?.message}
        </Text>
      ) : !props?.hasError && bottomLabel ? (
        <Text styleName="caption3" color="RG04">
          {bottomLabel}
        </Text>
      ) : (
        <></>
      )}
    </S.InputWrapper>
  );
};

export const Select = ({
  register,
  setValue,
  options,
  name,
  rightArea,
  label,
  defaultValue,
  selectBottomLabel,
  ...props
}: any): React.ReactElement => {
  const [key, setKey] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setKey(options.filter((d: { [key: string]: any }) => d.value === JSON.parse(defaultValue))[0].key);
    }
    return () => {};
  }, []);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef?.current?.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <S.InputWrapper>
      <S.LabelArea {...{ rightArea, label }}>
        {label && <S.Label htmlFor={name}>{label}</S.Label>}
        {rightArea && <S.RightArea onClick={rightArea.rightFunc}>{rightArea.rightContent}</S.RightArea>}
      </S.LabelArea>
      <Stack sx={{ width: '100%', position: 'relative' }}>
        <S.Input {...register(name)} {...props} style={{ display: 'none' }} />
        <S.Input
          {...props}
          readOnly
          value={key}
          onClick={() => {
            setIsOpen(prev => !prev);
          }}
        />
        {isOpen && (
          <PopupYIndexFixer>
            <SelectContainer ref={targetRef}>
              <Stack>
                {options.map((d: { value: string; key: string }) => (
                  <SelectItem
                    key={`${d.key}-select-item`}
                    onClick={() => {
                      setKey(d.key);
                      setValue(name, d.value, { shouldValidate: true, shouldDirty: true });
                      setIsOpen(false);
                    }}
                  >
                    <Stack spacing={8} direction="row-reverse">
                      <Text styleName={'caption3'} color={'RG03'}>
                        {d.key}
                      </Text>
                    </Stack>
                  </SelectItem>
                ))}
                {selectBottomLabel && <S.SelectBottomLabel>{selectBottomLabel}</S.SelectBottomLabel>}
              </Stack>
            </SelectContainer>
          </PopupYIndexFixer>
        )}
      </Stack>
    </S.InputWrapper>
  );
};

export const SelectContainer = styled.div<{ width?: string; top?: string; height?: string }>`
  display: flex;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  position: absolute;
  top: ${({ top }) => (top ? top : '50px')};
  z-index: 3;
  left: 0;

  width: ${({ width }) => (width ? width : '100%')};

  max-height: ${({ height }) => (height ? height : 'auto')};
  ${({ theme }) => theme.scrollStyle.vertical};
`;

export const SelectItem = styled.div`
  width: 100%;
  min-height: 32px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};
  }
`;
