import styled from 'styled-components';

export const ComplexDoubleviewDateselectorContainer = styled.div<{ top?: string; fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : '584px')};

  position: absolute;
  left: 0;
  top: ${({ top }) => (top ? top : '40px')};
  z-index: 3;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.RG00};
  box-shadow: ${({ theme }) => theme.shadows.strong};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
`;

export const Summary = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 202px;
  height: 32px;
  padding: 6px 10px;

  border-radius: 6px;
  color: ${({ theme }) => theme.colors.RG02};
  background: ${({ theme, hasError }) => (hasError ? theme.colors.RC04_1 : theme.colors.RC03_1)};

  ${({ theme }) => theme.fontStyle.caption2}

  & > input {
    width: 87px;
    padding: 6px 10px;

    background: none;
    text-align: center;
  }
`;
