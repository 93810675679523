import React, { useState } from 'react';

import { Stack } from 'components/Stack';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import { Form } from 'components/Form';
import { ILoginSubmitForm } from 'constants/types';
import { useMutation, useQuery } from 'react-query';
import { getMyInfo, getServiceDomain, login } from 'api';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';

import { ReactComponent as Banner } from 'constants/graphics/Frame_LoginPage.svg';
import styled from 'styled-components';
import { BoxContainer } from 'components/BoxContainer';
import PlainHeader from 'components/GNB/PlainHeader';
import { IErrorResponse } from 'constants/types';
import strings from 'util/Localization';

const LoginPage = () => {
  const DEFAULT_ERROR_MESSAGE = '아이디 또는 비밀번호를 잘못 입력했습니다.';
  const navigate = useNavigate();

  const { setUserGrade } = useStore();

  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);
  const [err, setErr] = useState(false);

  const { mutate, isLoading, isError, isSuccess } = useMutation(login, {
    onSuccess: res => {
      const { accessToken, refreshToken } = res;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    },
    onError: (err: any) => {
      setErr(true);
      const error = err?.response?.data?.error as IErrorResponse;
      if (error && error.name === 'Unauthorized') {
        const message = DEFAULT_ERROR_MESSAGE;
        setErrorMessage(message);
      } else if (error.advice) {
        const message = error.advice as String;
        setErrorMessage(message.replace(/.*Error: /, ''));
      }
    },
  });

  const {} = useQuery('serviceDomain', getServiceDomain, {
    refetchOnWindowFocus: false,
    enabled: isSuccess,
    onSuccess: res => {
      window.localStorage.setItem(`serviceDomain`, res.type);
      strings.setLanguage(res.type);
    },
  });

  const { isLoading: isLoadingMyInfo, data: fetchedMyInfo } = useQuery('getMyInfo', getMyInfo, {
    refetchOnWindowFocus: false,
    enabled: isSuccess,
    onSuccess: res => {
      (() => {
        for (const [key, value] of Object.entries(res)) {
          window.localStorage.setItem(`${key}`, value as string);
        }
      })();
      setUserGrade(res.roleId);

      navigate(`/`);
    },
  });

  const onSubmit = (data: ILoginSubmitForm) => {
    const restData = {
      ...data,
    };
    mutate(restData);
  };

  return (
    <PageWrapper name="page-wrapper">
      <PlainHeader />

      <BoxContainer bg="RC03_1" sx={{ margin: '0 0 60px 0' }}>
        <Banner viewBox="0 0 1024 220" preserveAspectRatio="xMidYMax slice" />
      </BoxContainer>
      <Container>
        <Stack name="contents-area">
          <Form onSubmit={onSubmit} defaultValues={null}>
            <Input
              label="아이디"
              placeholder="발급받은 로그인 아이디를 입력해주세요"
              type="text"
              sx={{ margin: '0 0 30px 0' }}
              name="loginAccount"
              validation={{ required: true }}
              onFocus={() => setErr(false)}
              hasError={err}
            />
            <Input
              label="비밀번호"
              placeholder="비밀번호를 입력해주세요 "
              type="password"
              name="password"
              validation={{ required: true }}
              onFocus={() => setErr(false)}
              hasError={err}
            />
            <Button
              variant="default"
              fullWidth
              height={48}
              sx={{ margin: '40px 0 16px 0' }}
              disabled={isDisabled}
              type="submit"
            >
              <Text styleName="body2" color={isDisabled ? 'RG04' : 'RG00'}>
                로그인
              </Text>
            </Button>

            <Button
              variant="fifth"
              fullWidth
              height={48}
              type="button"
              styleName="body2"
              onClick={() => {
                // FIXME RT-965
                // navigate('/signup');
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSey5EfpE6MNGvTl2g4txFvMp1IbAs4gWw1kJAxVMGe991G3uA/viewform',
                  '_blank'
                );
              }}
            >
              회원가입
            </Button>
          </Form>
          {err && isError && (
            <Stack sx={{ margin: '16px 0 0 0' }}>
              <Text styleName="caption3" color="RC04">
                {errorMessage}
              </Text>
              <Text styleName="caption3" color="RC04">
                입력하신 내용을 다시 확인해주세요.
              </Text>
            </Stack>
          )}

          <Stack sx={{ margin: '40px 0 30px 0', textAlign: 'center' }}>
            <Text styleName="caption3" color="RG04">
              루티에서 발급받은 계정으로 로그인해주세요.
              <br />
              로그인에 문제가 있는 경우 아래 이메일 문의바랍니다.
              <br />
              {/* RT-932 */}
              support@wemeetmobility.com
            </Text>
          </Stack>
        </Stack>
      </Container>
    </PageWrapper>
  );
};

export default LoginPage;

const PageWrapper = styled(Stack)`
  height: 100%;

  overflow-y: scroll;
`;

const Container = styled.div`
  width: 360px;
`;
