import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './constants/font/font.css';

import { init as initApm } from '@elastic/apm-rum';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const apiServerUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'roouty-admin-react',

  environment: process.env.REACT_APP_APM_STAGE,

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: process.env.REACT_APP_APM_SERVER_URL,

  // Set service version (required for sourcemap feature)
  // serviceVersion: '0.1.0',
  serviceVersion: process.env.REACT_APP_GIT_VERSION,

  // https://www.elastic.co/guide/en/apm/agent/rum-js/5.x/distributed-tracing-guide.html
  distributedTracingOrigins: [ apiServerUrl ]
});

console.log(`Service Version : ${process.env.REACT_APP_GIT_VERSION}`)

reportWebVitals();
