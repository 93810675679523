import { Stack } from 'components/Stack/style';
import styled, { css } from 'styled-components';
import { SwapProps } from '.';

interface ISwapnIndicator extends SwapProps {
  selectedIndex: number;
}

export const SwapnWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 6px;

  ${({ theme }) => ({
    border: `1px solid ${theme.colors.RG07}`,
    background: theme.colors.RG00,
  })}

  position: relative;
`;

export const SwapnItemsWrapper = styled.div<SwapProps>`
  width: 100%;
  display: flex;
  gap: 8px;
  padding: 7px;
  max-width: ${({ maxWidth }) => maxWidth || 540}px;
`;

export const SwapnItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme }) => ({
    ':hover': {
      backgroundColor: theme.colors.RG08,
    },
  })};

  border-radius: 4px;
  height: 26px;
  box-sizing: border-box;
  background-clip: content-box;

  transition: background 0.3s;
`;

export const SwapnIndicatorWrapper = styled.div<SwapProps>`
  pointer-events: none;
  display: flex;

  position: absolute;
  height: 40px;
  max-width: ${({ maxWidth }) => maxWidth || 540}px;
  width: 100%;
  padding: 7px;
`;

const setTranslateXPosition = (optionLength: number, index: number) => {
  if (index === 0) return `0%`;
  else return `calc(${100 * index}% + var(--padding) / ${optionLength - index})`;
};

export const SwapnIndicator = styled.div<ISwapnIndicator>`
  --padding: ${({ options, maxWidth }) =>
    `calc(min(100vw, ${maxWidth ?? 540}px) - (100% * ${options.length}) - (8px * ${options.length}))`};

  ${({ theme }) => ({
    backgroundColor: theme.colors.RC03_1,
  })};

  ${({ selectedIndex, options }) => ({
    // width: 100 / options.length + '%',
    width: `calc(${100 / options.length}%  - ${
      selectedIndex > 0 ? (selectedIndex + 1) * 2 : (selectedIndex + 1) * 4
    }px)`,

    // width: `calc(${100 / options.length}%  - ${(selectedIndex + 1) * 8}px)`,
    transform: `translateX(${setTranslateXPosition(options.length, selectedIndex)})`,
  })};

  border-radius: 4px;
  height: 26px;
  box-sizing: border-box;
  background-clip: content-box;

  transition: transform 0.3s;
`;

export const SwapContainer = styled(Stack)``;
