import React, { useEffect, useState } from 'react';

import { BoxContainer } from 'components/BoxContainer';
import { Highlight, Text } from '../components/Typography';
import { Stack } from '../components/Stack';
import Sidebar from 'components/SidebarLayout/Sidebar';
import PageContentWrapper from 'components/PageContentWrapper';
import styled from 'styled-components';
import RouteHistoryNav from 'components/SidebarLayout/RouteHistoryNav';
import { useMutation, useQuery } from 'react-query';
import { cancelRouteOrder, getRouteDetail } from 'api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IParams, IGetRouteDetailResponsesDriver } from 'constants/types';
import Divider from 'components/Divider';
import { Button } from 'components/Button';
import RouteHistoryDetailTable from 'components/Table/RouteHistoryDetailTable';
import { RouteHistoryMap } from '../components/MapContainer/RouteHistoryMap';

import { ReactComponent as IcBin } from '../constants/icon/ic_bin.svg';
import { ReactComponent as IcClose } from '../constants/icon/ic_close.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import dayjs from 'dayjs';
import strings from '../util/Localization';
import Modal from 'components/Modal';
import OrderDetailModal from 'components/Modal/OrderDetailModal';
import DeactiveModal from 'components/Modal/DeactiveModal';
import { getTotalReqTime } from 'util/getDriverSummary';

const RouteHistory = () => {
  let params = useParams<IParams>() as IParams;
  let routeId = params.id;
  let propStates = useLocation().state as { driverId: number; orderId: number };
  let navigate = useNavigate();
  const {
    data: routeDetail,
    isLoading: routeDetailIsLoading,
    isSuccess: routeDetailIsSuccess,
    refetch: refetchRouteDetail,
    failureCount,
  } = useQuery(['getRouteDetail', routeId], () => getRouteDetail(routeId), {
    enabled: Boolean(routeId),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    onSuccess: () => {
      setSelectedOrder([]);
    },
  });
  // FIXME : react query 내부에서 error catch가 안되는 버그 있음
  // RT-1003 / RT-1019
  useEffect(() => {
    if (failureCount > 0) navigate('/manage/route/history', { replace: true });
  }, [failureCount]);

  const { mutate: mutateCancelRouteOrder } = useMutation(cancelRouteOrder, {
    onSuccess: () => refetchRouteDetail(),
  });

  const [targetOrderId, setTargetOrderId] = useState<number>();
  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState<boolean>(false);

  const [target, setTarget] = useState(propStates ? propStates.driverId : routeDetail?.driverList[0].driverId);
  const [targetObject, setTargetObject] = useState<IGetRouteDetailResponsesDriver | null>(
    propStates &&
      (routeDetail?.driverList.filter(
        d => d.driverId ?? -1 === propStates.driverId
      )[0] as IGetRouteDetailResponsesDriver)
  );

  const [summaryInfo, setSummaryInfo] = useState<{
    routeStartTime: dayjs.Dayjs;
    totalReqTime: number;
    distance: string;
  }>({
    routeStartTime: dayjs(),
    totalReqTime: 0,
    distance: '0',
  });

  const [rowSelection, setRowSelection] = useState({});
  const [selectedOrder, setSelectedOrder] = React.useState<Array<any>>([]);
  const [delTargetOrders, setDelTargetOrders] = React.useState<Array<any>>([]);
  const [callbackModalOpen, setCallbackModalOpen] = React.useState<boolean>(false);

  const [driverTableIsOpen, setDriverTableIsOpen] = React.useState<boolean>(propStates ? true : false);
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = React.useState<boolean>(false);

  useEffect(() => {
    if (!driverTableIsOpen) {
      setTarget(undefined);
      setSelectedOrder([]);
    }
  }, [driverTableIsOpen]);

  useEffect(() => {
    setSelectedOrder([]);
  }, [target]);

  useEffect(() => {
    let res = selectedOrder.map(d => d.orderId).filter(Boolean);
    setDelTargetOrders(res);
  }, [selectedOrder]);

  useEffect(() => {
    routeDetail?.driverList
      .filter(d => d.driverId === target)
      .map(d => {
        const { routeStartTime, totalReqTime } = getTotalReqTime({
          performedDate: routeDetail?.performedDate,
          workStartTime: d.workStartTime,
          lastOrderETA: d.orderList.at(-1)?.route.eta ?? '00:00',
        });

        let distance = (
          d.orderList.map(d => d.route.requiredDistance ?? 0).reduce((prev, current) => prev + current, 0) / 1000
        ).toFixed(1);

        setSummaryInfo({
          routeStartTime,
          totalReqTime,
          distance,
        });

        setTargetObject(d);
      });
  }, [target, routeDetail]);

  return (
    <React.Fragment>
      <Stack align={'flex-start'} direction={'row'}>
        <Sidebar bg="RG08" width="288px" ds="strong" index={2} sx={{ minWidth: '288px' }} padding={'28px 0 0 0'}>
          <RouteHistoryNav {...{ driverTableIsOpen, setDriverTableIsOpen, target, setTarget }} />
        </Sidebar>

        <PageContentWrapper>
          <DriverStack
            align={'start'}
            sx={{
              maxWidth: `${driverTableIsOpen ? '881px' : '0px'}`,
              minWidth: `${driverTableIsOpen ? '400px' : '0px'}`,
              padding: `${driverTableIsOpen ? '18px 0 18px 18px' : '0px'}`,
              // position: 'relative',
            }}
          >
            <Stack align="end">
              <IcClose
                width={12}
                height={12}
                onClick={() => {
                  setDriverTableIsOpen(false);
                }}
                style={{ cursor: 'pointer', padding: '0 18px 0 0' }}
              />
            </Stack>
            <BoxContainer
              useVerticalScroll
              align={'start'}
              justify={'start'}
              sx={{
                padding: '0 18px 0 0',
                margin: '18px 0 30px',
              }}
            >
              <Stack>
                <Stack name="header-area" spacing={8} align={'start'}>
                  <Text styleName={'title1'} color={'RG03'}>
                    {targetObject?.name}
                  </Text>

                  <Stack
                    direction={'row'}
                    spacing={8}
                    divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                  >
                    <Text styleName={'subheadline2'} color={'RG03'}>
                      {strings.주문}&nbsp;{' '}
                      <Highlight styleName={'subheadline2'} color={'RC02'}>
                        {targetObject?.orderList?.filter(d => d.type === 'order').length}
                      </Highlight>{' '}
                      개
                    </Text>

                    {Boolean(targetObject?.orderList.length) && (
                      <Text styleName={'subheadline2'} color={'RG03'}>
                        {summaryInfo.routeStartTime.format('HH:mm')}~{' '}
                        {summaryInfo.routeStartTime.add(summaryInfo.totalReqTime, 's').format('HH:mm')}
                      </Text>
                    )}

                    <Text styleName={'subheadline2'} color={'RG03'}>
                      {Math.floor(summaryInfo.totalReqTime / 60 / 60)
                        ? `${Math.floor(summaryInfo.totalReqTime / 60 / 60)} 시간 `
                        : ''}
                      {Math.floor((summaryInfo.totalReqTime / 60) % 60) < 10
                        ? `0${Math.floor((summaryInfo.totalReqTime / 60) % 60)}`
                        : Math.floor((summaryInfo.totalReqTime / 60) % 60)}{' '}
                      분
                    </Text>

                    {targetObject?.hasLunch && (
                      <Stack spacing={8} direction="row" align="center" sx={{ width: 'fit-content' }}>
                        <IcHasLunch />
                        <Text styleName={'subheadline2'} color={'RG03'}>
                          1 시간
                        </Text>
                      </Stack>
                    )}

                    <Text styleName={'subheadline2'} color={'RG03'}>
                      {summaryInfo?.distance} km
                    </Text>
                  </Stack>
                </Stack>

                <Stack direction={'row'} justify={'space-between'} align="end" sx={{ marginTop: '40px' }}>
                  <Text styleName={'subheadline2'} color={'RG03'} sx={{ minWidth: 'fit-content' }}>
                    {strings.선택된주문}&nbsp;:&nbsp;
                    {selectedOrder.length.toString().padStart(2, '0')}
                    &nbsp;개
                  </Text>
                  <Button
                    variant={'second'}
                    type={'button'}
                    styleName={'caption2'}
                    color={'RG03'}
                    height={32}
                    width={126}
                    onClick={() => {
                      if (selectedOrder.length <= 0) return setCallbackModalOpen(true);
                      else setDeleteOrderModalOpen(true);
                    }}
                  >
                    <IcBin style={{ marginRight: '10px' }} />
                    {strings.배차취소}
                  </Button>
                </Stack>
                <Stack sx={{ marginTop: '16px', flex: 1, overflow: 'hidden' }}>
                  <RouteHistoryDetailTable
                    // data={targetObject?.orderList?.filter(d => d.type === 'order')}
                    data={targetObject?.orderList}
                    defaultCheckOrderIds={propStates && [propStates.orderId]}
                    {...{
                      setSelectedOrder,
                      selectedOrder,
                      setRowSelection,
                      rowSelection,
                      setTargetOrderId,
                      setOrderDetailModalOpen,
                    }}
                  />
                </Stack>
              </Stack>
            </BoxContainer>
          </DriverStack>

          <Stack sx={{ minWidth: '1px', height: '100%', flex: 1 }}>
            <RouteHistoryMap
              routeDetail={routeDetail}
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              selectedDriver={target}
            />
          </Stack>
        </PageContentWrapper>
      </Stack>

      {targetOrderId && (
        <OrderDetailModal
          noModi
          {...{ target: targetOrderId, isOpen: orderDetailModalOpen, setIsOpen: setOrderDetailModalOpen }}
        />
      )}

      <DeactiveModal
        isOpen={deleteOrderModalOpen}
        setIsOpen={setDeleteOrderModalOpen}
        headline={`선택하신 ${selectedOrder.length} 건의 ${strings.배차} 취소하시겠습니까?`}
        text={`(${strings.주문} 취소는 '${strings.주문관리}'에서 가능합니다.)`}
        highlight={`취소 이후 ‘${strings.배차대기}’ 상태로 변경되고 되돌릴 수 없습니다.\n추후 재${
          strings.배차 === '배차' ? `${strings.배차}는` : `${strings.배차}은`
        } 가능합니다.`}
        returnFunc={() => {
          targetObject?.driverId &&
            mutateCancelRouteOrder({
              routeId,
              props: {
                driverId: targetObject?.driverId,
                orderList: delTargetOrders,
              },
            });
          setDelTargetOrders([]);
        }}
        RBT={strings.배차취소}
        LBT={'닫기'}
        ms={9}
        reverse
      />
      <Modal isModalOpen={callbackModalOpen} setIsModalOpen={setCallbackModalOpen} padding={24} width={503}>
        <Stack>
          <Stack sx={{ padding: '40px 0' }}>
            <Text styleName="title2" color="RG02">
              최소 1개 이상의 {strings.모달_주문}을 선택 해주세요
            </Text>
          </Stack>
          <Button
            variant="default"
            type="button"
            height={40}
            fullWidth
            styleName="body2"
            color="RG00"
            onClick={() => {
              setCallbackModalOpen(false);
            }}
          >
            돌아가기
          </Button>
        </Stack>
      </Modal>
    </React.Fragment>
  );
};

export default RouteHistory;
export const DriverStack = styled(Stack)`
  width: 45vw;
  transition: all 0.2s ease-in-out;
`;
