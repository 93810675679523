import {
  PaginationState,
  createTable,
  useTableInstance,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { getInvestigatorList, postPsLink } from 'api';
import { IInvestigator } from 'constants/types';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { UseFormProps } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useStore } from 'store';
import strings from 'util/Localization';
import DeactiveModal from 'components/Modal/DeactiveModal';
import ModifyUserModal from 'components/Modal/ModifyUserModal';
import TableComponent from '.';
import { Text } from 'components/Typography';
import { Button } from 'components/Button';
import { validateAccountStatus } from 'util/validateStatus';
import { Tag } from 'components/Tag';
import dayjs from 'dayjs';
import { DetailSelectBoxButton } from './style';

let investigatorManagementTable = createTable().setRowType<IInvestigator>();

function InvestigatorManagementTable({
  mutateDeactivateMember,
  mutateSetInvestigator,
  modifyMemberModalIsOpen,
  setModifyMemberModalIsOpen,
  mutateActivateMember,
}: {
  mutateDeactivateMember: Function;
  mutateSetInvestigator: Function;
  modifyMemberModalIsOpen: boolean;
  setModifyMemberModalIsOpen: Dispatch<SetStateAction<boolean>>;
  mutateActivateMember: Function;
}) {
  const { userGrade } = useStore();

  const { data: investigators, isFetching: getInvestigatorListIsFetching } = useQuery(
    ['investigators'],
    getInvestigatorList,
    {}
  );

  const [userId, setUserId] = React.useState<number>(-1);
  const [target, setTarget] = React.useState<null | string>(null);
  const [targetUser, setTargetUser] = React.useState<null | string>(null);
  const [deactiveUserModalOpen, setDeactiveUserModalOpen] = React.useState<boolean>(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = React.useState<{ [key: string]: boolean }>({
    startAddress: false,
    endAddress: false,
  });

  const [timePickerPopupOpen, setTimePickerPopupOpen] = React.useState<boolean>(false);
  const [useFormProps, setUseFormProps] = React.useState<UseFormProps>({ mode: 'onSubmit', defaultValues: {} });

  function isActiveStatus(status: 'invited' | 'accepted' | 'activated' | 'deactivated') {
    if (status === 'activated') return true;
    return false;
  }

  const { mutate: mutatePostPsLink } = useMutation(postPsLink, {
    onSuccess: () => {
      alert('계정에 설정된 연락처로\n비빌번호 재설정 링크를 보냈습니다.');
    },
    onError: () => {
      alert('비밀번호 재설정 링크 발송에 실패했습니다.\n다시 시도해주세요.');
    },
  });

  // 유저 권한에 따른 column 열람 제한
  useEffect(() => {
    // !(userGrade <= 1) && setColumnVisibility({ ...columnVisibility, modify: false, manage: false });
  }, []);

  const columns = useMemo(
    () => [
      investigatorManagementTable.createDataColumn(row => row.status, {
        id: 'status',
        header: () => '계정상태',
        cell: info => {
          let status = info.getValue();
          let { key, name } = validateAccountStatus(status as 'invited' | 'accepted' | 'activated' | 'deactivated');
          return (
            <Menu
              menuButton={
                <div style={{ cursor: 'pointer' }}>
                  <Tag
                    styleName="caption2"
                    status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
                    sx={{ width: '56px' }}
                  >
                    {name}
                  </Tag>
                </div>
              }
              onMenuChange={e => console.log(e)}
              offsetY={10}
              menuClassName={'table-menu'}
            >
              {status === 'activated' && (
                <MenuItem
                  onClick={() => {
                    setTarget(`${info.row?.original?.userId}`);
                    setTargetUser(`${info.row.getValue('name')} ${info.row.getValue('phone')}`);
                    setDeactiveUserModalOpen(true);
                  }}
                >
                  <DetailSelectBoxButton>정지</DetailSelectBoxButton>
                </MenuItem>
              )}

              {(status === 'accepted' || status === 'deactivated') && (
                <MenuItem
                  onClick={() => {
                    mutateActivateMember(info.row.original?.userId);
                  }}
                >
                  <DetailSelectBoxButton>
                    <Text styleName="caption3" color="RG03">
                      활성화
                    </Text>
                  </DetailSelectBoxButton>
                </MenuItem>
              )}
            </Menu>
          );
        },
        enableSorting: false,
      }),

      // license_number
      investigatorManagementTable.createDataColumn(row => row.name, {
        id: 'name',
        header: () => '조사원이름',
        enableSorting: true,
        enableMultiSort: true,
      }),
      investigatorManagementTable.createDataColumn(
        row => row?.phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
        {
          id: 'phone',
          header: () => '연락처(ID)',
          enableSorting: false,
        }
      ),
      investigatorManagementTable.createDataColumn(row => (row?.workStartTime ? row.workStartTime : '-'), {
        id: 'workStartTime',
        header: () => '업무 시작 시간',
        enableSorting: true,
        enableMultiSort: true,
      }),
      investigatorManagementTable.createDataColumn(row => row.vehicle?.startAddress, {
        id: 'startAddress',
        header: () => '출발지 주소',
      }),
      investigatorManagementTable.createDataColumn(row => row.vehicle?.endAddress, {
        id: 'endAddress',
        header: () => '도착지 주소',
      }),

      investigatorManagementTable.createDataColumn(
        row =>
          `${(row.acceptedAt && dayjs(row.acceptedAt).format('YYYY.MM.DD')) || '--'} / ${
            (row.invitedAt && dayjs(row.invitedAt).format('YYYY.MM.DD')) || '--'
          }`,
        {
          id: 'date',
          header: () => '등록 일자 / 초대 일자',
          enableSorting: true,
          enableMultiSort: true,
        }
      ),
      investigatorManagementTable.createDisplayColumn({
        id: 'modify',
        header: () => '수정',
        cell: ({ row }) => (
          <Button
            variant={'second'}
            type={'button'}
            sx={{ padding: '3px 6px' }}
            onClick={() => {
              setUseFormProps({
                defaultValues: {
                  phone: row.getValue('phone'),
                  password: '********',
                  name: row.getValue('name'),
                  workStartTime: row.getValue('workStartTime'),
                  startAddress: row.getValue('startAddress'),
                  endAddress: row.getValue('endAddress'),
                  hasLunch: row.original?.hasLunch,
                },
              });
              setUserId(row.original?.userId as number);
              setTarget(`${row.original?.driverId}`);
              setModifyMemberModalIsOpen(true);
            }}
          >
            <Text styleName="caption2" color="RG04">
              수정
            </Text>
          </Button>
        ),
        enableSorting: false,
      }),
    ],
    []
  );

  const instance = useTableInstance(investigatorManagementTable, {
    data: investigators?.memberList ?? [],
    columns,
    state: {
      columnVisibility,
      pagination,
    },
    initialState: {},
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const modifyForm = [
    { type: 'Input', name: 'phone', label: '아이디', disabled: true },
    {
      type: 'Input',
      name: 'password',
      label: '비밀번호',
      disabled: true,
      rightArea: {
        rightContent: '재설정 링크 보내기',
        rightFunc: () => {
          mutatePostPsLink(userId as number);
        },
      },
    },
    { type: 'Input', name: 'name', label: '이름', required: true },
    {
      type: 'Input',
      name: 'workStartTime',
      label: '업무 시작 시간',
      timePicker: {
        open: timePickerPopupOpen,
        setOpen: setTimePickerPopupOpen,
      },
      onClick: () => setTimePickerPopupOpen(true),
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => e.target.blur(),
      readonly: true,
    },

    { type: 'Input', name: 'startAddress', label: '출발지 주소', postcode: true, readOnly: true },
    { type: 'Input', name: 'endAddress', label: '도착지 주소', postcode: true, readOnly: true },
    // RT-982
    {
      type: 'Select',
      name: 'hasLunch',
      label: `${strings.드라이버} 점심시간`,
      options: [
        { value: true, key: '설정 (1시간)' },
        { value: false, key: '미설정 (점심시간 없음)' },
      ],
    },
  ];

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [investigators?.memberList]);

  return (
    <React.Fragment>
      <TableComponent
        table={instance}
        isDataFetching={getInvestigatorListIsFetching}
        disableOptions={{
          selectCount: true,
        }}
        {...{ pagination, setPagination }}
      />
      <DeactiveModal
        isOpen={deactiveUserModalOpen}
        setIsOpen={setDeactiveUserModalOpen}
        target={`${target}`}
        targetName={`${targetUser}`}
        text={'선택하신 계정을 정지 하시겠습니까?'}
        highlight={'이후 해당 계정 이용이 불가합니다.'}
        returnFunc={mutateDeactivateMember}
        RBT={'정지하기'}
        LBT={'취소'}
      />
      <ModifyUserModal
        {...{
          target,
          useFormProps,
          modifyForm,
          mutateSetInfo: mutateSetInvestigator,
          modifyMemberModalIsOpen,
          setModifyMemberModalIsOpen,
        }}
      />
    </React.Fragment>
  );
}

export default InvestigatorManagementTable;
