import { useEffect } from 'react';
import { DriverIdUnSelected, IEqualOptimizeResponsesDriver, IGetRouteDetailResponsesDriver } from 'constants/types';
import { GoogleMapsOverlay } from './GoogleMapsOverlay';
// import { PathLayer } from 'deck.gl';
import { PathLayer } from '@deck.gl/layers/typed';
import { ColorPalette, hexToRgb } from '../../../constants/colorPalette';
import polyline from 'google-polyline';

export function Polyline({
  overlay,
  optimizeDrivers,
  responseDrivers,
  selectedDriverId,
}: {
  overlay: GoogleMapsOverlay;
  optimizeDrivers: IEqualOptimizeResponsesDriver[];
  responseDrivers: IGetRouteDetailResponsesDriver[];
  selectedDriverId: number | undefined;
}) {
  useEffect(() => {
    if (!overlay) return;

    let data: any[] = [];

    optimizeDrivers.forEach(driver => {
      if (driver.orderList && driver.orderList.length > 0) {
        driver.orderList.forEach(order => {
          if (order.route && order.route.external && order.route.external.polyline) {
            let alpha = 255;
            if (selectedDriverId) {
              if (selectedDriverId !== DriverIdUnSelected) {
                alpha = selectedDriverId === driver.driverId ? 255 : 50;
              }
            }

            let decodedLine = polyline.decode(order.route.external.polyline);
            let item = {
              path: decodedLine,
              color: hexToRgb(ColorPalette.getColor(driver.driverId || 0), alpha),
              alpha,
            };
            data.push(item);
          }
        });
      }
    });

    responseDrivers.forEach(driver => {
      if (driver.orderList && driver.orderList.length > 0) {
        driver.orderList.forEach(order => {
          if (order.route && order.route.polyline) {
            let alpha = 255;
            if (selectedDriverId) {
              if (selectedDriverId !== DriverIdUnSelected) {
                alpha = selectedDriverId === driver.driverId ? 255 : 50;
              }
            }

            let decodedLine = polyline.decode(order.route.polyline);
            let item = {
              path: decodedLine,
              color: hexToRgb(ColorPalette.getColor(driver.driverId || 0), alpha),
              alpha,
            };
            data.push(item);
          }
        });
      }
    });

    data.sort(function (a, b) {
      return a.alpha > b.alpha ? 1 : a.alpha < b.alpha ? -1 : 0;
    });

    let layers = [
      new PathLayer({
        data,
        widthUnits: 'pixels',
        widthScale: 1,
        getPath: (d: any) => d.path,
        getColor: (d: any) => [255, 255, 255, d.alpha],
        getWidth: 10,
        jointRounded: true,
        capRounded: true,
      }),
      new PathLayer({
        data,
        widthUnits: 'pixels',
        widthScale: 1,
        getPath: (d: any) => d.path,
        getColor: (d: any) => d.color,
        getWidth: 6,
        jointRounded: true,
        capRounded: true,
      }),
    ];
    overlay.setProps({ layers });
  }, [optimizeDrivers, responseDrivers, selectedDriverId]);
  return null;
}
