import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'constants/theme';
import { Text } from '../Typography';
import { ReactComponent as IconClose } from '../../constants/icon/ic_close_16.svg';
import { Stack } from '../Stack';
import { Button } from '../Button';
import { UseFormSetValue } from 'react-hook-form';
import strings from '../../util/Localization';

interface WorkTimePopupProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  //   setValue: (name: string, value: unknown, config?: Object) => void;
  setValue: UseFormSetValue<any>;
  name: string;
}

function WorkTimePopup({ setIsOpen, setValue, name }: WorkTimePopupProps) {
  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <WorkTimePopupContainer ref={targetRef}>
      <Stack>
        <Header>
          <Text styleName="subheadline2" color="RG04">
            예상되는 {strings.작업소요시간분}을 눌러주세요
          </Text>

          <IconClose
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Header>
        <Stack direction={'row'} sx={{ paddingTop: '50px' }} spacing={23}>
          {[10, 20, 30, 60].map((x, i) => (
            <WButton
              onClick={() => {
                setValue(name, `${x}분`, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                setIsOpen(false);
              }}
            >
              {x}분
            </WButton>
          ))}
        </Stack>
        <Stack direction={'row'} sx={{ paddingTop: '16px' }} spacing={23}>
          {[80, 100, 120, 150].map((x, i) => (
            <WButton
              onClick={() => {
                setValue(name, `${x}분`, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                setIsOpen(false);
              }}
            >
              {x}분
            </WButton>
          ))}
        </Stack>
      </Stack>
    </WorkTimePopupContainer>
  );
}

export default WorkTimePopup;

const WorkTimePopupContainer = styled.div`
  display: flex;
  padding: 24px 20px;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;

  position: absolute;
  top: 76px;
  width: 100%;

  z-index: 3;
`;

const Header = styled.div`
  display: flex;

  width: 100%;
  align-items: center;
  justify-items: end;
  justify-content: space-between;

  & > svg {
    cursor: pointer;
  }
`;

const WButton = styled.div`
  ${({ theme }) => theme.fontStyle.caption2};
  background: ${({ theme }) => theme.colors.RG00};
  color: ${({ theme }) => theme.colors.RG04};

  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  border-color: ${({ theme }) => theme.colors.RG06};
  padding: 7px 10px;
  width: 100%;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.RC03_1};
    border-color: ${({ theme }) => theme.colors.RC03};
    color: ${({ theme }) => theme.colors.RC02};
  }
`;
