import React, { SetStateAction, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { getVehicleModel } from 'api';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import PopupYIndexFixer from './PopupYIndexFixer';
import { UseFormReturn } from 'react-hook-form';
import { ReactComponent as SpinLoader } from 'constants/icon/spin_loader.svg';
import { SelectItem } from 'components/Input';

interface VehiclePickerProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  setIsHovering: React.Dispatch<SetStateAction<boolean>>;
  setSelectedModel: React.Dispatch<SetStateAction<number>>;

  name: string;
  methods: UseFormReturn;
}

export const VehiclePicker = ({
  isOpen,
  setIsOpen,
  setIsHovering,
  setSelectedModel,
  name,
  methods,
}: VehiclePickerProps) => {
  const {
    data: vehicleModels,
    isSuccess: getvehicleModelListIsSuccess,
    isLoading: getvehicleModelListIsLoading,
    refetch: refetchVehicleModels,
  } = useQuery(['vehicleModels'], getVehicleModel, {});

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <PopupYIndexFixer>
      <VehiclePickerContainer
        ref={targetRef}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
      >
        <Stack>
          {getvehicleModelListIsLoading && (
            <SpinLoader style={{ width: '20px', height: '20px' }} className="infinite_rotating" />
          )}
          {getvehicleModelListIsSuccess &&
            vehicleModels.vehicleModelList.map((x, i) => (
              <SelectItem
                key={`${x.name}-picker-item`}
                onClick={() => {
                  setIsOpen(false);
                  setSelectedModel(x.vehicleModelId);
                  methods.setValue(name, x.name, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              >
                <Stack spacing={8} direction={'row'}>
                  <Text styleName={'caption3'} color={'RG03'}>
                    {x.name}
                  </Text>
                </Stack>
              </SelectItem>
            ))}
        </Stack>
      </VehiclePickerContainer>
    </PopupYIndexFixer>
  );
};

const VehiclePickerContainer = styled.div`
  display: flex;
  padding: 17px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  width: 100%;
  position: absolute;
  top: 80px;
  z-index: 3;
  left: 0;
  max-height: 161px;
  ${({ theme }) => theme.scrollStyle.vertical};
`;
