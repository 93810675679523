import React, { ChangeEvent, DragEvent, useRef } from 'react';
import mapKeys from 'lodash/mapKeys';

import { FileDrop } from 'react-file-drop';
import readXlsxFile, { Row } from 'read-excel-file';

import styled from 'styled-components';

import { UseMutateFunction, useMutation } from 'react-query';
import { manualExcelValidate, uploadExcel } from 'api';
import { IDomainTypes, IUpsertOrder } from 'constants/types';
import { rooutySchema, surveySchema } from './upsertOrderSchema';

import './style.css';
import Divider from '../../Divider';
import ProgressBar from 'components/ProgressBar';
import { Highlight, Text } from 'components/Typography';
import { Stack } from 'components/Stack';

import { Stack as StackStyle } from 'components/Stack/style';

import { ReactComponent as IconError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IconArrowdown } from 'constants/icon/ic_arrowdown.svg';
import { ReactComponent as Ic_FileUpload } from 'constants/icon/ic_fileupload.svg';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';

import theme from 'constants/theme';
import strings from '../../../util/Localization';
import { xlsxUri } from 'constants/uris';
import sleep from 'util/sleep';
import { Button } from 'components/Button';

const XlsxLoader = ({
  manual,
  updateFunc,
  setXlsxFormIsOpen,
  setIsDownloadedErrorFile,
  isXlsxError,
  setIsXlsxError,
}: {
  manual?: boolean;
  updateFunc?: Function;
  setXlsxFormIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDownloadedErrorFile: React.Dispatch<React.SetStateAction<boolean>>;
  isXlsxError: boolean;
  setIsXlsxError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // const {
  //   mutate: mutateUpsertOrder,
  //   isLoading: upsertOrderIsLoading,
  //   isError: upsertOrderIsError,
  //   error: upsertOrderError,
  //   isSuccess: upsertOrderIsSuccess,
  // } = useMutation(upsertOrder, {
  //   onSuccess: res => {
  //     updateFunc && updateFunc();
  //     sleep(3000).then(() => setXlsxFormIsOpen(false));
  //   },
  // });

  const {
    data: uploadedInfo,
    mutate: mutateUploadExcel,
    isLoading: uploadExcelIsLoading,
    isError: uploadExcelIsError,
    isSuccess: uploadExcelIsSuccess,
  } = useMutation(uploadExcel, {
    onSuccess(data, variables, context) {
      if (data?.erroredExcel) {
        setIsXlsxError(true);
      } else {
        updateFunc && updateFunc();
        sleep(3000).then(() => setXlsxFormIsOpen(false));
      }
    },
  });

  const {
    mutate: mutateManualExcelValidate,
    isLoading: mutateManualExcelValidateIsLoading,
    isError: mutateManualExcelValidateIsError,
    isSuccess: mutateManualExcelValidateIsSuccess,
  } = useMutation(manualExcelValidate, {
    onSuccess: res => {
      updateFunc && updateFunc(res);
      sleep(2000).then(() => setXlsxFormIsOpen(false));
    },
  });

  const domain = window.localStorage.getItem('serviceDomain');

  const [uploadedFileName, setUploadedFileName] = React.useState<string>();

  const [completedPercent, setCompletedPercent] = React.useState<number>(0);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) loadXlsx(files);
  };

  const onFileDropped = (filesList: FileList | null, event: DragEvent<HTMLDivElement>) => {
    if (filesList && filesList.length > 0) loadXlsx(filesList);
  };

  const onTargetClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const loadXlsx = async (files: FileList) => {
    const formData = new FormData();
    formData.append('excel', files[0]);

    setIsDownloadedErrorFile(false);
    setIsXlsxError(false);
    setCompletedPercent(0);
    setUploadedFileName(files[0].name);

    if (manual)
      return mutateManualExcelValidate({
        file: formData,
        config: {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;

            let completedPercent = Math.round((loaded / total) * 100);
            console.log(completedPercent);
            setCompletedPercent(completedPercent);
          },
          onDownloadProgress: (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            let completedPercent = Math.round((loaded / total) * 100);
            if (completedPercent === 100) setCompletedPercent(completedPercent);
          },
        },
      });
    else
      mutateUploadExcel({
        file: formData,
        config: {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;

            let completedPercent = Math.round((loaded / total) * 100);
            console.log(completedPercent);
            setCompletedPercent(completedPercent);
          },
          onDownloadProgress: (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            let completedPercent = Math.round((loaded / total) * 100);
            if (completedPercent === 100) setCompletedPercent(completedPercent);
          },
        },
      });

    // let xlsxUploadResult: any;
    // try {
    //   let xlsxfile = files[0];
    //   setUploadedFileName(xlsxfile.name);
    //   let sheet = await readXlsxFile(xlsxfile, {
    //     schema: domain === 'survey' ? surveySchema : rooutySchema,
    //     transformData(rows: Row[]): Row[] {
    //       let headerData = rows[0].map(x => {
    //           if (typeof x === 'string') return x.trim();
    //           return x;
    //         }),
    //         contentData = rows.slice(2),
    //         rowsData: Row[] = [[...headerData], ...contentData];
    //       return rowsData;
    //     },
    //   });
    //   if (sheet.errors.length != 0) {
    //     throw sheet.errors;
    //   } else {
    //     xlsxUploadResult = {
    //       result: sheet.rows,
    //       fileName: xlsxfile.name,
    //     };
    //   }
    // } catch (e) {
    //   xlsxUploadResult = {
    //     error: e,
    //   };
    // }
    // console.log(xlsxUploadResult);
    // if (xlsxUploadResult.error) {
    //   setIsXlsxError(true);
    // } else {
    //   // setUploadedFileName(xlsxUploadResult.fileName);
    //   let hasError = '';

    //   let resultOrderList: IUpsertOrder = {
    //     orderList: [],
    //   };

    //   xlsxUploadResult.result.forEach((row: any) => {
    //     let tempArray: string[] = [];
    //     mapKeys(row, (value, key) => {
    //       if (key.includes('note')) {
    //         tempArray.push(value);
    //         return delete row[key];
    //       }
    //     });
    //     row.note = tempArray;
    //     if (row.serviceTime) {
    //       // row.serviceTime = row.serviceTime ? row.serviceTime * 60 : 60;
    //     } else {
    //       row.serviceTime = 1;
    //     }

    //     if (domain === 'survey') {
    //       row.capacity = 1;
    //       row.product = { ...row.product, quantity: 1 };
    //     }

    //     // 이미 ClientKey를 가진 주문이 있는경우 병합 or 오류표출
    //     if (row.clientKey && resultOrderList.orderList.filter(x => x.clientKey === row.clientKey).length > 0) {
    //       let arOrder = resultOrderList.orderList.filter(x => x.clientKey === row.clientKey)[0];

    //       if (
    //         arOrder.receivedDate === row.receivedDate &&
    //         arOrder.consigneeName === row.consigneeName &&
    //         arOrder.consigneePhone === row.consigneePhone &&
    //         arOrder.address === row.address
    //       ) {
    //         // 성공
    //         let orderIndex = -1;
    //         resultOrderList.orderList.forEach((item, i) => {
    //           if (item.clientKey === row.clientKey) {
    //             orderIndex = i;
    //           }
    //         });
    //         if (orderIndex !== -1) {
    //           resultOrderList.orderList[orderIndex].product.push(row.product);
    //           resultOrderList.orderList[orderIndex].capacity = Math.max(
    //             resultOrderList.orderList[orderIndex].capacity,
    //             row.capacity
    //           );
    //         }
    //       } else {
    //         hasError = ': 동일한 ClientOrderId 주문의 추가정보 불일치';
    //       }
    //     } else {
    //       row.product = [{ ...row.product }];
    //       resultOrderList.orderList.push(row);
    //     }
    //   });

    //   setIsXlsxError(hasError !== '');
    //   if (hasError) {
    //     alert(hasError);
    //     return;
    //   }

    //   setCompletedPercent(0);

    //   console.log(resultOrderList, ' resultOrderList');

    //   mutateUpsertOrder({
    //     props: resultOrderList,
    //     config: {
    //       onUploadProgress: (progressEvent: ProgressEvent) => {
    //         const { loaded, total } = progressEvent;

    //         let completedPercent = Math.round((loaded / total) * 100);
    //         console.log(completedPercent);
    //         setCompletedPercent(completedPercent);
    //       },
    //       onDownloadProgress: (progressEvent: ProgressEvent) => {
    //         const { loaded, total } = progressEvent;
    //         let completedPercent = Math.round((loaded / total) * 100);
    //         if (completedPercent === 100) setCompletedPercent(completedPercent);
    //       },
    //     },
    //   });
    // }
  };
  return (
    <React.Fragment>
      <iframe style={{ width: 0, height: 0, display: 'none' }} name="window_opener" />

      <Stack direction={'row'} spacing={24} divider={<Divider vertical={true} style={{ height: '278px' }} />}>
        <Stack>
          {isXlsxError && uploadExcelIsSuccess ? (
            <React.Fragment>
              <PaddingBox>
                <Stack spacing={12}>
                  <IconWarning width={24} height={24} />

                  <Text styleName="title2" color="RG02">
                    {uploadedInfo?.successCount > 0
                      ? '일부 주문에 업로드 오류가 발생했습니다.'
                      : '파일에 일부 데이터 오류가 있습니다.'}
                  </Text>
                  <Text styleName="subheadline2" color="RG03">
                    업로드 실패한 엑셀 파일을 다운로드해 수정 후,
                    <br />
                    다시 업로드 해주시길 바랍니다.
                  </Text>
                </Stack>
              </PaddingBox>
              <Stack spacing={20}>
                <UploadSummaryBox spacing={20}>
                  <UploadSummaryInfo failure={uploadedInfo?.failureCount} success={uploadedInfo?.successCount} />
                  <Button
                    variant="seventh"
                    type="button"
                    width={180}
                    height={32}
                    styleName="caption2"
                    color="RG03"
                    onClick={() => {
                      window.open(uploadedInfo?.erroredExcel, 'window_opener', 'noopener,noreferrer')?.blur();
                      setIsDownloadedErrorFile(true);
                    }}
                  >
                    <Stack direction="row" spacing={8} sx={{ width: 'fit-content' }}>
                      <IconArrowdown />
                      업로드 실패 엑셀 다운로드
                    </Stack>
                  </Button>
                </UploadSummaryBox>
                <UploadWarningBox padding="16px">
                  <Stack spacing={16} align="start">
                    <Text styleName="subheadline2" color="RG02">
                      업로드 실패 엑셀 사용방법은 아래와 같습니다.
                    </Text>
                    <Text styleName="subheadline2" color="RG02" align="start">
                      1. 업로드 실패 엑셀파일을 다운로드해 주세요.
                      <br />
                      2. 다운한 파일에서 실패 사유를 확인 후 수정하여 다시 업로드해 주세요.
                    </Text>
                    <Text styleName="subheadline2" color="RC04" align="start">
                      <Highlight styleName="subheadline1">주의사항</Highlight>
                      <br />
                      이미 업로드한 주문까지 같이 업로드할 경우 주문 중복으로
                      <br />
                      오류가 발생할 수 있습니다.
                      <br />
                      반드시 업로드 실패 엑셀파일을 다운로드해 수정해 주시길 바랍니다.
                    </Text>
                  </Stack>
                </UploadWarningBox>
              </Stack>
            </React.Fragment>
          ) : uploadExcelIsError || mutateManualExcelValidateIsError ? (
            <React.Fragment>
              <PaddingBox>
                <Stack spacing={12}>
                  <IconError width={24} height={24} />

                  <Text styleName="title2" color="RG02">
                    잘못된 파일 형식이거나 오류가 있습니다.
                    <br />
                    아래 주의사항을 읽어보시고 다시 시도해주세요.
                  </Text>
                </Stack>
              </PaddingBox>

              <Stack spacing={16}>
                <UploadWarningBox padding="20px 8px">
                  <li>• 파일과 확장자가 맞는지 확인해주세요.</li>
                  <li>• 파일 내 칼럼이 정확하게 설정되어 있는지 확인해주세요.</li>
                  <li>• 빈 파일은 업로드가 불가합니다. 파일 내 데이터를 확인해주세요.</li>
                </UploadWarningBox>

                <input
                  onChange={onFileInputChange}
                  onClick={e => {
                    e.currentTarget.value = '';
                  }}
                  ref={fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                />
                <div className="filedrop-container">
                  <FileDrop onDrop={onFileDropped} onTargetClick={onTargetClick}>
                    <Ic_FileUpload />
                    <Text styleName="subheadline3" color="RG04">
                      {strings.주문파일을마우스로끌어서}
                    </Text>
                    <div className="filedrop-mypc">
                      <Text styleName="caption2" color="RG03">
                        내 PC에서 찾아보기
                      </Text>
                    </div>
                  </FileDrop>
                </div>
              </Stack>

              <PaddingBox spacing={4}>
                <Text styleName="subheadline2" color="RG03">
                  루티 엑셀 양식이 없으신가요? 아래 버튼을 눌러 다운 받아주세요.
                </Text>
                <a
                  target="window_opener"
                  rel="noopener noreferrer"
                  href={xlsxUri[manual ? 'manual' : (domain as IDomainTypes)]}
                >
                  <Text styleName="subheadline2" color="RC02" sx={{ textDecoration: 'underline' }}>
                    {strings.주문업로드템플릿다운로드}
                  </Text>
                </a>
              </PaddingBox>
            </React.Fragment>
          ) : uploadExcelIsSuccess || mutateManualExcelValidateIsSuccess ? (
            <Stack spacing={62}>
              <PaddingBox>
                <Stack spacing={12}>
                  <IconSuccess fill={theme.colors.RC02} width={24} height={24} />
                  <Text styleName="title2" color="RG02">
                    {uploadedFileName}
                    <br />
                    파일 업로드가 완료되었습니다.
                  </Text>
                </Stack>
              </PaddingBox>
              <Stack
                name="progress-area"
                direction="row"
                spacing={20}
                justify="space-evenly"
                sx={{ padding: '30px 0' }}
              >
                <ProgressBar
                  label={uploadedFileName}
                  progress={completedPercent}
                  type={'xlsx'}
                  isError={uploadExcelIsError || isXlsxError}
                />
              </Stack>
            </Stack>
          ) : uploadExcelIsLoading || mutateManualExcelValidateIsLoading ? (
            <React.Fragment>
              {/* <SpinLoader style={{ width: '44px', height: '44px' }} className="infinite_rotating" /> */}
              <Text styleName="title2" color="RG02">
                {strings.대상주문} 목록을 업로드 중입니다.
                <br />
                잠시만 기다려주세요.
              </Text>

              <Stack
                name="progress-area"
                direction="row"
                spacing={20}
                justify="space-evenly"
                sx={{ padding: '30px 0' }}
              >
                <ProgressBar
                  label={uploadedFileName}
                  progress={completedPercent}
                  type={'xlsx'}
                  isError={uploadExcelIsError || isXlsxError}
                />
              </Stack>

              <PaddingBox spacing={4}>
                <Text styleName="subheadline2" color="RG03">
                  루티 엑셀 양식이 없으신가요? 아래 버튼을 눌러 다운 받아주세요.
                </Text>
                <a
                  target="window_opener"
                  rel="noopener noreferrer"
                  href={xlsxUri[manual ? 'manual' : (domain as IDomainTypes)]}
                >
                  <Text styleName="subheadline2" color="RC02" sx={{ textDecoration: 'underline' }}>
                    {strings.주문업로드템플릿다운로드}
                  </Text>
                </a>
              </PaddingBox>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <PaddingBox>
                <Stack spacing={4}>
                  <Text styleName="title2" color="RG02">
                    {strings.배차}를 진행할 루티 엑셀 파일을
                    <br />
                    업로드해주세요.
                  </Text>
                </Stack>
              </PaddingBox>

              <input
                onChange={onFileInputChange}
                onClick={e => {
                  e.currentTarget.value = '';
                }}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
              />
              <div className="filedrop-container">
                <FileDrop onDrop={onFileDropped} onTargetClick={onTargetClick}>
                  <Ic_FileUpload />
                  <Text styleName="subheadline3" color="RG04">
                    {strings.주문파일을마우스로끌어서}
                  </Text>
                  <div className="filedrop-mypc">
                    <Text styleName="caption2" color="RG03">
                      내 PC에서 찾아보기
                    </Text>
                  </div>
                </FileDrop>
              </div>
              <PaddingBox spacing={4}>
                <Text styleName="subheadline2" color="RG03">
                  루티 엑셀 양식이 없으신가요? 아래 버튼을 눌러 다운 받아주세요.
                </Text>
                <a
                  target="window_opener"
                  rel="noopener noreferrer"
                  href={xlsxUri[manual ? 'manual' : (domain as IDomainTypes)]}
                >
                  <Text styleName="subheadline2" color="RC02" sx={{ textDecoration: 'underline' }}>
                    {strings.주문업로드템플릿다운로드}
                  </Text>
                </a>
              </PaddingBox>
            </React.Fragment>
          )}
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default XlsxLoader;

const UploadSummaryInfo = ({ failure, success }: { failure: number; success: number }) => {
  const total = failure + success;
  return (
    <Stack spacing={16} justify="center" direction="row">
      <Stack
        name="success-order-count"
        spacing={8}
        direction="row"
        sx={{ flexBasis: 'max-content' }}
        divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
      >
        <Text styleName="subheadline2" color="RC02">
          성공
        </Text>
        <Text styleName="subheadline2" color="RC02">
          {success} 건
        </Text>
      </Stack>
      <Stack
        name="failure-order-count"
        spacing={8}
        direction="row"
        sx={{ flexBasis: 'max-content' }}
        divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
      >
        <Text styleName="subheadline2" color="RC04">
          실패
        </Text>
        <Text styleName="subheadline2" color="RC04">
          {failure} 건
        </Text>
      </Stack>
      <Stack
        name="total-order-count"
        spacing={8}
        direction="row"
        sx={{ flexBasis: 'max-content' }}
        divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
      >
        <Text styleName="subheadline2" color="RG04">
          전체
        </Text>
        <Text styleName="subheadline2" color="RG04">
          {total} 건
        </Text>
      </Stack>
    </Stack>
  );
};

const PaddingBox = styled(StackStyle)`
  padding: 30px 0 40px;
`;

const UploadWarningBox = styled.ul<{ padding: string }>`
  padding: ${({ padding }) => padding};

  width: 100%;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.RC04_1};

  li {
    ${({ theme }) => theme.fontStyle.subheadline2}
    text-align: start;
    color: ${({ theme }) => theme.colors.RG02};
  }
`;
const UploadSummaryBox = styled(StackStyle)`
  padding: 20px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.RG08};
`;
