import {
  PaginationState,
  createTable,
  useTableInstance,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import OrderDetailModal from 'components/Modal/OrderDetailModal';
import { Stack } from 'components/Stack';
import { IEqualOptimizeResponsesDriversOrderList, UnBatchedRouteIndex } from 'constants/types';
import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import TableComponent from '.';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import { Text } from 'components/Typography';

import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';
import { ReactComponent as IconPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import dayjs from 'dayjs';
import strings from 'util/Localization';
import TableSelectManager from './util/TableSelectManager';
import { RowDirectionContainer, TableDeatilButton } from './style';
import { shipment_type } from 'constants/commons';

let routePlanConfirmTable = createTable().setRowType<IEqualOptimizeResponsesDriversOrderList>();

function RoutePlanConfirmTable({
  data,
  selectedOrder,
  setSelectedOrder,
  rowSelection,
  setRowSelection,
  disableOptions = {
    totalCount: false,
    selectCount: false,
    pagnation: false,
    pageSizeSelector: false,
  },
}: {
  data: IEqualOptimizeResponsesDriversOrderList[] | undefined;
  selectedOrder: Array<any>;
  setSelectedOrder: React.Dispatch<SetStateAction<Array<object>>>;
  rowSelection: {};
  setRowSelection: React.Dispatch<React.SetStateAction<{}>>;
  disableOptions?: {
    totalCount?: boolean;
    selectCount?: boolean;
    pagnation?: boolean;
    pageSizeSelector?: boolean;
  };
}) {
  let domain = window.localStorage.getItem('serviceDomain');

  const [target, setTarget] = useState<number>();
  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState<boolean>(false);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'routeIndex',
      desc: false,
    },
    {
      id: 'address',
      desc: false,
    },
    {
      id: 'requiredTime',
      desc: false,
    },
    {
      id: 'eta',
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: disableOptions.pagnation ? 100000 : 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({
    orderId: false,
    rooutyEngineETA: JSON.parse(process.env.REACT_APP_TB_ONLY as string),
  });

  useEffect(() => {
    let t: any = {};
    selectedOrder.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.orderId === x.orderId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [selectedOrder, setRowSelection]);

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });

      orders = orders
        .filter(y => y.orderId)
        .filter(y => selectedOrder.filter(z => z.orderId === y.orderId).length === 0);
      setSelectedOrder([...selectedOrder, ...orders]);
    } else {
      let orders: Array<any> = selectedOrder;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.orderId !== s.original?.orderId);
      });
      setSelectedOrder(orders);
    }
  }

  const [isHover, setIsHover] = useState<
    {
      id: string;
      value: boolean;
    }[]
  >([]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      routePlanConfirmTable.createDisplayColumn({
        id: 'select',
        header: ({ instance }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked:
                instance
                  .getCoreRowModel()
                  .rows.filter(d => d.original?.type === 'order')
                  .every(d => d.getIsSelected() === true) &&
                instance.getCoreRowModel().rows.filter(d => d.original?.type === 'order').length > 0,
              indeterminate: instance.getIsSomeRowsSelected(),
              onChange: instance.getToggleAllRowsSelectedHandler(),

              instance,
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) =>
          row.original?.type === 'order' && (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        enableSorting: false,
      }),
      routePlanConfirmTable.createDataColumn(row => row.route?.external?.routeIndex, {
        id: 'routeIndex',
        header: () => '순서',
        cell: info => {
          if (info.row.original?.route?.external?.routeIndex === UnBatchedRouteIndex) {
            return (
              <Text styleName={'caption1'} color={'RC02'}>
                신규(N)
              </Text>
            );
          }
          return info.getValue();
        },
      }),

      routePlanConfirmTable.createDataColumn(row => (row.route ? row.route.external?.requiredTime : '-'), {
        id: 'requiredTime',
        header: () => '이동시간',
        cell: info => {
          let isUnBatched = info.row.original?.route?.external?.routeIndex === UnBatchedRouteIndex;
          let requiredTime = info.getValue();

          let hour = Math.floor(Math.floor((requiredTime as number) / 60) / 60) || 0;
          let min = Math.floor(Math.floor((requiredTime as number) / 60) % 60) || 0;

          return (
            <Stack direction="row" spacing={40}>
              <div style={{ minWidth: '56px' }}>
                {info.row.original?.type === 'break'
                  ? '-'
                  : !requiredTime
                  ? '00 : 00'
                  : `${hour < 10 ? `0${hour}` : hour} : ${min < 10 ? `0${min}` : min}`}
              </div>

              <Stack direction="row" sx={{ margin: '0 -9px' }}>
                {info.row.original?.type === 'break' ? (
                  <RowDirectionContainer>
                    <IcHasLunch width={20} height={20} />
                  </RowDirectionContainer>
                ) : (
                  <React.Fragment>
                    {info.column
                      .getFacetedRowModel()
                      .rows.find(d => Boolean(d.original?.desiredTimeStart) || Boolean(d.original?.desiredTimeEnd)) && (
                      <RowDirectionContainer>
                        {(info.row.original?.desiredTimeStart || info.row.original?.desiredTimeEnd) && (
                          <IconDesiredTime width={20} height={20} />
                        )}
                      </RowDirectionContainer>
                    )}

                    {info.column.getFacetedRowModel().rows.find(d => d.original?.skill) && (
                      <RowDirectionContainer>
                        {info.row.original?.skill &&
                          (domain === 'roouty' ? (
                            <IconTruck width={20} height={20} />
                          ) : (
                            <IconPerson width={20} height={20} />
                          ))}
                      </RowDirectionContainer>
                    )}
                  </React.Fragment>
                )}
              </Stack>
            </Stack>
          );
        },
      }),

      routePlanConfirmTable.createDataColumn(
        row => (row.route && row.route.external?.eta ? dayjs(row.route.external?.eta).format('HH:mm') : '-'),
        {
          id: 'eta',
          header: '도착 예정',
          cell: info => {
            if (info.row.original?.route?.external?.routeIndex === UnBatchedRouteIndex) {
              return '-';
            }
            return info.getValue();
          },
        }
      ),

      routePlanConfirmTable.createDataColumn(row => row.shipmentType, {
        id: 'shipmentType',
        header: '유형',
        cell: info => {
          let rowShipmentType = info.getValue();
          return React.createElement(
            shipment_type.find(d => d.value === rowShipmentType)?.icon[
              isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
            ] ?? 'div',
            {
              width: 20,
              height: 20,
            }
          );
        },
        footer: info => info.column.id,
      }),
      routePlanConfirmTable.createDataColumn(
        row =>
          row.type === 'break'
            ? '점심시간'
            : row?.address
            ? row.address + (row?.detailAddress ? ', ' + row?.detailAddress : '')
            : '-',
        {
          id: 'address',
          header: () => '주소',
        }
      ),
      routePlanConfirmTable.createDisplayColumn({
        id: 'details',
        header: ({ instance }) => strings.주문상세,
        cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableDeatilButton
              deactive={row.original?.type !== 'order'}
              onClick={() => {
                if (row.original?.type === 'order') {
                  row.original?.orderId && setTarget(row.original.orderId);
                  setOrderDetailModalOpen(true);
                }
              }}
            >
              ︙
            </TableDeatilButton>
          </div>
        ),
        enableSorting: false,
      }),
    ],
    [anchorIsOpen, domain, isHover]
  );

  const instance = useTableInstance(routePlanConfirmTable, {
    data: data?.filter(d => !(d.type === 'end' && !Boolean(d.address))) || [],
    columns,
    state: {
      sorting,
      rowSelection,
      columnVisibility,
      pagination,
    },
    initialState: {},
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: customSetRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [data]);

  return (
    <React.Fragment>
      <TableComponent
        table={instance}
        isDataFetching={false}
        disableOptions={disableOptions}
        draggableRow
        {...{ pagination, setPagination, setIsHover }}
      />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}
      {target && (
        <OrderDetailModal noModi {...{ target, isOpen: orderDetailModalOpen, setIsOpen: setOrderDetailModalOpen }} />
      )}
    </React.Fragment>
  );
}

export default RoutePlanConfirmTable;
