import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import * as S from './style';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as SpinLoader } from 'constants/icon/spin_loader.svg';
import { Card } from 'components/Card';
import { CloseConfirmModal } from './OrderDetailModal';
import useModal from 'hooks/useModal';

export interface ModalProps {
  children: React.ReactNode;

  isLoadingModal?: boolean;
  loadingPlaceholder?: React.ReactNode | string;

  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalFooter?: React.ReactNode;

  padding?: number;

  width?: number;
  height?: string;

  zIndex?: number;

  closeCallbackFunc?: any;
  confirm?: { message: string | ReactNode; value: boolean; RBT?: string; LBT?: string };
  validateToClose?: Function;

  // header나 function 이 엮이지 않은 단순한 모달
  plain?: boolean;
  bd?: boolean;

  ds?: 'weak' | 'normal' | 'strong';

  //modal header 관련 부분
  title?: string; // modal header title
  ms?: number; //modal header와 children 사이 거리
  nomt?: boolean;
  sx?: React.CSSProperties;
}

const Modal = ({
  closeCallbackFunc,
  isLoadingModal,
  loadingPlaceholder,
  modalFooter,
  plain,
  ds,
  ms,
  title,
  sx,
  isModalOpen,
  setIsModalOpen,
  padding = 0,
  width,
  height,
  children,
  validateToClose,
  confirm,
  nomt,
  ...props
}: ModalProps) => {
  const { closeConfirmModalIsOpen, setCloseConfirmModalIsOpen } = useModal();

  // const whenESCKeydown = (e: KeyboardEvent) => {
  //   if (e.code === 'Escape') {
  //     console.log(validateToClose, confirm, closeCallbackFunc);

  //     if (validateToClose) {
  //       return validateToClose();
  //     }
  //     if (confirm && confirm.value) {
  //       return setCloseConfirmModalIsOpen(true);
  //     }
  //     setIsModalOpen(false);
  //     closeCallbackFunc && closeCallbackFunc();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('keydown', whenESCKeydown);
  //   return () => {
  //     window.removeEventListener('keydown', whenESCKeydown);
  //   };
  // }, []);

  return (
    <React.Fragment>
      {isModalOpen ? (
        plain ? (
          <S.Modal {...props}>
            <Card variant={'menu'} {...{ width, padding: padding ?? isLoadingModal ? 24 : 0, sx, ds }}>
              <S.ScrollWrapper nomt={nomt}>
                <Stack spacing={ms}>
                  {isLoadingModal ? (
                    <Stack spacing={30} sx={{ padding: '10px 0 20px' }}>
                      <SpinLoader style={{ width: '44px', height: '44px' }} className="infinite_rotating" />

                      {loadingPlaceholder ? (
                        loadingPlaceholder
                      ) : (
                        <Text styleName="subheadline3" color="RG03">
                          잠시만 기다려주세요.
                        </Text>
                      )}
                    </Stack>
                  ) : (
                    children
                  )}
                </Stack>
              </S.ScrollWrapper>
            </Card>
          </S.Modal>
        ) : (
          <>
            <S.Modal {...props}>
              <S.ScrollWrapper nomt={nomt}>
                <Card variant={'menu'} {...{ width, padding, sx, ds }}>
                  <Stack spacing={(ms as number) / 2 || 0} sx={{ height: '100%' }}>
                    <S.ModalHeader>
                      <Text styleName="subheadline2" color="RG04">
                        {title}
                      </Text>

                      <IconClose
                        onClick={() => {
                          if (validateToClose) {
                            return validateToClose();
                          }
                          if (confirm && confirm.value) {
                            return setCloseConfirmModalIsOpen(true);
                          }
                          setIsModalOpen(false);
                          closeCallbackFunc && closeCallbackFunc();
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </S.ModalHeader>
                    <S.ModalBody style={{ paddingTop: (ms as number) / 2 || 0 }}>
                      <Stack spacing={30}>
                        {isLoadingModal ? (
                          <React.Fragment>
                            <SpinLoader style={{ width: '44px', height: '44px' }} className="infinite_rotating" />
                            {children}
                            {loadingPlaceholder ? (
                              loadingPlaceholder
                            ) : (
                              <Text styleName="subheadline3" color="RG03">
                                잠시만 기다려주세요.
                              </Text>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>{children}</React.Fragment>
                        )}
                      </Stack>
                    </S.ModalBody>
                    {modalFooter && modalFooter}
                  </Stack>
                </Card>
              </S.ScrollWrapper>
            </S.Modal>

            <CloseConfirmModal
              isOpen={closeConfirmModalIsOpen}
              setIsOpen={setCloseConfirmModalIsOpen}
              targetSetIsOpen={setIsModalOpen}
              text={confirm?.message}
              RBT={confirm?.RBT ?? '이어서 수정하기'}
              LBT={confirm?.LBT ?? '종료'}
            />
          </>
        )
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default Modal;
