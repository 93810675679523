import {
  PaginationState,
  SortingState,
  createTable,
  useTableInstance,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { getDriverInfo, getVehicleInfo, postPsLink } from 'api';
import { IDriverUserList } from 'constants/types';
import React, { Dispatch, SetStateAction, useEffect, useState, FocusEvent, useMemo } from 'react';
import { UseFormProps } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useStore } from 'store';
import strings from 'util/Localization';
import DeactiveModal from 'components/Modal/DeactiveModal';
import ModifyUserModal from 'components/Modal/ModifyUserModal';
import TableComponent from '.';
import { Text } from 'components/Typography';
import { Button } from 'components/Button';
import { validateAccountStatus } from 'util/validateStatus';
import { Tag } from 'components/Tag';
import dayjs from 'dayjs';

let driverManagementTable = createTable().setRowType<IDriverUserList>();

function DriverManagementTable({
  mutateDeactivateMember,
  mutateSetDriverInfo,
  modifyMemberModalIsOpen,
  setModifyMemberModalIsOpen,
  mutateActivateMember,
}: {
  mutateDeactivateMember: Function;
  mutateSetDriverInfo: Function;
  modifyMemberModalIsOpen: boolean;
  setModifyMemberModalIsOpen: Dispatch<SetStateAction<boolean>>;
  mutateActivateMember: Function;
}) {
  const { data: drivers, isFetching: getDriverListIsFetching } = useQuery(['drivers'], getDriverInfo, {});

  const { userGrade } = useStore();

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'status',
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100000,
  });

  const [userId, setUserId] = useState<number>(-1);
  const [target, setTarget] = useState<null | string>(null);
  const [targetUser, setTargetUser] = useState<null | string>(null);
  const [deactiveUserModalOpen, setDeactiveUserModalOpen] = useState<boolean>(false);

  const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>({ vehicleId: false });

  const [timePickerPopupOpen, setTimePickerPopupOpen] = useState<boolean>(false);
  const [useFormProps, setUseFormProps] = useState<UseFormProps>({ mode: 'onSubmit', defaultValues: {} });

  const {
    data: vehicles,
    isSuccess: getVehicleListIsSuccess,
    refetch: refetchVehicle,
  } = useQuery(['vehicles'], getVehicleInfo, {});

  const { mutate: mutatePostPsLink } = useMutation(postPsLink, {
    onSuccess: () => {
      alert('계정에 설정된 연락처로\n비빌번호 재설정 링크를 보냈습니다.');
    },
    onError: () => {
      alert('비밀번호 재설정 링크 발송에 실패했습니다.\n다시 시도해주세요.');
    },
  });

  function isActiveStatus(status: 'invited' | 'accepted' | 'activated' | 'deactivated') {
    if (status === 'activated') return true;
    return false;
  }

  // 유저 권한에 따른 column 열람 제한
  useEffect(() => {
    !(userGrade <= 1) && setColumnVisibility({ ...columnVisibility, modify: false, manage: false });
  }, []);

  const columns = useMemo(
    () => [
      driverManagementTable.createDataColumn(row => row.status, {
        id: 'status',
        header: () => '계정상태',
        cell: info => {
          let { key, name } = validateAccountStatus(
            info.getValue() as 'invited' | 'accepted' | 'activated' | 'deactivated'
          );
          return (
            <Tag
              styleName="caption2"
              status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
              sx={{ width: '56px' }}
            >
              {name}
            </Tag>
          );
        },
        enableSorting: false,
      }),
      driverManagementTable.createDataColumn(row => row.name, {
        id: 'name',
        header: () => '이름',
        enableSorting: true,
        enableMultiSort: true,
      }),
      driverManagementTable.createDataColumn(row => row?.phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`), {
        id: 'phone',
        header: () => '연락처(ID)',
        enableSorting: false,
      }),
      driverManagementTable.createDataColumn(row => row.vehicle?.licenseNumber, {
        id: 'vehicleInfo',
        header: () => '차량번호',
        cell: info =>
          info.getValue() ? (
            info.getValue()
          ) : (
            <Text styleName="caption3" color="RG05">
              차량미등록
            </Text>
          ),
        enableSorting: true,
        enableMultiSort: true,
      }),
      driverManagementTable.createDataColumn(row => (row?.workStartTime ? row.workStartTime : '-'), {
        id: 'workStartTime',
        header: () => '업무 시작 시간',
        enableSorting: true,
        enableMultiSort: true,
      }),
      driverManagementTable.createDataColumn(
        row =>
          `${(row.acceptedAt && dayjs(row.acceptedAt).format('YYYY.MM.DD')) || '--'} / ${
            (row.invitedAt && dayjs(row.invitedAt).format('YYYY.MM.DD')) || '--'
          }`,
        {
          id: 'date',
          header: () => '등록 일자 / 초대 일자',
          enableSorting: true,
          enableMultiSort: true,
        }
      ),
      driverManagementTable.createDataColumn(row => row.vehicle?.vehicleId, {
        id: 'vehicleId',
        header: () => '할당된차량ID',
      }),
      driverManagementTable.createDisplayColumn({
        id: 'modify',
        header: () => '수정',
        cell: ({ row }) => (
          <Button
            variant={'second'}
            type={'button'}
            sx={{ padding: '3px 6px' }}
            onClick={() => {
              setUseFormProps({
                defaultValues: {
                  phone: row.getValue('phone'),
                  password: '********',
                  name: row.getValue('name'),
                  vehicleId: row.getValue('vehicleId'),
                  workStartTime: row.getValue('workStartTime'),
                  // RT-899
                  hasLunch: row.original?.hasLunch,
                },
              });
              setUserId(row.original?.userId as number);
              setTarget(`${row.original?.driverId}`);
              setModifyMemberModalIsOpen(true);
            }}
          >
            <Text styleName="caption2" color="RG04">
              수정
            </Text>
          </Button>
        ),
        enableSorting: false,
      }),
      driverManagementTable.createDisplayColumn({
        id: 'manage',
        header: () => '관리',
        cell: ({ row }) => (
          <Button
            variant={!isActiveStatus(row.getValue('status')) ? 'RTB' : 'fourth'}
            type={'button'}
            sx={
              !isActiveStatus(row.getValue('status'))
                ? { padding: '3px 6px', minWidth: '56px' }
                : { padding: '3px 6px', minWidth: '56px', border: 'none', background: '#F0F3F9' }
            }
            onClick={() => {
              if (isActiveStatus(row.getValue('status'))) {
                // RT-838
                setTarget(`${row?.original?.userId}`);
                setTargetUser(`${row.getValue('name')} ${row.getValue('phone')}`);
                setDeactiveUserModalOpen(true);
                return;
              }

              mutateActivateMember(row.original?.userId);
            }}
            styleName={'caption2'}
          >
            {!isActiveStatus(row.getValue('status')) ? '활성화' : '정지'}
          </Button>
        ),

        enableSorting: false,
      }),
    ],
    []
  );

  const instance = useTableInstance(driverManagementTable, {
    data: drivers?.memberList ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    initialState: {},
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const modifyForm = [
    { type: 'Input', name: 'phone', label: '아이디', disabled: true },
    {
      type: 'Input',
      name: 'password',
      label: '비밀번호',
      disabled: true,
      rightArea: {
        rightContent: '재설정 링크 보내기',
        rightFunc: () => {
          mutatePostPsLink(userId as number);
        },
      },
    },
    { type: 'Input', name: 'name', label: '이름', required: true },
    {
      type: 'Select',
      name: 'vehicleId',
      label: '차량 정보',
      placeholder: '차량을 선택해 주세요',
      options: vehicles?.vehicleList.map(d => {
        return { value: d.vehicleId, key: d.licenseNumber };
      }),

      selectBottomLabel: (
        <Text styleName="caption3" color="RC03">
          신규 차량은 ‘차량 관리'에서 등록할 수 있습니다.
        </Text>
      ),
    },
    // RT-764
    {
      type: 'Input',
      name: 'workStartTime',
      label: '업무 시작 시간',
      timePicker: {
        open: timePickerPopupOpen,
        setOpen: setTimePickerPopupOpen,
      },
      onClick: () => setTimePickerPopupOpen(true),
      onFocus: (e: FocusEvent<HTMLInputElement>) => e.target.blur(),
      readonly: true,
    },

    // RT-982
    {
      type: 'Select',
      name: 'hasLunch',
      label: `${strings.드라이버} 점심시간`,
      options: [
        { value: true, key: '설정 (1시간)' },
        { value: false, key: '미설정 (점심시간 없음)' },
      ],
    },
  ];

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [drivers?.memberList]);

  return (
    <React.Fragment>
      <TableComponent
        table={instance}
        isDataFetching={getDriverListIsFetching}
        disableOptions={{
          totalCount: true,
          selectCount: true,
          pagnation: true,
          pageSizeSelector: true,
        }}
        {...{ pagination, setPagination }}
      />
      <DeactiveModal
        isOpen={deactiveUserModalOpen}
        setIsOpen={setDeactiveUserModalOpen}
        target={`${target}`}
        targetName={`${targetUser}`}
        text={'선택하신 계정을 정지 하시겠습니까?'}
        highlight={'이후 해당 계정 이용이 불가합니다.'}
        returnFunc={mutateDeactivateMember}
        RBT={'정지하기'}
        LBT={'취소'}
      />
      <ModifyUserModal
        {...{
          target,
          useFormProps,
          modifyForm,
          mutateSetInfo: mutateSetDriverInfo,
          modifyMemberModalIsOpen,
          setModifyMemberModalIsOpen,
        }}
      />
    </React.Fragment>
  );
}

export default DriverManagementTable;
