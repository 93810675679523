import styled from 'styled-components';
import arrowIcon from '../../constants/icon/ic_arrow_count_down.svg';
import { InputProps, SelectProps } from '.';
import { Stack } from 'components/Stack/style';

interface LabelAreaProps {
  rightArea?: string | React.ReactNode;
  label?: string | React.ReactNode;
}

export const Input = styled.input<InputProps>`
  ${({ theme, field, variant }) => {
    if (variant === 'third' || variant === 'second') return theme.fontStyle.caption2;
    else if (field) return theme.fontStyle.caption3;
    else return theme.fontStyle.subheadline3;
  }}

  display: block;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: transparent;

  ${({ theme, height, hasError, field, xAxisPadding, variant }) => {
    if (variant === 'second')
      return {
        height: height || '48px',

        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC02,
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        ':focus , :hover': {
          '::placeholder': { color: theme.colors.RC05 },
        },
        textAlign: 'center',
        backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG00,
        border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG06}`,
        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',
      };
    else if (variant === 'third')
      return {
        height: height || '48px',
        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC02,
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        '::placeholder': { color: theme.colors.RG05 },
        textAlign: 'left',

        backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG00,
        border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG06}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',
      };
    else if (variant === 'fourth')
      return {
        height: height || '48px',
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC03,
        '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },

        ':focus , :hover': {
          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },
        },

        backgroundColor: field ? theme.colors.RG08 : hasError ? theme.colors.RC04_1 : theme.colors.RG08,
        border: `1px solid ${field ? theme.colors.RG07 : hasError ? theme.colors.RC04 : theme.colors.RG07}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',

        ':disabled': {
          color: `${field && theme.colors.RG04}`,
          backgroundColor: `${field && theme.colors.RG07}`,
        },
      };
    else
      return {
        height: height || '48px',
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC03,

        ':focus , :hover': {
          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RC05 },
        },
        ':placeholder-shown': {
          backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG08,
          border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG07}`,

          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },
        },

        backgroundColor: field ? theme.colors.RG08 : hasError ? theme.colors.RC04_1 : theme.colors.RG00,
        border: `1px solid ${field ? theme.colors.RG07 : hasError ? theme.colors.RC04 : theme.colors.RG06}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',

        ':disabled': {
          color: `${field && theme.colors.RG04}`,
          backgroundColor: `${field && theme.colors.RG07}`,
        },
      };
  }}

  ${({ theme, hasError }) => ({
    backgroundColor: hasError && theme.colors.RC04_1,
    border: `1px solid ${hasError && theme.colors.RC04}`,
  })}

  :disabled {
    pointer-events: none;
  }

  :focus,
  :hover {
    ${({ theme, hasError }) => ({
      backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RC03_2,
      border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RC03}`,
    })}
  }
`;

// 재설정 링크 외 다른 바리에이션이 필요할 땐 삭제하고 node로 받아 사용하도록 합니다
export const RightArea = styled.div`
  ${({ theme }) => theme.fontStyle.caption3}
  ${({ theme }) => ({ color: theme.colors.RC02 })}

  text-decoration : underline;
  cursor: pointer;
`;

export const Label = styled.label`
  ${({ theme }) => theme.fontStyle.caption3}

  color: ${({ theme }) => theme.colors.RG03};
`;

export const InputWrapper = styled(Stack)`
  align-items: flex-start;
  width: 100%;
  text-align: left;
`;

export const LabelArea = styled.div<LabelAreaProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ rightArea, label }) => (rightArea && label ? 'space-between' : label ? 'start' : 'end')};
`;

export const Select = styled.select<SelectProps>`
  ${({ theme }) => theme.fontStyle.caption3}
  ${({ theme, field, height }) =>
    field
      ? {
          height: height || '48px',
          display: 'block',
          width: '100%',
          padding: '10px',
          borderRadius: '8px',
          boxSizing: 'border-box',

          background: `url(${arrowIcon}) no-repeat 99% 50%`,
          appearance: 'none',
          backgroundColor: field ? theme.colors.RG08 : theme.colors.RG00,
          border: `1px solid ${field ? theme.colors.RG07 : theme.colors.RG06}`,
        }
      : {}}
`;

export const SelectBottomLabel = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;

  padding: 6px 20px 24px 20px;
`;
