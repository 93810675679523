import styled from 'styled-components';

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.RG08};
  width: 32px;
  padding: 8px;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    svg > path {
      stroke: ${({ theme }) => theme.colors.RC02};
    }
    background: ${({ theme }) => theme.colors.RC03_1};
  }
  :disabled {
    cursor: not-allowed;
  }

  transition: background 0.3s ease;
`;

export const PageListBox = styled.div<{ active: boolean }>`
  ${({ theme }) => theme.fontStyle.caption1}

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  border-radius: 12px;
  aspect-ratio: 1 / 1;

  cursor: pointer;

  ${({ theme, active }) =>
    active
      ? {
          color: theme.colors.RG00,
          background: theme.colors.RC03,
        }
      : {
          color: theme.colors.RG02,
          background: theme.colors.RG00,

          ':hover': {
            background: theme.colors.RC03_3,
          },
        }}

  transition: background .3s ease;
`;
