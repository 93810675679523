import React, { useEffect, useState } from 'react';
import { Stack } from 'components/Stack';
import { useQuery } from 'react-query';

import { getOrderReport } from 'api';
import { IOrderReportDimensionRoute, IOrderReportMetrics, IReportQuery, IReportResponsesList } from 'constants/types';

import { Text } from 'components/Typography';
import ReportFilter from './utils/ReportFilter';
import PageContentWrapper from 'components/PageContentWrapper';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { dimension_options_default, metrics_options } from 'constants/commons';
import OrderReportTable from 'components/Table/OrderReportTable';
import { ReportDownloadModal } from 'components/Modal/ReportDownloadModal';

const Order = () => {
  const [orderReportDownloadModalIsOpen, setOrderReportDownloadModalIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<IReportQuery | undefined>();
  const [dimensionSelectorOptions, setDimensionSelectorOptions] = useState<TDragablePickerOptions>(
    Array.from(
      dimension_options_default.map(d => {
        if (`${d.key}`.includes('route')) {
          return { ...d, isSelected: false };
        }
        return d;
      })
    )
  );
  const [metricsSelectorOptions, setMetricsSelectorOptions] = useState<TDragablePickerOptions>(
    Array.from(metrics_options['order'])
  );

  const [rowSelection, setRowSelection] = useState({});

  const { data, refetch, isLoading } = useQuery(
    ['getOrderReport', filter],
    () => filter && getOrderReport({ ...filter }),
    {
      enabled: filter !== undefined,
    }
  );
  const [refinedData, setRefinedData] = useState<
    Array<IReportResponsesList<IOrderReportDimensionRoute, IOrderReportMetrics>>
  >([]);

  const [orderReportDimensionMetrics, setOrderReportDimensionMetrics] = useState<TDragablePickerOptions>(() => {
    let ordm = Array.from(metricsSelectorOptions);

    dimensionSelectorOptions.forEach((d, i) => {
      if (i === 0) {
        ordm.splice(2, 0, d);
      }
      if (i === 1) {
        ordm.splice(1, 0, d);
      }
      if (i === 2 || i === 3) {
        ordm.splice(5, 0, d);
      } else return;
    });
    return ordm;
  });

  useEffect(() => {
    setOrderReportDimensionMetrics(() => {
      let ordm = Array.from(
        metricsSelectorOptions.map(d => {
          if (d.default) {
            return { ...d, isSelected: true };
          }
          return { ...d, isSelected: false };
        })
      );

      Array.from(
        dimension_options_default.map(d => {
          if (`${d.key}`.includes('route') || `${d.key}`.includes('driver')) {
            return { ...d, isSelected: false };
          }
          return { ...d, isSelected: true };
        })
      ).forEach((d, i) => {
        if (i === 0) {
          ordm.splice(2, 0, d);
        }
        if (i === 1) {
          ordm.splice(1, 0, d);
        }
        if (i === 2 || i === 3) {
          ordm.splice(5, 0, d);
        } else return;
      });
      return ordm;
    });
  }, []);

  useEffect(() => {
    setDimensionSelectorOptions([
      ...orderReportDimensionMetrics.filter(d => dimensionSelectorOptions.map(x => x.key).includes(d.key)),
    ]);
    setMetricsSelectorOptions([
      ...orderReportDimensionMetrics.filter(d => metricsSelectorOptions.map(x => x.key).includes(d.key)),
    ]);
  }, [orderReportDimensionMetrics]);

  useEffect(() => {
    // console.log(data);
    // console.log(filter);
    refetch();

    if (data) {
      let destructedOrderReportMetricsReportList: Array<
        IReportResponsesList<IOrderReportDimensionRoute, IOrderReportMetrics>
      > = [];

      data.orderList.map(d =>
        d.metrics.map(x => {
          return destructedOrderReportMetricsReportList.push({ dimension: d.dimension, metrics: { ...x } });
        })
      );

      setRefinedData(destructedOrderReportMetricsReportList);
    }
  }, [data, filter, refetch]);

  return (
    <PageContentWrapper>
      <Stack useHorizontalScroll align="start" sx={{ padding: '48px 20px' }}>
        <Text styleName="title1" color="RG03" sx={{ marginBottom: 20 }}>
          주문 정보
        </Text>

        <Stack spacing={12} align="start" sx={{ width: '100%', minWidth: '1293px' }}>
          <ReportFilter
            type="order"
            setDownloadModalIsOpen={setOrderReportDownloadModalIsOpen}
            {...{
              setFilter,
              dimensionSelectorOptions,
              setDimensionSelectorOptions,
              metricsSelectorOptions,
              setMetricsSelectorOptions,
              orderReportDimensionMetrics,
              setOrderReportDimensionMetrics,
            }}
          />
          <ReportDownloadModal
            selected={[...Object.keys(rowSelection).map(x => parseInt(x))]}
            total={refinedData}
            combined={orderReportDimensionMetrics}
            isOpen={orderReportDownloadModalIsOpen}
            setIsOpen={setOrderReportDownloadModalIsOpen}
            reportType="order"
          />
          <OrderReportTable
            reportData={refinedData}
            {...{
              isFetching: isLoading,
              orderReportDimensionMetrics,
              rowSelection,
              setRowSelection,
            }}
          />
        </Stack>
      </Stack>
    </PageContentWrapper>
  );
};

export default Order;
