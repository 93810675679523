import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { SelectorContainer, SelectorItem, SelectorDisplayBox } from './style';

import { ReactComponent as IcArrowDF } from 'constants/icon/ic_arrow_double_faced.svg';
import theme from 'constants/theme';

interface SelectorProps {
  defaultValue?: any;

  anchor?: 'top' | 'bottom';
  options: Array<{ key: string; value: any }>;
  onOptionsClick: (item: { key: string; value: any }) => void;
}

const Selector = ({ anchor = 'bottom', defaultValue, options, onOptionsClick }: SelectorProps) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [displayContent, setDisplayContent] = useState<string>(
    defaultValue ? `${options.find(d => d.value === defaultValue)?.key}` : 'placeholder'
  );

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!selectorRef.current?.contains(e.target) && !containerRef.current?.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, selectorRef]);

  return (
    <Stack sx={{ flexBasis: 'content', position: 'relative' }}>
      <SelectorDisplayBox
        onClick={() => {
          setIsOpen(prev => !prev);
        }}
        ref={containerRef}
      >
        <Text styleName="caption2" color="RG03">
          {displayContent}
        </Text>
        <IcArrowDF fill={theme.colors.RG04} />
      </SelectorDisplayBox>
      {isOpen && (
        <PopupYIndexFixer>
          <SelectorContainer width={216} spacing={10} ref={selectorRef} anchor={anchor}>
            <Stack align="start">
              {options.map((d, index) => {
                return (
                  <SelectorItem
                    key={`${d.key}-selector-${index}`}
                    onClick={() => {
                      onOptionsClick(d);
                      setDisplayContent(d.key);
                      setIsOpen(false);
                    }}
                  >
                    {d.key}
                  </SelectorItem>
                );
              })}
            </Stack>
          </SelectorContainer>
        </PopupYIndexFixer>
      )}
    </Stack>
  );
};

export default Selector;
