import styled, { css } from 'styled-components';
import { Stack } from '../Stack';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  height: 72px;
  background: ${({ theme }) => theme.colors.RG02};
  z-index: 3;
`;

export const LogoArea = styled.div`
  width: 100%;
`;

export const NavArea = styled(Stack)`
  min-width: fit-content;
  padding: 0 20px;
`;

const IconBaseStyle = css`
  width: 20px;
  height: 20px;

  fill: ${({ theme }) => theme.colors.RG05};

  :hover {
    fill: ${({ theme }) => theme.colors.RC03};
  }

  transition: all 0.2s;
`;
export const IconArea = styled(Stack)`
  .isOnActive {
    ${IconBaseStyle};
    fill: ${({ theme }) => theme.colors.RC03};
  }
  .isInActive {
    ${IconBaseStyle};
  }
`;

export const ProfileArea = styled(Stack)<{ isActive: boolean }>`
  width: fit-content;
  height: 36px;
  background: ${({ theme }) => theme.colors.RC03_1};
  padding: 0 8px;
  opacity: 0.77;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;

  ${({ isActive, theme }) => isActive && { opacity: 1, background: theme.colors.RC03_3 }}
  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.colors.RC03_3};
  }
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const ProfileIcon = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export const IConAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const PageContentWrapper = styled.div`
  padding: 0;
`;
