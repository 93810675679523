import React, { useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  DriverIdUnSelected,
  IEqualOptimizeResponses,
  IEqualOptimizeResponsesDriversOrderList,
  UnBatchedDriverId,
} from 'constants/types';
import { Polyline } from './DeckGL/Polyline';
import './style.css';
import { NewOrderMarker, OrderMarker, StartEndMarker, UnAssignedMarker } from './components/CustomMarker';
import { initDrawingManager } from './components/DrawingManager';
import { MapConfigDefaultProps, MapConfigKeys, MapConfigOptions } from './MapConfig';
import { MovedOrder } from '../../pages/RoutePlanConfirm';
import { GoogleMapsOverlay } from './DeckGL/GoogleMapsOverlay';

interface RoutePlanConfirmMapProps {
  optimizeEqualData?: IEqualOptimizeResponses;
  selectedDriverId?: number;
  movedOrderIds: MovedOrder[];
  selectedOrder: any[];
  setSelectedOrder: React.Dispatch<React.SetStateAction<any[]>>;
  currentOrders: IEqualOptimizeResponsesDriversOrderList[];
  editingOrders: IEqualOptimizeResponsesDriversOrderList[];
}

export function RoutePlanConfirmMap({
  optimizeEqualData,
  selectedDriverId,
  movedOrderIds,
  selectedOrder,
  setSelectedOrder,
  currentOrders,
  editingOrders,
}: RoutePlanConfirmMapProps) {
  const [mapInstance, setMapInstance] = React.useState<any>(null);
  const [mapsInstance, setMapsInstance] = React.useState<any>(null);

  const [overlayInstance, setOverlayInstance] = React.useState<GoogleMapsOverlay | null>(null);
  const [drawingInstance, setDrawingInstance] = React.useState<any>(null);
  const [draggedBound, setDraggedBound] = React.useState<any>(null);
  useEffect(() => {
    if (
      mapsInstance &&
      mapInstance &&
      (selectedDriverId === UnBatchedDriverId || (selectedDriverId === DriverIdUnSelected && optimizeEqualData))
    ) {
      let bounds = new mapsInstance.LatLngBounds();
      optimizeEqualData?.driverList.forEach(x => {
        x.orderList.forEach(y => {
          y.coordinate && bounds.extend({ lat: y.coordinate?.coordinates[1], lng: y.coordinate?.coordinates[0] });
        });
      });
      setTimeout(
        () => {
          mapInstance.fitBounds(bounds, 120);
        },
        selectedDriverId === DriverIdUnSelected ? 100 : 0
      );
    }
  }, [mapsInstance, mapInstance, optimizeEqualData, selectedDriverId]);

  useEffect(() => {
    if (mapsInstance && mapInstance) {
      let boundsEdited = false;
      let bounds = new mapsInstance.LatLngBounds();
      currentOrders.forEach(
        x => x.coordinate && bounds.extend({ lat: x.coordinate?.coordinates[1], lng: x.coordinate?.coordinates[0] })
      );
      editingOrders.forEach(
        x => x.coordinate && bounds.extend({ lat: x.coordinate?.coordinates[1], lng: x.coordinate?.coordinates[0] })
      );

      if (selectedDriverId !== DriverIdUnSelected) {
        optimizeEqualData?.driverList.forEach(x => {
          if (x.driverId === selectedDriverId && x.vehicle) {
            if (x.vehicle.startCoordinate || x.vehicle.endCoordinate) boundsEdited = true;
            x.vehicle.startCoordinate &&
              bounds.extend({
                lat: x.vehicle.startCoordinate.coordinates[1],
                lng: x.vehicle.startCoordinate.coordinates[0],
              });
            x.vehicle.endCoordinate &&
              bounds.extend({
                lat: x.vehicle.endCoordinate.coordinates[1],
                lng: x.vehicle.endCoordinate.coordinates[0],
              });
          }
        });
      }
      (currentOrders.length + editingOrders.length > 0 || boundsEdited) && mapInstance.fitBounds(bounds, 120);
    }
  }, [mapsInstance, mapInstance, currentOrders, editingOrders]);

  useEffect(() => {
    if (draggedBound == null) return;
    let order: IEqualOptimizeResponsesDriversOrderList[] = [];
    currentOrders.concat(editingOrders).forEach(x => {
      if (x.coordinate) {
        let position = { lat: x.coordinate.coordinates[1], lng: x.coordinate.coordinates[0] };
        if (draggedBound.contains(position)) {
          if (selectedOrder.filter(d => d.orderId === x.orderId).length === 0) order.push(x);
        }
      }
    });
    setSelectedOrder([...selectedOrder, ...order]);
    setDraggedBound(null);
  }, [draggedBound]);

  const onDrawingEventCalled = (bound: any) => {
    setDraggedBound(bound);
  };

  function onMapLoaded(map: any, maps: any) {
    if (!mapInstance) setMapInstance(map);
    if (!mapsInstance) setMapsInstance(maps);
    if (!drawingInstance) {
      let drawingManager = initDrawingManager({ map, maps, onEvent: onDrawingEventCalled });
      setDrawingInstance(drawingManager);
    }
    if (!overlayInstance) {
      let overlay = new GoogleMapsOverlay({});
      setOverlayInstance(overlay);
      overlay.setMap(map);
    }
  }

  function selectOrderByMarkerSelect(orderId: number) {
    if (selectedOrder.filter(x => x.orderId === orderId).length > 0) {
      setSelectedOrder(selectedOrder.filter(x => x.orderId !== orderId));
      return;
    }
    let order: IEqualOptimizeResponsesDriversOrderList[] = [];

    currentOrders.concat(editingOrders).forEach(x => {
      if (x.orderId === orderId) order.push(x);
    });
    setSelectedOrder([...selectedOrder, ...order]);
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={MapConfigKeys}
      options={MapConfigOptions}
      defaultCenter={MapConfigDefaultProps.center}
      defaultZoom={MapConfigDefaultProps.zoom}
      onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
    >
      {optimizeEqualData?.driverList && overlayInstance && (
        <Polyline
          overlay={overlayInstance}
          optimizeDrivers={optimizeEqualData?.driverList}
          responseDrivers={[]}
          selectedDriverId={selectedDriverId}
        />
      )}

      {(optimizeEqualData?.driverList || []).map((x, index) => {
        let markers = [];
        x.vehicle &&
          x.vehicle.startCoordinate &&
          markers.push(
            <StartEndMarker
              {...{
                lat: x.vehicle.startCoordinate?.coordinates[1],
                lng: x.vehicle.startCoordinate?.coordinates[0],
                displayType: 'start',
                content: '시작',
                driverName: x.name ?? '',
                colorIndex: x.driverId ?? 0,
                zIndex: -2002,
                alpha:
                  (selectedDriverId || DriverIdUnSelected) > DriverIdUnSelected
                    ? selectedDriverId === x.driverId
                      ? 1
                      : 0.3
                    : 1,
              }}
            />
          );
        x.vehicle &&
          x.vehicle.endCoordinate &&
          markers.push(
            <StartEndMarker
              {...{
                lat: x.vehicle.endCoordinate?.coordinates[1],
                lng: x.vehicle.endCoordinate?.coordinates[0],
                displayType: 'end',
                content: '종료',
                zIndex: -2003,
                alpha:
                  (selectedDriverId || DriverIdUnSelected) > DriverIdUnSelected
                    ? selectedDriverId === x.driverId
                      ? 1
                      : 0.3
                    : 1,
              }}
            />
          );
        x.orderList
          .filter(b => b.orderId)
          .filter(z => movedOrderIds.filter(m => z.orderId === m.id).length > 0)
          .forEach(o => {
            let item = movedOrderIds.filter(m => o.orderId === m.id)[0];

            if (item.targetDriver === UnBatchedDriverId) {
              markers.push(
                <UnAssignedMarker
                  onClick={() => {
                    o.orderId && selectOrderByMarkerSelect(o.orderId);
                  }}
                  {...{
                    lat: o.coordinate?.coordinates[1],
                    lng: o.coordinate?.coordinates[0],
                    displayType: 'unassigned',
                    zIndex: 1,
                    chips: {
                      truck: !!o.skill,
                      time: !!(o.desiredTimeEnd || o.desiredTimeStart),
                    },
                    isSelected: selectedOrder.filter(g => g.orderId === o.orderId).length !== 0,
                  }}
                />
              );
            } else {
              markers.push(
                <NewOrderMarker
                  onClick={() => {
                    o.orderId && selectOrderByMarkerSelect(o.orderId);
                  }}
                  {...{
                    lat: o.coordinate?.coordinates[1],
                    lng: o.coordinate?.coordinates[0],
                    displayType: 'new',

                    isSelected: selectedOrder.filter(g => g.orderId === o.orderId).length !== 0,
                    chips: {
                      truck: !!o.skill,
                      time: !!(o.desiredTimeEnd || o.desiredTimeStart),
                    },
                    zIndex:
                      (selectedDriverId || DriverIdUnSelected) > DriverIdUnSelected
                        ? selectedDriverId === item.targetDriver
                          ? 1000
                          : 1
                        : 1000,
                    alpha:
                      (selectedDriverId || DriverIdUnSelected) > DriverIdUnSelected
                        ? selectedDriverId === item.targetDriver
                          ? 1
                          : 0.3
                        : 1,
                  }}
                />
              );
            }
          });

        x.orderList
          .filter(b => b.orderId)
          .filter(z => movedOrderIds.filter(m => z.orderId === m.id).length === 0)
          .forEach(o => {
            x.driverId &&
              markers.push(
                <OrderMarker
                  onClick={() => {
                    o.orderId && selectOrderByMarkerSelect(o.orderId);
                  }}
                  {...{
                    lat: o.coordinate?.coordinates[1],
                    lng: o.coordinate?.coordinates[0],
                    displayType: 'number',
                    colorIndex: x.driverId,
                    isSelected: selectedOrder.filter(g => g.orderId === o.orderId).length !== 0,
                    zIndex:
                      (selectedDriverId || DriverIdUnSelected) > DriverIdUnSelected
                        ? selectedDriverId === x.driverId
                          ? 1000
                          : o.route?.external?.displayIndex ?? 1
                        : 1000,

                    content: `${o.route?.external?.displayIndex ?? ''}`,
                    chips: {
                      truck: !!o.skill,
                      time: !!(o.desiredTimeEnd || o.desiredTimeStart),
                    },
                    alpha:
                      (selectedDriverId || DriverIdUnSelected) > DriverIdUnSelected
                        ? selectedDriverId === x.driverId
                          ? 1
                          : 0.3
                        : 1,
                  }}
                />
              );

            !x.driverId &&
              markers.push(
                <UnAssignedMarker
                  onClick={() => {
                    o.orderId && selectOrderByMarkerSelect(o.orderId);
                  }}
                  {...{
                    lat: o.coordinate?.coordinates[1],
                    lng: o.coordinate?.coordinates[0],
                    displayType: 'unassigned',
                    zIndex: 1,
                    chips: {
                      truck: !!o.skill,
                      time: !!(o.desiredTimeEnd || o.desiredTimeStart),
                    },
                    isSelected: selectedOrder.filter(g => g.orderId === o.orderId).length !== 0,
                  }}
                />
              );
          });
        return markers;
      })}
    </GoogleMapReact>
  );
}
