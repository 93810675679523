import React from 'react';

import * as S from './style';

interface AltProps extends React.ComponentProps<'div'> {
  children: string;
}

const Alt = ({ children, ...props }: AltProps): JSX.Element => {
  return <S.Alt style={props.style}>{children}</S.Alt>;
};

export default Alt;
