import React, { forwardRef, ReactNode } from 'react';
import * as S from './style';

export interface StackProps {
  children: ReactNode;

  /**  flex-direction 에 대한 방향 스타일 속성값입니다*/
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';

  divider?: ReactNode;

  /** children 요소 사이의 여백 값으로 예제에선 number를 사용합니다*/
  spacing?: string | number;

  /** Stack 컴포넌트 박스에 대한 padding 값입니다*/
  padding?: number;

  justify?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';

  align?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'revert-layer'
    | 'unset'
    | 'baseline'
    | 'stretch';

  /** CSS override를 위한 props*/
  sx?: React.CSSProperties;

  ref?: any;

  as?: any;
  name?: string;
  onClick?: () => void;
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOut?: (e: React.MouseEvent<HTMLDivElement>) => void;

  useHorizontalScroll?: boolean;
  useVerticalScroll?: boolean;
}

export const Stack = forwardRef<any, StackProps>(({ as, sx, children, divider, ...props }, ref) => {
  let countofChilds = React.Children.count(children);

  return (
    <S.Stack style={sx} {...props} as={as} key={props.name} ref={ref}>
      {React.Children.map(children, (child, index) => {
        return (
          <React.Fragment>
            {child}
            {child && index < countofChilds - 1 && divider}
          </React.Fragment>
        );
      })}
    </S.Stack>
  );
});
