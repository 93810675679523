import React from 'react';
import { Outlet } from 'react-router-dom';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { Tag } from 'components/Tag';

import { ContentWrapper, CustomNavLink, SidebarWrapper } from './SettingNav';

const ReportContents: Array<{ path: string; title: string; disabled?: boolean }> = [
  {
    title: '주행 기록',
    path: 'driving',
  },
  {
    title: '주문 정보',
    path: 'order',
  },
  {
    title: '주행 경로',
    path: 'driving-route',
    disabled: true,
  },
  {
    title: '이용 내역',
    path: 'usage',
    disabled: true,
  },
];

let navActiveStyle = {
  backgroundColor: 'rgba(118, 157, 255, 0.3)',

  padding: '4px 10px',
  borderRadius: '6px',
  minWidth: 'fit-content',
};
let navDefaultStyle = {
  padding: '4px 10px',
  borderRadius: '6px',
  minWidth: 'fit-content',
};

const handleDisabledNav = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  alert('해당 페이지는 준비중입니다.');
};

const ReportNav = () => {
  return (
    <Stack direction="row" align="start">
      <SidebarWrapper>
        <Stack name="sidebar-content-wrapper" spacing={24}>
          <Stack align="start" spacing={8}>
            {ReportContents.map(d => {
              if (d.disabled) {
                return (
                  <CustomNavLink
                    key={d.title}
                    to={d.path}
                    disabled={d.disabled}
                    onClick={event => {
                      d.disabled && handleDisabledNav(event);
                    }}
                    style={({ isActive }) => (isActive ? navActiveStyle : navDefaultStyle)}
                  >
                    <Stack direction="row" justify="space-between">
                      <p>{d.title}</p>
                      <Tag status="preparing" fit styleName="tooltip1">
                        준비중
                      </Tag>
                    </Stack>
                  </CustomNavLink>
                );
              }
              return (
                <CustomNavLink
                  key={d.title}
                  to={d.path}
                  disabled={d.disabled}
                  onClick={event => {
                    d.disabled && handleDisabledNav(event);
                  }}
                  style={({ isActive }) => (isActive ? navActiveStyle : navDefaultStyle)}
                >
                  {d.title}
                </CustomNavLink>
              );
            })}
          </Stack>
        </Stack>
      </SidebarWrapper>
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </Stack>
  );
};

export default ReportNav;
