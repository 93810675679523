import React, { Dispatch, SetStateAction, useState } from 'react';

import strings from 'util/Localization';
import { ISetAdminInfo, ISetDriverInfo } from 'constants/types';

import Modal from '.';
import Field from 'components/Field';

const ModifyUserModal = ({
  modifyForm,
  useFormProps,
  admin,
  mutateSetInfo,
  modifyMemberModalIsOpen,
  setModifyMemberModalIsOpen,
  target,
}: {
  modifyForm: Array<any>;
  useFormProps: any;
  admin?: boolean;
  mutateSetInfo: Function;
  modifyMemberModalIsOpen: boolean;
  setModifyMemberModalIsOpen: Dispatch<SetStateAction<boolean>>;
  target: string | null;
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);

  return (
    <Modal
      isModalOpen={modifyMemberModalIsOpen}
      setIsModalOpen={setModifyMemberModalIsOpen}
      width={550}
      padding={24}
      ms={30}
      confirm={{
        value: isDirty,
        message: (
          <p>
            {strings.드라이버} 계정 정보를 저장하지 않고
            <br />
            닫으시겠습니까?
          </p>
        ),
      }}
    >
      <Field
        title={'계정 정보'}
        fieldContents={modifyForm}
        onSubmit={(data: any) => {
          if (data?.name)
            data.name = data.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '').replaceAll(' ', '');
          if (data?.phone) data.phone = data.phone.replace(/[^0-9]/g, '');

          if (data?.workStartTime) data.workStartTime = data.workStartTime?.split(' ')[1];
          if (data?.vehicleId) data.vehicleId = parseInt(data?.vehicleId);

          const restData: ISetAdminInfo | ISetDriverInfo = {
            target: `${target}`,
            ...data,
          };
          if (Boolean(Object.keys(data).length)) mutateSetInfo({ ...restData });
          setModifyMemberModalIsOpen(false);
        }}
        useFormProps={useFormProps}
        RBB={'수정 완료하기'}
        complex
        setIsDirty={setIsDirty}
      />
    </Modal>
  );
};

export default ModifyUserModal;
