import React, { SetStateAction, useEffect } from 'react';
import { BoxContainer } from 'components/BoxContainer';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import AdminManagementTable from 'components/Table/AdminManagementTable';
import DriverManagementTable from 'components/Table/DriverManagementTable';
import InvestigatorManagementTable from 'components/Table/InvestigatorManagementTable';
import { useMutation, useQuery } from 'react-query';
import {
  activateMember,
  deactivateMember,
  getAdminInfo,
  getDriverInfo,
  getInvestigatorList,
  inviteMember,
  setAdminInfo,
  setDriverInfo,
  setInvestigator,
} from 'api';
import Modal from 'components/Modal';
import styled from 'styled-components';
import Divider from 'components/Divider';

import { ReactComponent as IconAdmin } from 'constants/icon/ic_admin_avatar.svg';
import { ReactComponent as IconDrivier } from 'constants/icon/ic_driver_avatar.svg';
import { Input } from 'components/Input';
import { useForm } from 'react-hook-form';
import { IInviteMember } from 'constants/types';
import strings from '../../../util/Localization';

const Member = () => {
  const { refetch: refetchAdmins } = useQuery(['admins'], getAdminInfo, {});
  const { refetch: refetchDrivers } = useQuery(['drivers'], getDriverInfo, {});
  const { refetch: refetchInvestigators } = useQuery(['investigators'], getInvestigatorList, {});

  const { mutate: mutateActivateMember } = useMutation(activateMember, {
    onSettled(data, error, variables, context) {
      refetchAdmins();
      refetchDrivers();
    },
  });

  const { mutate: mutateDeactivateMember } = useMutation(deactivateMember, {
    onSettled(data, error, variables, context) {
      refetchAdmins();
      refetchDrivers();
    },
  });
  const { mutate: mutateSetDriverInfo } = useMutation(setDriverInfo, {
    onSettled(data, error, variables, context) {
      refetchDrivers();
    },
  });
  const { mutate: mutateSetAdminInfo } = useMutation(setAdminInfo, {
    onSettled(data, error, variables, context) {
      refetchAdmins();
    },
  });
  const { mutate: mutateSetInvestigator } = useMutation(setInvestigator, {
    onSettled(data, error, variables, context) {
      refetchInvestigators();
    },
  });

  let domain = window.localStorage.getItem('serviceDomain');

  const [currentTab, setCurrentTab] = React.useState<number>(0);

  const [currentStep, setCurrentStep] = React.useState<number>(domain === 'roouty' ? 1 : 2);
  const [inviteMemberModalIsOpen, setInviteMemberModalIsOpen] = React.useState<boolean>(false);
  const [modifyMemberModalIsOpen, setModifyMemberModalIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    inviteMemberModalIsOpen === false && setCurrentStep(domain === 'roouty' ? 1 : 2);
  }, [inviteMemberModalIsOpen]);

  return (
    <BoxContainer sx={{ padding: '48px 20px' }} align={'start'} justify={'start'} useVerticalScroll={true}>
      <Stack sx={{ maxWidth: '1024px', margin: '0 auto' }} spacing={40}>
        <Stack name="header_area" spacing={4}>
          <Stack direction="row" justify="space-between" align="flex-start">
            <Text styleName="title1" color="RG03">
              {strings.멤버관리}
            </Text>
            <Button
              variant={'default'}
              type={'button'}
              sx={{ padding: '9px 28px' }}
              onClick={() => {
                setInviteMemberModalIsOpen(true);
              }}
            >
              <Text styleName="subheadline2" color="RG00">
                + 초대하기
              </Text>
            </Button>
          </Stack>

          <Stack align="start">
            <Text styleName="caption3" color="RG03">
              {strings.매니저와드라이버를} 초대하고 업무의 효율을 높여보세요.
            </Text>
            <Text styleName="caption3" color="RG03">
              사용 중인 플랜에 따라 등록 가능한 최대 멤버 수가 제한될 수 있습니다.
            </Text>
          </Stack>
        </Stack>

        {window.localStorage.getItem('serviceDomain') === 'roouty' ? (
          <Stack name="table-area">
            <Stack name="table-tap" spacing={8} direction={'row'}>
              {/* // RT-762 */}
              <Tab
                isActive={currentTab === 0}
                onClick={() => {
                  setCurrentTab(0);
                }}
              >
                매니저 관리
              </Tab>
              <Tab
                isActive={currentTab === 1}
                onClick={() => {
                  setCurrentTab(1);
                }}
              >
                {strings.드라이버} 관리
              </Tab>
            </Stack>
            {currentTab === 0 ? (
              <AdminManagementTable
                {...{
                  mutateDeactivateMember,
                  mutateSetAdminInfo,
                  modifyMemberModalIsOpen,
                  setModifyMemberModalIsOpen,
                  mutateActivateMember,
                }}
              />
            ) : (
              <DriverManagementTable
                {...{
                  mutateDeactivateMember,
                  mutateSetDriverInfo,
                  modifyMemberModalIsOpen,
                  setModifyMemberModalIsOpen,
                  mutateActivateMember,
                }}
              />
            )}
          </Stack>
        ) : (
          <Stack>
            <InvestigatorManagementTable
              {...{
                mutateDeactivateMember,
                mutateSetInvestigator,
                modifyMemberModalIsOpen,
                setModifyMemberModalIsOpen,
                mutateActivateMember,
              }}
            />
          </Stack>
        )}
      </Stack>
      <InviteMemberModal
        callbackFunc={() => {
          refetchAdmins();
          refetchDrivers();
        }}
        {...{ currentStep, setCurrentStep, inviteMemberModalIsOpen, setInviteMemberModalIsOpen }}
      />
    </BoxContainer>
  );
};

export default Member;

export const InviteMemberModal = ({
  currentStep,
  setCurrentStep,
  inviteMemberModalIsOpen,
  setInviteMemberModalIsOpen,
  callbackFunc,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<SetStateAction<number>>;
  inviteMemberModalIsOpen: boolean;
  setInviteMemberModalIsOpen: React.Dispatch<SetStateAction<boolean>>;
  callbackFunc: Function;
}) => {
  const methods = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' });
  const { handleSubmit } = methods;

  let domain = window.localStorage.getItem('serviceDomain');

  const [target, setTarget] = React.useState<null | string>(domain === 'roouty' ? null : '조사원');

  const { mutate: mutateInviteMember } = useMutation(inviteMember, {
    onSuccess: () => {
      alert(
        `입력하신 ${target === '매니저' ? '이메일' : '연락처'}로 인증${
          target === '매니저' ? '메일' : '문자'
        }을 발송하였습니다.`
      );
    },
    onSettled: () => {
      callbackFunc();
    },
  });

  useEffect(() => {
    methods.reset();
    return methods.reset();
  }, [currentStep, inviteMemberModalIsOpen]);

  return (
    <Modal
      title={domain === 'roouty' ? `${currentStep} / 2` : ''}
      isModalOpen={inviteMemberModalIsOpen}
      setIsModalOpen={setInviteMemberModalIsOpen}
      ds="strong"
      padding={24}
      ms={23}
      width={701}
    >
      <Stack
        spacing={currentStep < 2 ? 28 : 8}
        align={currentStep < 2 ? 'center' : 'start'}
        sx={currentStep < 2 ? {} : { padding: '0 74px' }}
      >
        <Text
          styleName="title3"
          color={target === '조사원' ? 'RG03' : 'RG04'}
          sx={target === '조사원' ? { margin: '0 0 60px 0' } : {}}
        >
          {target === '조사원' ? '조사원 초대하기' : '초대할 멤버 유형을 선택하세요.'}
        </Text>
        {currentStep < 2 ? (
          <Stack
            direction="row"
            spacing={24}
            justify="space-between"
            divider={<Divider vertical />}
            sx={{ height: '230px' }}
          >
            <InviteAvatarBox
              admin
              onClick={() => {
                setTarget('매니저');
                setCurrentStep(2);
              }}
            >
              <Stack spacing={8}>
                <IconAdmin />
                <Text styleName="body1" color="RC11">
                  매니저
                </Text>
                <Text styleName="subheadline3" color="RG03">
                  배차 관리, 실시간 관제 등<br />
                  TMS 기능을 통해
                  <br />
                  주문, 배차, {strings.드라이버} 등을 관리합니다.
                </Text>
              </Stack>
            </InviteAvatarBox>
            <InviteAvatarBox
              onClick={() => {
                setTarget(`${strings.드라이버}`);
                setCurrentStep(2);
              }}
            >
              <Stack spacing={8}>
                <IconDrivier />
                <Text styleName="body1" color="RC02">
                  {strings.드라이버}
                </Text>
                <Text styleName="subheadline3" color="RG03">
                  {/* 최적화된 경로를 할당받고
                  <br />
                  고객에게 서비스를 제공합니다. */}
                  최적화된 배차 경로를 앱을 통해 할당받아
                  <br /> 고객에게 서비스를 제공합니다.
                </Text>
              </Stack>
            </InviteAvatarBox>
          </Stack>
        ) : (
          <>
            <form
              onSubmit={handleSubmit(data => {
                mutateInviteMember(data as IInviteMember);

                setInviteMemberModalIsOpen(false);
              })}
            >
              <Stack spacing={30} align={'start'}>
                {domain === 'roouty' && (
                  <Text styleName="body2" color="RG03">
                    {target}
                  </Text>
                )}

                <Stack spacing={6} align="start">
                  <Input
                    name="loginAccount"
                    label="아이디"
                    placeholder={
                      target === '매니저' ? '로그인에 사용할 이메일을 입력해주세요.' : '연락처를 입력해주세요. (숫자만)'
                    }
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    validation={
                      target === '매니저'
                        ? {
                            required: { value: true, message: '해당 필드는 필수값입니다.' },
                            pattern: {
                              value: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g,
                              message: '이메일 형식에 맞춰주세요.',
                            },
                          }
                        : {
                            required: { value: true, message: '해당 필드는 필수값입니다.' },
                            pattern: {
                              value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
                              message: '휴대폰 형식에 맞춰주세요.',
                            },
                            maxLength: { value: 13, message: '휴대폰 형식에 맞춰주세요.' },
                          }
                    }
                    errors={methods.formState.errors.loginAccount}
                    hasError={methods.formState.errors.loginAccount}
                  />
                  {!Boolean(methods.formState.errors.loginAccount) && (
                    <Text styleName="caption3" color="RC03">
                      {target === '매니저'
                        ? '매니저 아이디는 이메일 주소입니다.'
                        : `${strings.드라이버}의 아이디는 연락처 번호입니다.`}
                    </Text>
                  )}
                </Stack>
                <Input
                  name="name"
                  label="이름"
                  placeholder="이름을 입력해주세요."
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  validation={{
                    required: { value: true, message: '해당 필드는 필수값입니다.' },
                  }}
                  errors={methods.formState.errors.name}
                  hasError={methods.formState.errors.name}
                />
                <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                  <Button variant={'default'} type={'submit'} sx={{ padding: '9px 51.5px' }}>
                    <Text styleName="subheadline2" color="RG00">
                      초대하기
                    </Text>
                  </Button>
                </div>
              </Stack>
            </form>
          </>
        )}
      </Stack>
    </Modal>
  );
};

interface Style {
  isActive: boolean;
}
export const Tab = styled.div<Style>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 154px;
  height: 48px;

  // RT-762
  ${({ theme }) => theme.fontStyle.caption2}

  color: ${({ isActive, theme }) => (isActive ? theme.colors.RG03 : theme.colors.RG04)};
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.RG07 : theme.colors.RG06)};
  border-radius: 4px 4px 0px 0px;

  cursor: pointer;
`;

interface AvatarBoxStyle {
  admin?: boolean;
}
const InviteAvatarBox = styled.div<AvatarBoxStyle>`
  width: 100%;
  height: 100%;

  padding: 24px;

  background: ${({ theme, admin }) => (admin ? `rgba(121, 117, 255, 0.05)` : theme.colors.RC03_1)};
  border-radius: 8px;
  border: ${({ theme, admin }) => `1px solid ${admin ? `rgba(121, 117, 255, 0.15)` : theme.colors.RC03_3}`};

  cursor: pointer;
`;
