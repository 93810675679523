import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Stack } from 'components/Stack';
import { Text } from '../Typography';
import PopupYIndexFixer from './PopupYIndexFixer';

import { ReactComponent as IconFileUploadCircle } from 'constants/icon/ic_success.svg';
import theme from 'constants/theme';
import { SelectItem } from 'components/Input';

interface DriverPickerPopupProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: (name: string, value: unknown, config?: Object) => void;
  name: string;
  drivers: DriverListItem[];
  setDrivers: React.Dispatch<React.SetStateAction<DriverListItem[]>>;
  setIsHovering: React.Dispatch<SetStateAction<boolean>>;
}

export interface DriverListItem {
  name: string;
  driverId: number;
  licenseNumber?: string;
  vehicleId?: number;
  isSelected: boolean;
}

function DriverPickerPopup({ setIsOpen, setValue, name, drivers, setDrivers, setIsHovering }: DriverPickerPopupProps) {
  function toggleDriverSelect(index: number) {
    let items = [...drivers];
    items[index].isSelected = !items[index].isSelected;
    setDrivers(items);
  }

  function selectAllDrivers() {
    let items = [...drivers];

    if (drivers.filter(x => x.isSelected).length === drivers.length) items.forEach(x => (x.isSelected = false));
    else items.forEach(x => (x.isSelected = true));
    setDrivers(items);
  }

  function updateValue() {
    let items = drivers.filter(x => x.isSelected);
    let isAllSelected = drivers.filter(x => x.isSelected).length == drivers.length;

    setValue(
      name,
      isAllSelected
        ? '전체'
        : items.map(x => {
            return x.name;
          }),
      {
        shouldValidate: true,
      }
    );
  }

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <PopupYIndexFixer>
      <DriverPickerContainer
        ref={targetRef}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
      >
        <Stack>
          <SelectItem
            onClick={() => {
              selectAllDrivers();
              setIsOpen(false);
              updateValue();
            }}
          >
            <Stack spacing={8} direction={'row'}>
              <IconFileUploadCircle
                fill={
                  drivers.filter(x => x.isSelected).length === drivers.length ? theme.colors.RC02 : theme.colors.RG05
                }
                width={16}
                height={16}
              />

              <Text
                styleName={drivers.filter(x => x.isSelected).length === drivers.length ? 'caption1' : 'caption2'}
                color={'RG02'}
              >
                모두 선택
              </Text>
            </Stack>
          </SelectItem>

          {drivers.map((x, i) => (
            <SelectItem
              key={`${x.name}-driver-picker-item`}
              onClick={() => {
                if (x.driverId === 0) setIsOpen(false);
                toggleDriverSelect(i);
                updateValue();
              }}
            >
              <Stack spacing={8} direction={'row'}>
                <IconFileUploadCircle
                  fill={x.isSelected ? theme.colors.RC02 : theme.colors.RG05}
                  width={16}
                  height={16}
                />
                <Text styleName={x.isSelected ? 'caption1' : 'caption2'} color={'RG02'}>
                  {x.name}
                </Text>
              </Stack>
            </SelectItem>
          ))}
        </Stack>
      </DriverPickerContainer>
    </PopupYIndexFixer>
  );
}

export default DriverPickerPopup;

const DriverPickerContainer = styled.div`
  display: flex;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  width: 100%;
  position: absolute;
  top: 40px;
  z-index: 3;
  left: 0;
  max-height: 161px;
  ${({ theme }) => theme.scrollStyle.vertical};
`;
