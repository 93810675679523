import { Text } from 'components/Typography';
import { ColorPalette } from 'constants/colorPalette';

import { ReactComponent as Truck } from '../../../constants/icon/ic_marker_truck.svg';
import { ReactComponent as Time } from '../../../constants/icon/ic_marker_time.svg';
import { ReactComponent as Done } from '../../../constants/icon/ic_done.svg';
import { ReactComponent as MyLocation } from '../../../constants/icon/ic_mylocation.svg';
import { ReactComponent as MyLocationOutside } from '../../../constants/icon/ic_mylocation_outside.svg';
import React from 'react';

interface MarkerChips {
  truck?: boolean;
  time?: boolean;
}

interface MarkerInfo {
  displayType?: 'start' | 'end' | 'number' | 'unassigned' | 'new';
  showNameChip?: boolean;
  content?: string;
  driverName?: string;
  colorIndex?: number;
  zIndex?: number;
  alpha?: number;
  status?: string;
  chips?: MarkerChips;
  isSelected?: boolean;
  onClick?: () => void;
}

export const MyLocationMarker = ({ ...props }) => {
  return (
    <div className={'marker-myLocation'}>
      <MyLocation id={'mylocation'} />
      <MyLocationOutside id={'mylocation-outside'} />
      <div className="marker-driver" style={{ top: '-60px' }}>
        <div className="marker-highlight" style={{ background: ColorPalette.getColor(props.colorIndex || 0) }} />
        <Text styleName="caption2" color="RG02">
          {props.driverName}
        </Text>
      </div>
    </div>
  );
};
export const StartEndMarker = ({ ...props }: MarkerInfo) => {
  return (
    <div
      className={`marker-container marker-${props.displayType}`}
      style={{ zIndex: (props.zIndex || 0) + (props.isSelected ? 1000 : 0), opacity: props.alpha || 1 }}
      onClick={props.onClick}
    >
      <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 16.0222V16.0221C0.499467 11.9483 2.09819 8.03777 4.95103 5.13396C7.8029 2.23113 11.6795 0.566978 15.7451 0.5H16.24C20.3082 0.563078 24.1887 2.22552 27.0438 5.12882C29.8998 8.03304 31.5005 11.9458 31.5 16.0221C31.5 18.2484 31.1778 19.9523 30.4252 21.6421C29.6674 23.3433 28.4626 25.0558 26.6512 27.2878C25.9774 28.0458 25.4006 28.6667 24.8745 29.2329C23.162 31.0763 21.9875 32.3406 19.753 35.8736C18.701 37.5274 18.1988 39.2608 17.7991 40.6409C17.7339 40.8657 17.6715 41.0811 17.6099 41.2853C17.3833 42.036 17.1785 42.5982 16.9183 42.9774C16.6821 43.3217 16.4232 43.4853 16.0444 43.5H15.9555C15.5666 43.4852 15.3045 43.3192 15.0684 42.9766C14.8077 42.5984 14.6042 42.0372 14.3801 41.2867C14.3182 41.0797 14.2557 40.861 14.1904 40.6327C13.7962 39.2544 13.3014 37.5242 12.2547 35.8737L12.2541 35.8727C10.0221 32.3705 8.84278 31.0941 7.14461 29.2562C6.61504 28.683 6.03499 28.0552 5.35682 27.2883C3.54475 25.0556 2.338 23.3467 1.5784 21.6472C0.824084 19.9595 0.5 18.2558 0.5 16.0222Z"
          fill={props.displayType === 'start' ? ColorPalette.startColor : ColorPalette.endColor}
          stroke="white"
        />
      </svg>

      <div className={'marker-startend'}>
        <Text styleName={'caption1'} color="RG00">
          {props.content}
        </Text>
      </div>

      {props.showNameChip && (
        <div className="marker-driver">
          <div className="marker-highlight" style={{ background: ColorPalette.getColor(props.colorIndex || 0) }} />
          <Text styleName="caption2" color="RG02">
            {props.driverName}
          </Text>
        </div>
      )}
    </div>
  );
};

export const NewOrderMarker = ({ ...props }: MarkerInfo) => {
  return (
    <div
      className={`marker-container marker-${props.displayType}`}
      style={{ zIndex: (props.zIndex || 0) + (props.isSelected ? 1000 : 0), opacity: props.alpha || 1 }}
      onClick={props.onClick}
    >
      <svg width="44" height="60" viewBox="0 0 44 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 21.8484V21.8484C0.499261 16.2469 2.71578 10.869 6.67245 6.87491C10.6283 2.88166 16.0066 0.591757 21.6479 0.5H22.3314C27.9764 0.586414 33.36 2.87397 37.3203 6.86784C41.2814 10.8625 43.5007 16.2434 43.5 21.8484C43.5 24.9037 43.0536 27.2528 42.0065 29.5845C40.9541 31.9278 39.284 34.279 36.7897 37.3268C35.8585 38.3657 35.0629 39.215 34.3383 39.9886C31.9867 42.4991 30.3828 44.2114 27.3197 49.0144C25.8883 51.2459 25.2054 53.5828 24.6552 55.4655C24.5653 55.7731 24.4789 56.0686 24.3934 56.3495C24.0824 57.3716 23.7942 58.1637 23.4184 58.7068C23.0667 59.215 22.6595 59.4781 22.0647 59.5H21.9352C21.3275 59.478 20.9155 59.2126 20.5635 58.706C20.1868 58.1638 19.9003 57.3727 19.5926 56.351C19.5068 56.066 19.4202 55.766 19.3301 55.4535C18.7876 53.5734 18.1148 51.2413 16.6908 49.0144L16.6902 49.0135C13.6304 44.2521 12.0197 42.5232 9.68772 40.02C8.95827 39.237 8.15825 38.3783 7.22122 37.3273C4.72628 34.2788 3.05347 31.9326 1.99848 29.5917C0.948911 27.2628 0.5 24.9139 0.5 21.8484Z"
          fill={props.isSelected ? ColorPalette.selectedNewColor : ColorPalette.newColor}
          stroke="white"
        />
        <path
          d="M26.8563 28H24.3123L18.9043 19.04H18.8403C18.8509 19.2747 18.8616 19.5253 18.8723 19.792C18.8936 20.048 18.9096 20.3147 18.9203 20.592C18.9309 20.8587 18.9416 21.1307 18.9523 21.408C18.9629 21.6853 18.9736 21.9627 18.9843 22.24V28H17.1443V16.576H19.6723L25.0642 25.472H25.1123C25.1016 25.2587 25.0909 25.0293 25.0803 24.784C25.0696 24.528 25.0589 24.2667 25.0483 24C25.0483 23.7333 25.0429 23.4613 25.0323 23.184C25.0216 22.9067 25.0109 22.64 25.0003 22.384V16.576H26.8563V28Z"
          fill="white"
        />
      </svg>

      <div className={props.isSelected ? 'marker-tag-large' : 'marker-tag'}>
        <Text styleName={'number2'} color="RG00">
          {props.content}
        </Text>
      </div>

      {props.chips && Object.values(props.chips).includes(true) && (
        <div className="marker-chip-large">
          {props.chips.time && <Time />}
          {props.chips.truck && <Truck />}
        </div>
      )}
    </div>
  );
};

export const OrderMarker = ({ ...props }: MarkerInfo) => {
  return (
    <div
      className={`marker-container marker-${props.displayType}`}
      style={{ zIndex: (props.zIndex || 0) + (props.isSelected ? 1000 : 0), opacity: props.alpha || 1 }}
      onClick={props.onClick}
    >
      {props.isSelected ? (
        <svg width="44" height="60" viewBox="0 0 44 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 21.8484V21.8484C0.499261 16.2469 2.71578 10.869 6.67245 6.87491C10.6283 2.88166 16.0066 0.591757 21.6479 0.5H22.3314C27.9764 0.586414 33.36 2.87397 37.3203 6.86784C41.2814 10.8625 43.5007 16.2434 43.5 21.8484C43.5 24.9037 43.0536 27.2528 42.0065 29.5845C40.9541 31.9278 39.284 34.279 36.7897 37.3268C35.8585 38.3657 35.0629 39.215 34.3383 39.9886C31.9867 42.4991 30.3828 44.2114 27.3197 49.0144C25.8883 51.2459 25.2054 53.5828 24.6552 55.4655C24.5653 55.7731 24.4789 56.0686 24.3934 56.3495C24.0824 57.3716 23.7942 58.1637 23.4184 58.7068C23.0667 59.215 22.6595 59.4781 22.0647 59.5H21.9352C21.3275 59.478 20.9155 59.2126 20.5635 58.706C20.1868 58.1638 19.9003 57.3727 19.5926 56.351C19.5068 56.066 19.4202 55.766 19.3301 55.4535C18.7876 53.5734 18.1148 51.2413 16.6908 49.0144L16.6902 49.0135C13.6304 44.2521 12.0197 42.5232 9.68772 40.02C8.95827 39.237 8.15825 38.3783 7.22122 37.3273C4.72628 34.2788 3.05347 31.9326 1.99848 29.5917C0.948911 27.2628 0.5 24.9139 0.5 21.8484Z"
            fill={ColorPalette.selectedNewColor}
            stroke="white"
          />
        </svg>
      ) : (
        <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 16.0222V16.0221C0.499467 11.9483 2.09819 8.03777 4.95103 5.13396C7.8029 2.23113 11.6795 0.566978 15.7451 0.5H16.24C20.3082 0.563078 24.1887 2.22552 27.0438 5.12882C29.8998 8.03304 31.5005 11.9458 31.5 16.0221C31.5 18.2484 31.1778 19.9523 30.4252 21.6421C29.6674 23.3433 28.4626 25.0558 26.6512 27.2878C25.9774 28.0458 25.4006 28.6667 24.8745 29.2329C23.162 31.0763 21.9875 32.3406 19.753 35.8736C18.701 37.5274 18.1988 39.2608 17.7991 40.6409C17.7339 40.8657 17.6715 41.0811 17.6099 41.2853C17.3833 42.036 17.1785 42.5982 16.9183 42.9774C16.6821 43.3217 16.4232 43.4853 16.0444 43.5H15.9555C15.5666 43.4852 15.3045 43.3192 15.0684 42.9766C14.8077 42.5984 14.6042 42.0372 14.3801 41.2867C14.3182 41.0797 14.2557 40.861 14.1904 40.6327C13.7962 39.2544 13.3014 37.5242 12.2547 35.8737L12.2541 35.8727C10.0221 32.3705 8.84278 31.0941 7.14461 29.2562C6.61504 28.683 6.03499 28.0552 5.35682 27.2883C3.54475 25.0556 2.338 23.3467 1.5784 21.6472C0.824084 19.9595 0.5 18.2558 0.5 16.0222Z"
            fill={ColorPalette.getColor(props.colorIndex || 0)}
            stroke="white"
          />
          {props.status === 'completed' && (
            <path
              d="M0.5 16.0222V16.0221C0.499467 11.9483 2.09819 8.03777 4.95103 5.13396C7.8029 2.23113 11.6795 0.566978 15.7451 0.5H16.24C20.3082 0.563078 24.1887 2.22552 27.0438 5.12882C29.8998 8.03304 31.5005 11.9458 31.5 16.0221C31.5 18.2484 31.1778 19.9523 30.4252 21.6421C29.6674 23.3433 28.4626 25.0558 26.6512 27.2878C25.9774 28.0458 25.4006 28.6667 24.8745 29.2329C23.162 31.0763 21.9875 32.3406 19.753 35.8736C18.701 37.5274 18.1988 39.2608 17.7991 40.6409C17.7339 40.8657 17.6715 41.0811 17.6099 41.2853C17.3833 42.036 17.1785 42.5982 16.9183 42.9774C16.6821 43.3217 16.4232 43.4853 16.0444 43.5H15.9555C15.5666 43.4852 15.3045 43.3192 15.0684 42.9766C14.8077 42.5984 14.6042 42.0372 14.3801 41.2867C14.3182 41.0797 14.2557 40.861 14.1904 40.6327C13.7962 39.2544 13.3014 37.5242 12.2547 35.8737L12.2541 35.8727C10.0221 32.3705 8.84278 31.0941 7.14461 29.2562C6.61504 28.683 6.03499 28.0552 5.35682 27.2883C3.54475 25.0556 2.338 23.3467 1.5784 21.6472C0.824084 19.9595 0.5 18.2558 0.5 16.0222Z"
              fill="#0000004D"
              stroke="none"
            />
          )}
        </svg>
      )}

      <div className={props.isSelected ? 'marker-tag-large' : 'marker-tag'}>
        <Text styleName={'number2'} color="RG00">
          {props.content}
        </Text>
      </div>

      {props.status !== 'completed' && props.chips && Object.values(props.chips).includes(true) && (
        <div className={props.isSelected ? 'marker-chip-large' : 'marker-chip'}>
          {props.chips.time && <Time />}
          {props.chips.truck && <Truck />}
        </div>
      )}
      {props.status === 'completed' && (
        <div className={'marker-done'}>
          <Done />
        </div>
      )}
    </div>
  );
};

export const UnAssignedMarker = ({ ...props }: MarkerInfo) => {
  return (
    <div
      className={`marker-container marker-${props.displayType}`}
      style={{ zIndex: 1000 - (props.zIndex || 0) + (props.isSelected ? 1000 : 0), opacity: props.alpha || 1 }}
      onClick={props.onClick}
    >
      {props.isSelected ? (
        <svg width="44" height="60" viewBox="0 0 44 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 21.8484V21.8484C0.499261 16.2469 2.71578 10.869 6.67245 6.87491C10.6283 2.88166 16.0066 0.591757 21.6479 0.5H22.3314C27.9764 0.586414 33.36 2.87397 37.3203 6.86784C41.2814 10.8625 43.5007 16.2434 43.5 21.8484C43.5 24.9037 43.0536 27.2528 42.0065 29.5845C40.9541 31.9278 39.284 34.279 36.7897 37.3268C35.8585 38.3657 35.0629 39.215 34.3383 39.9886C31.9867 42.4991 30.3828 44.2114 27.3197 49.0144C25.8883 51.2459 25.2054 53.5828 24.6552 55.4655C24.5653 55.7731 24.4789 56.0686 24.3934 56.3495C24.0824 57.3716 23.7942 58.1637 23.4184 58.7068C23.0667 59.215 22.6595 59.4781 22.0647 59.5H21.9352C21.3275 59.478 20.9155 59.2126 20.5635 58.706C20.1868 58.1638 19.9003 57.3727 19.5926 56.351C19.5068 56.066 19.4202 55.766 19.3301 55.4535C18.7876 53.5734 18.1148 51.2413 16.6908 49.0144L16.6902 49.0135C13.6304 44.2521 12.0197 42.5232 9.68772 40.02C8.95827 39.237 8.15825 38.3783 7.22122 37.3273C4.72628 34.2788 3.05347 31.9326 1.99848 29.5917C0.948911 27.2628 0.5 24.9139 0.5 21.8484Z"
            fill="#769DFF"
            stroke="white"
          />
          <circle cx="22" cy="22" r="8" fill="#406EE2" />
        </svg>
      ) : (
        <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 16.0222V16.0221C0.499467 11.9483 2.09819 8.03777 4.95103 5.13396C7.8029 2.23113 11.6795 0.566978 15.7451 0.5H16.24C20.3082 0.563078 24.1887 2.22552 27.0438 5.12882C29.8998 8.03304 31.5005 11.9458 31.5 16.0221C31.5 18.2484 31.1778 19.9523 30.4252 21.6421C29.6674 23.3433 28.4626 25.0558 26.6512 27.2878C25.9774 28.0458 25.4006 28.6667 24.8745 29.2329C23.162 31.0763 21.9875 32.3406 19.753 35.8736C18.701 37.5274 18.1988 39.2608 17.7991 40.6409C17.7339 40.8657 17.6715 41.0811 17.6099 41.2853C17.3833 42.036 17.1785 42.5982 16.9183 42.9774C16.6821 43.3217 16.4232 43.4853 16.0444 43.5H15.9555C15.5666 43.4852 15.3045 43.3192 15.0684 42.9766C14.8077 42.5984 14.6042 42.0372 14.3801 41.2867C14.3182 41.0797 14.2557 40.861 14.1904 40.6327C13.7962 39.2544 13.3014 37.5242 12.2547 35.8737L12.2541 35.8727C10.0221 32.3705 8.84278 31.0941 7.14461 29.2562C6.61504 28.683 6.03499 28.0552 5.35682 27.2883C3.54475 25.0556 2.338 23.3467 1.5784 21.6472C0.824084 19.9595 0.5 18.2558 0.5 16.0222Z"
            fill="white"
            stroke="#B2B8CC"
          />
          <circle cx="16" cy="16" r="8" fill="#769DFF" fillOpacity="0.3" />
        </svg>
      )}

      {props.chips && Object.values(props.chips).includes(true) && (
        <div className={props.isSelected ? 'marker-chip-large' : 'marker-chip'}>
          {props.chips.time && <Time />}
          {props.chips.truck && <Truck />}
        </div>
      )}
    </div>
  );
};

export const CustomMarker = ({ ...props }: MarkerInfo) => {
  return (
    <div
      className={`marker-container marker-${props.displayType}`}
      style={{ zIndex: 1000 - (props.zIndex || 0) + (props.isSelected ? 1000 : 0), opacity: props.alpha || 1 }}
      onClick={props.onClick}
    >
      <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 16.0222V16.0221C0.499467 11.9483 2.09819 8.03777 4.95103 5.13396C7.8029 2.23113 11.6795 0.566978 15.7451 0.5H16.24C20.3082 0.563078 24.1887 2.22552 27.0438 5.12882C29.8998 8.03304 31.5005 11.9458 31.5 16.0221C31.5 18.2484 31.1778 19.9523 30.4252 21.6421C29.6674 23.3433 28.4626 25.0558 26.6512 27.2878C25.9774 28.0458 25.4006 28.6667 24.8745 29.2329C23.162 31.0763 21.9875 32.3406 19.753 35.8736C18.701 37.5274 18.1988 39.2608 17.7991 40.6409C17.7339 40.8657 17.6715 41.0811 17.6099 41.2853C17.3833 42.036 17.1785 42.5982 16.9183 42.9774C16.6821 43.3217 16.4232 43.4853 16.0444 43.5H15.9555C15.5666 43.4852 15.3045 43.3192 15.0684 42.9766C14.8077 42.5984 14.6042 42.0372 14.3801 41.2867C14.3182 41.0797 14.2557 40.861 14.1904 40.6327C13.7962 39.2544 13.3014 37.5242 12.2547 35.8737L12.2541 35.8727C10.0221 32.3705 8.84278 31.0941 7.14461 29.2562C6.61504 28.683 6.03499 28.0552 5.35682 27.2883C3.54475 25.0556 2.338 23.3467 1.5784 21.6472C0.824084 19.9595 0.5 18.2558 0.5 16.0222Z"
          fill="white"
          stroke="#B2B8CC"
        />
        <circle cx="16" cy="16" r="8" fill="#769DFF" fillOpacity="0.3" />
      </svg>

      {props.chips && Object.values(props.chips).includes(true) && (
        <div className={props.isSelected ? 'marker-chip-large' : 'marker-chip'}>
          {props.chips.time && <Time />}
          {props.chips.truck && <Truck />}
        </div>
      )}
    </div>
  );
};
