import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStore } from 'store';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';

import { getDriverInfo, getOrderList } from 'api';
import { IGetOrderListResponses } from 'constants/types';

import Sidebar from './Sidebar';
import { Input } from '../Input';
import { Button } from '../Button';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { BoxContainer } from '../BoxContainer';
import DatePickerPopup from '../Popup/DatePicker';
import OrderManagePage from 'pages/OrderManagePage';
import PageContentWrapper from 'components/PageContentWrapper';
import { DriverListItem } from '../Popup/DriverPicker';

import RooutyLhStatusDiagram from 'constants/graphics/roouty_lh_status_cycle_diagram.png';
import RooutyOrderStatusDiagram from 'constants/graphics/roouty_order_status_cycle_diagram.png';

import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import { ReactComponent as IcCaution } from 'constants/icon/ic_caution.svg';
import { ReactComponent as IcClose } from '../../constants/icon/ic_close2.svg';
import { ReactComponent as IcArrowDropDown } from '../../constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as IcMagnifyingGlass } from '../../constants/icon/ic_magnifyingglass.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';

import dayjs from 'dayjs';
import theme from 'constants/theme';
import { DriverSelector } from '../DriverSelector';
import strings from '../../util/Localization';
import GridButtonFilter from 'components/GridButtonFilter';
import { statusFilter, shipmentFilter, shipment_type } from 'constants/commons';

const OrderManageNav = () => {
  const now = dayjs();

  const StatusCycleDiagram = {
    roouty: `${RooutyOrderStatusDiagram}`,
    survey: `${RooutyLhStatusDiagram}`,
  };

  const methods = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' });

  // FIXME: 초기값 설정
  const { getOrderListProps, setGetOrderListProps, resetGetOrderListProps, userGrade } = useStore();

  const [status, setStatus] = useState<boolean[]>([false, true, ...new Array(statusFilter.length - 2).fill(false)]);
  const [shipmentStatus, setShipmentStatus] = useState<boolean[]>([
    true,
    ...new Array(shipmentFilter.length - 1).fill(false),
  ]);
  const [receivedStartDatePickerIsOpen, setReceivedStartDatePickerIsOpen] = useState<boolean>(false);
  const [receivedEndDatePickerIsOpen, setReceivedEndDatePickerIsOpen] = useState<boolean>(false);
  const [desiredStartDatePickerIsOpen, setDesiredStartDatePickerIsOpen] = useState<boolean>(false);
  const [desiredEndDatePickerIsOpen, setDesiredEndDatePickerIsOpen] = useState<boolean>(false);
  const [completedStartDatePickerIsOpen, setCompletedStartDatePickerIsOpen] = useState<boolean>(false);
  const [completedEndDatePickerIsOpen, setCompletedEndDatePickerIsOpen] = useState<boolean>(false);
  const [driverPickerIsOpen, setDriverPickerIsOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log(receivedStartDatePickerIsOpen);
  }, [receivedStartDatePickerIsOpen]);
  useEffect(() => {
    console.log(desiredStartDatePickerIsOpen);
  }, [desiredStartDatePickerIsOpen]);
  useEffect(() => {
    console.log(completedStartDatePickerIsOpen);
  }, [completedStartDatePickerIsOpen]);
  useEffect(() => {
    console.log(driverPickerIsOpen);
  }, [driverPickerIsOpen]);

  const [driverState, setDriverState] = React.useState<DriverListItem[]>([]);

  const {
    data: getOrderListResponses,
    isLoading: getOrderListResponsesIsLoading,
    refetch: refetchOrderList,
  } = useQuery<IGetOrderListResponses>(['getOrderList', getOrderListProps], () => getOrderList(getOrderListProps), {
    keepPreviousData: true,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
  });

  const { data: drivers, isSuccess: getDriverListIsSuccess } = useQuery(['drivers'], getDriverInfo, {});

  // RT-1031
  useEffect(() => {
    if (getDriverListIsSuccess) {
      let driverList = drivers?.memberList.map(x => {
        return { name: x.name, driverId: x.driverId, vehicleId: x?.vehicle?.vehicleId, isSelected: false };
      });

      return setDriverState(
        window.localStorage.getItem('serviceDomain') === 'survey' && userGrade === 1
          ? [{ name: `지정되지 않음`, driverId: 0, isSelected: false }].concat(driverList)
          : driverList
      );
    }
  }, [drivers, getDriverListIsSuccess]);

  useEffect(() => {
    methods.setValue('status', statusFilter[1].id);
    methods.setValue('receivedDate.start', now.subtract(1, 'week').format('YYYY.MM.DD'));
    methods.setValue('receivedDate.end', now.format('YYYY.MM.DD'));
  }, []);

  const resetForms = () => {
    closeAllDatePickers();
    resetGetOrderListProps();

    setStatus([false, true, ...new Array(statusFilter.length - 2).fill(false)]);
    setShipmentStatus([true, ...new Array(shipmentFilter.length - 1).fill(false)]);

    methods.reset();
    methods.setValue('status', statusFilter[1].id);
    methods.setValue('receivedDate.start', now.subtract(1, 'week').format('YYYY.MM.DD')); // 1달 전 날짜로 초기화
    methods.setValue('receivedDate.end', now.format('YYYY.MM.DD'));
  };
  const closeAllDatePickers = () => {
    setReceivedStartDatePickerIsOpen(false);
    setReceivedEndDatePickerIsOpen(false);
    setDesiredStartDatePickerIsOpen(false);
    setDesiredEndDatePickerIsOpen(false);
    setCompletedStartDatePickerIsOpen(false);
    setCompletedEndDatePickerIsOpen(false);
    setDriverPickerIsOpen(false);
  };
  const removeDot = (str: string) => {
    return str.replace(/\./g, '');
  };

  useEffect(() => {
    console.log(methods.formState.errors);
  }, [methods.formState.errors]);

  const handleFormComplete = (data: any) => {
    let json: any = {
      allow_desired_date_null: data.allow_desired_date_null,
      status: data.status,
    };

    // RT-1031
    /// 드라이버가 전부 선택된경우가 아니면 id를 가져와서 붙임 1,2,3,4
    // if (driverState.filter(x => x.isSelected).length !== driverState.length) {
    let driverIds = '';
    driverState.forEach(x => {
      if (x.isSelected) {
        if (driverIds === '') driverIds = driverIds + x.driverId;
        else driverIds = driverIds + ',' + x.driverId;
      }
    });

    if (driverIds !== '') json['driverId'] = driverIds;
    // }

    if (data?.name !== '') json['keyword'] = data.name;

    if (data.receivedDate.start && data.receivedDate.end) {
      if (
        dayjs(data.receivedDate.start).isAfter(dayjs(data.receivedDate.end)) ||
        dayjs(data.receivedDate.end).isBefore(dayjs(data.receivedDate.start))
      ) {
        methods.setError('receivedDate.start', {
          type: 'invalidDateRange',
          message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
        });
        methods.setError('receivedDate.end', {
          type: 'invalidDateRange',
          message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
        });
      }
      json['received_date'] = `${removeDot(data.receivedDate.start)}-${removeDot(data.receivedDate.end)}`;
    } else {
      // RT-814

      methods.setError('receivedDate.start', {
        type: 'required',
        message: '해당 필드는 필수값입니다.',
      });
      methods.setError('receivedDate.end', {
        type: 'required',
        message: '해당 필드는 필수값입니다.',
      });
    }

    //  RT-577/RT-602
    if (data.desiredDate) {
      if (data.desiredDate.start && data.desiredDate.end) {
        if (
          dayjs(data.desiredDate.start).isAfter(dayjs(data.desiredDate.end)) ||
          dayjs(data.desiredDate.end).isBefore(dayjs(data.desiredDate.start))
        ) {
          methods.setError('desiredDate.start', {
            type: 'invalidDateRange',
            message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
          });
          methods.setError('desiredDate.end', {
            type: 'invalidDateRange',
            message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
          });
        }
        json['desired_date'] = `${removeDot(data.desiredDate.start)}-${removeDot(data.desiredDate.end)}`;
      } else if (data.desiredDate.start) {
        json['desired_date'] = `${removeDot(data.desiredDate.start)}-21000101`;
      } else if (data.desiredDate.end) {
        json['desired_date'] = `19700101-${removeDot(data.desiredDate.end)}`;
      }
    }
    if (data.desiredDate) {
      if (data.completedDate.start && data.completedDate.end) {
        if (
          dayjs(data.completedDate.start).isAfter(dayjs(data.completedDate.end)) ||
          dayjs(data.completedDate.end).isBefore(dayjs(data.completedDate.start))
        ) {
          methods.setError('completedDate.start', {
            type: 'invalidDateRange',
            message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
          });
          methods.setError('completedDate.end', {
            type: 'invalidDateRange',
            message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
          });
        }
        json['completed_date'] = `${removeDot(data.completedDate.start)}-${removeDot(data.completedDate.end)}`;
      } else if (data.completedDate.start) {
        json['completed_date'] = `${removeDot(data.completedDate.start)}-21000101`;
      } else if (data.completedDate.end) {
        json['completed_date'] = `19700101-${removeDot(data.completedDate.end)}`;
      }
    }

    if (data.shipmentType) {
      if (data.shipmentType.includes('all')) delete data.shipmentType;
      else json.shipment_type = data.shipmentType;
    }

    console.log(data, 'its data');
    console.log(json, 'its json');

    console.log(methods.formState.errors);

    setGetOrderListProps({ ...json });
  };

  return (
    <Stack align={'flex-start'} direction={'row'}>
      <Sidebar bg="RG08" width="288px" ds="strong" padding={'28px 0 0 0'} index={2} sx={{ minWidth: '288px' }}>
        <form
          onSubmit={methods.handleSubmit(data => {
            handleFormComplete(data);
          })}
          style={{ height: '100%' }}
        >
          <Stack sx={{ padding: '20px 0 0 30px', height: '100%' }}>
            <Stack spacing={20} sx={{ padding: '0 0 48px 0', height: '100%' }}>
              <Stack name="tnd" align="start" spacing={8} sx={{ padding: '0 20px 0 0' }}>
                <BoxContainer justify="space-between">
                  <Text styleName="body2" color="RG02">
                    {strings.주문필터링}
                  </Text>
                  <Button
                    type={'button'}
                    variant={'second'}
                    sx={{ padding: '3px 6px' }}
                    onClick={() => {
                      resetForms();
                      resetGetOrderListProps();
                    }}
                  >
                    <Text styleName={'caption2'} color={'RG04'}>
                      초기화
                    </Text>
                  </Button>
                </BoxContainer>

                <Text styleName="caption3" color="RG04">
                  모든 항목 중복 선택 가능합니다.
                </Text>
              </Stack>

              <Stack useVerticalScroll sx={{ height: 'fit-content' }}>
                <Stack spacing={20} sx={{ maxHeight: '696px', padding: '0 20px 0 0' }}>
                  <Stack align="start">
                    <Stack justify="space-between" direction="row">
                      <Stack spacing={6} direction="row" align="center" sx={{ width: 'fit-content' }}>
                        <Text styleName="caption2" color="RG02">
                          {strings.주문} 상태
                        </Text>

                        <IcInfo
                          width={16}
                          height={16}
                          fill={theme.colors.RG05}
                          onClick={() => {
                            // RT-1009

                            let domain: string | null = window.localStorage.getItem('serviceDomain');

                            window.open(
                              `${
                                domain ? StatusCycleDiagram[domain as 'roouty' | 'survey'] : StatusCycleDiagram.roouty
                              }`,
                              'RooutyStatusDiagram',
                              `width=auto,height=auto`
                            );
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack spacing={10} sx={{ marginTop: '6px' }}>
                      <GridButtonFilter name="status" content={statusFilter} {...{ status, setStatus, methods }} />
                    </Stack>
                  </Stack>

                  <Stack align="start">
                    <Stack justify="space-between" direction="row">
                      <Stack spacing={6} direction="row" align="center" sx={{ width: 'fit-content' }}>
                        <Text styleName="caption2" color="RG02">
                          {strings.주문} 유형
                        </Text>

                        <IcInfo
                          onClick={() => {
                            // RT-1009

                            let domain: string | null = window.localStorage.getItem('serviceDomain');

                            window.open(
                              `${
                                domain ? StatusCycleDiagram[domain as 'roouty' | 'survey'] : StatusCycleDiagram.roouty
                              }`,
                              'RooutyStatusDiagram',
                              `width=auto,height=auto`
                            );
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack spacing={10} sx={{ marginTop: '6px' }}>
                      <GridButtonFilter
                        name="shipmentType"
                        content={shipmentFilter}
                        {...{ status: shipmentStatus, setStatus: setShipmentStatus, methods }}
                      />
                    </Stack>
                  </Stack>
                  <Stack spacing={12} name="order-filter-date">
                    <Stack align="start" spacing={4}>
                      <Text styleName="caption2" color="RG02">
                        {strings.주문접수일}
                      </Text>
                      <Stack direction={'row'} sx={{ position: 'relative' }}>
                        <Input
                          variant={'second'}
                          type="text"
                          name="receivedDate.start"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          height={32}
                          readOnly={true}
                          onClick={() => {
                            closeAllDatePickers();
                            setReceivedStartDatePickerIsOpen(true);
                          }}
                          hasError={methods.formState.errors?.receivedDate?.start}
                        />
                        <div>&nbsp;-&nbsp;</div>
                        <Input
                          variant={'second'}
                          type="text"
                          name="receivedDate.end"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          height={32}
                          readOnly={true}
                          onClick={() => {
                            closeAllDatePickers();
                            setReceivedEndDatePickerIsOpen(true);
                          }}
                          hasError={methods.formState.errors?.receivedDate?.start}
                        />
                        {receivedStartDatePickerIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setReceivedStartDatePickerIsOpen}
                            setValue={methods.setValue}
                            name={'receivedDate.start'}
                            initDate={methods.getValues('receivedDate.start')}
                          />
                        )}
                        {receivedEndDatePickerIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setReceivedEndDatePickerIsOpen}
                            setValue={methods.setValue}
                            name={'receivedDate.end'}
                            initDate={methods.getValues('receivedDate.end')}
                          />
                        )}
                      </Stack>

                      <Text styleName="tooltip2" color="RC04">
                        {methods.formState.errors?.receivedDate?.start?.message}
                      </Text>
                    </Stack>

                    <Stack align="start" spacing={4}>
                      <Stack
                        justify="space-between"
                        direction="row"
                        align="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          methods.setValue('allow_desired_date_null', !methods.getValues('allow_desired_date_null'), {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }}
                      >
                        <Text styleName="caption2" color="RG02">
                          {strings.작업희망일}
                        </Text>
                        <Stack spacing={4} direction="row" align="center" sx={{ width: 'fit-content' }}>
                          <IcSuccess
                            width={10}
                            height={10}
                            fill={theme.colors[methods.getValues('allow_desired_date_null') ? 'RC02' : 'RG05']}
                          />
                          <Text
                            styleName="tooltip1"
                            color={methods.getValues('allow_desired_date_null') ? 'RC02' : 'RG04'}
                          >
                            희망일 없음 포함
                          </Text>
                          <Input
                            type="checkbox"
                            name="allow_desired_date_null"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            sx={{ display: 'none' }}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction={'row'} sx={{ position: 'relative' }}>
                        <Input
                          variant={'second'}
                          type="text"
                          name="desiredDate.start"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          height={32}
                          readOnly={true}
                          onClick={() => {
                            closeAllDatePickers();
                            setDesiredStartDatePickerIsOpen(true);
                          }}
                          hasError={methods.formState.errors?.desiredDate?.start}
                        />
                        <div>&nbsp;-&nbsp;</div>
                        <Input
                          variant={'second'}
                          type="text"
                          name="desiredDate.end"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          height={32}
                          readOnly={true}
                          onClick={() => {
                            closeAllDatePickers();
                            setDesiredEndDatePickerIsOpen(true);
                          }}
                          hasError={methods.formState.errors?.desiredDate?.start}
                        />
                        {desiredStartDatePickerIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setDesiredStartDatePickerIsOpen}
                            setValue={methods.setValue}
                            name={'desiredDate.start'}
                            initDate={methods.getValues('desiredDate.start')}
                          />
                        )}
                        {desiredEndDatePickerIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setDesiredEndDatePickerIsOpen}
                            setValue={methods.setValue}
                            name={'desiredDate.end'}
                            initDate={methods.getValues('desiredDate.end')}
                          />
                        )}
                      </Stack>
                      <Text styleName="tooltip2" color="RC04">
                        {methods.formState.errors?.desiredDate?.start?.message}
                      </Text>
                    </Stack>

                    <Stack align="start" spacing={4}>
                      <Text styleName="caption2" color="RG02">
                        {strings.완료일}
                      </Text>
                      <Stack direction={'row'} sx={{ position: 'relative' }}>
                        <Input
                          variant={'second'}
                          type="text"
                          name="completedDate.start"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          height={32}
                          sx={{ cursor: 'not-allowed' }}
                          readOnly={true}
                          onClick={() => {
                            closeAllDatePickers();
                            setCompletedStartDatePickerIsOpen(true);
                          }}
                          hasError={methods.formState.errors?.completedDate?.start}
                        />
                        <div>&nbsp;-&nbsp;</div>
                        <Input
                          variant={'second'}
                          type="text"
                          name="completedDate.end"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          height={32}
                          sx={{ cursor: 'not-allowed' }}
                          readOnly={true}
                          onClick={() => {
                            closeAllDatePickers();
                            setCompletedEndDatePickerIsOpen(true);
                          }}
                          hasError={methods.formState.errors?.completedDate?.start}
                        />
                        {completedStartDatePickerIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setCompletedStartDatePickerIsOpen}
                            setValue={methods.setValue}
                            name={'completedDate.start'}
                            initDate={methods.getValues('completedDate.start')}
                          />
                        )}
                        {completedEndDatePickerIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setCompletedEndDatePickerIsOpen}
                            setValue={methods.setValue}
                            name={'completedDate.end'}
                            initDate={methods.getValues('completedDate.end')}
                          />
                        )}
                      </Stack>
                      <Text styleName="tooltip2" color="RC04">
                        {methods.formState.errors?.completedDate?.start?.message}
                      </Text>
                    </Stack>
                  </Stack>

                  <Stack spacing={12} name="order-filter-driver-keyword">
                    <Stack align="start" spacing={4}>
                      <Stack align={'start'} direction={'row'} spacing={12}>
                        <Text styleName="caption2" color="RG02">
                          {strings.드라이버}
                        </Text>
                        <Text styleName="caption2" color="RG03">
                          {driverState
                            .filter(x => x.isSelected)
                            .filter(d => d.driverId > 0)
                            .length.toString()
                            .padStart(2, '0')}{' '}
                          명 /{' '}
                          {driverState
                            .filter(d => d.driverId > 0)
                            .length.toString()
                            .padStart(2, '0')}{' '}
                          명
                        </Text>
                      </Stack>
                      <DriverSelector
                        methods={methods}
                        drivers={driverState}
                        setDrivers={setDriverState}
                        driverPickerIsOpen={driverPickerIsOpen}
                        setDriverPickerIsOpen={setDriverPickerIsOpen}
                      />
                    </Stack>
                    <Stack align="start" spacing={4}>
                      <Text styleName="caption2" color="RG02">
                        {strings.고객명}
                      </Text>
                      <RelativeFrame>
                        <Input
                          height={32}
                          name="name"
                          variant={'third'}
                          placeholder={strings.고객이름을입력해주세요}
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          xAxisPadding={36}
                        />
                        <IcMagnifyingGlass style={{ position: 'absolute', top: '6px', left: '8px' }} />
                        <IcClose
                          style={{ position: 'absolute', top: '6px', right: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            methods.setValue('name', '', { shouldValidate: true });
                          }}
                        />
                      </RelativeFrame>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack name="button-area" sx={{ margin: '0 0 30px 0', padding: '0 20px 0 0' }}>
                <Button variant={'eighth'} type={'submit'} fullWidth height={32} color="RG03" styleName="caption1">
                  필터 적용
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Sidebar>
      <PageContentWrapper>
        <OrderManagePage />
      </PageContentWrapper>
    </Stack>
  );
};

export default OrderManageNav;

const ScrollableStack = styled(Stack)`
  ${({ theme }) => theme.modalScrollStyle.vertical}
  margin-right: -24px;
  width: calc(100% + 24px);
  flex: 1;
`;

const RelativeFrame = styled.div`
  position: relative;
  width: 100%;
`;
