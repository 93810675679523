import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import Divider from '../Divider';
import { DateSelector } from '../DateSelector';
import { Text } from '../Typography';
import dayjs from 'dayjs';
import PopupYIndexFixer from './PopupYIndexFixer';

interface StateDatePickerPopupProps {
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  setValue: React.Dispatch<SetStateAction<string>>;
  initDate: any;
  format?: any;
  banPast?: any;
  top?: any;
  rSide?: boolean;
}

function StateDatePicker({ setIsOpen, setValue, initDate, format, banPast, top, rSide }: StateDatePickerPopupProps) {
  const currentDate = dayjs();
  const parsedInitDate =
    initDate && initDate !== ''
      ? initDate.includes('GMT')
        ? dayjs(initDate).toDate()
        : dayjs(initDate, 'YYYY-MM-DD').toDate()
      : currentDate.add(1, 'day').toDate();
  const [date, setDate] = useState<Date | null>(parsedInitDate);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <PopupYIndexFixer>
      <DatePickerContainer top={top} ref={targetRef} rSide={rSide}>
        <Stack spacing={18} divider={<Divider />}>
          <DateSelector {...{ date, setDate, allowPastDate: banPast ? false : true }} />

          <Stack spacing={10} direction={'row'}>
            <Button
              fullWidth={true}
              variant={'second'}
              sx={{ height: '26px' }}
              type="button"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Text styleName="caption2" color={'RG04'}>
                닫기
              </Text>
            </Button>
            <Button
              variant="default"
              fullWidth={true}
              height={26}
              type="button"
              onClick={() => {
                if (date === null)
                  setValue(
                    `${dayjs()
                      .locale('ko')
                      .format(format || 'YYYY.MM.DD')}`
                  );
                else
                  setValue(
                    `${dayjs(date)
                      .locale('ko')
                      .format(format || 'YYYY.MM.DD')}`
                  );
                setIsOpen(false);
              }}
            >
              <Text styleName="caption2" color={'RG00'}>
                적용하기
              </Text>
            </Button>
          </Stack>
        </Stack>
      </DatePickerContainer>
    </PopupYIndexFixer>
  );
}

export default StateDatePicker;

const DatePickerContainer = styled.div<{ top?: string; rSide?: boolean }>`
  display: flex;
  padding: 20px 13px;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  width: 238px;
  position: absolute;
  top: ${({ top }) => (top ? top : '40px')};
  z-index: 3;

  ${({ rSide }) =>
    rSide
      ? {
          right: 0,
        }
      : {
          left: 0,
        }}
`;
