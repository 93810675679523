import React, { useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { IUnassignedOrdersList } from 'constants/types';
import './style.css';
import { initDrawingManager } from './components/DrawingManager';
import { MapConfigDefaultProps, MapConfigKeys, MapConfigOptions } from './MapConfig';
import { GoogleMapsOverlay } from './DeckGL/GoogleMapsOverlay';
import { DynamicMarker } from './DeckGL/DynamicMarker';
import { DynamicSvgPresets } from '../DynamicSvg/Presets';

interface RoutePlanSetupMapProps {
  unassignedOrderList?: IUnassignedOrdersList[];
  selectedOrder: any[];
  setSelectedOrder: React.Dispatch<React.SetStateAction<any[]>>;
}

export function RoutePlanSetupMap({ unassignedOrderList, selectedOrder, setSelectedOrder }: RoutePlanSetupMapProps) {
  const [mapInstance, setMapInstance] = React.useState<any>(null);
  const [mapsInstance, setMapsInstance] = React.useState<any>(null);
  const [overlayInstance, setOverlayInstance] = React.useState<GoogleMapsOverlay | null>(null);
  const [drawingInstance, setDrawingInstance] = React.useState<any>(null);
  const [draggedBound, setDraggedBound] = React.useState<any>(null);

  useEffect(() => {
    if (mapsInstance && mapInstance) {
      let bounds = new mapsInstance.LatLngBounds();
      unassignedOrderList?.forEach(x => {
        x.coordinate && bounds.extend({ lat: x.coordinate.coordinates[1], lng: x.coordinate.coordinates[0] });
      });
      mapInstance.fitBounds(bounds, 120);
    }
  }, [mapsInstance, mapInstance, unassignedOrderList]);

  useEffect(() => {
    if (draggedBound == null) return;
    let order: IUnassignedOrdersList[] = [];
    unassignedOrderList?.forEach(x => {
      if (x.coordinate) {
        let position = { lat: x.coordinate?.coordinates[1], lng: x.coordinate?.coordinates[0] };
        if (draggedBound.contains(position)) {
          if (selectedOrder.filter(d => d.order_id === x.order_id).length === 0) order.push(x);
        }
      }
    });
    setSelectedOrder([...selectedOrder, ...order]);
    setDraggedBound(null);
  }, [draggedBound]);

  const onDrawingEventCalled = (bound: any) => {
    setDraggedBound(bound);
  };

  function onMapLoaded(map: any, maps: any) {
    if (!mapInstance) setMapInstance(map);
    if (!mapsInstance) setMapsInstance(maps);
    if (!drawingInstance) {
      let drawingManager = initDrawingManager({ map, maps, onEvent: onDrawingEventCalled });
      setDrawingInstance(drawingManager);
    }
    if (!overlayInstance) {
      let overlay = new GoogleMapsOverlay({});
      setOverlayInstance(overlay);
      overlay.setMap(map);
    }
  }

  function selectOrderByMarkerSelect(order_id: number) {
    if (selectedOrder.filter(x => x.order_id === order_id).length > 0) {
      setSelectedOrder(selectedOrder.filter(x => x.order_id !== order_id));
      return;
    }
    let order: IUnassignedOrdersList[] = [];

    unassignedOrderList?.forEach(x => {
      if (x.order_id === order_id) order.push(x);
    });
    setSelectedOrder([...selectedOrder, ...order]);
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={MapConfigKeys}
      options={MapConfigOptions}
      defaultCenter={MapConfigDefaultProps.center}
      defaultZoom={MapConfigDefaultProps.zoom}
      onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
    >
      {unassignedOrderList && overlayInstance && (
        <DynamicMarker
          overlay={overlayInstance}
          marker={unassignedOrderList
            .filter(x => x.coordinate && x.coordinate.coordinates && x.coordinate.coordinates.length > 1)
            .map(x => {
              let info =
                selectedOrder.filter(y => y.order_id === x.order_id).length > 0
                  ? DynamicSvgPresets.waitingSelected
                  : DynamicSvgPresets.waiting;
              info = JSON.parse(JSON.stringify(info));
              info.chip = {
                time: !!(x.desired_time_start || x.desired_time_end),
                pickup: x.shipment_type === 'pickup',
                skill: !!x.skill,
              };
              return {
                info,
                coordinates: [x.coordinate.coordinates[0], x.coordinate.coordinates[1]],
                onClick: () => {
                  console.log(x);
                  selectOrderByMarkerSelect(x.order_id);
                },
              };
            })}
        />
      )}
    </GoogleMapReact>
  );
}
