import React, { ReactNode } from 'react';
import * as S from './style';

export interface CardProps {
  children: ReactNode;
  variant: 'menu' | 'popup';
  ds?: 'weak' | 'normal' | 'strong';

  width: number | undefined;
  padding: number;

  sx?: React.CSSProperties;
}

const MappedStyled = {
  menu: S.MenuCardOutline,
  popup: S.PopupCardOutline,
};

export function Card({ sx, children, ...props }: CardProps): React.ReactElement {
  const MappedStyledComponent = MappedStyled[props.variant];

  if (Object.keys(MappedStyled).includes(props.variant))
    return (
      <MappedStyledComponent style={sx} {...props}>
        {children}
      </MappedStyledComponent>
    );
  else return <React.Fragment />;
}
