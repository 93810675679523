export const DynamicSvgPresets: any = {
  start: {
    markerColor: '#1C48CD',
    markerSize: 'normal',
    borderColor: 'white',
    markerType: 'start',
  },
  end: {
    markerColor: '#25282F',
    markerSize: 'normal',
    borderColor: 'white',
    markerType: 'end',
  },
  skip: {
    markerColor: '#565B69',
    markerSize: 'normal',
    borderColor: 'white',
    markerType: 'skip',
  },
  waiting: {
    markerType: 'waiting',
    markerColor: 'white',
    markerSize: 'normal',
    borderColor: '#B2B8CC',
  },
  waitingSelected: {
    markerType: 'waiting',
    markerColor: '#769DFF',
    markerSize: 'large',
    borderColor: 'white',
  },
  orderComplete: {
    markerType: 'order',
    markerColor: 'white',
    markerSize: 'normal',
    borderColor: '#406EE2',
    text: '36',
    textColor: '#406EE2',
  },
  order: {
    markerType: 'order',
    markerColor: '#449FD2',
    markerSize: 'normal',
    borderColor: 'white',
    text: '733',
  },
  orderSelected: {
    markerType: 'order',
    markerColor: '#406EE2',
    markerSize: 'large',
    borderColor: 'white',
    text: '7',
  },
  orderEditing: {
    markerType: 'orderEditing',
    markerColor: '#769DFF',
    markerSize: 'large',
    borderColor: 'white',
  },
  test1: {
    markerType: 'order',
    markerColor: '#770000',
    markerSize: 'large',
    borderColor: 'white',
    text: '7',
    chip: {
      time: true,
      skill: true,
    },
  },
  test4: {
    markerType: 'order',
    markerColor: '#770000',
    markerSize: 'normal',
    borderColor: 'white',
    text: '7',
    chip: {
      delivery: true,
    },
  },
  test2: {
    markerType: 'order',
    markerColor: '#770000',
    markerSize: 'large',
    borderColor: 'white',
    text: '7',
    chip: {
      completed: true,
    },
  },
  test3: {
    markerType: 'order',
    markerColor: '#770000',
    markerSize: 'normal',
    borderColor: 'white',
    text: '7',
    chip: {
      time: true,
      pickup: true,
      skill: true,
    },
  },
};
