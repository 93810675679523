import { Text } from '../../Typography';
import { ColorPalette } from '../../../constants/colorPalette';
import Skill from '../../../constants/icon/ic_truck.png';
import Time from '../../../constants/icon/ic_time.png';
import { ReactComponent as Done } from '../../../constants/icon/ic_done.svg';
import Pickup from '../../../constants/icon/ic_pickup.png';
import Delivery from '../../../constants/icon/ic_delivery.png';
import React from 'react';
interface MarkerChip {
  complete?: boolean;
  time?: boolean;
  pickup?: boolean;
  delivery?: boolean;
  skill?: boolean;
}

interface MarkerProps {
  type: 'order' | 'order_end' | 'unassigned' | 'unassigned_selected';
  size?: 'normal' | 'large';
  text?: string;
  color?: number;
  zIndex?: number;
  alpha?: number;
  chip?: MarkerChip;
  onClick?: () => void;
}

function hasAtLeastOneTrue(obj?: MarkerChip) {
  if (!obj) return false;
  return Object.values(obj).some(value => value === true);
}

export const AIOMarker = ({ ...props }: MarkerProps) => {
  return (
    <div
      className={`aiomarker-container ${props.size || 'normal'}`}
      style={{ zIndex: props.zIndex || 0, opacity: props.alpha || 1 }}
      onClick={props.onClick}
    >
      {props.type === 'order' && <MARKER_ORDER {...props} />}
      {props.type === 'order_end' && <MARKER_ORDER_END {...props} />}
      {props.type === 'unassigned' && <MARKER_UNASSIGNED {...props} />}
      {props.type === 'unassigned_selected' && <MARKER_UNASSIGNED_SELECTED {...props} />}
    </div>
  );
};

const MARKER_UNASSIGNED = ({ ...props }: MarkerProps) => {
  return (
    <>
      <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 16.0222V16.0221C0.499467 11.9483 2.09819 8.03777 4.95103 5.13396C7.8029 2.23113 11.6795 0.566978 15.7451 0.5H16.24C20.3082 0.563078 24.1887 2.22552 27.0438 5.12882C29.8998 8.03304 31.5005 11.9458 31.5 16.0221C31.5 18.2484 31.1778 19.9523 30.4252 21.6421C29.6674 23.3433 28.4626 25.0558 26.6512 27.2878C25.9774 28.0458 25.4006 28.6667 24.8745 29.2329C23.162 31.0763 21.9875 32.3406 19.753 35.8736C18.701 37.5274 18.1988 39.2608 17.7991 40.6409C17.7339 40.8657 17.6715 41.0811 17.6099 41.2853C17.3833 42.036 17.1785 42.5982 16.9183 42.9774C16.6821 43.3217 16.4232 43.4853 16.0444 43.5H15.9555C15.5666 43.4852 15.3045 43.3192 15.0684 42.9766C14.8077 42.5984 14.6042 42.0372 14.3801 41.2867C14.3182 41.0797 14.2557 40.861 14.1904 40.6327C13.7962 39.2544 13.3014 37.5242 12.2547 35.8737L12.2541 35.8727C10.0221 32.3705 8.84278 31.0941 7.14461 29.2562C6.61504 28.683 6.03499 28.0552 5.35682 27.2883C3.54475 25.0556 2.338 23.3467 1.5784 21.6472C0.824084 19.9595 0.5 18.2558 0.5 16.0222Z"
          fill="white"
          stroke="#B2B8CC"
        />
        <circle cx="16" cy="16" r="8" fill="#769DFF" fillOpacity="0.3" />
      </svg>
      {hasAtLeastOneTrue(props.chip) && <MARKER_CHIP chip={props.chip!} />}
    </>
  );
};

const MARKER_UNASSIGNED_SELECTED = ({ ...props }: MarkerProps) => {
  return (
    <>
      <svg width="44" height="60" viewBox="0 0 44 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 21.8484V21.8484C0.499261 16.2469 2.71578 10.869 6.67245 6.87491C10.6283 2.88166 16.0066 0.591757 21.6479 0.5H22.3314C27.9764 0.586414 33.36 2.87397 37.3203 6.86784C41.2814 10.8625 43.5007 16.2434 43.5 21.8484C43.5 24.9037 43.0536 27.2528 42.0065 29.5845C40.9541 31.9278 39.284 34.279 36.7897 37.3268C35.8585 38.3657 35.0629 39.215 34.3383 39.9886C31.9867 42.4991 30.3828 44.2114 27.3197 49.0144C25.8883 51.2459 25.2054 53.5828 24.6552 55.4655C24.5653 55.7731 24.4789 56.0686 24.3934 56.3495C24.0824 57.3716 23.7942 58.1637 23.4184 58.7068C23.0667 59.215 22.6595 59.4781 22.0647 59.5H21.9352C21.3275 59.478 20.9155 59.2126 20.5635 58.706C20.1868 58.1638 19.9003 57.3727 19.5926 56.351C19.5068 56.066 19.4202 55.766 19.3301 55.4535C18.7876 53.5734 18.1148 51.2413 16.6908 49.0144L16.6902 49.0135C13.6304 44.2521 12.0197 42.5232 9.68772 40.02C8.95827 39.237 8.15825 38.3783 7.22122 37.3273C4.72628 34.2788 3.05347 31.9326 1.99848 29.5917C0.948911 27.2628 0.5 24.9139 0.5 21.8484Z"
          fill="#769DFF"
          stroke="white"
        />
        <circle cx="22" cy="22" r="8" fill="#406EE2" />
      </svg>
      {hasAtLeastOneTrue(props.chip) && <MARKER_CHIP chip={props.chip!} />}
    </>
  );
};

const MARKER_ORDER = ({ ...props }: MarkerProps) => {
  return (
    <>
      <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 16.0222V16.0221C0.499467 11.9483 2.09819 8.03777 4.95103 5.13396C7.8029 2.23113 11.6795 0.566978 15.7451 0.5H16.24C20.3082 0.563078 24.1887 2.22552 27.0438 5.12882C29.8998 8.03304 31.5005 11.9458 31.5 16.0221C31.5 18.2484 31.1778 19.9523 30.4252 21.6421C29.6674 23.3433 28.4626 25.0558 26.6512 27.2878C25.9774 28.0458 25.4006 28.6667 24.8745 29.2329C23.162 31.0763 21.9875 32.3406 19.753 35.8736C18.701 37.5274 18.1988 39.2608 17.7991 40.6409C17.7339 40.8657 17.6715 41.0811 17.6099 41.2853C17.3833 42.036 17.1785 42.5982 16.9183 42.9774C16.6821 43.3217 16.4232 43.4853 16.0444 43.5H15.9555C15.5666 43.4852 15.3045 43.3192 15.0684 42.9766C14.8077 42.5984 14.6042 42.0372 14.3801 41.2867C14.3182 41.0797 14.2557 40.861 14.1904 40.6327C13.7962 39.2544 13.3014 37.5242 12.2547 35.8737L12.2541 35.8727C10.0221 32.3705 8.84278 31.0941 7.14461 29.2562C6.61504 28.683 6.03499 28.0552 5.35682 27.2883C3.54475 25.0556 2.338 23.3467 1.5784 21.6472C0.824084 19.9595 0.5 18.2558 0.5 16.0222Z"
          fill={ColorPalette.getColor(props.color || 0)}
          stroke="white"
        />
      </svg>
      <MARKER_TEXT color={'#FFFFFF'} text={props.text} />
      {hasAtLeastOneTrue(props.chip) && <MARKER_CHIP chip={props.chip!} />}
    </>
  );
};

const MARKER_ORDER_END = ({ ...props }: MarkerProps) => {
  return (
    <>
      <svg width="32" height="46" viewBox="0 0 32 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 16.5415V16.5414C0.499467 12.4676 2.09819 8.55705 4.95103 5.65324C7.8029 2.75042 11.6795 1.08626 15.7451 1.01929H16.24C20.3082 1.08237 24.1887 2.74481 27.0438 5.64811C29.8998 8.55233 31.5005 12.4651 31.5 16.5414C31.5 18.7677 31.1778 20.4716 30.4252 22.1613C29.6674 23.8626 28.4626 25.5751 26.6512 27.8071C25.9774 28.5651 25.4006 29.186 24.8745 29.7522C23.162 31.5956 21.9875 32.8599 19.753 36.3929C18.701 38.0467 18.1988 39.7801 17.7991 41.1601C17.7339 41.3849 17.6715 41.6004 17.6099 41.8046C17.3833 42.5553 17.1785 43.1175 16.9183 43.4967C16.6821 43.841 16.4232 44.0046 16.0444 44.0193H15.9555C15.5666 44.0045 15.3045 43.8385 15.0684 43.4958C14.8077 43.1176 14.6042 42.5565 14.3801 41.806C14.3182 41.5989 14.2557 41.3803 14.1904 41.152C13.7962 39.7737 13.3014 38.0435 12.2547 36.393L12.2541 36.392C10.0221 32.8898 8.84278 31.6134 7.14461 29.7755C6.61504 29.2023 6.03499 28.5745 5.35682 27.8076C3.54475 25.5749 2.338 23.866 1.5784 22.1665C0.824084 20.4788 0.5 18.7751 0.5 16.5415Z"
          fill="white"
          stroke={ColorPalette.getColor(props.color || 0)}
        />
      </svg>
      <MARKER_TEXT color={ColorPalette.getColor(props.color || 0)} text={props.text} />
      {hasAtLeastOneTrue(props.chip) && <MARKER_CHIP chip={props.chip!} />}
    </>
  );
};

const MARKER_TEXT = ({ color, text }: { color?: string; text?: string }) => {
  return (
    <div className={'aiomarker-text'}>
      <Text styleName={'number2'} color={color}>
        {text}
      </Text>
    </div>
  );
};

const MARKER_CHIP = ({ chip }: { chip: MarkerChip }) => {
  if (chip.complete) return <></>;
  else
    return (
      <div className={'aiomarker-chip'}>
        {chip.complete && <Done />}
        {chip.time && <img src={Time} alt={'time'} />}
        {chip.pickup && <img src={Pickup} alt={'pickup'} />}
        {chip.delivery && <img src={Delivery} alt={'delivery'} />}
        {chip.skill && <img src={Skill} alt={'skill'} />}
      </div>
    );
};
