import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Button } from 'components/Button';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';

import { ReactComponent as IcArrowCountDown } from 'constants/icon/ic_arrow_count_down.svg';
import { ReactComponent as IcArrowCountUp } from 'constants/icon/ic_arrow_count_up.svg';
import { ReactComponent as IcDot } from 'constants/icon/ic_dot.svg';

import theme from 'constants/theme';

interface TimePickerPopupProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: (name: string, value: unknown, config?: Object) => void;
  name: string;

  rSide?: boolean;
  desired?: boolean;
}

type Tampm = 'AM' | 'PM' | 'NDAM';

const AMPMContents: Record<Tampm, { text: string }> = {
  AM: { text: '오전' },
  PM: { text: '오후' },
  NDAM: { text: '익일오전' },
};

const MiniTimePicker = ({ isOpen, setIsOpen, setValue, name, rSide, desired }: TimePickerPopupProps) => {
  const [amPm, setAmPm] = React.useState<Tampm>('AM');
  const [hour, setHour] = React.useState('00');
  const [minute, setMinute] = React.useState('00');

  const [isTimeFocused, setIsTimeFocused] = React.useState(false);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <TimePickerContainer ref={targetRef} rSide={rSide}>
      <Stack spacing={20} justify={'center'} align={'center'}>
        <Stack name="timePicker_header_area" direction="row" justify="space-between" align="center">
          <Text styleName="caption2" color={'RG04'}>
            시간 설정 {desired && '희망'}
          </Text>
          <Button
            variant={'second'}
            sx={{ width: '45px', height: '26px' }}
            type={'button'}
            onClick={() => {
              // RT-702
              setValue(name, '', { shouldValidate: true, shouldDirty: true });
              setAmPm('AM');
              setHour('00');
              setMinute('00');

              setIsOpen(false);
            }}
          >
            <Text styleName="caption2" color={'RG04'}>
              초기화
            </Text>
          </Button>
        </Stack>

        <Stack direction={'row'} sx={{ borderRadius: '6px', overflow: 'hidden', width: '100%' }}>
          {Object.entries(AMPMContents).map(d => {
            return (
              <AmpmContainer
                style={amPm === d[0] ? { backgroundColor: theme.colors.RC03 } : { backgroundColor: theme.colors.RG07 }}
                onClick={() => {
                  setAmPm(d[0] as Tampm);
                }}
                len={desired ? 3 : 2}
              >
                <Text styleName={'caption3'} color={amPm === d[0] ? 'RG00' : 'RG04'}>
                  {d[1].text}
                </Text>
              </AmpmContainer>
            );
          })}
        </Stack>

        <Stack spacing={4}>
          <Stack direction={'row'} align={'center'}>
            <Stack>
              <IcArrowCountUp
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let h = parseInt(hour) + 1;
                  if (h === 12) setHour('00');
                  if (h < 12) setHour(`${h > 9 ? '' : '0'}${h}`);
                }}
              />
            </Stack>
            <Stack>
              <IcArrowCountUp
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let m = parseInt(minute) + 1;
                  if (m === 60) setMinute('00');
                  if (m < 60) setMinute(`${m > 9 ? '' : '0'}${m}`);
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            align={'center'}
            sx={{
              background: isTimeFocused ? theme.colors.RC03_1 : theme.colors.RG08,
              border: '1px solid #769DFF',
              borderColor: isTimeFocused ? theme.colors.RC03 : theme.colors.RG08,
              borderRadius: '6px',
            }}
          >
            <Stack padding={6}>
              <NumberInput
                onBlur={() => {
                  setIsTimeFocused(false);
                }}
                onFocus={() => {
                  setIsTimeFocused(true);
                }}
                placeholder="00"
                value={hour}
                onChange={e => {
                  let parsed = parseInt(e.target.value) || 0;
                  if (parsed < 0) parsed = 0;
                  else if (parsed > 11) parsed = 11;

                  setHour(`${parsed > 9 ? '' : '0'}${parsed}`);
                }}
              />
            </Stack>
            <Stack sx={{ width: '4px', height: '18px' }}>
              <IcDot />
            </Stack>
            <Stack padding={6}>
              <NumberInput
                onBlur={() => {
                  setIsTimeFocused(false);
                }}
                onFocus={() => {
                  setIsTimeFocused(true);
                }}
                placeholder="00"
                value={minute}
                onChange={e => {
                  let parsed = parseInt(e.target.value) || 0;
                  if (parsed < 0) parsed = 0;
                  else if (parsed > 59) parsed = 59;
                  setMinute(`${parsed > 9 ? '' : '0'}${parsed}`);
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} align={'center'}>
            <Stack>
              <IcArrowCountDown
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let h = parseInt(hour) - 1;
                  if (h === -1) setHour('11');
                  if (h >= 0) setHour(`${h > 9 ? '' : '0'}${h}`);
                }}
              />
            </Stack>
            <Stack>
              <IcArrowCountDown
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let m = parseInt(minute) - 1;
                  if (m === -1) setMinute('59');
                  if (m >= 0) setMinute(`${m > 9 ? '' : '0'}${m}`);
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={10} sx={{ justifyContent: 'end' }}>
          <Button
            variant="default"
            fullWidth
            height={26}
            type="submit"
            onClick={() => {
              setValue(
                name,
                `${AMPMContents[amPm].text} ${amPm === 'PM' ? parseInt(hour) + 12 : hour}:${minute}`,
                // `${dayjs(date).format('HH:mm')} ${amPm === 'AM' ? hour : parseInt(hour) + 12}:${minute}:00`,
                { shouldValidate: true, shouldDirty: true }
              );
              setIsOpen(false);
            }}
          >
            <Text styleName="caption2" color={'RG00'}>
              적용하기
            </Text>
          </Button>

          <Button
            variant={'second'}
            fullWidth
            height={26}
            type="submit"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Text styleName="caption2" color={'RG04'}>
              닫기
            </Text>
          </Button>
        </Stack>
      </Stack>
    </TimePickerContainer>
  );
};

export default MiniTimePicker;

const TimePickerContainer = styled.div<{ rSide?: boolean }>`
  display: flex;
  padding: 24px 20px;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  position: absolute;
  top: 76px;
  ${({ rSide }) =>
    rSide
      ? {
          right: 0,
        }
      : {
          left: 0,
        }}
  z-index: 3;
`;

const AmpmContainer = styled.div<{ len: number }>`
  ${({ len }) => ({
    width: `calc(100% / ${len})`,
  })}
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NumberInput = styled.input`
  ${({ theme }) => theme.fontStyle.title3}
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.RG03};
  background: transparent;
`;
