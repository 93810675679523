import React, { SetStateAction, useEffect } from 'react';
import Modal from './index';
import { Stack } from '../Stack';
import { Text } from '../Typography';
import Divider from '../Divider';
import { ReactComponent as IconFileUpload } from '../../constants/icon/ic_file_download.svg';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { getOrderListDownload } from 'api';
import xlsxDownloader from 'util/xlsxDownloader';
import strings from '../../util/Localization';

export const OrderDownloadModal = ({
  selected,
  total,
  orderDownloadModalIsOpen,
  setOrderDownloadModalIsOpen,
}: {
  selected: Array<number>;
  total: Array<number>;
  orderDownloadModalIsOpen: boolean;
  setOrderDownloadModalIsOpen: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const { mutate: mutateGetOrderListDownload } = useMutation(getOrderListDownload, {
    onSuccess: res => {
      // console.log(res);
      xlsxDownloader(res.orderList, 'order');
    },
    onSettled: (data, error, variables, context) => {
      setOrderDownloadModalIsOpen(false);
    },
  });

  const runDownloadXlsx = (type: 'selected' | 'all') => {
    if (type === 'all') {
      return mutateGetOrderListDownload({ orderList: total });
    }

    if (type === 'selected') {
      if (selected.length > 0) {
        return mutateGetOrderListDownload({ orderList: selected });
      } else {
        alert(strings.선택된주문 + '이 없습니다.');

        setOrderDownloadModalIsOpen(false);
        return;
      }
    }
  };

  return (
    <Modal
      title={strings.주문파일다운로드}
      isModalOpen={orderDownloadModalIsOpen}
      setIsModalOpen={setOrderDownloadModalIsOpen}
      ds="strong"
      padding={24}
      ms={48}
      width={701}
    >
      <Stack spacing={52}>
        <Text styleName="body3" color="RG03">
          다운로드 받으실 파일을 선택해주세요.
        </Text>
        <Stack
          direction="row"
          spacing={24}
          justify="space-between"
          divider={<Divider vertical />}
          sx={{ height: '230px' }}
        >
          <BorderBox
            onClick={() => {
              runDownloadXlsx('selected');
            }}
          >
            <Stack spacing={8}>
              <Stack spacing={12}>
                <IconFileUpload />
                <Text styleName="body1" color="RC02">
                  {strings.선택한주문다운로드}
                </Text>
              </Stack>

              <Text styleName="subheadline2" color="RC02">
                총 {selected.length} 건
              </Text>
            </Stack>
          </BorderBox>

          <BorderBox
            onClick={() => {
              runDownloadXlsx('all');
            }}
          >
            <Stack spacing={8}>
              <Stack spacing={12}>
                <IconFileUpload />
                <Text styleName="body1" color="RC02">
                  {strings.전체주문다운로드}
                </Text>
              </Stack>

              <Text styleName="subheadline2" color="RC02">
                총 {total.length} 건
              </Text>
            </Stack>
          </BorderBox>
        </Stack>
      </Stack>
    </Modal>
  );
};

const BorderBox = styled.div`
  width: 100%;

  padding: 40px 20px 42px;

  background: ${({ theme }) => theme.colors.RC03_2};
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.RC03_3}`};

  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.RC03_3};
  }
  transition: all 0.5s;
`;
