import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {
  IOrderReportDimensionRoute,
  IOrderReportMetrics,
  IReportResponsesList,
  IRouteReportDimensionRoute,
  IRouteReportMetrics,
} from 'constants/types';
import dayjs from 'dayjs';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { TReportType } from 'components/Modal/ReportDownloadModal';
import { validateOrderStatus } from './validateStatus';
import { shipment_type } from 'constants/commons';

export default function reportXlsxDownloader(data: any, type: TReportType, combined: TDragablePickerOptions) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('roouty');

  const activatedColumn: Array<ExcelJS.Column> = combined
    .filter(d => d.isSelected)
    .map(d => {
      return {
        header: [d.key.toString(), d.name.toString()],
        key: d.key.toString(),
      };
    }) as Array<ExcelJS.Column>;

  const handleDrivingExcel = async (
    data: Array<IReportResponsesList<IRouteReportDimensionRoute, IRouteReportMetrics>>
  ) => {
    worksheet.columns = combined
      .filter(d => d.isSelected)
      .map(d => {
        return {
          header: [d.key.toString(), d.name.toString()],
          key: d.key.toString(),
        };
      });

    // row 추가 length 만큼 반복 후  key로 매핑
    // 노출 여부와 상관없이 모든 column에 대한 데이터 매핑해두기
    data.forEach(d =>
      worksheet.addRow({
        driver: d.dimension.driver?.name,
        route_id: d.dimension.route?.id,
        route_name: d.dimension.route?.name,
        performedDate: d.dimension?.performedDate,
        estimatedTime: Math.round(d.metrics.estimatedTime / 60),
        actualTime: Math.round(d.metrics.actualTime / 60),
        estimatedDistance: Number((d.metrics.estimatedDistance / 1000).toFixed(2)),
        actualDistance: Number((d.metrics.actualDistance / 1000).toFixed(2)),
        totalCapacity: d.metrics.totalCapacity,
        actualCapacity: d.metrics.actualCapacity,
        totalOrder: d.metrics.totalOrder,
        completedOrder: d.metrics.completedOrder,
        skippedOrder: d.metrics.skippedOrder,
        podUploaded: d.metrics.podUploaded,
        rotation: d.metrics.rotation,
      })
    );

    worksheet.columns.forEach(column => {
      // 한글은 적절히 2Byte로 계산
      const lengths = column.values
        ? column.values.map(v => (v ? v.toString().replace(/[가-힣]/g, '00').length : 0))
        : [];

      let expectationWidth = Math.max(...lengths.filter(v => typeof v === 'number'));
      let maxWidth = 100;
      column.width = (expectationWidth > maxWidth ? maxWidth : expectationWidth) + 3;
    });

    // 다운로드
    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    // RT-841
    saveAs(blob, `(루티) 보고서 다운로드_${dayjs().format('YYMMDD')}.xlsx`);
  };

  const handleOrderExcel = async (
    data: Array<IReportResponsesList<IOrderReportDimensionRoute, IOrderReportMetrics>>
  ) => {
    worksheet.columns = activatedColumn;

    // row 추가 length 만큼 반복 후  key로 매핑
    // 노출 여부와 상관없이 모든 column에 대한 데이터 매핑해두기
    data.forEach(d =>
      worksheet.addRow({
        driver: d.dimension.driver?.name,
        route_id: d.dimension.route?.id,
        route_name: d.dimension.route?.name,
        performedDate: d.dimension?.performedDate,
        orderCode: d.metrics.orderId,
        status: validateOrderStatus(d.metrics.status as any).name,
        consigneeName: d.metrics.consigneeName,
        address: d.metrics.address,
        detailAddress: d.metrics.detailAddress,
        capacity: d.metrics.capacity,
        routeIndex: d.metrics.routeIndex,
        serviceTime: Math.round(d.metrics.serviceTime / 60),
        shipmentType: d.metrics.shipmentType.includes('Rotation')
          ? `${shipment_type.find(x => x.value === d.metrics.shipmentType.replace('Rotation', ''))?.label} 후 회차`
          : shipment_type.find(x => x.value === d.metrics.shipmentType)?.label,
        createdAt: dayjs(d.metrics.createdAt).format('YYYY-MM-DD'),
        historyComment: d.metrics.historyComment,
        podUrl: d.metrics.podUrl,
        podCreatedAt: d.metrics.podCreatedAt,
        actualCapacity: d.metrics.actualCapacity,
        capacityComment: d.metrics.capacityComment,
      })
    );

    worksheet.columns.forEach(column => {
      // 한글은 적절히 2Byte로 계산
      const lengths = column.values
        ? column.values.map(v => (v ? v.toString().replace(/[가-힣]/g, '00').length : 0))
        : [];

      let expectationWidth = Math.max(...lengths.filter(v => typeof v === 'number'));
      let maxWidth = 100;
      column.width = (expectationWidth > maxWidth ? maxWidth : expectationWidth) + 3;
    });

    // 다운로드
    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    // RT-841
    saveAs(blob, `(루티) 보고서 다운로드_${dayjs().format('YYMMDD')}.xlsx`);
  };

  if (type === 'driving') return handleDrivingExcel(data);
  if (type === 'order') return handleOrderExcel(data);
}
