import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  PaginationState,
  createTable,
  useTableInstance,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  RowSelectionState,
} from '@tanstack/react-table';

import { Menu, MenuItem } from '@szhsin/react-menu';
import dayjs from 'dayjs';
import strings from 'util/Localization';
import { validateOrderStatus } from 'util/validateStatus';
import { IControlRouteDriverOrderList } from 'constants/types';

import { DetailSelectBoxButton, RowDirectionContainer, TableDeatilButton } from './style';

import TableComponent from '.';
import { Tag } from 'components/Tag';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import SkipModal from 'components/Modal/SkipModal';

import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';
import { ReactComponent as IconPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import { shipment_type } from 'constants/commons';
import TableSelectManager from './util/TableSelectManager';

let routeDriverControlTable = createTable().setRowType<IControlRouteDriverOrderList>();

function RouteDriverControlTable({
  data,
  setTarget,
  cardTargetDriverId,
  setOrderDetailModalOpen,
  refetchControlRouteByIdData,
  defaultCheckOrderIds,
}: {
  data: IControlRouteDriverOrderList[] | undefined;
  cardTargetDriverId: number;
  setTarget: Dispatch<SetStateAction<number | undefined>>;
  setOrderDetailModalOpen: Dispatch<SetStateAction<boolean>>;
  refetchControlRouteByIdData: Function;
  defaultCheckOrderIds?: Array<number>;
}) {
  let domain = window.localStorage.getItem('serviceDomain');

  const [isHover, setIsHover] = useState<
    {
      id: string;
      value: boolean;
    }[]
  >([]);
  const [selectedOrder, setSelectedOrder] = useState<Array<any>>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  function customSetRowSelection(value: any) {
    if (Object.keys(value() ?? {})[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });

      orders = orders
        .filter(y => y.orderId)
        .filter(y => selectedOrder.filter(z => z.orderId === y.orderId).length === 0);
      setSelectedOrder([...selectedOrder, ...orders]);
    } else {
      let orders: Array<any> = selectedOrder;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.orderId !== s.original?.orderId);
      });
      setSelectedOrder(orders);
    }
  }

  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = React.useState<boolean>(false);
  const [delTarget, setDelTarget] = React.useState<Array<number>>([]);
  const [noCancelTrigger, setNoCancelTrigger] = React.useState<boolean>(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({
    sorter: false,
  });
  const [sorting, setSorting] = useState<SortingState>([{ id: 'sorter', desc: true }]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      routeDriverControlTable.createDataColumn(row => defaultCheckOrderIds?.includes(row?.orderId as number), {
        id: 'sorter',
        header: 'sortingColumn',
        enableSorting: true,
        sortDescFirst: true,
        enableMultiSort: true,
        sortingFn: (x, y, z) => {
          return defaultCheckOrderIds?.includes(Number(x.original?.orderId)) &&
            x.getIsSelected() &&
            selectedOrder.filter(d => !defaultCheckOrderIds?.includes(d.orderId)).length < 1
            ? 1
            : 0;
        },
      }),

      routeDriverControlTable.createDisplayColumn({
        id: 'select',
        header: ({ instance }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked:
                instance
                  .getCoreRowModel()
                  .rows.filter(d => d.original?.type === 'order')
                  .every(d => d.getIsSelected() === true) &&
                instance.getCoreRowModel().rows.filter(d => d.original?.type === 'order').length > 0,
              indeterminate: instance.getIsSomeRowsSelected(),
              onChange: instance.getToggleAllRowsSelectedHandler(),

              instance,
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) =>
          row.original?.type === 'order' && (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        enableSorting: false,
      }),

      routeDriverControlTable.createDataColumn(row => row.route.routeIndex, {
        id: 'routeIndex',
        header: () => '순서',
        sortingFn: 'basic',
        enableSorting: true,
        enableMultiSort: true,
      }),
      routeDriverControlTable.createDataColumn(row => row.status, {
        id: 'status',
        header: '상태',
        cell: info => {
          if (info.row.original?.type === 'break' || info.row.original?.type === 'end') return null;

          let status = info.getValue();
          if (Boolean(status)) {
            let { key, name } = validateOrderStatus(
              status as
                | 'unassigned'
                | 'scheduled'
                | 'processing'
                | 'arrived'
                | 'completed'
                | 'skipped'
                | 'canceled'
                | 'deleted'
            );

            return (
              <Menu
                menuButton={
                  <div style={{ cursor: 'pointer' }} onClick={e => e.stopPropagation()}>
                    <Tag
                      active={isHover.find(d => d.id === info.row.id)?.value}
                      styleName="caption1"
                      status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
                      sx={{ width: '56px' }}
                    >
                      {name}
                    </Tag>
                  </div>
                }
                offsetY={10}
                menuClassName={'table-menu'}
              >
                <MenuItem>
                  <DetailSelectBoxButton
                    onClick={e => {
                      e.stopPropagation();

                      setDelTarget([info.row.original!.orderId as number]);
                      setDeleteOrderModalOpen(true);
                    }}
                  >
                    보류
                  </DetailSelectBoxButton>
                </MenuItem>
              </Menu>
            );
          } else return '';
        },
        enableSorting: false,
      }),
      routeDriverControlTable.createDataColumn(
        row => (row?.completedAt ? dayjs(row.completedAt).format('HH:mm') : '-'),
        {
          id: 'completedAt',
          header: () => strings.완료시간,
          enableSorting: true,
          enableMultiSort: true,
        }
      ),
      routeDriverControlTable.createDataColumn(row => row.route.requiredTime, {
        id: 'requiredTime',
        header: () => '이동시간',
        cell: info => {
          let requiredTime = info.getValue();

          let hour = Math.floor(Math.floor((requiredTime as number) / 60) / 60) || 0;
          let min = Math.floor(Math.floor((requiredTime as number) / 60) % 60) || 0;

          return info.row.original?.type === 'break'
            ? '-'
            : !requiredTime
            ? '00 : 00'
            : `${hour < 10 ? `0${hour}` : hour} : ${min < 10 ? `0${min}` : min}`;
        },
        enableSorting: true,
        enableMultiSort: true,
      }),
      routeDriverControlTable.createDataColumn(row => (row.route.eta ? dayjs(row.route.eta).format('HH:mm') : '-'), {
        id: 'eta',
        header: () => strings.도착예정,
        enableSorting: true,
        enableMultiSort: true,
      }),

      routeDriverControlTable.createDataColumn(row => 1, {
        id: 'types',
        header: '유형',
        cell: info => {
          let rowShipmentType = info.row.original?.shipmentType;
          return (
            <Stack direction="row" sx={{ margin: '0 -9px' }}>
              {info.row.original?.type === 'break' ? (
                <RowDirectionContainer>
                  <IcHasLunch width={20} height={20} />
                </RowDirectionContainer>
              ) : (
                <React.Fragment>
                  {info.column
                    .getFacetedRowModel()
                    .rows.find(d => Boolean(d.original?.desiredTimeStart) || Boolean(d.original?.desiredTimeEnd)) && (
                    <RowDirectionContainer>
                      {(info.row.original?.desiredTimeStart || info.row.original?.desiredTimeEnd) && (
                        <IconDesiredTime width={20} height={20} />
                      )}
                    </RowDirectionContainer>
                  )}

                  {info.column.getFacetedRowModel().rows.find(d => d.original?.skill) && (
                    <RowDirectionContainer>
                      {info.row.original?.skill &&
                        (domain === 'roouty' ? (
                          <IconTruck width={20} height={20} />
                        ) : (
                          <IconPerson width={20} height={20} />
                        ))}
                    </RowDirectionContainer>
                  )}

                  {domain === 'roouty' && (
                    <RowDirectionContainer>
                      {React.createElement(
                        shipment_type.find(d => d.value === rowShipmentType?.replace('Rotation', ''))?.icon[
                          isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
                        ] ?? 'div',
                        {
                          width: 20,
                          height: 20,
                        }
                      )}
                    </RowDirectionContainer>
                  )}

                  {domain === 'roouty' && (
                    <RowDirectionContainer>
                      {React.createElement(
                        rowShipmentType?.includes('Rotation')
                          ? shipment_type.find(d => d.value === 'rotation')?.icon[
                              isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
                            ] ?? 'div'
                          : 'div',
                        {
                          width: 20,
                          height: 20,
                        }
                      )}
                    </RowDirectionContainer>
                  )}
                </React.Fragment>
              )}
            </Stack>
          );
        },
        footer: info => info.column.id,
      }),

      routeDriverControlTable.createDataColumn(row => row?.consigneeName, {
        id: '고객명',
        footer: info => info.column.id,
      }),

      routeDriverControlTable.createDataColumn(
        row => (row.type === 'break' ? '점심시간' : row.address + (row?.detailAddress ? ', ' + row.detailAddress : '')),
        {
          id: 'address',
          header: () => '주소',
          enableSorting: true,
          enableMultiSort: true,
          footer: info => info.column.id,
        }
      ),
      routeDriverControlTable.createDisplayColumn({
        id: 'details',
        header: ({ instance }) => strings.주문상세,
        cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableDeatilButton
              deactive={row.original?.type !== 'order'}
              onClick={() => {
                if (row.original?.type === 'order') {
                  row.original?.orderId && setTarget(row.original.orderId);
                  setOrderDetailModalOpen(true);
                }
              }}
            >
              ︙
            </TableDeatilButton>
          </div>
        ),
      }),
    ],
    [anchorIsOpen, defaultCheckOrderIds, domain, isHover, selectedOrder, setOrderDetailModalOpen, setTarget]
  );

  const instance = useTableInstance(routeDriverControlTable, {
    data: data?.filter(d => !(d.type === 'end' && !Boolean(d.address))) || [],
    columns,
    state: {
      sorting,
      pagination,
      columnVisibility,
      rowSelection,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: customSetRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    if (defaultCheckOrderIds) {
      defaultCheckOrderIds.forEach(x =>
        instance
          .getCoreRowModel()
          .flatRows.filter(d => {
            return d.original?.orderId === x;
          })
          .forEach(d => d.toggleSelected(true))
      );
      instance.setSorting([{ id: 'sorter', desc: true }]);
    }
  }, [defaultCheckOrderIds, instance, columns, setSorting]);

  useEffect(() => {
    if (defaultCheckOrderIds && defaultCheckOrderIds?.length > 0) return;
    else setSelectedOrder([]);
  }, [defaultCheckOrderIds]);

  useEffect(() => {
    let t: any = {};
    selectedOrder.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.orderId === x.orderId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [instance, selectedOrder, setRowSelection]);

  useEffect(() => {
    setDeleteOrderModalOpen(false);
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [cardTargetDriverId]);

  return (
    <React.Fragment>
      <TableComponent table={instance} isDataFetching={false} {...{ pagination, setPagination, setIsHover }} />

      <SkipModal
        {...{
          deleteOrderModalOpen,
          setDeleteOrderModalOpen,
          delTarget,
          setDelTarget,
          noCancelTrigger,
          refetchControlRouteByIdData,
        }}
      />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}
    </React.Fragment>
  );
}

export default RouteDriverControlTable;
