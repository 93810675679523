import React from 'react';
import * as S from './style';
import theme from 'constants/theme';

export interface DividerProps {
  vertical?: boolean;
  color?: string;
  style?: object;
}

const Divider = (props: DividerProps) => {
  return <S.Divider {...props} />;
};

export default Divider;
