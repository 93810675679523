import React, { useEffect, useState } from 'react';
import { Stack } from 'components/Stack';
import { useQuery } from 'react-query';

import { getRouteReport } from 'api';
import { IReportQuery } from 'constants/types';

import { Text } from 'components/Typography';
import ReportFilter from './utils/ReportFilter';
import PageContentWrapper from 'components/PageContentWrapper';
import RouteReportTable from 'components/Table/RouteReportTable';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { dimension_options_default, metrics_options } from 'constants/commons';
import { ReportDownloadModal } from '../../components/Modal/ReportDownloadModal';
import styled from 'styled-components';

const Driving = () => {
  const [drivingReportDownloadModalIsOpen, setDrivingReportDownloadModalIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<IReportQuery | undefined>();
  const [dimensionSelectorOptions, setDimensionSelectorOptions] = useState<TDragablePickerOptions>(
    Array.from(dimension_options_default)
  );
  const [metricsSelectorOptions, setMetricsSelectorOptions] = useState<TDragablePickerOptions>(
    Array.from(metrics_options['route'])
  );

  const [rowSelection, setRowSelection] = useState({});

  const { data, refetch, isLoading } = useQuery(
    ['getRouteReport', filter],
    () => filter && getRouteReport({ ...filter }),
    {
      enabled: filter !== undefined,
    }
  );

  useEffect(() => {
    setDimensionSelectorOptions(
      Array.from(
        dimension_options_default.map(d => {
          if (`${d.key}`.includes('route')) {
            return { ...d, isSelected: false };
          }
          return { ...d, isSelected: true };
        })
      )
    );
    setMetricsSelectorOptions(
      Array.from(
        metrics_options['route'].map(d => {
          if (d?.default) return { ...d, isSelected: true };
          return { ...d, isSelected: false };
        })
      )
    );
  }, []);

  useEffect(() => {
    // console.log(data);
    // console.log(filter);
    refetch();
  }, [data, filter, refetch]);

  return (
    <PageContentWrapper>
      <Stack useHorizontalScroll align="start" sx={{ padding: '48px 20px' }}>
        <Text styleName="title1" color="RG03" sx={{ marginBottom: 20 }}>
          주행 기록
        </Text>
        <Stack spacing={12} align="start" sx={{ width: '100%', minWidth: '1293px' }}>
          <ReportFilter
            type="route"
            setDownloadModalIsOpen={setDrivingReportDownloadModalIsOpen}
            {...{
              setFilter,
              dimensionSelectorOptions,
              setDimensionSelectorOptions,
              metricsSelectorOptions,
              setMetricsSelectorOptions,
            }}
          />
          <ReportDownloadModal
            selected={[...Object.keys(rowSelection).map(x => parseInt(x))]}
            total={data?.routeList}
            combined={dimensionSelectorOptions.concat(metricsSelectorOptions)}
            isOpen={drivingReportDownloadModalIsOpen}
            setIsOpen={setDrivingReportDownloadModalIsOpen}
            reportType="driving"
          />
          <Splitter dimensionLength={dimensionSelectorOptions.filter(d => d.isSelected).length}>
            <RouteReportTable
              reportData={data}
              {...{
                isFetching: isLoading,
                dimensionSelectorOptions,
                setDimensionSelectorOptions,
                metricsSelectorOptions,
                setMetricsSelectorOptions,
                rowSelection,
                setRowSelection,
              }}
            />
          </Splitter>
        </Stack>
      </Stack>
    </PageContentWrapper>
  );
};

export default Driving;

export const Splitter = styled.div<{ dimensionLength: number }>`
  & > div > div > table > thead > tr > th:nth-child(${({ theme, dimensionLength }) => dimensionLength + 1}),
  & > div > div > table > tbody > tr > td:nth-child(${({ theme, dimensionLength }) => dimensionLength + 1}) {
    border-right: 1px solid ${({ theme }) => theme.colors.RG06};
  }

  width: 100%;
  max-height: 686px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: none;
`;
