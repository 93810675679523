import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { IControlRouteDriverList, IHistoryList, ILocationMqOnStore } from 'constants/types';
import { getControlRoute, getControlRouteHistory, getControlRouteListByDate, getLocationTopicList } from 'api';

import Divider from 'components/Divider';
import { Stack } from '../components/Stack';
import { Highlight, Text } from '../components/Typography';
import Sidebar from 'components/SidebarLayout/Sidebar';
import { BoxContainer } from 'components/BoxContainer';
import PageContentWrapper from 'components/PageContentWrapper';
import RouteControlMap from 'components/MapContainer/RouteControlMap';
import RouteControlNav from '../components/SidebarLayout/RouteControlNav';

import { ReactComponent as IconClose } from 'constants/icon/ic_close.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import strings from '../util/Localization';
import { validateControlDriverStatus } from 'util/validateStatus';
import RouteDriverControlTable from 'components/Table/RouteDriverControlTable';
import OrderDetailModal from 'components/Modal/OrderDetailModal';
import useMqtt from '../hooks/useMqtt';
import { useLocation } from 'react-router-dom';
import { getTotalReqTime } from 'util/getDriverSummary';

const RouteControlPage = () => {
  const [propStates, setPropStates] = useState<{
    driverId: number;
    orderId: number;
    routeId: number | undefined;
    targetPerformedDate: string | undefined;
  }>(
    useLocation().state as {
      driverId: number;
      orderId: number;
      routeId: number;
      targetPerformedDate: string;
    }
  );

  const [targetRouteId, setTargetRouteId] = useState<number>(propStates?.routeId ?? -1);
  const [cardTargetDriverId, setCardTargetDriverId] = useState<number>(propStates?.driverId ?? -1);
  const [cardTargetDriver, setCardTargetDriver] = useState<Array<IControlRouteDriverList>>([]);
  const [historyList, setHistoryList] = useState<Array<IHistoryList>>([]);

  const [attributedDriverId, setAttributedDriverId] = useState<Array<number>>([]);

  const [target, setTarget] = useState<number>();
  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState<boolean>(false);
  const [locationMqResponse, setLocationMqResponse] = useState<ILocationMqOnStore[]>([]);
  const [performedDate, setPerformedDate] = useState<string>(
    propStates?.targetPerformedDate ?? dayjs().format('YYYY-MM-DD')
  );
  const [tabStackProps, setTabStackProps] = useState<{ subject: 'driver' | 'history'; isOpen: boolean }>({
    subject: 'history',
    isOpen: false,
  });

  const {
    data: controlRouteListByDate,
    isSuccess: getControlRouteListByDateIsSuccess,
    dataUpdatedAt: controlRouteListByDateUpdatedAt,
  } = useQuery(
    ['controlRouteListByDate', performedDate],
    () => getControlRouteListByDate(dayjs(performedDate).format('YYYY-MM-DD')),
    {
      onError: err => {
        console.log(err, 'err');
      },

      enabled: !!performedDate,
    }
  );

  const {} = useQuery(['controlRouteHistory', targetRouteId], () => getControlRouteHistory(targetRouteId), {
    onSuccess: res => setHistoryList(res.historyList),
    refetchInterval: 60000,
    enabled: targetRouteId > 0,
  });

  const {
    data: controlRouteByIdData,
    isSuccess: controlRouteByIdDataIsSuccess,
    refetch: refetchControlRouteByIdData,
    isRefetching,
  } = useQuery(['controlRouteById', targetRouteId], () => getControlRoute(targetRouteId), {
    refetchInterval: 60000,
    enabled: targetRouteId > 0,
  });

  useEffect(() => {
    if (cardTargetDriverId !== -1 && controlRouteByIdDataIsSuccess) {
      setCardTargetDriver(controlRouteByIdData.driverList.filter(d => d.driverId === cardTargetDriverId));
    }
  }, [cardTargetDriverId, controlRouteByIdData?.driverList, controlRouteByIdDataIsSuccess]);

  useEffect(() => {
    setOrderDetailModalOpen(false);
  }, [tabStackProps]);

  useEffect(() => {
    if (propStates?.targetPerformedDate === performedDate)
      setTabStackProps(prev => {
        return { subject: 'driver', isOpen: true };
      });
    else {
      setTabStackProps(prev => {
        return { ...prev, isOpen: false };
      });
    }
    setHistoryList([]);
  }, [performedDate, targetRouteId]);

  useEffect(() => {
    setAttributedDriverId(controlRouteByIdData?.driverList.map(d => d.driverId) ?? []);
    return () => setAttributedDriverId([]);
  }, [controlRouteByIdData]);

  const {
    client,
    setClient,
    isSub,
    setIsSub,
    connectStatus,
    setConnectStatus,
    mqttConnect,
    mqttSub,
    mqttUnSub,
    mqttPublish,
    mqttDisconnect,
  } = useMqtt({
    locationMqResponse,
    setLocationMqResponse,
    attributedDriverId: attributedDriverId,
  });

  // 관제 입/이탈 시에 자동으로 connect / disconnect 처리
  useEffect(() => {
    console.log(controlRouteByIdData);

    if (controlRouteByIdData?.routeId === targetRouteId && controlRouteByIdData?.routeStatus === 'processing')
      mqttConnect(process.env.REACT_APP_MQTT_HOST || 'localhost', {
        port: Number(process.env.REACT_APP_MQTT_PORT) || 61619,
        clientId: 'client_test_random' + Math.round(Math.random() * 1000),
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
      });
    else {
      mqttUnSub({ topic: topics?.topicList ?? '' });
      mqttDisconnect();
    }

    return () => {
      mqttUnSub({ topic: topics?.topicList ?? '' });
      mqttDisconnect();
    };
  }, [targetRouteId, controlRouteByIdData]);

  const { data: topics } = useQuery(['topics'], getLocationTopicList, {});

  // mqtt 객체 connected 일 때 자동으로 topic 끌어와서 갱신
  useEffect(() => {
    if (connectStatus === 'Connected') {
      mqttSub({ topic: topics?.topicList ?? [], qos: 0 });
    }
    return () => {};
  }, [connectStatus, topics?.topicList]);

  const { routeStartTime, totalReqTime } = getTotalReqTime({
    performedDate,
    workStartTime: cardTargetDriver[0]?.workStartTime,
    lastOrderETA: cardTargetDriver[0]?.orderList.at(-1)?.route.eta ?? '00:00',
  });

  return (
    <Stack align={'flex-start'} direction={'row'} sx={{ height: '100%', width: '100%' }}>
      <Sidebar bg="RG08" width="288px" ds="strong" index={2} sx={{ minWidth: '288px' }} padding={'48px 0 0 0'}>
        <RouteControlNav
          {...{
            setPropStates,
            propStates,
            performedDate,
            setPerformedDate,
            tabStackProps,
            setTabStackProps,
            cardTargetDriverId,
            setCardTargetDriverId,
            targetRouteId,
            setTargetRouteId,
          }}
        />
      </Sidebar>

      <PageContentWrapper>
        <Stack direction="row">
          {React.useMemo(
            () => (
              <Stack sx={{ width: 'fit-content', height: '100%' }} useVerticalScroll>
                <DriverStack
                  align={'start'}
                  sx={{
                    width: '100%',
                    maxWidth: `${
                      tabStackProps.isOpen ? (tabStackProps.subject === 'driver' ? '1138px' : '314px') : '0px'
                    }`,
                    minWidth: `${orderDetailModalOpen ? '0px' : tabStackProps.isOpen ? '314px' : '0px'}`,
                    padding: `0 0 48px`,
                  }}
                >
                  <Stack align="end">
                    <Stack justify="center" sx={{ height: 48, width: 50 }}>
                      <IconClose
                        width={12}
                        height={12}
                        onClick={() => {
                          setTabStackProps(prev => ({ ...prev, isOpen: false }));
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </Stack>
                  </Stack>
                  {tabStackProps.isOpen && tabStackProps.subject === 'driver' ? (
                    <BoxContainer useVerticalScroll align={'start'} justify={'start'}>
                      <Stack align={'end'} sx={{ height: '100%' }}>
                        <Stack spacing={40} sx={{ padding: '0 18px 0' }}>
                          <Stack justify="start" align="start">
                            <Text styleName="title1" color="RG02">
                              {cardTargetDriver[0]?.name}
                            </Text>

                            <Stack
                              direction="row"
                              spacing={8}
                              divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                            >
                              <Stack spacing={4} direction="row" sx={{ width: 'fit-content' }}>
                                {/* <IcMarker /> */}
                                <Text styleName="subheadline2" color="RG03">
                                  주문{' '}
                                  <Highlight color="RC02">
                                    {cardTargetDriver[0]?.orderList.filter(d => d.type === 'order').length}
                                  </Highlight>{' '}
                                  개
                                </Text>
                              </Stack>

                              <Text styleName="subheadline2" color="RG03">
                                {/* {cardTargetDriver[0]?.workStartTime} -{' '} */}
                                {routeStartTime.format('HH:mm')} -{' '}
                                {routeStartTime.add(totalReqTime, 's').format('HH:mm')}
                                {/* {dayjs(
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    Number(cardTargetDriver[0]?.workStartTime.split(':')[0]),
                                    Number(cardTargetDriver[0]?.workStartTime.split(':')[1]),
                                    0
                                  )
                                )
                                  .add(
                                    cardTargetDriver[0]?.orderList.reduce((x, a) => x + (a.route.requiredTime || 0), 0),
                                    'second'
                                  )
                                  .format('HH:mm')} */}
                              </Text>

                              <Text styleName={'subheadline2'} color={'RG03'}>
                                {Math.floor(Math.floor(totalReqTime / 60) / 60) !== 0
                                  ? `${Math.floor(Math.floor(totalReqTime / 60) / 60)} 시간 `
                                  : ``}
                                {Math.floor(Math.floor(totalReqTime / 60) % 60) !== 0
                                  ? `${Math.floor(Math.floor(totalReqTime / 60) % 60)} 분`
                                  : ``}
                                {Math.floor(Math.floor(totalReqTime / 60) / 60) === 0 &&
                                  Math.floor(Math.floor(totalReqTime / 60) % 60) === 0 &&
                                  '0 분'}
                              </Text>

                              {cardTargetDriver[0]?.orderList.filter(d => d.type === 'break').length ? (
                                <Stack spacing={8} direction="row" sx={{ width: 'fit-content' }}>
                                  <IcHasLunch />
                                  <Text styleName="subheadline2" color="RG03">
                                    1시간
                                  </Text>
                                </Stack>
                              ) : null}
                              <Text styleName="subheadline2" color="RG03">
                                {(
                                  cardTargetDriver[0]?.orderList.reduce(
                                    (x, a) => x + (a.route.requiredDistance || 0),
                                    0
                                  ) / 1000
                                ).toFixed(1)}{' '}
                                km
                              </Text>
                            </Stack>
                          </Stack>
                          <RouteDriverControlTable
                            data={cardTargetDriver[0]?.orderList}
                            defaultCheckOrderIds={
                              cardTargetDriverId === propStates?.driverId ? [propStates?.orderId] : []
                            }
                            {...{ cardTargetDriverId, setTarget, setOrderDetailModalOpen, refetchControlRouteByIdData }}
                          />
                        </Stack>
                      </Stack>
                    </BoxContainer>
                  ) : (
                    tabStackProps.isOpen &&
                    tabStackProps.subject === 'history' && (
                      <Stack align="start" spacing={30} sx={{ padding: '0 20px 48px', height: '100%' }}>
                        <Text styleName="title1" color="RG03">
                          {strings.주행히스토리}
                        </Text>

                        {historyList.length > 0 ? (
                          <BoxContainer useVerticalScroll align={'start'} justify={'start'}>
                            <Stack justify="start" align="start">
                              <Stack spacing={8} align="baseline">
                                {historyList.map(d => HistoryText(d))}
                              </Stack>
                            </Stack>
                          </BoxContainer>
                        ) : (
                          <BoxContainer align="center" sx={{ height: '100%' }}>
                            <Text styleName="title2" color="RG05">
                              {window.localStorage.getItem('serviceDomain') === 'roouty'
                                ? '주행 히스토리'
                                : '업무 히스토리'}
                              가 없습니다.
                            </Text>
                          </BoxContainer>
                        )}
                      </Stack>
                    )
                  )}
                </DriverStack>
                {target && (
                  <OrderDetailModal
                    ds={'strong'}
                    nomt
                    noModi
                    bd
                    sx={{ margin: '0 20px 0' }}
                    style={{ justifyContent: 'flex-end' }}
                    {...{ target, isOpen: orderDetailModalOpen, setIsOpen: setOrderDetailModalOpen }}
                  />
                )}
              </Stack>
            ),
            [
              cardTargetDriver,
              cardTargetDriverId,
              historyList,
              orderDetailModalOpen,
              propStates?.driverId,
              propStates?.orderId,
              refetchControlRouteByIdData,
              tabStackProps.isOpen,
              tabStackProps.subject,
              target,
            ]
          )}

          <Stack sx={{ minWidth: '1px', height: '100%', flex: 1, position: 'relative' }}>
            <RouteControlMap
              data={controlRouteByIdData}
              isRefetching={isRefetching}
              locationMqResponse={locationMqResponse}
              selectedDriver={tabStackProps.isOpen ? cardTargetDriverId : -1}
            />
          </Stack>
        </Stack>
      </PageContentWrapper>
    </Stack>
  );
};

export default RouteControlPage;

export function HistoryText(obj: IHistoryList) {
  return (
    <Text
      styleName="caption2"
      color="RG03"
      sx={{ flexWrap: 'nowrap' }}
      key={`${obj.routeIndex}-${obj.driverName}-${obj.createdAt}`}
    >
      {/* {dayjs(obj.createdAt).format(
        JSON.parse(process.env.REACT_APP_TB_ONLY as string) ? 'YY.MM.DD HH : mm : ss' : 'YY.MM.DD HH : mm'
      )} */}
      {dayjs(obj.createdAt).format('YY.MM.DD HH : mm ')}
      {obj?.createdBy}
      &nbsp;<Highlight color="RC02">{obj.driverName}</Highlight>&nbsp;
      {obj.type === 'start' || obj.type === 'end' ? null : `${obj?.routeIndex} 번째 ${strings.주문} `}
      <Highlight color="RC02">{validateControlDriverStatus(obj.type as any)}</Highlight>
    </Text>
  );
}

const DriverStack = styled(Stack)`
  width: 45vw;
  transition: all 0.2s ease-in-out;
`;
