import React from 'react';
import * as S from './style';

export interface TagProps {
  children: string | React.ReactNode;
  disable?: boolean;
  active?: boolean;
  fit?: boolean;

  status: 'done' | 'cancel' | 'todo' | 'moving' | 'preparing' | 'purple' | 'etaGood' | 'etaCaution' | 'etaWarning';
  styleName?:
    | 'headline1'
    | 'headline2'
    | 'headline3'
    | 'title1'
    | 'title2'
    | 'title3'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'subheadline1'
    | 'subheadline2'
    | 'subheadline3'
    | 'caption1'
    | 'caption2'
    | 'caption3'
    | 'tooltip1'
    | 'tooltip2'
    | 'number1'
    | 'number2'
    | 'number3'
    | 'number4'
    | 'number5';

  sx?: React.CSSProperties;
}

const MappedStyled = {
  done: S.Done,
  cancel: S.cancel,
  todo: S.Todo,
  moving: S.Moving,
  preparing: S.Preparing,
  purple: S.Purple,
  skipped: S.Skipped,
  etaGood: S.EtaGood,
  etaCaution: S.EtaCaution,
  etaWarning: S.EtaWarning,
};

export function Tag({ sx, children, ...props }: TagProps): React.ReactElement {
  const MappedStyledComponent = MappedStyled[props.status];

  if (Object.keys(MappedStyled).includes(props.status))
    return (
      <MappedStyledComponent {...props} style={sx}>
        {children}
      </MappedStyledComponent>
    );
  else return <React.Fragment />;
}
