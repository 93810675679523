import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { IOrderListFormat, IRouteOrderListFormat } from 'constants/types';
import dateConverter from './dateConverter';
import dayjs from 'dayjs';
import strings from './Localization';

export default function xlsxDownloader(data: any, type: 'order' | 'route') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('roouty');

  const isSurvey: boolean = window.localStorage.getItem('serviceDomain') === 'survey';

  const handleOrderExcel = async (orderList: IOrderListFormat[]) => {
    worksheet.columns = isSurvey
      ? [
          { header: ['order date', '접수일'], key: 'receivedDate' },
          { header: ['name', '조사대상자'], key: 'consigneeName' },
          { header: ['contact', '연락처'], key: 'consigneePhone' },
          { header: ['notes', '조사대상자 전달사항'], key: 'consigneeNote' },
          { header: ['address', '주소'], key: 'address' },
          { header: ['address_detail', '상세 주소'], key: 'detailAddress' },
          { header: ['product_code', '조사코드'], key: 'product_code' },
          { header: ['product_name', '조사명'], key: 'product_name' },
          { header: ['skill_vehicle', '담당 조사원'], key: 'skill' },
          { header: ['service_duration', '소요시간 (분)'], key: 'serviceTime' },
          { header: ['service_desired date', '희망일'], key: 'desiredDate' },
          { header: ['service_desired time(after)', '희망시간 (이후)'], key: 'desiredTimeStart' },
          { header: ['service_desired time(before)', '희망시간 (이전)'], key: 'desiredTimeEnd' },
          { header: ['customer field 1', '비고1'], key: 'note1' },
          { header: ['customer field 2', '비고2'], key: 'note2' },
          { header: ['customer field 3', '비고3'], key: 'note3' },
          { header: ['customer field 4', '비고4'], key: 'note4' },
          { header: ['customer field 5', '비고5'], key: 'note5' },
          { header: ['orderId', '조사대상 ID'], key: 'orderId' },
          { header: ['status', '조사 상태'], key: 'status' },
        ]
      : [
          { header: ['clientOrderId', '업체주문번호'], key: 'clientOrderId' },
          { header: ['order date', '주문 접수일'], key: 'receivedDate' },
          { header: ['name', '고객명'], key: 'consigneeName' },
          { header: ['contact', '고객 연락처'], key: 'consigneePhone' },
          { header: ['notes', '고객 전달사항'], key: 'consigneeNote' },
          { header: ['address', '주소'], key: 'address' },
          { header: ['address_detail', '상세 주소'], key: 'detailAddress' },
          { header: ['order_capacity', '주문 용적량'], key: 'order_capacity' },
          { header: ['product_code', '상품코드'], key: 'product_code' },
          { header: ['product_name', '상품명'], key: 'product_name' },
          { header: ['product_quantity', '수량'], key: 'product_quantity' },
          { header: ['service_type', '작업유형'], key: 'shipmentType' },
          { header: ['skill_vehicle', '특정 차량 지정'], key: 'skill' },
          { header: ['service_duration', '작업소요시간 (분)'], key: 'serviceTime' },
          { header: ['service_desired date', '희망일'], key: 'desiredDate' },
          { header: ['service_desired time(after)', '희망시간 (이후)'], key: 'desiredTimeStart' },
          { header: ['service_desired time(before)', '희망시간 (이전)'], key: 'desiredTimeEnd' },
          { header: ['customer field 1', '비고1'], key: 'note1' },
          { header: ['customer field 2', '비고2'], key: 'note2' },
          { header: ['customer field 3', '비고3'], key: 'note3' },
          { header: ['customer field 4', '비고4'], key: 'note4' },
          { header: ['customer field 5', '비고5'], key: 'note5' },
          { header: ['orderId', '주문 ID'], key: 'orderId' },
          { header: ['status', '주문 상태'], key: 'status' },
        ];

    // row 추가 length 만큼 반복 후  key로 매핑
    orderList.forEach(d =>
      worksheet.addRow(
        isSurvey
          ? {
              orderId: d.orderId,
              receivedDate: dateConverter(d.receivedDate, 'YYMMDD'),
              consigneeName: d.consigneeName,
              consigneePhone: d.consigneePhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
              consigneeNote: d.consigneeNote,
              address: d.address,
              detailAddress: d.detailAddress,
              desiredDate: d.desiredDate && dateConverter(d.desiredDate, 'YYMMDD'),
              desiredTimeStart: d.desiredTimeStart,
              desiredTimeEnd: d.desiredTimeEnd,
              serviceTime: d.serviceTime && Math.floor(d.serviceTime / 60) > 0 ? Math.floor(d.serviceTime / 60) : 0,
              skill: d.skill,
              note1: d.note && d.note[0],
              note2: d.note && d.note[1],
              note3: d.note && d.note[2],
              note4: d.note && d.note[3],
              note5: d.note && d.note[4],
              status: d.status,
              product_code: d.product[0].code,
              product_name: d.product[0].name,
            }
          : {
              clientOrderId: d.clientKey,
              orderId: d.orderId,
              receivedDate: dateConverter(d.receivedDate, 'YYMMDD'),
              consigneeName: d.consigneeName,
              consigneePhone: d.consigneePhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
              consigneeNote: d.consigneeNote,
              address: d.address,
              detailAddress: d.detailAddress,
              desiredDate: d.desiredDate && dateConverter(d.desiredDate, 'YYMMDD'),
              desiredTimeStart: d.desiredTimeStart,
              desiredTimeEnd: d.desiredTimeEnd,
              serviceTime: d.serviceTime && Math.floor(d.serviceTime / 60) > 0 ? Math.floor(d.serviceTime / 60) : 0,
              skill: d.skill,
              note1: d.note && d.note[0],
              note2: d.note && d.note[1],
              note3: d.note && d.note[2],
              note4: d.note && d.note[3],
              note5: d.note && d.note[4],
              status: d.status,
              order_capacity: d.capacity,
              product_code: d.product[0].code,
              product_name: d.product[0].name,
              product_quantity: d.product[0].quantity,
              shipmentType: d.shipmentType,
            }
      )
    );

    worksheet.columns.forEach(column => {
      // 한글은 적절히 2Byte로 계산
      const lengths = column.values
        ? column.values.map(v => (v ? v.toString().replace(/[가-힣]/g, '00').length : 0))
        : [];

      let expectationWidth = Math.max(...lengths.filter(v => typeof v === 'number'));
      let maxWidth = 100;
      column.width = (expectationWidth > maxWidth ? maxWidth : expectationWidth) + 3;
    });

    // 다운로드
    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    // RT-841
    saveAs(blob, `(루티) ${strings.주문}내역 다운로드_${dayjs().format('YYMMDD')}.xlsx`);
  };
  const handleRouteExcel = async ({ name, createdAt, orderList }: IRouteOrderListFormat) => {
    worksheet.columns = isSurvey
      ? [
          { header: `${strings.드라이버}`, key: 'driverName' },
          { header: `${strings.주행예정일}`, key: 'performedDate' },
          { header: `${strings.주행순서}`, key: 'routeIndex' },
          { header: '도착예정시간', key: 'eta' },
          { header: '이동시간', key: 'requiredTime' },
          { header: '접수일', key: 'receivedDate' },
          { header: `${strings.고객명}`, key: 'consigneeName' },
          { header: `${strings.고객명}연락처`, key: 'consigneePhone' },
          { header: `${strings.고객전달사항}`, key: 'consigneeNote' },
          { header: '주소', key: 'address' },
          { header: '상세 주소', key: 'detailAddress' },
          { header: `${strings.상품코드}`, key: 'product_code' },
          { header: `${strings.상품명}`, key: 'product_name' },
          { header: `${strings.특정차량지정}`, key: 'skill' },
          { header: `소요시간 (분)`, key: 'serviceTime' },
          { header: '희망일', key: 'desiredDate' },
          { header: '희망시간 (이후)', key: 'desiredTimeStart' },
          { header: '희망시간 (이전)', key: 'desiredTimeEnd' },
          { header: '비고1', key: 'note1' },
          { header: '비고2', key: 'note2' },
          { header: '비고3', key: 'note3' },
          { header: '비고4', key: 'note4' },
          { header: '비고5', key: 'note5' },
          { header: `${strings.주문} ID`, key: 'orderId' },
        ]
      : [
          { header: `${strings.드라이버}`, key: 'driverName' },
          { header: `${strings.주행예정일}`, key: 'performedDate' },
          { header: `${strings.주행순서}`, key: 'routeIndex' },
          { header: '도착예정시간', key: 'eta' },
          { header: '이동시간', key: 'requiredTime' },
          { header: '접수일', key: 'receivedDate' },
          { header: `${strings.고객명}`, key: 'consigneeName' },
          { header: `${strings.고객명}연락처`, key: 'consigneePhone' },
          { header: `${strings.고객전달사항}`, key: 'consigneeNote' },
          { header: '주소', key: 'address' },
          { header: '상세 주소', key: 'detailAddress' },
          { header: `${strings.상품코드}`, key: 'product_code' },
          { header: `${strings.상품명}`, key: 'product_name' },
          { header: '수량', key: 'product_quantity' },
          { header: '주문 용적량', key: 'capacity' },
          { header: '작업유형', key: 'shipmentType' },
          { header: `${strings.특정차량지정}`, key: 'skill' },
          { header: `소요시간 (분)`, key: 'serviceTime' },
          { header: '희망일', key: 'desiredDate' },
          { header: '희망시간 (이후)', key: 'desiredTimeStart' },
          { header: '희망시간 (이전)', key: 'desiredTimeEnd' },
          { header: '비고1', key: 'note1' },
          { header: '비고2', key: 'note2' },
          { header: '비고3', key: 'note3' },
          { header: '비고4', key: 'note4' },
          { header: '비고5', key: 'note5' },
          { header: `${strings.주문} ID`, key: 'orderId' },
        ];

    // row 추가 length 만큼 반복 후  key로 매핑
    orderList.forEach(d => {
      worksheet.addRow(
        isSurvey
          ? {
              driverName: d.driverName,
              performedDate: dateConverter(d.performedDate, 'YYMMDD'),
              routeIndex: d.routeIndex,
              eta: d.eta && `${d.eta?.slice(-6, -4)}:${d.eta?.slice(-4, -2)}`,
              requiredTime: d.requiredTime
                ? `${Math.floor(d.requiredTime / 3600) > 0 ? `${Math.floor(d.requiredTime / 3600)}시간` : ''}${
                    Math.floor(Math.floor(d.requiredTime % 3600) / 60) > 0
                      ? `${Math.floor(Math.floor(d.requiredTime % 3600) / 60)}분`
                      : // RT-1005
                        '1분'
                  }`
                : // RT-929
                  '0분',
              receivedDate: d.receivedDate && dateConverter(d.receivedDate, 'YYMMDD'),
              consigneeName: d.consigneeName,
              consigneePhone: d.consigneePhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
              consigneeNote: d.consigneeNote,
              address: d.address,
              detailAddress: d.detailAddress,
              desiredDate: d.desiredDate && dateConverter(d.desiredDate, 'YYMMDD'),
              desiredTimeStart: d.desiredTimeStart,
              desiredTimeEnd: d.desiredTimeEnd,
              serviceTime: d.serviceTime && Math.floor(d.serviceTime / 60) > 0 ? Math.floor(d.serviceTime / 60) : 0,
              skill: d.skill,
              note1: d.note && d.note[0],
              note2: d.note && d.note[1],
              note3: d.note && d.note[2],
              note4: d.note && d.note[3],
              note5: d.note && d.note[4],
              product_code: d.product && d.product[0].code,
              product_name: d.product && d.product[0].name,
              orderId: d.orderId,
            }
          : {
              driverName: d.driverName,
              performedDate: dateConverter(d.performedDate, 'YYMMDD'),
              routeIndex: d.routeIndex,
              eta: d.eta && `${d.eta?.slice(-6, -4)}:${d.eta?.slice(-4, -2)}`,
              requiredTime: d.requiredTime
                ? `${Math.floor(d.requiredTime / 3600) > 0 ? `${Math.floor(d.requiredTime / 3600)}시간` : ''}${
                    Math.floor(Math.floor(d.requiredTime % 3600) / 60) > 0
                      ? `${Math.floor(Math.floor(d.requiredTime % 3600) / 60)}분`
                      : // RT-1005
                        '1분'
                  }`
                : // RT-929
                  '0분',
              receivedDate: d.receivedDate && dateConverter(d.receivedDate, 'YYMMDD'),
              consigneeName: d.consigneeName,
              consigneePhone: d.consigneePhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
              consigneeNote: d.consigneeNote,
              address: d.address,
              detailAddress: d.detailAddress,
              desiredDate: d.desiredDate && dateConverter(d.desiredDate, 'YYMMDD'),
              desiredTimeStart: d.desiredTimeStart,
              desiredTimeEnd: d.desiredTimeEnd,
              serviceTime: d.serviceTime && Math.floor(d.serviceTime / 60) > 0 ? Math.floor(d.serviceTime / 60) : 0,
              skill: d.skill,
              note1: d.note && d.note[0],
              note2: d.note && d.note[1],
              note3: d.note && d.note[2],
              note4: d.note && d.note[3],
              note5: d.note && d.note[4],
              capacity: d.capacity,
              product_code: d.product && d.product[0].code,
              product_name: d.product && d.product[0].name,
              product_quantity: d.product && d.product[0].quantity,
              orderId: d.orderId,
              shipmentType: d.shipmentType,
            }
      );
    });

    worksheet.columns.forEach(column => {
      const lengths = column.values
        ? column.values.map(v => (v ? v.toString().replace(/[가-힣]/g, '00').length : 0))
        : [];

      let expectationWidth = Math.max(...lengths.filter(v => typeof v === 'number'));
      let maxWidth = 110;
      column.width = (expectationWidth > maxWidth ? maxWidth : expectationWidth) + 3;
    });

    // 다운로드
    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `${name}_${dayjs().format('YYMMDD')}.xlsx`);
  };
  if (type === 'order') return handleOrderExcel(data);
  if (type === 'route') return handleRouteExcel(data);
}
