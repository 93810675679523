import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getOrderList } from 'api';
import { useQuery } from 'react-query';
import { IGetOrderListResponses } from 'constants/types';

import { useStore } from 'store';

import Modal from '../components/Modal';
import { Stack } from '../components/Stack';
import { Button } from '../components/Button';
import { Highlight, Text } from '../components/Typography';
import XlsxLoader from '../components/Modal/XlsxLoader';
import CancelOrderModal from 'components/Modal/CancelOrderModal';
import SingleOrderForm from '../components/Modal/SingleOrderForm';
import { OrderDownloadModal } from '../components/Modal/OrderDownload';
import { OrderManageMap } from '../components/MapContainer/OrderManageMap';
import BatchOrdersModifyingModal from 'components/Modal/BatchOrdersModifyingModal';

import { ReactComponent as IcBin } from '../constants/icon/ic_bin.svg';
import { ReactComponent as IcPlus } from '../constants/icon/ic_plus.svg';
import { ReactComponent as IcDown } from '../constants/icon/ic_arrowdown.svg';
import { ReactComponent as IcWide } from '../constants/icon/ic_map_btn_wide.svg';
import { ReactComponent as IcNarrow } from '../constants/icon/ic_map_btn_narrow.svg';
import strings from '../util/Localization';
import OrderManagementTable from 'components/Table/OrderManagementTable';
import { usePrompt } from 'hooks/usePrompt';

const OrderManagePage = () => {
  const { getOrderListProps, resetGetOrderListProps } = useStore();

  const [batchOrdersModifyingModalIsOpen, setBatchOrdersModifyingModalIsOpen] = useState<boolean>(false);
  const [singleOrderFormIsOpen, setSingleOrderFormIsOpen] = useState<boolean>(false);
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState<boolean>(false);
  const [orderDownloadModalIsOpen, setOrderDownloadModalIsOpen] = useState<boolean>(false);
  const [xlsxFormIsOpen, setXlsxFormIsOpen] = useState<boolean>(false);
  const [isXlsxError, setIsXlsxError] = useState<boolean>(false);
  const [callbackModalOpen, setCallbackModalOpen] = useState<boolean>(false);

  const [noCancelTrigger, setNoCancelTrigger] = useState<boolean>(false);
  const [noEditPopup, setNoEditPopup] = useState<boolean>(false);
  const [isDownloadedErrorFile, setIsDownloadedErrorFile] = useState<boolean>(false);

  const [selectedOrder, setSelectedOrder] = useState<Array<any>>([]);
  const [delTarget, setDelTarget] = useState<Array<number>>([]);

  const [rowSelection, setRowSelection] = useState({});

  const {
    data: getOrderListResponses,
    isFetching: getOrderListResponsesIsFetching,
    isSuccess: getOrderListResponsesIsSuccess,
    refetch: refetchOrderList,
  } = useQuery<IGetOrderListResponses>(['getOrderList', getOrderListProps], () => getOrderList(getOrderListProps), {
    keepPreviousData: true,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
  });

  const updateFunc = () => {
    refetchOrderList();
  };

  useEffect(() => {
    setIsXlsxError(false);
    setIsDownloadedErrorFile(false);
  }, [xlsxFormIsOpen]);

  // RT-1055
  useEffect(() => {
    resetGetOrderListProps();
  }, []);

  useEffect(() => {
    let res = selectedOrder.map(d => d.order_id);
    setDelTarget(res);

    if (selectedOrder.filter(d => d.status !== 'unassigned' && d.status !== 'skipped').length) setNoCancelTrigger(true);
    else setNoCancelTrigger(false);
  }, [selectedOrder]);

  const [extended, setExtended] = useState(false);

  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(!isDownloadedErrorFile && isXlsxError);

  return (
    <React.Fragment>
      <Stack direction={'row'} sx={{ height: '100%', width: '100%' }} useHorizontalScroll>
        <Stack
          align={'start'}
          sx={{
            padding: '48px 21px',
            maxWidth: extended ? '770px' : '1118px',
            minWidth: '770px',
            height: '100%',
            transition: 'max-width 0.3s',
          }}
          spacing={48}
          useVerticalScroll
        >
          <Text styleName={'title1'} color={'RG03'}>
            {strings.주문관리}
          </Text>

          <Stack>
            <Stack direction={'row'} spacing={12}>
              <Button
                variant="RTB"
                type="button"
                width={126}
                height={32}
                styleName="caption1"
                onClick={() => {
                  if (selectedOrder.length <= 0) return setCallbackModalOpen(true);
                  if (selectedOrder.filter(d => d.status !== 'unassigned').length > 0) return setNoEditPopup(true);
                  setBatchOrdersModifyingModalIsOpen(true);
                }}
                sx={{ minWidth: 'fit-content' }}
              >
                {strings.주문정보일괄수정}
              </Button>
              <Button
                variant={'second'}
                type={'button'}
                styleName={'caption2'}
                color={'RG03'}
                height={32}
                onClick={() => {
                  if (selectedOrder.length <= 0) return setCallbackModalOpen(true);
                  else setDeleteOrderModalOpen(true);
                }}
                sx={{ minWidth: 'fit-content', padding: '0 16px' }}
              >
                <IcBin style={{ marginRight: '10px' }} />
                {strings.주문취소}
              </Button>
              <Button
                variant={'second'}
                type={'button'}
                styleName={'caption2'}
                color={'RG03'}
                height={32}
                width={126}
                onClick={() => {
                  setOrderDownloadModalIsOpen(true);
                }}
                sx={{ minWidth: 'fit-content', padding: '0 16px' }}
              >
                <IcDown style={{ marginRight: '10px' }} />
                {strings.주문다운로드}
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                height={32}
                width={154}
                onClick={() => {
                  setSingleOrderFormIsOpen(true);
                }}
                sx={{ minWidth: 'fit-content' }}
              >
                <Text styleName={'caption1'} color={'RG00'}>
                  <IcPlus style={{ marginRight: '10px' }} />
                  {strings.주문추가} (1건)
                </Text>
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                height={32}
                width={154}
                onClick={() => {
                  setXlsxFormIsOpen(true);
                }}
                sx={{ minWidth: 'fit-content' }}
              >
                <Text styleName={'caption1'} color={'RG00'}>
                  <IcPlus style={{ marginRight: '10px' }} />
                  {strings.주문추가} (엑셀)
                </Text>
              </Button>
            </Stack>

            <Stack spacing={16} sx={{ marginTop: '16px', flex: 1 }} name="table-area">
              <OrderManagementTable
                {...{
                  setSelectedOrder,
                  setDeleteOrderModalOpen,
                  setDelTarget,
                  selectedOrder,
                  rowSelection,
                  setRowSelection,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ minWidth: '200px', height: '100%', flex: 1, position: 'relative' }}>
          <MapButtonContainer
            onClick={() => {
              setExtended(!extended);
            }}
          >
            {extended ? <IcNarrow /> : <IcWide />}
          </MapButtonContainer>
          <OrderManageMap
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            data={getOrderListResponses}
          />
        </Stack>
      </Stack>
      <SingleOrderForm {...{ singleOrderFormIsOpen, setSingleOrderFormIsOpen, updateFunc }} />
      <Modal
        padding={24}
        width={503}
        isModalOpen={xlsxFormIsOpen}
        setIsModalOpen={setXlsxFormIsOpen}
        confirm={{
          value: (!isDownloadedErrorFile && isXlsxError) || showPrompt,
          message: (
            <Text styleName="subheadline2" color="RG02">
              <Highlight color="RC04">업로드 실패 엑셀을 다운로드 하지 않았습니다.</Highlight>
              <br />
              다운로드를 하시겠습니까?
            </Text>
          ),
          LBT: '닫기',
          RBT: '확인',
        }}
      >
        <XlsxLoader {...{ setXlsxFormIsOpen, updateFunc, setIsDownloadedErrorFile, isXlsxError, setIsXlsxError }} />
      </Modal>

      <Modal isModalOpen={showPrompt} setIsModalOpen={() => {}} width={486} padding={24} ms={5}>
        <Stack name="delete-vehicle-modal">
          <Stack name="content-area" spacing={16} sx={{ padding: '30px 0 40px' }}>
            <Text styleName="subheadline2" color="RG02">
              <Highlight color="RC04">업로드 실패 엑셀을 다운로드 하지 않았습니다.</Highlight>
              <br />
              다운로드를 하시겠습니까?
            </Text>
          </Stack>

          <Stack direction="row" spacing={16}>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                confirmNavigation();
              }}
            >
              <Text styleName="body2" color="RG04">
                닫기
              </Text>
            </Button>
            <Button
              variant={'default'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                cancelNavigation();
              }}
            >
              <Text styleName="body2" color="RG00">
                확인
              </Text>
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <OrderDownloadModal
        {...{
          selected: selectedOrder.map(d => d.order_id),
          total: getOrderListResponses?.orderList.map(d => d.order_id) ?? [],
          orderDownloadModalIsOpen,
          setOrderDownloadModalIsOpen,
        }}
      />

      <CancelOrderModal
        {...{ deleteOrderModalOpen, setDeleteOrderModalOpen, delTarget, setDelTarget, noCancelTrigger }}
      />

      <BatchOrdersModifyingModal
        {...{
          targetOrderList: selectedOrder.map(d => d.order_id),
          batchOrdersModifyingModalIsOpen,
          setBatchOrdersModifyingModalIsOpen,
        }}
      />
      <Modal isModalOpen={noEditPopup} setIsModalOpen={setNoEditPopup} padding={24} width={503}>
        <Stack>
          <Stack sx={{ padding: '40px 0' }}>
            <Text styleName="subheadline2" color="RC04">
              ‘{strings.배차대기}’ 상태인 {strings.모달_주문}만 수정이 가능합니다.
            </Text>
            <Text styleName="subheadline3" color="RG03">
              다른 상태의 {strings.모달_주문}을 제외하고 다시 시도해주세요.
            </Text>
          </Stack>
          <Button
            variant="fourth"
            type="button"
            height={40}
            fullWidth
            styleName="body2"
            color="RG04"
            onClick={() => {
              setNoEditPopup(false);
            }}
          >
            닫기
          </Button>
        </Stack>
      </Modal>

      <Modal isModalOpen={callbackModalOpen} setIsModalOpen={setCallbackModalOpen} padding={24} width={503}>
        <Stack>
          <Stack sx={{ padding: '40px 0' }}>
            <Text styleName="title2" color="RG02">
              최소 1개 이상의 {strings.모달_주문}을 선택 해주세요
            </Text>
          </Stack>
          <Button
            variant="default"
            type="button"
            height={40}
            fullWidth
            styleName="body2"
            color="RG00"
            onClick={() => {
              setCallbackModalOpen(false);
            }}
          >
            돌아가기
          </Button>
        </Stack>
      </Modal>
    </React.Fragment>
  );
};

export default OrderManagePage;

const MapButtonContainer = styled.div`
  position: absolute;
  z-index: 8;
  top: 12px;
  left: 12px;
  cursor: pointer;
`;
