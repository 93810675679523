import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import theme from 'constants/theme';
import GlobalStyle from 'constants/GlobalStyle';
import ConfigRouters from 'ConfigRouters';
import DraggableWrapper from 'components/Table/util/DraggableWrapper';

function App() {
  let queryClient: QueryClient;
  queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <DraggableWrapper>
            <ConfigRouters />
          </DraggableWrapper>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
