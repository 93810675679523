import React, { ReactNode } from 'react';
import * as S from './style';

export interface SidebarProps {
  children: ReactNode;

  width?: string | string;
  padding?: string | number;

  bg?: string;
  ds?: 'weak' | 'normal' | 'strong';
  /** CSS override를 위한 props*/
  sx?: React.CSSProperties;

  index?: number;

  useVerticalScroll?: boolean;
}

const Sidebar = ({ sx, children, ...props }: SidebarProps) => {
  return (
    <S.SidebarWrapper {...props} style={sx}>
      {children}
    </S.SidebarWrapper>
  );
};

export default Sidebar;
