import {
  ColumnOrderState,
  createTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useTableInstance,
} from '@tanstack/react-table';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { Highlight } from 'components/Typography';
import {
  IReportResponses,
  IReportResponsesList,
  IRouteReportDimensionRoute,
  IRouteReportMetrics,
} from 'constants/types';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import TableComponent from '.';
import TableSelectManager from './util/TableSelectManager';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';

let routeReportTable =
  createTable().setRowType<IReportResponsesList<IRouteReportDimensionRoute, IRouteReportMetrics>>();

const RouteReportTable = ({
  isFetching,
  reportData,

  dimensionSelectorOptions,
  setDimensionSelectorOptions,
  metricsSelectorOptions,
  setMetricsSelectorOptions,
  rowSelection,
  setRowSelection,
}: {
  isFetching: boolean;
  reportData: IReportResponses<IRouteReportDimensionRoute, IRouteReportMetrics> | undefined;

  dimensionSelectorOptions: TDragablePickerOptions;
  setDimensionSelectorOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;
  metricsSelectorOptions: TDragablePickerOptions;
  setMetricsSelectorOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;
  rowSelection: {};
  setRowSelection: Dispatch<SetStateAction<{}>>;
}) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      routeReportTable.createGroup({
        header: 'dimension',
        id: 'group_inv_dimension',
        columns: [
          routeReportTable.createDisplayColumn({
            id: 'select',
            header: ({ instance }) => (
              <HeaderIndeterminateCheckbox
                {...{
                  checked: instance.getIsAllPageRowsSelected(),
                  indeterminate: instance.getIsSomePageRowsSelected(),
                  onChange: instance.getToggleAllPageRowsSelectedHandler(),

                  instance,
                  anchorIsOpen,
                  setAnchorIsOpen,
                  setAnchorPoint,
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            ),
            enableSorting: false,
          }),
          routeReportTable.createDataColumn(row => row.dimension.route?.id, {
            id: 'route_id',
            header: '경로 ID',
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.dimension.driver?.name, {
            id: 'driver',
            header: '드라이버',
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.dimension.performedDate, {
            id: 'performedDate',
            header: '주행일',
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.dimension.route?.name, {
            id: 'route_name',
            header: '주행 이름',
            footer: info => info.column.id,
          }),
        ],
      }),

      routeReportTable.createGroup({
        header: 'metrics',
        id: 'group_metrics',
        columns: [
          routeReportTable.createDataColumn(row => row.metrics.estimatedTime, {
            id: 'estimatedTime',
            // header: 'ETA (분)',
            header: '예상 이동시간 (분)',
            cell: info => `${Math.round(info.getValue() / 60)} 분`,
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.actualTime, {
            id: 'actualTime',
            // header: 'ATA (분)',
            header: '실제 이동시간 (분)',
            cell: info => <Highlight color="RC02">{`${Math.round(info.getValue() / 60)} 분`}</Highlight>,
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.estimatedDistance, {
            id: 'estimatedDistance',
            // header: 'ATA (km)',
            header: '예상 이동거리 (km)',
            cell: info => `${(info.getValue() / 1000).toFixed(2)} km`.replace('.00', ''),

            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.actualDistance, {
            id: 'actualDistance',
            // header: 'ATD (km)',
            header: '실제 이동거리 (km)',
            cell: info => (
              <Highlight color="RC02">{`${(info.getValue() / 1000).toFixed(2)} km`.replace('.00', '')}</Highlight>
            ),
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.totalCapacity, {
            id: 'totalCapacity',
            header: '총 용적량',
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.actualCapacity, {
            id: 'actualCapacity',
            header: '실 용적량',
            cell: info => <Highlight color="RC02">{info.getValue()}</Highlight>,
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.totalOrder, {
            id: 'totalOrder',
            header: '주문 집계 (개)',
            cell: info => `${info.getValue()} 개`,
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.completedOrder, {
            id: 'completedOrder',
            header: '완료 주문 집계 (개)',
            cell: info => `${info.getValue()} 개`,
            footer: info => info.column.id,
          }),

          // default_invisible columns
          routeReportTable.createDataColumn(row => row.metrics.skippedOrder, {
            id: 'skippedOrder',
            header: '보류 주문 집계 (개)',
            cell: info => `${info.getValue()} 개`,
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.podUploaded, {
            id: 'podUploaded',
            header: 'PoD 집계 (개)',
            cell: info => `${info.getValue()} 개`,
            footer: info => info.column.id,
          }),
          routeReportTable.createDataColumn(row => row.metrics.rotation, {
            id: 'rotation',
            header: '회차 (횟수)',
            footer: info => info.column.id,
          }),
        ],
      }),
    ],
    []
  );

  const instance = useTableInstance(routeReportTable, {
    data: reportData?.routeList ?? [],
    columns,
    state: {
      pagination,
      rowSelection,
      columnVisibility,
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    let columnVisible: { [key: string]: boolean } = {};
    const combined = dimensionSelectorOptions.concat(metricsSelectorOptions);

    combined.forEach(d => {
      columnVisible[`${d.key}`] = d.isSelected;
    });

    setColumnVisibility(columnVisible);
    setColumnOrder(['select'].concat(combined.map(d => `${d.key}`)));
  }, [dimensionSelectorOptions, metricsSelectorOptions]);

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, []);

  return (
    <React.Fragment>
      <TableComponent
        report
        table={instance}
        isDataFetching={isFetching}
        {...{ pagination, setPagination }}
        placeholder="불러올 주행 기록 리포트가 없습니다."
      />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}
    </React.Fragment>
  );
};

export default RouteReportTable;
