import React, { useEffect, useRef, useState } from 'react';
import * as S from './style';

import { Text } from 'components/Typography';
import { Input } from 'components/Input';
import { UseFormReturn } from 'react-hook-form';

export type OptionType<T = unknown> = {
  label: string;
  value: any;
};

export interface SwapProps {
  name: string;
  options: Array<OptionType>;

  defaultIndex?: number;
  methods: UseFormReturn<any>;
  maxWidth?: number;
  label?: string | React.ReactNode;
  sx?: React.CSSProperties;
}

export function Swap({ defaultIndex, label, sx, ...props }: SwapProps): React.ReactElement {
  const renderCounter = useRef(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex ?? 0);

  const setColor = (index: number) => {
    return index === selectedIndex ? 'RC02' : 'RG04';
  };

  useEffect(() => {
    // do not set dirty at first render with DefaultValues !!!!
    if (typeof defaultIndex === 'number' && renderCounter.current === 0) {
      renderCounter.current += 1;
      return props.methods.setValue(props.name, props.options[selectedIndex].value, {});
    } else {
      console.log(props.options[selectedIndex].value, 'else');
      props.methods.setValue(props.name, props.options[selectedIndex].value, {
        shouldDirty: true,
      });
    }

    return () => {};
  }, [defaultIndex, selectedIndex, props.methods, props.name, props.options]);

  return (
    <S.SwapContainer spacing={6} style={sx}>
      {label}
      <S.SwapnWrapper>
        <S.SwapnItemsWrapper {...props}>
          {props.options.map((items, index) => {
            return (
              <S.SwapnItems
                key={`${items.label}-${index}-${items.value}`}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              >
                <Text styleName={'caption1'} color={setColor(index)}>
                  {items.label}
                </Text>
              </S.SwapnItems>
            );
          })}
        </S.SwapnItemsWrapper>
        <S.SwapnIndicatorWrapper {...props}>
          <S.SwapnIndicator selectedIndex={selectedIndex} {...props} />
        </S.SwapnIndicatorWrapper>
      </S.SwapnWrapper>
      <Input
        name={props.name}
        register={props.methods.register}
        watch={props.methods.watch}
        reset={props.methods.reset}
        sx={{ display: 'none' }}
      />
    </S.SwapContainer>
  );
}
