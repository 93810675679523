import { Stack } from 'components/Stack/style';
import styled from 'styled-components';

export interface ModalProps {
  width?: number;
  height?: number;

  padding?: string;

  zIndex?: number;

  bd?: boolean;

  sx?: React.CSSProperties;

  nomt?: boolean;
}

export const Modal = styled.div<ModalProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute; /* Stay in place */
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 10)}; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* Enable scroll if needed */

  background: ${({ theme, bd }) => (bd ? '' : `${theme.colors.RG03}66`)};
`;

export const ScrollWrapper = styled.div<ModalProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 164px);
  margin-top: ${({ nomt }) => (nomt ? '0px' : '0px')};
  border-radius: 8px;
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-items: end;
  justify-content: space-between;

  & > svg {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  width: calc(100% + 24px);
  flex: 1;
  ${({ theme }) => theme.modalScrollStyle.vertical};
  margin-right: -24px;
`;

export const DescriptionTable = styled.table`
  caption {
    margin: 0 0 12px 0;
  }

  width: 100%;
  table-layout: fixed;

  border-spacing: 20px;
  border-collapse: collapse;
  border-top: 1px solid ${({ theme }) => theme.colors.RG06};
  th,
  td {
    overflow: hidden;
    text-align: start;
    padding: 11px 10px;

    vertical-align: middle;

    height: auto;

    white-space: nowrap;
    border-bottom: 1px solid ${({ theme }) => theme.colors.RG06};
  }
`;

export const DescriptionTh = styled.th`
  ${({ theme }) => theme.fontStyle.caption2};
  ${({ theme }) => ({ color: theme.colors.RG03, backgroundColor: theme.colors.RG07 })}
`;
export const DescriptionTd = styled.td<{ elipsis?: boolean; broken?: boolean; disabled?: boolean }>`
  ${({ theme }) => theme.fontStyle.caption2};
  ${({ theme }) => ({ color: theme.colors.RG01 })};

  ${({ broken }) => broken && { 'white-space': 'break-spaces !important' }};

  ${({ elipsis }) =>
    elipsis && {
      'white-space': 'nowrap',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    }};

  ${({ disabled, theme }) =>
    disabled && {
      background: theme.colors.RG08,
    }}
`;

export const OrderDetailModalHeader = styled(Stack)`
  width: 100%;
  justify-content: start;
  align-items: start;

  gap: 24px;
  padding: 24px;
`;

export const OrderDetailModalBody = styled(Stack)``;

export const OrderDetailModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;

  gap: 16px;
  padding: 24px;
`;

export const BatchOrdersDetailTable = styled.table`
  caption {
    margin: 0 0 12px 0;
  }

  width: 100%;

  table-layout: fixed;

  border-spacing: 20px;
  border-collapse: collapse;
  border-top: 1px solid ${({ theme }) => theme.colors.RG06};
  th,
  td {
    overflow: hidden;
    text-align: start;
    padding: 10px 10px;

    vertical-align: middle;

    height: auto;

    white-space: nowrap;
    border-bottom: 1px solid ${({ theme }) => theme.colors.RG06};
    border-left: 1px solid ${({ theme }) => theme.colors.RG06};
  }
  th:first-child,
  td:first-child {
    border-left: none;
  }
`;

export const BatchOrdersDetailTableContainer = styled.div`
  width: 100%;
  max-height: 141px;

  ${({ theme }) => theme.modalScrollStyle.vertical}
`;

export const BatchOrdersDetailTh = styled.th`
  ${({ theme }) => theme.fontStyle.caption1};
  ${({ theme }) => ({ color: theme.colors.RG03, backgroundColor: theme.colors.RG07 })}
`;
export const BatchOrdersDetailTd = styled.td<{ elipsis?: boolean; disabled?: boolean }>`
  ${({ theme }) => theme.fontStyle.caption3};
  ${({ theme }) => ({ color: theme.colors.RG02 })}
  ${({ elipsis }) =>
    elipsis && {
      'white-space': 'nowrap',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    }}
    ${({ disabled, theme }) =>
    disabled && {
      background: theme.colors.RG08,
    }}
`;
