import {
  createTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useTableInstance,
} from '@tanstack/react-table';
import { getAdminInfo, postPsLink } from 'api';
import { Button } from 'components/Button';
import { Tag } from 'components/Tag';
import { IAdminUserList } from 'constants/types';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { UseFormProps } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useStore } from 'store';
import { validateAccountStatus } from 'util/validateStatus';
import DeactiveModal from 'components/Modal/DeactiveModal';
import ModifyUserModal from 'components/Modal/ModifyUserModal';
import TableComponent from '.';
import { Text } from 'components/Typography';

let adminManagementTable = createTable().setRowType<IAdminUserList>();

function AdminManagementTable({
  mutateDeactivateMember,
  mutateSetAdminInfo,
  modifyMemberModalIsOpen,
  setModifyMemberModalIsOpen,
  mutateActivateMember,
}: {
  mutateDeactivateMember: Function;
  mutateSetAdminInfo: Function;
  modifyMemberModalIsOpen: boolean;
  setModifyMemberModalIsOpen: Dispatch<SetStateAction<boolean>>;
  mutateActivateMember: Function;
}) {
  const { userGrade } = useStore();

  const { data: admins, isFetching: getAdminListIsFetching } = useQuery(['admins'], getAdminInfo, {});

  const [sorting, setSorting] = useState<SortingState>([{ id: 'status', desc: false }]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100000,
  });

  const [userId, setUserId] = useState<number>(-1);
  const [target, setTarget] = useState<null | string>(null);
  const [targetUser, setTargetUser] = useState<null | string>(null);
  const [deactiveUserModalOpen, setDeactiveUserModalOpen] = useState<boolean>(false);

  const [columnVisibility, setColumnVisibility] = useState({});

  const [useFormProps, setUseFormProps] = useState<UseFormProps>({ mode: 'onSubmit', defaultValues: {} });

  const { mutate: mutatePostPsLink } = useMutation(postPsLink, {
    onSuccess: () => {
      alert('계정에 설정된 메일로\n비빌번호 재설정 링크를 보냈습니다.');
    },
    onError: () => {
      alert('비밀번호 재설정 링크 발송에 실패했습니다.\n다시 시도해주세요.');
    },
  });

  // 유저 권한에 따른 column 열람 제한
  useEffect(() => {
    !(userGrade <= 1) &&
      setColumnVisibility({
        modify: false,
        manage: false,
      });
  }, []);

  function isActiveStatus(status: 'invited' | 'accepted' | 'activated' | 'deactivated') {
    if (status === 'activated') return true;
    return false;
  }

  // RT-840
  // function sortByStatus(status: 'invited' | 'accepted' | 'activated' | 'deactivated') {
  //   if (status === 'invited') return 1;
  //   else if (status === 'accepted') return 2;
  //   else if (status === 'activated') return 3;
  //   else return 4;
  // }

  const columns = useMemo(
    () => [
      adminManagementTable.createDataColumn(row => row.status, {
        id: 'status',
        header: () => '계정상태',
        cell: info => {
          let { key, name } = validateAccountStatus(
            info.row.original?.status as 'invited' | 'accepted' | 'activated' | 'deactivated'
          );
          return (
            <Tag
              styleName="caption2"
              status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
              sx={{ width: '56px' }}
            >
              {name}
            </Tag>
          );
        },
        sortingFn: 'basic',
        sortDescFirst: false,
        enableMultiSort: true,
      }),
      adminManagementTable.createDataColumn(row => row.name, {
        id: 'name',
        header: () => '이름',
        sortingFn: 'alphanumeric',
        enableMultiSort: true,
      }),
      adminManagementTable.createDataColumn(row => row.email, {
        id: 'email',
        header: () => 'Email(ID)',
        enableSorting: false,
      }),
      adminManagementTable.createDataColumn(
        row => (row.phone ? row.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : '-'),
        {
          id: 'phone',
          header: () => '연락처',
          enableSorting: false,
        }
      ),
      adminManagementTable.createDataColumn(
        row =>
          `${(row.acceptedAt && dayjs(row.acceptedAt).format('YYYY.MM.DD')) || '--'} / ${
            (row.invitedAt && dayjs(row.invitedAt).format('YYYY.MM.DD')) || '--'
          }`,
        {
          id: 'date',
          header: () => '등록 일자 / 초대 일자',
          enableSorting: true,
          enableMultiSort: true,
        }
      ),
      adminManagementTable.createDisplayColumn({
        id: 'modify',
        header: () => '수정',
        cell: ({ row }) => (
          <Button
            variant={'second'}
            type={'button'}
            sx={{ padding: '3px 6px' }}
            onClick={() => {
              setUseFormProps({
                defaultValues: {
                  email: row.getValue('email'),
                  password: '********',
                  name: row.getValue('name'),
                  phone: row
                    .getValue('phone')
                    .replace(/[^0-9]/g, '')
                    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
                },
              });
              setUserId(row.original?.userId as number);
              setTarget(`${row.original?.userId}`);
              setModifyMemberModalIsOpen(true);
            }}
          >
            <Text styleName="caption2" color="RG04">
              수정
            </Text>
          </Button>
        ),
        enableSorting: false,
      }),
      adminManagementTable.createDisplayColumn({
        id: 'manage',
        header: () => '관리',
        cell: ({ row }) => (
          <Button
            variant={!isActiveStatus(row.getValue('status')) ? 'RTB' : 'fourth'}
            type={'button'}
            sx={
              !isActiveStatus(row.getValue('status'))
                ? { padding: '3px 6px', minWidth: '56px' }
                : { padding: '3px 6px', minWidth: '56px', border: 'none', background: '#F0F3F9' }
            }
            onClick={() => {
              if (isActiveStatus(row.getValue('status'))) {
                setTarget(`${row.original?.userId}`);
                setTargetUser(`${row.getValue('name')} ${row.getValue('email')}`);
                setDeactiveUserModalOpen(true);
                return;
              }
              mutateActivateMember(row.original?.userId);
            }}
            styleName={'caption2'}
          >
            {!isActiveStatus(row.getValue('status')) ? '활성화' : '정지'}
          </Button>
        ),
        enableSorting: false,
      }),
    ],
    []
  );

  const instance = useTableInstance(adminManagementTable, {
    data: admins?.userList ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const modifyForm = [
    { type: 'Input', name: 'email', label: '아이디', disabled: true },
    {
      type: 'Input',
      name: 'password',
      label: '비밀번호',
      disabled: true,
      rightArea: {
        rightContent: '재설정 링크 보내기',
        rightFunc: () => {
          mutatePostPsLink(userId as number);
        },
      },
    },
    {
      type: 'Input',
      name: 'name',
      label: '이름',
      validation: {
        required: { value: true, message: '해당 필드는 필수값 입니다.' },
      },
    },
    {
      type: 'Input',
      name: 'phone',
      label: '연락처',
      validation: {
        required: { value: true, message: '해당 필드는 필수값 입니다.' },
        pattern: {
          value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
          message: '휴대폰 형식에 맞춰주세요.',
        },
        maxLength: {
          value: 13,
          message: '휴대폰 형식에 맞춰주세요.',
        },
      },
    },
  ];

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [admins?.userList]);

  return (
    <React.Fragment>
      <TableComponent
        table={instance}
        isDataFetching={getAdminListIsFetching}
        disableOptions={{
          totalCount: true,
          selectCount: true,
          pagnation: true,
          pageSizeSelector: true,
        }}
        {...{ pagination, setPagination }}
      />
      <DeactiveModal
        isOpen={deactiveUserModalOpen}
        setIsOpen={setDeactiveUserModalOpen}
        target={`${target}`}
        targetName={`${targetUser}`}
        text={'선택하신 계정을 정지 하시겠습니까?'}
        highlight={'이후 해당 계정 이용이 불가합니다.'}
        returnFunc={mutateDeactivateMember}
        RBT={'정지하기'}
        LBT={'취소'}
      />
      <ModifyUserModal
        {...{
          target,
          useFormProps,
          modifyForm,
          mutateSetInfo: mutateSetAdminInfo,
          modifyMemberModalIsOpen,
          setModifyMemberModalIsOpen,
        }}
      />
    </React.Fragment>
  );
}

export default AdminManagementTable;
