import React, { SetStateAction, useEffect } from 'react';

import { BoxContainer } from 'components/BoxContainer';
import { Text } from '../components/Typography';
import { Stack } from '../components/Stack';
import { Button } from '../components/Button';

import { ReactComponent as IcBin } from '../constants/icon/ic_bin.svg';
import RouteHistoryManagementTable from 'components/Table/RouteHistoryManagementTable';
import dayjs from 'dayjs';
import PageContentWrapper from 'components/PageContentWrapper';
import { Input } from '../components/Input';
import DatePickerPopup from '../components/Popup/DatePicker';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { cancelRoute, getControlRouteListByDate, getRouteList } from 'api';
import { useQuery } from 'react-query';
import strings from '../util/Localization';
import Modal from 'components/Modal';
import DeactiveModal from 'components/Modal/DeactiveModal';

const RoutePlanHistoryPage = () => {
  let domain = window.localStorage.getItem('serviceDomain');
  let navigate = useNavigate();

  const [callbackModalOpen, setCallbackModalOpen] = React.useState<boolean>(false);
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = React.useState<boolean>(false);

  const [selectedOrder, setSelectedOrder] = React.useState<Array<any>>([]);
  const [delTarget, setDelTarget] = React.useState<Array<number>>([]);

  const [datePickerIsOpen, setDatePickerIsOpen] = React.useState<boolean>(false);

  const { refetch: refetchGetRouteList } = useQuery(['getRouteList'], getRouteList, {});

  React.useEffect(() => {
    let res = selectedOrder.map(d => d.original.routeId);
    setDelTarget(res);
  }, [selectedOrder]);
  let todayFormat = dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)');

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: `${todayFormat} ${domain === 'roouty' ? '배차' : '배정'}${
        window.localStorage.getItem('roleId') === '4' ? ` ${window.localStorage.getItem('name')}` : ''
      } #1`,

      date: todayFormat,
    },
  });

  const { data: dailyRoutes, refetch: refetchDailyRoutes } = useQuery(
    ['dailyRoutes'],
    () => getControlRouteListByDate(dayjs(methods.getValues().date).format('YYYY-MM-DD')),
    {}
  );

  const resetForms = () => {
    methods.reset();
  };

  const handleFormComplete = (data: any, type?: 'manual') => {
    console.log(data, dayjs().toJSON());

    navigate(type === undefined ? '../plan' : `../${type}`, {
      state: { name: type === undefined ? data.name : data.name.replace(' 배차 ', ' 수동배차 '), date: data.date },
      replace: true,
    });
  };

  useEffect(() => {
    refetchDailyRoutes();
    methods.setValue(
      'name',
      `${dayjs(methods.getValues().date).format('YYYY.MM.DD (ddd)')} ${domain === 'roouty' ? '배차' : '배정'}${
        window.localStorage.getItem('roleId') === '4' ? ` ${window.localStorage.getItem('name')}` : ''
      } #${(dailyRoutes?.routeList.length ?? 0) + 1}`
    );
  }, [methods.getValues().date, dailyRoutes, domain]);

  // useEffect(() => {
  //   const subscription = methods.watch((value, { name, type }) => {
  //     if (name === 'date') {
  //       methods.setValue(
  //         'name',
  //         `${value.date} ${domain === 'roouty' ? '배차' : '배정'}${
  //           window.localStorage.getItem('roleId') === '4' ? ` ${window.localStorage.getItem('name')}` : ''
  //         } #${(dailyRoutes?.routeList.length ?? 0) + 1}`
  //       );
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [methods, dailyRoutes]);

  return (
    <React.Fragment>
      <Stack align={'flex-start'} direction={'row'}>
        <PageContentWrapper>
          <BoxContainer sx={{ padding: '48px 21px' }} align={'start'} justify={'start'} useVerticalScroll={true}>
            <Stack align={'start'} sx={{ margin: '0 auto', maxWidth: '805px' }}>
              <Stack name="header-area" spacing={8} align={'start'}>
                <Text styleName={'title1'} color={'RG03'}>
                  {strings.배차정보설정}
                </Text>

                <Text styleName={'caption3'} color={'RG04'}>
                  경로 생성을 위해 기본 정보를 입력해주세요.
                </Text>
              </Stack>
              <form
                onSubmit={methods.handleSubmit(data => {
                  handleFormComplete(data);
                })}
              >
                {domain === 'survey' ? (
                  <ControllerStack spacing={24} direction={'row'}>
                    <Stack align="start" spacing={6} sx={{ flex: 1 }}>
                      <Text styleName="caption2" color="RG03">
                        {strings.주행이름}
                      </Text>
                      <Input
                        height={32}
                        name="name"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        variant={'third'}
                        placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')} 배차`}
                        validation={{ required: true }}
                      />
                    </Stack>
                    <Stack align="start" spacing={6} sx={{ flex: 1 }}>
                      <Text styleName="caption2" color="RG03">
                        {strings.주행일자}
                      </Text>
                      <Stack direction={'row'} sx={{ position: 'relative' }}>
                        <Input
                          variant={'third'}
                          type="text"
                          name="date"
                          placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')}`}
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          height={32}
                          readOnly={true}
                          onClick={() => {
                            setDatePickerIsOpen(true);
                          }}
                        />

                        {datePickerIsOpen && (
                          <DatePickerPopup
                            banPast
                            allowUncheck={false}
                            setIsOpen={setDatePickerIsOpen}
                            setValue={methods.setValue}
                            name={'date'}
                            initDate={methods.getValues('date')}
                            format={'YYYY.MM.DD (ddd)'}
                          />
                        )}
                      </Stack>
                    </Stack>
                    <Stack name="button-area" sx={{ height: '56px', flex: 1 }}>
                      <Button
                        sx={{ marginTop: 'auto' }}
                        variant={'default'}
                        type={'submit'}
                        fullWidth
                        height={32}
                        color="RG00"
                        styleName="caption1"
                      >
                        {strings.배차경로생성시작}
                      </Button>
                    </Stack>
                  </ControllerStack>
                ) : (
                  <ControllerStack align="start" spacing={24}>
                    <Stack spacing={24} direction={'row'}>
                      <Stack name="route-name-input" align="start" spacing={6} sx={{ flex: 1 }}>
                        <Text styleName="caption2" color="RG03">
                          {strings.주행이름}
                        </Text>
                        <Input
                          height={32}
                          name="name"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          variant={'third'}
                          placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')} 배차`}
                          validation={{ required: true }}
                        />
                      </Stack>

                      <Stack name="auto-routing-button" sx={{ height: '56px', flex: 1 }}>
                        <Button
                          sx={{ marginTop: 'auto' }}
                          variant={'default'}
                          type={'submit'}
                          fullWidth
                          height={32}
                          color="RG00"
                          styleName="caption1"
                          onClick={methods.handleSubmit(data => {
                            handleFormComplete(data);
                          })}
                        >
                          자동 최적화 경로 생성
                        </Button>
                      </Stack>

                      <Stack name="manual-routing-button" sx={{ height: '56px', flex: 1 }}>
                        <Button
                          sx={{ marginTop: 'auto' }}
                          variant={'RTB'}
                          type={'submit'}
                          fullWidth
                          height={32}
                          color="RG00"
                          styleName="caption1"
                          onClick={methods.handleSubmit(data => {
                            handleFormComplete(data, 'manual');
                          })}
                        >
                          수동 경로 생성
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack spacing={24} direction={'row'}>
                      <Stack name="performing-date-input" align="start" spacing={6} sx={{ flex: 1 }}>
                        <Text styleName="caption2" color="RG03">
                          {strings.주행일자}
                        </Text>
                        <Stack direction={'row'} sx={{ position: 'relative' }}>
                          <Input
                            variant={'third'}
                            type="text"
                            name="date"
                            placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')}`}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            height={32}
                            readOnly={true}
                            onClick={() => {
                              setDatePickerIsOpen(true);
                            }}
                          />

                          {datePickerIsOpen && (
                            <DatePickerPopup
                              banPast
                              allowUncheck={false}
                              setIsOpen={setDatePickerIsOpen}
                              setValue={methods.setValue}
                              name={'date'}
                              initDate={methods.getValues('date')}
                              format={'YYYY.MM.DD (ddd)'}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <div style={{ flex: 1 }} />
                      <div style={{ flex: 1 }} />
                    </Stack>
                  </ControllerStack>
                )}
              </form>

              <Stack name="header-area" spacing={8} align={'start'}>
                <Text styleName={'title1'} color={'RG03'}>
                  {strings.배차이력} 조회
                </Text>

                {window.localStorage.getItem('serviceDomain') === 'survey' ? (
                  <Stack align="start">
                    <Text styleName={'caption3'} color={'RG04'}>
                      {strings.배송전배차완료상태주문들만.split('.')[0]}
                      {'.'}
                    </Text>
                    <Text styleName={'caption3'} color={'RG03'}>
                      {strings.배송전배차완료상태주문들만.split('.')[1]}
                      {'.'}
                    </Text>
                  </Stack>
                ) : (
                  <Text styleName={'caption3'} color={'RG04'}>
                    {strings.배송전배차완료상태주문들만}
                  </Text>
                )}
              </Stack>
              <Stack direction={'row'} justify={'space-between'} align="end" sx={{ marginTop: '40px' }}>
                <Text styleName={'caption2'} color={'RG04'}>
                  선택된 {strings.배차} : {selectedOrder.length.toString().padStart(2, '0')} 개{' '}
                </Text>
                <Button
                  variant={'second'}
                  type={'button'}
                  styleName={'caption2'}
                  color={'RG03'}
                  height={32}
                  width={126}
                  onClick={() => {
                    if (selectedOrder.length <= 0) return setCallbackModalOpen(true);
                    else setDeleteOrderModalOpen(true);
                  }}
                >
                  <IcBin style={{ marginRight: '10px' }} />
                  {strings.배차취소}
                </Button>
              </Stack>

              <Stack spacing={16} sx={{ marginTop: '16px' }} name="table-area">
                <RouteHistoryManagementTable {...{ setSelectedOrder }} />
              </Stack>
            </Stack>
          </BoxContainer>
        </PageContentWrapper>
      </Stack>

      <DeactiveModal
        isOpen={deleteOrderModalOpen}
        setIsOpen={setDeleteOrderModalOpen}
        text={`취소 이후 복구는 불가하며, 다시 시도할 경우\n신규${strings.배차경로생성}을 해야합니다.`}
        highlight={`선택하신 ${strings.배차} ${selectedOrder.length ? selectedOrder.length : 1} 건을 취소하시겠습니까?`}
        returnFunc={() => {
          new Promise((resolve, reject) => {
            delTarget.forEach(d => cancelRoute(d));
            resolve(1);
          })
            .then(() => refetchGetRouteList())
            .finally(() => {
              refetchGetRouteList();
              setDelTarget([]);
            });
        }}
        RBT={strings.배차취소}
        LBT={'닫기'}
        reverse
      />

      <Modal isModalOpen={callbackModalOpen} setIsModalOpen={setCallbackModalOpen} padding={24} width={503}>
        <Stack>
          <Stack sx={{ padding: '40px 0' }}>
            <Text styleName="title2" color="RG02">
              최소 1개 이상의 {strings.배차경로}를 선택 해주세요
            </Text>
          </Stack>
          <Button
            variant="default"
            type="button"
            height={40}
            fullWidth
            styleName="body2"
            color="RG00"
            onClick={() => {
              setCallbackModalOpen(false);
            }}
          >
            돌아가기
          </Button>
        </Stack>
      </Modal>
    </React.Fragment>
  );
};

export default RoutePlanHistoryPage;

const ControllerStack = styled(Stack)`
  flex-wrap: wrap;

  background: #f8f9fb;
  border-radius: 16px;
  padding: 30px 20px;
  margin-top: 30px;
  margin-bottom: 100px;
`;
