import React, { Dispatch, SetStateAction, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';
import { PageButton, PageListBox } from './style';

import { Stack } from 'components/Stack';

import { ReactComponent as IcLeft } from 'constants/icon/ic_arrowleft.svg';
import { ReactComponent as IcRight } from 'constants/icon/ic_arrowright.svg';

interface IPaginationProps {
  range: Array<number>;
  current: number;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}

const Pagination = ({ range, current, setPagination }: IPaginationProps) => {
  const [pageGroupIndex, setPageGroupIndex] = useState<Array<number>>([0, 5]);

  return (
    <Stack direction="row" name="page-select" spacing={10} sx={{ flexBasis: 'content' }}>
      <PageButton
        onClick={() => {
          setPageGroupIndex(prev => [prev[0] - 5, prev[0]]);
        }}
        disabled={pageGroupIndex[0] - 5 < 0}
      >
        <IcLeft />
      </PageButton>

      {range.slice(...pageGroupIndex).map(d => {
        return (
          <PageListBox
            key={`pageListBox-${d}-Selector`}
            active={d === current}
            onClick={() => {
              setPagination(prev => {
                return { ...prev, pageIndex: d };
              });
            }}
          >
            {d + 1}
          </PageListBox>
        );
      })}
      <PageButton
        onClick={() => {
          setPageGroupIndex(prev => [prev[1], prev[1] + 5]);
        }}
        disabled={range[range.length - 1] < pageGroupIndex[1] || range.length < 1}
      >
        <IcRight />
      </PageButton>
    </Stack>
  );
};

export default Pagination;
