import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';

interface GradeAuthorizationProps {
  children: React.ReactNode;

  // 필요 권한
  level: number;
  pass?: Array<number>;
}

const GradeAuthorization = ({ children, level, pass }: GradeAuthorizationProps) => {
  let navigate = useNavigate();
  // 로그인 한 유저 권한
  const { userGrade } = useStore();

  const validation: boolean = pass
    ? (userGrade as number) > level && !pass?.includes(userGrade as number)
    : (userGrade as number) > level;

  React.useEffect(() => {
    if (validation) {
      alert('페이지에 접근할 수 없습니다.\n접근 권한이 없습니다.');

      navigate('/');
      return;
    } else return;
  });

  return <React.Fragment>{validation ? <></> : children}</React.Fragment>;
};

export default GradeAuthorization;

// - role_id: 유저 권한
//     - 1: Owner
//     - 2: Manager
//     - 3: Driver
