import React, { SetStateAction, useEffect } from 'react';
import Modal from './index';
import { Stack } from '../Stack';
import { Text } from '../Typography';
import Divider from '../Divider';
import { ReactComponent as IconFileUpload } from '../../constants/icon/ic_xlsx.svg';
import styled from 'styled-components';
import strings from '../../util/Localization';
import {
  IOrderReportDimensionRoute,
  IOrderReportMetrics,
  IReportResponsesList,
  IRouteReportDimensionRoute,
  IRouteReportMetrics,
} from '../../constants/types';
import reportXlsxDownloader from '../../util/reportXlsxDownloader';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';

export type TReportType = 'driving' | 'order';

export const ReportDownloadModal = ({
  selected,
  total,
  combined,
  isOpen,
  setIsOpen,
  reportType,
}: {
  selected: Array<number>;

  total?:
    | Array<IReportResponsesList<IRouteReportDimensionRoute, IRouteReportMetrics>>
    | Array<IReportResponsesList<IOrderReportDimensionRoute, IOrderReportMetrics>>;
  combined: TDragablePickerOptions;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  reportType: TReportType;
}) => {
  const runDownloadXlsx = (type: 'selected' | 'all') => {
    if (type === 'all') {
      reportXlsxDownloader(total, reportType, combined);
    }
    if (type === 'selected') {
      if (selected.length > 0) {
        if (total && total.length >= selected.length) {
          reportXlsxDownloader(
            selected.map(x => total[x]),
            reportType,
            combined
          );
        }
      } else {
        alert(strings.선택된주문 + '이 없습니다.');

        setIsOpen(false);
        return;
      }
    }
  };

  return (
    <Modal
      title={''}
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      ds="strong"
      padding={24}
      // ms={48}
      width={701}
    >
      <Stack>
        <Text styleName="title2" color="RG02" sx={{ padding: '30px 0 40px' }}>
          다운로드 받으실 파일을 선택해주세요.
        </Text>
        <Stack
          direction="row"
          spacing={24}
          justify="space-between"
          divider={<Divider vertical />}
          sx={{ height: '184px' }}
        >
          <BorderBox
            onClick={() => {
              runDownloadXlsx('selected');
            }}
          >
            <Stack spacing={8}>
              <Stack spacing={12}>
                <IconFileUpload />
                <Text styleName="body2" color="RG02">
                  선택한 건수 다운로드
                </Text>
              </Stack>

              <Text styleName="body1" color="RG02">
                총 {selected.length} 건
              </Text>
            </Stack>
          </BorderBox>

          <BorderBox
            onClick={() => {
              runDownloadXlsx('all');
            }}
          >
            <Stack spacing={8}>
              <Stack spacing={12}>
                <IconFileUpload />
                <Text styleName="body2" color="RG02">
                  전체 건수 다운로드
                </Text>
              </Stack>

              <Text styleName="body1" color="RG02">
                총 {total ? total.length : 0} 건
              </Text>
            </Stack>
          </BorderBox>
        </Stack>
      </Stack>
    </Modal>
  );
};

const BorderBox = styled.div`
  width: 100%;

  padding: 40px 20px 42px;

  background: ${({ theme }) => theme.colors.RC03_2};
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.RC03_3}`};

  cursor: pointer;

  :hover {
    border: ${({ theme }) => `1px solid ${theme.colors.RC03}`};
    background: ${({ theme }) => theme.colors.RC03_1};
  }
  transition: all 0.5s;
`;
