import React, { Dispatch, SetStateAction, useState } from 'react';
import _, { throttle } from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import { Button } from '../Button';
import { Stack } from 'components/Stack';
import Divider from 'components/Divider';
import DroppableWrapper from '../Droppable/Droppable';
import { Highlight, Text } from 'components/Typography';

import strings from '../../util/Localization';
import { ColorChip, DriverItem } from './style';
import { ColorPalette } from '../../constants/colorPalette';

import { ReactComponent as IcMarker } from 'constants/icon/ic_mini_marker.svg';
import { ReactComponent as IcArrowRight } from '../../constants/icon/ic_arrowright20.svg';
import { DriverIdUnSelected, ImanualExcelValidateVehicleList } from 'constants/types';
import { Input } from 'components/Input';
import { useForm } from 'react-hook-form';
import DatePickerPopup from 'components/Popup/DatePicker';
import { useMutation } from 'react-query';
import { manualRouting } from 'api';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import { canceler } from 'api/instance';
import theme from 'constants/theme';

import { ReactComponent as IconError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';

const ManualRoutingNav = ({
  manualExcelValidatedData,
  setXlsxFormIsOpen,
  targetId,
  setTargetId,
  tabIsOpen,
  setTabIsOpen,
}: {
  manualExcelValidatedData: Array<ImanualExcelValidateVehicleList>;
  setXlsxFormIsOpen: Dispatch<SetStateAction<boolean>>;

  targetId: number;
  setTargetId: Dispatch<SetStateAction<number>>;
  tabIsOpen: boolean;
  setTabIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  type TformProp = {
    name: string;
    date: string;
  };
  let navigate = useNavigate();
  const { state } = useLocation();
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: (state as TformProp).name,
      date: dayjs((state as TformProp).date).format('YYYY.MM.DD (ddd)'),
    },
  });
  const { control } = methods;

  const [mutateManualRoutingModalIsOpen, setMutateManualRoutingModalIsOpen] = useState<boolean>(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState<boolean>(false);

  const {
    data: mutateManualRoutingResponse,
    mutate: mutateManualRouting,
    status: mutateManualRoutingStatus,
  } = useMutation(manualRouting, {
    onMutate: () => setMutateManualRoutingModalIsOpen(true),
  });

  const onSubmit = throttle((formData: unknown) => {
    if (mutateManualRoutingStatus === 'loading') return;

    const data = formData as TformProp;

    mutateManualRouting({
      performedDate: dayjs(data.date).format('YYYY-MM-DD'),
      name: data.name,
      vehicleList: manualExcelValidatedData,
    });
  }, 1000);

  const uSuck = (): boolean => manualExcelValidatedData.length > 0;

  return (
    <Stack useVerticalScroll sx={{ height: '100%', padding: '20px 0 0 30px' }}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={20}>
          <Stack align={'start'} direction="row" justify="space-between" sx={{ padding: '0 20px 0 0' }}>
            <Text styleName={'body2'} color={'RG04'}>
              {dayjs(methods.getValues('date')).format('YYYY-MM-DD')}
            </Text>
          </Stack>

          <Stack align={'start'} spacing={12} sx={{ padding: '0 20px 0 0' }}>
            <Text styleName="body2" color="RG02">
              배차 설정
            </Text>

            <Stack name="route-perfomance-setting" spacing={10}>
              <Stack align="start" spacing={2}>
                <Text styleName="caption2" color="RG03">
                  {strings.주행이름}
                </Text>
                <Input
                  height={32}
                  name="name"
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  variant={'third'}
                  placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')} 배차`}
                  errors={methods.formState.errors.name}
                  hasError={methods.formState.errors.name}
                />
              </Stack>

              <Stack align="start" spacing={2}>
                <Text styleName="caption2" color="RG03">
                  {strings.주행일자}
                </Text>

                <Stack direction={'row'} sx={{ position: 'relative' }}>
                  <Input
                    variant={'third'}
                    type="text"
                    name="date"
                    placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')}`}
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    height={32}
                    readOnly={true}
                    onFocus={() => {
                      setDatePickerIsOpen(true);
                    }}
                    onClick={() => {
                      setDatePickerIsOpen(true);
                    }}
                    errors={methods.formState.errors.date}
                    hasError={methods.formState.errors.date}
                  />

                  {datePickerIsOpen && (
                    <DatePickerPopup
                      banPast
                      allowUncheck={false}
                      setIsOpen={setDatePickerIsOpen}
                      setValue={methods.setValue}
                      name={'date'}
                      initDate={methods.getValues('date')}
                      format={'YYYY.MM.DD (ddd)'}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Stack align={'start'} spacing={12}>
            <Text styleName="body2">수동 배차 요약</Text>
            <Stack
              direction="row"
              sx={{ margin: '1px 0 0 0' }}
              spacing={8}
              divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
            >
              <Stack spacing={6} direction={'row'} sx={{ width: 'fit-content' }}>
                <Text styleName={'caption2'} color={'RG03'}>
                  {strings.배차주문}
                </Text>
                <Text styleName={'caption2'} color={'RG04'}>
                  <Highlight styleName={'caption2'} color={'RC02'}>
                    {manualExcelValidatedData.map(x => x.orderList.length).reduce((x, a) => x + a, 0) ?? 0}
                  </Highlight>
                  &nbsp;개
                </Text>
              </Stack>
              <Stack spacing={6} direction={'row'} sx={{ width: 'fit-content' }}>
                <Text styleName={'caption2'} color={'RG03'}>
                  {strings.모달_드라이버}
                </Text>
                <Text styleName={'caption2'} color={'RG04'}>
                  <Highlight styleName={'caption2'} color={'RC02'}>
                    {manualExcelValidatedData.length}
                  </Highlight>
                  &nbsp;명
                </Text>
              </Stack>
            </Stack>

            <Stack sx={{ height: 'fit-content' }}>
              <Stack
                useVerticalScroll
                spacing={10}
                sx={{
                  minHeight: (manualExcelValidatedData.length ?? 0) > 0 ? '128px' : 'auto',
                  maxHeight: '430px',
                  padding: '0 20px 0 0',
                }}
              >
                {manualExcelValidatedData.map(d => {
                  return (
                    <DroppableWrapper id={`driver_${d.vehicle.vehicleId}`} sx={{ width: '100%' }}>
                      <DriverItem
                        spacing={10}
                        padding={10}
                        onClick={() => {
                          if (tabIsOpen && targetId === d.vehicle.vehicleId) {
                            setTabIsOpen(false);
                            setTargetId(DriverIdUnSelected);
                          } else {
                            d.vehicle.vehicleId && setTargetId(d.vehicle.vehicleId);
                            setTabIsOpen(true);
                          }
                        }}
                        isActive={d.vehicle.vehicleId === targetId}
                      >
                        <Stack direction={'row'}>
                          <Stack sx={{ flex: 1 }} align={'center'} spacing={4} direction={'row'}>
                            <ColorChip style={{ backgroundColor: ColorPalette.getColor(d.vehicle.vehicleId) }} />
                            <Text styleName={'subheadline1'} color={'RG02'}>
                              {d.vehicle.licenseNumber}
                            </Text>
                          </Stack>

                          <IcArrowRight />
                        </Stack>
                        <Stack
                          direction={'row'}
                          spacing={6}
                          divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                        >
                          <Text styleName={'caption2'} color={'RG02'}>
                            {strings.주문}&nbsp;
                            <Highlight styleName={'caption2'} color={'RC02'}>
                              {d.orderList.length}
                            </Highlight>
                            &nbsp;개
                          </Text>

                          <Text styleName={'caption2'} color={'RG02'}>
                            {d.vehicle.licenseNumber}
                          </Text>
                        </Stack>
                      </DriverItem>
                    </DroppableWrapper>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack name="button-area" sx={{ marginBottom: '28px', padding: '18px 20px 0 0' }} spacing={12}>
          <Button
            type={'button'}
            variant={uSuck() ? 'second' : 'default'}
            sx={{ padding: '3px 6px' }}
            onClick={() => {
              setXlsxFormIsOpen(true);
            }}
            styleName={'caption2'}
            color={uSuck() ? 'RG04' : 'RG00'}
            height={32}
            fullWidth
          >
            {uSuck() ? '엑셀 재업로드' : '엑셀 업로드'}
          </Button>
          <Button
            variant={'default'}
            ds={'normal'}
            type={'submit'}
            fullWidth
            height={40}
            color="RG00"
            styleName="body1"
            disabled={!(manualExcelValidatedData.length ?? 0 > 0) || mutateManualRoutingStatus === 'loading'}
            //   onClick={methods.handleSubmit(data => handleSetRouteSetting(data))}
          >
            경로 확정
          </Button>
        </Stack>
      </form>

      <Modal
        isModalOpen={mutateManualRoutingModalIsOpen}
        width={504}
        padding={24}
        setIsModalOpen={setMutateManualRoutingModalIsOpen}
        isLoadingModal={mutateManualRoutingStatus === 'loading'}
        loadingPlaceholder={
          <Text styleName="subheadline2" color="RG02">
            확정된 경로를 저장하고있습니다.
          </Text>
        }
        modalFooter={
          <Button
            fullWidth
            styleName="body2"
            color="RG03"
            variant={'second'}
            type={'button'}
            sx={{ padding: '8px 10px', margin: '40px 0 0 0' }}
            onClick={() => {
              if (mutateManualRoutingStatus === 'success')
                navigate(`../history/${mutateManualRoutingResponse.routeId}`, { replace: true });
              if (mutateManualRoutingStatus === 'loading') canceler();

              setMutateManualRoutingModalIsOpen(false);
            }}
          >
            닫기
          </Button>
        }
      >
        {mutateManualRoutingStatus === 'success' ? (
          <React.Fragment>
            <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
            <Text styleName="subheadline2" color="RG02">
              경로 확정을 완료하였습니다.
            </Text>
          </React.Fragment>
        ) : mutateManualRoutingStatus === 'error' ? (
          <React.Fragment>
            <IconError style={{ width: '44px', height: '44px' }} />
            <Text styleName="subheadline2" color="RG02">
              경로 확정을 하지 못하였습니다.
            </Text>
          </React.Fragment>
        ) : null}
      </Modal>
    </Stack>
  );
};

export default ManualRoutingNav;
