import React, { useState } from 'react';
import { getMyInfo, postPsLink, setMyInfo } from 'api';
import { useMutation, useQuery } from 'react-query';

import { Text } from 'components/Typography';
import Modal from 'components/Modal';
import FieldForm from 'components/Field/FieldForm';

import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import { ISetMyInfo } from 'constants/types';
import { useForm } from 'react-hook-form';
import theme from 'constants/theme';

const Account = () => {
  const {
    isLoading: isLoadingMyInfo,
    data: myInfo,
    refetch,
  } = useQuery('getMyInfo', getMyInfo, {
    refetchOnWindowFocus: false,
  });
  const { mutate: MutateSetMyInfo, isLoading: isLoadingSetMyInfo } = useMutation(setMyInfo, {
    onSuccess: () => {
      alert('성공정으로 수정 완료되었습니다.');
      refetch();
    },
  });

  const { mutate: mutatePostPsLink } = useMutation(postPsLink, {
    onSuccess: () => {
      setPasswordResetModalOpen(true);
    },
    onError: () => {
      alert('비밀번호 재설정 링크 발송에 실패했습니다.\n다시 시도해주세요.');
    },
  });

  const [isModi, setIsModi] = useState<boolean>(false);

  const [passwordResetModalOpen, setPasswordResetModalOpen] = React.useState<boolean>(false);

  const account = [
    {
      title: '계정 정보',
      //   desc : '',
      RTB: isModi ? '저장' : '수정',

      fieldContents: [
        { type: 'Input', name: 'email', label: '아이디', disabled: true },
        {
          type: 'Input',
          name: 'password',
          label: '비밀번호',
          disabled: true,
          rightArea: {
            rightContent: '재설정 링크 보내기',
            rightFunc: () => {
              mutatePostPsLink(myInfo?.userId as number);
            },
          },
        },
        {
          type: 'Input',
          name: 'name',
          label: '이름',
          readOnly: !isModi,
          validation: {
            required: { value: true, message: '해당 필드는 필수값 입니다.' },
          },
        },
        {
          type: 'Input',
          name: 'phone',
          label: '연락처',
          readOnly: !isModi,
          validation: {
            required: { value: true, message: '해당 필드는 필수값 입니다.' },
            pattern: {
              value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
              message: '휴대폰 형식에 맞춰주세요.',
            },
            maxLength: {
              value: 13,
              message: '휴대폰 형식에 맞춰주세요.',
            },
          },
        },
      ],
      useFormProps: {
        mode: 'onSubmit',
        defaultValues: {
          email: myInfo?.email ?? myInfo?.name,
          name: myInfo?.name,
          phone: myInfo?.phone?.replace(/[^0-9]/g, '')?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
          password: '********',
        },
      },

      onSubmit: (data: ISetMyInfo) => {
        if (isModi && Boolean(Object.keys(data).length)) {
          if (data.phone) data.phone = data?.phone?.replace(/[^0-9]/g, '');
          MutateSetMyInfo(data);
        }
        setIsModi(prev => !prev);
      },
      isModi,
      setIsModi,
      complex: true,
    },
  ];

  // controll passwordResetModal
  React.useEffect(() => {
    let interval = setInterval(() => {
      if (passwordResetModalOpen) {
        setPasswordResetModalOpen(false);
      } else clearInterval(interval);
    }, 2000);

    return () => clearInterval(interval);
  }, [passwordResetModalOpen]);

  return (
    <React.Fragment>
      {isLoadingMyInfo ? <></> : <FieldForm FieldProps={account} />}
      <Modal
        isModalOpen={passwordResetModalOpen}
        setIsModalOpen={setPasswordResetModalOpen}
        width={502}
        padding={54}
        ms={20}
        plain
        bd
        ds="strong"
      >
        <IconSuccess fill={theme.colors.RC02} />
        <Text styleName="subheadline3">
          계정에 설정된 메일로
          <br />
          비빌번호 재설정 링크를 보냈습니다.
        </Text>
      </Modal>
    </React.Fragment>
  );
};

export default Account;
