import create from 'zustand';
import { IEqualOptimizeResponses, IEqualOptimizeResponsesOnStore, IGetOrderList } from 'constants/types';
import dayjs from 'dayjs';
interface StoreContentState {
  userGrade: 1 | 2 | 3 | 4;
  setUserGrade: (grade: 1 | 2 | 3 | 4) => void;

  getOrderListProps: IGetOrderList;
  setGetOrderListProps: (data: {
    status?: string;
    received_date?: string;
    desired_date?: string;
    completed_date?: string;
    driverId?: string;
    keyword?: string;
    sort_by?: string;
    sort_method?: string;
    page_per_count?: number;
    page?: number;
  }) => void;
  resetGetOrderListProps: () => void;

  temporaryOptimizeData?: IEqualOptimizeResponsesOnStore;
  setTemporaryOptimizeData: ({
    dateKey,
    name,
    originName,
    data,
  }: {
    dateKey: string;
    name: string;
    originName: string;
    data: IEqualOptimizeResponses;
  }) => void;
  loadTemporaryOptimizeData: ({ dateKey }: { dateKey: string }) => void;

  selectedOrderIds?: number[];
  setSelectedOrderIds: (ids: number[]) => void;
}

export const useStore = create<StoreContentState>(set => ({
  setSelectedOrderIds: (ids: number[]) => set({ selectedOrderIds: ids }),

  userGrade: (Number(window.localStorage.getItem('roleId')) as 1 | 2 | 3 | 4) ?? 3,
  setUserGrade: grade => set({ userGrade: grade }),
  setTemporaryOptimizeData: ({ dateKey, name, originName, data }) => {
    let unBatched = data.driverList.filter(x => !x.driverId);
    if (unBatched.length === 0)
      data.driverList.push({
        driverId: null,
        name: null,
        orderList: [],
        workStartTime: '',
        hasLunch: false,
      });

    let onStoreData: IEqualOptimizeResponsesOnStore = {
      key: dateKey,
      name,
      originName,
      data,
    };
    sessionStorage.setItem(`temp_${dateKey}`, JSON.stringify(onStoreData));
  },

  loadTemporaryOptimizeData: ({ dateKey }) => {
    let item = sessionStorage.getItem(`temp_${dateKey}`);
    if (item) {
      let parsedItem: IEqualOptimizeResponsesOnStore = JSON.parse(item);
      set({ temporaryOptimizeData: parsedItem });
    }
  },

  getOrderListProps: {
    received_date: `${dayjs().subtract(1, 'week').format('YYYYMMDD')}-${dayjs().format('YYYYMMDD')}`,
    allow_desired_date_null: false,
    status: 'unassigned',

    // page: 1,
    // page_per_count: 5,
  },

  setGetOrderListProps: data => {
    set({ getOrderListProps: data as any });
  },
  resetGetOrderListProps: () => {
    set({
      getOrderListProps: {
        allow_desired_date_null: false,
        received_date: `${dayjs().subtract(1, 'week').format('YYYYMMDD')}-${dayjs().format('YYYYMMDD')}`,
        status: 'unassigned',

        // page: 1,
        // page_per_count: 5,
      },
    });
  },
}));
