import React, { useEffect, useMemo, useState } from 'react';
import { Stack } from 'components/Stack';
import { Highlight, Text } from 'components/Typography';
import { Button } from '../Button';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { ReactComponent as IcArrowDropdown } from '../../constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as IcArrowRight } from '../../constants/icon/ic_arrowright20.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as IcMarker } from 'constants/icon/ic_mini_marker.svg';

import { useForm } from 'react-hook-form';
import { useStore } from '../../store';
import { getTemporaryOptimizeDataList } from '../../util/sessionStorageManager';
import { MovedOrder } from '../../pages/RoutePlanConfirm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DroppableWrapper from '../Droppable/Droppable';
import {
  DriverIdUnSelected,
  IEqualOptimizeResponsesDriversOrderList,
  IRunRecalculateOptimize,
  UnBatchedDriverId,
} from '../../constants/types';
import { useMutation } from 'react-query';
import { registerRoute, runRecalculateOptimize } from 'api';
import RunOptimizeModal from '../Modal/RunOptimizeModal';
import { getDriverSummary, getTotalReqTime } from '../../util/getDriverSummary';
import _, { throttle } from 'lodash';
import SpeechBubble from 'components/Popup/SpeechBubble';
import useInterval from '../../hooks/useInterval';
import { ColorPalette } from '../../constants/colorPalette';
import Divider from 'components/Divider';
import strings from '../../util/Localization';
import Modal from 'components/Modal';
import {
  AbsoluteSvg,
  ColorChip,
  DriverItem,
  DriverItemDisabled,
  DriverPickerContainer,
  DriverPickerItem,
  Editing,
  ScrollableStack,
  SelectBox,
} from './style';
import { usePrompt } from 'hooks/usePrompt';
import theme from 'constants/theme';

interface RoutePlanConfirmNavProps {
  setDriverTableIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDriverId: number;
  setSelectedDriverId: React.Dispatch<React.SetStateAction<number>>;
  movedOrderIds: MovedOrder[];
  driverTableIsOpen: boolean;
}
const RoutePlanConfirmNav = ({
  setDriverTableIsOpen,
  selectedDriverId,
  setSelectedDriverId,
  movedOrderIds,
  driverTableIsOpen,
}: RoutePlanConfirmNavProps) => {
  const methods = useForm({ mode: 'onSubmit' });
  const { temporaryOptimizeData, setTemporaryOptimizeData } = useStore();
  const navigate = useNavigate();
  const [isModiContent, setIsModiContent] = useState<boolean>(false);
  const [noRegister, setNoRegister] = useState<boolean>(false);
  const [noMoreRoute, setNoMoreRoute] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [temporaryOptimizeKeyList, setTemporaryOptimizeKeyList] = useState(getTemporaryOptimizeDataList());

  const [completedPercent, setCompletedPercent] = useState<number>(0);

  const [modiAlarmPopupIsOpen, setModiAlarmPopupIsOpen] = React.useState<boolean>(false);
  const [runReCalcProgressModal, setRunReCalcProgressModal] = React.useState<boolean>(false);

  const { mutate: mutateRegisterRoute, isLoading: mutateRegisterRouteIsLoading } = useMutation(registerRoute, {
    onSuccess: () => {
      alert('경로 확정 완료');
      navigate('../history', { replace: true });
    },
  });

  const EXPECTED_CALC_MULTIPLIER = 2.5;

  useInterval(
    () => {
      let nextPercent = completedPercent;
      if (nextPercent < 20) nextPercent += Math.floor(Math.random() * 3);
      else if (nextPercent < 50) nextPercent += Math.floor(Math.random() * 2);
      else if (nextPercent < 90) nextPercent += Math.random() > 0.7 ? 1 : 0;
      else if (nextPercent < 100) nextPercent += Math.random() > 0.9 ? 1 : 0;

      setCompletedPercent(nextPercent > 100 ? 100 : nextPercent);
    },
    movedOrderIds.length / EXPECTED_CALC_MULTIPLIER > 40
      ? Math.floor(movedOrderIds.length / EXPECTED_CALC_MULTIPLIER)
      : 40
  );

  const { mutate: mutateRunRecalculateOptimize, status: mutateRunRecalculateOptimizeStatus } = useMutation(
    runRecalculateOptimize,
    {
      mutationKey: 'mutateRunRecalculateOptimize',
      onMutate: () => {
        setCompletedPercent(0);
        setRunReCalcProgressModal(true);
      },
      onSuccess: res => {
        setCompletedPercent(100);
        setRunReCalcProgressModal(false);

        if (temporaryOptimizeData) {
          let beforeTemporaryData = _.cloneDeep(temporaryOptimizeData);
          beforeTemporaryData.data.driverList = beforeTemporaryData.data.driverList.map(d => {
            let changedDriver = res.driverList.filter(x => x.driverId === d.driverId),
              hasChangedDriver = changedDriver.length > 0;

            if (hasChangedDriver && changedDriver[0]) {
              d.orderList = changedDriver[0].orderList;
              d.hasLunch = changedDriver[0].hasLunch;
            }

            return d;
          });

          beforeTemporaryData.data.driverList = beforeTemporaryData.data.driverList.map(d => {
            if (d.driverId === null) {
              let allOrders: IEqualOptimizeResponsesDriversOrderList[] = [];
              temporaryOptimizeData.data.driverList.forEach(n =>
                n.orderList.forEach(m => {
                  delete m.route;
                  allOrders.push(m);
                })
              );
              d.orderList = allOrders.filter(z => {
                let isAllocated = false;
                beforeTemporaryData.data.driverList
                  .filter(n => n.driverId)
                  .forEach(o => {
                    o.orderList.forEach(k => {
                      if (k.orderId === z.orderId) isAllocated = true;
                    });
                  });
                return !isAllocated;
              });
            }
            return d;
          });

          console.log(beforeTemporaryData);

          let key = `${Date.now()}`;
          setTemporaryOptimizeData({
            dateKey: key,
            name: beforeTemporaryData.originName + `_(${getTemporaryOptimizeDataList().length + 1})`,
            originName: beforeTemporaryData.originName,
            data: beforeTemporaryData.data,
          });

          setTemporaryOptimizeKeyList(getTemporaryOptimizeDataList());
          navigate(`../confirm/${key}`, { replace: true });
        }
      },
      onError: err => console.log(err),
    }
  );

  const unBatchedDrivers = useMemo(() => {
    return temporaryOptimizeData?.data.driverList.filter(y => y.driverId === null);
  }, [temporaryOptimizeData]);

  const batchedDrivers = useMemo(() => {
    return temporaryOptimizeData?.data.driverList.filter(y => y.driverId !== null);
  }, [temporaryOptimizeData]);

  const drivers = useMemo(() => {
    return temporaryOptimizeData?.data.driverList;
  }, [temporaryOptimizeData]);

  const onRegisterRoute = throttle(() => {
    if (mutateRegisterRouteIsLoading) return;

    let reqDrivers = temporaryOptimizeData?.data.driverList
      .filter(d => Boolean(d.driverId))
      .map(d => {
        return {
          driverId: d.driverId,
          vehicle: { vehicleId: d.vehicle?.vehicleId },
          orderList: d.orderList.map(d => {
            return {
              orderId: d.orderId,
              type: d.type,
              route: d.route,
            };
          }),
        };
      });

    // RT-815
    reqDrivers?.forEach(d =>
      d.orderList.forEach(d => {
        delete d.route?.external?.displayIndex;
        delete d.route?.internal?.displayIndex;
      })
    );

    mutateRegisterRoute({
      name: temporaryOptimizeData?.originName as string,
      performedDate: temporaryOptimizeData?.data.performedDate as string,
      driverList: reqDrivers as any,
    });
  }, 1000);

  const runRecalculation = () => {
    let editedDrivers = temporaryOptimizeData?.data.driverList
      .filter(
        driver =>
          movedOrderIds.filter(z => z.originDriver === driver.driverId || z.targetDriver === driver.driverId).length > 0
      )
      .map(x => {
        let orders: { orderId: number }[] = [];
        x.orderList.forEach(order => {
          if (movedOrderIds.filter(x => x.id === order.orderId).length === 0) {
            order.orderId && orders.push({ orderId: order.orderId });
          }
        });

        temporaryOptimizeData?.data.driverList.forEach(drivers => {
          drivers.orderList.forEach(order => {
            if (movedOrderIds.filter(y => y.id === order.orderId && x.driverId === y.targetDriver).length > 0) {
              order.orderId && orders.push({ orderId: order.orderId });
            }
          });
        });

        return {
          driverId: x.driverId,
          vehicle: {
            vehicleId: x.vehicle?.vehicleId,
          },
          orderList: orders,
        };
      });

    let calcData = {
      performedDate: temporaryOptimizeData?.data.performedDate,
      driverList: editedDrivers,
    };
    console.log(calcData);

    mutateRunRecalculateOptimize(calcData as IRunRecalculateOptimize);
  };

  useEffect(() => {
    setModiAlarmPopupIsOpen(true);
  }, [movedOrderIds]);

  return (
    <Stack sx={{ height: '100%', padding: '20px 20px 0 30px' }}>
      <ScrollableStack>
        <Stack spacing={20}>
          <Stack align={'start'} spacing={9}>
            <Text styleName={'body2'} color={'RG04'}>
              {dayjs().format('YYYY - MM - DD')}
            </Text>

            <Stack spacing={6} direction="row" sx={{ margin: '7px 0 0 0' }}>
              <Text styleName="caption2" color="RG03">
                {strings.배차} 임시결과 저장
              </Text>
              <IcInfo width={16} height={16} fill={theme.colors.RG05} style={{ cursor: 'pointer' }} />
            </Stack>
            <div style={{ width: '100%', position: 'relative', cursor: 'pointer' }}>
              <SelectBox
                isActive={showDropdown}
                onClick={() => {
                  setShowDropdown(!showDropdown);
                }}
              >
                <Text styleName={'subheadline3'} color={'RG02'}>
                  {temporaryOptimizeData?.name}
                </Text>
                <AbsoluteSvg>
                  <IcArrowDropdown />
                </AbsoluteSvg>
              </SelectBox>
              {showDropdown && (
                <DriverPickerContainer>
                  {temporaryOptimizeKeyList.map(x => (
                    <DriverPickerItem
                      onClick={() => {
                        setShowDropdown(false);
                        navigate(`../confirm/${x.key.replace('temp_', '')}`, { replace: true });
                      }}
                    >
                      <Text styleName={'caption3'} color={'RG03'}>
                        {x.name}
                      </Text>
                    </DriverPickerItem>
                  ))}
                </DriverPickerContainer>
              )}
            </div>
            {/*             
            <Stack
              direction={'row'}
              spacing={10}
              divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
            >
              <Text styleName={'subheadline2'} color={'RG03'}>
                주문&nbsp;{' '}
                {drivers?.map(x => x.orderList.filter(d => d.type === 'order').length).reduce((x, a) => x + a, 0)} 개
              </Text>
              <Text styleName={'subheadline2'} color={'RG03'}>
                 RT-833 드라이버에서 미배차 주문 제외
                {strings.드라이버}&nbsp; {temporaryOptimizeData?.data.driverList.filter(d => d.driverId).length} 명
              </Text>
            </Stack>
             */}
          </Stack>
          <Stack align={'start'} sx={{ marginTop: '4px' }} spacing={8}>
            {/* <Stack spacing={9} direction={'row'}>
              <Text styleName={'caption2'} color={'RG03'}>
                {strings.미배차주문}
              </Text>
              <Text styleName={'caption2'} color={'RG04'}>
                {(unBatchedDrivers
                  ?.map(x => x.orderList.filter(d => d.type === 'order').length)
                  .reduce((x, a) => x + a, 0) ?? 0) -
                  movedOrderIds.filter(z => z.originDriver === UnBatchedDriverId).length +
                  movedOrderIds.filter(z => z.targetDriver === UnBatchedDriverId).length}{' '}
                / {drivers?.map(x => x.orderList.filter(d => d.type === 'order').length).reduce((x, a) => x + a, 0)}
              </Text>
            </Stack> */}

            <DroppableWrapper id={`driver_${UnBatchedDriverId}`} sx={{ width: '100%' }}>
              <DriverItemDisabled
                spacing={10}
                padding={10}
                onClick={() => {
                  if (driverTableIsOpen && selectedDriverId === UnBatchedDriverId) {
                    setDriverTableIsOpen(false);
                    setSelectedDriverId(DriverIdUnSelected);
                  } else {
                    setDriverTableIsOpen(true);
                    setSelectedDriverId(UnBatchedDriverId);
                  }
                }}
                selected={selectedDriverId === UnBatchedDriverId}
              >
                <Stack direction={'row'}>
                  <Stack sx={{ flex: 1 }} align={'start'}>
                    <Text styleName={'subheadline1'} color={'RG03'}>
                      {strings.미배차주문}
                    </Text>
                  </Stack>
                  {movedOrderIds.filter(
                    y => y.targetDriver === UnBatchedDriverId || y.originDriver === UnBatchedDriverId
                  ).length > 0 && (
                    <Editing>
                      <Text styleName={'tooltip1'} color={'RC06'}>
                        수정중
                      </Text>
                    </Editing>
                  )}
                  <IcArrowRight />
                </Stack>
                <Stack direction={'row'} spacing={8}>
                  <Text styleName={'caption2'} color={'RG03'}>
                    {strings.주문}&nbsp;
                    {unBatchedDrivers
                      ?.map(
                        x =>
                          x.orderList.filter(d => d.type === 'order').length -
                          movedOrderIds.filter(z => z.originDriver === UnBatchedDriverId).length +
                          movedOrderIds.filter(z => z.targetDriver === UnBatchedDriverId).length
                      )
                      .reduce((x, a) => x + a, 0)}
                    &nbsp;개 (총&nbsp;
                    {drivers?.map(x => x.orderList.filter(d => d.type === 'order').length).reduce((x, a) => x + a, 0)}
                    &nbsp;개 중)
                  </Text>
                </Stack>
              </DriverItemDisabled>
            </DroppableWrapper>
          </Stack>

          <Stack align={'start'} spacing={9}>
            <Text styleName="body2">{`${strings.대상주문} ${strings.배차} 결과`}</Text>
            <Stack
              direction="row"
              sx={{ margin: '1px 0 0 0' }}
              spacing={8}
              divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
            >
              <Stack spacing={6} direction={'row'} sx={{ width: 'fit-content' }}>
                <Text styleName={'caption2'} color={'RG03'}>
                  {strings.배차주문}
                </Text>
                <Text styleName={'caption2'} color={'RG04'}>
                  <Highlight styleName={'caption2'} color={'RC02'}>
                    {(drivers
                      ?.map(x => x.orderList.filter(d => d.type === 'order').length)
                      .reduce((x, a) => x + a, 0) ?? 0) -
                      ((unBatchedDrivers
                        ?.map(x => x.orderList.filter(d => d.type === 'order').length)
                        .reduce((x, a) => x + a, 0) ?? 0) -
                        movedOrderIds.filter(z => z.originDriver === UnBatchedDriverId).length +
                        movedOrderIds.filter(z => z.targetDriver === UnBatchedDriverId).length)}
                  </Highlight>
                  &nbsp;/{' '}
                  {drivers?.map(x => x.orderList.filter(d => d.type === 'order').length).reduce((x, a) => x + a, 0)}
                </Text>
              </Stack>
              <Stack spacing={6} direction={'row'} sx={{ width: 'fit-content' }}>
                <Text styleName={'caption2'} color={'RG03'}>
                  {strings.모달_드라이버}
                </Text>
                <Text styleName={'caption2'} color={'RG04'}>
                  <Highlight styleName={'caption2'} color={'RC02'}>
                    {
                      batchedDrivers?.filter(
                        x =>
                          x.orderList.filter(x => x.orderId).length -
                            movedOrderIds.filter(z => z.originDriver === x.driverId).length +
                            movedOrderIds.filter(z => z.targetDriver === x.driverId).length >
                          0
                      ).length
                    }

                    {/* {(drivers?.length as number) - 1} */}
                  </Highlight>
                  &nbsp;/ {(drivers?.length as number) - 1}명
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={10} sx={{ marginBottom: '70px' }}>
              {batchedDrivers?.map((x, i) => {
                const { routeStartTime, totalReqTime } = getTotalReqTime({
                  performedDate: temporaryOptimizeData?.data.performedDate ?? dayjs().format('YYYY-MM-DD'),
                  workStartTime: x.workStartTime,
                  lastOrderETA: x.orderList.at(-1)?.route?.external?.eta ?? '00:00',
                });

                let hour = Math.floor(totalReqTime / 60 / 60);
                let min = Math.floor((totalReqTime / 60) % 60);

                return (
                  <DroppableWrapper id={`driver_${x.driverId}`} sx={{ width: '100%' }}>
                    <DriverItem
                      spacing={10}
                      padding={10}
                      onClick={() => {
                        if (driverTableIsOpen && selectedDriverId === x.driverId) {
                          setDriverTableIsOpen(false);
                          setSelectedDriverId(DriverIdUnSelected);
                        } else {
                          setDriverTableIsOpen(true);
                          x.driverId && setSelectedDriverId(x.driverId);
                        }
                      }}
                      isActive={x.driverId === selectedDriverId}
                    >
                      <Stack direction={'row'}>
                        <Stack sx={{ flex: 1 }} align={'center'} spacing={4} direction={'row'}>
                          {x.driverId && <ColorChip style={{ backgroundColor: ColorPalette.getColor(x.driverId) }} />}
                          <Text styleName={'subheadline1'} color={'RG02'}>
                            {x.name}
                          </Text>
                        </Stack>
                        {movedOrderIds.filter(y => y.targetDriver === x.driverId || y.originDriver === x.driverId)
                          .length > 0 && (
                          <Editing>
                            <Text styleName={'tooltip1'} color={'RC06'}>
                              수정중
                            </Text>
                          </Editing>
                        )}
                        <IcArrowRight />
                      </Stack>
                      <Stack
                        direction={'row'}
                        spacing={6}
                        divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                      >
                        {/* <Text styleName={'caption2'} color={'RG02'}>
                          {strings.주문}&nbsp;
                          <Highlight styleName={'caption2'} color={'RC02'}>
                            {x.orderList.filter(x => x.orderId).length -
                              movedOrderIds.filter(z => z.originDriver === x.driverId).length +
                              movedOrderIds.filter(z => z.targetDriver === x.driverId).length}
                          </Highlight>
                          &nbsp;개
                        </Text> */}

                        <Stack spacing={4} direction="row" sx={{ width: 'fit-content' }}>
                          <IcMarker />
                          <Text styleName="caption2" color="RG03">
                            {x.orderList.filter(y => y.type === 'order').length}
                          </Text>
                        </Stack>

                        <Stack spacing={6} direction="row" sx={{ width: 'fit-content' }}>
                          {movedOrderIds.filter(y => y.targetDriver === x.driverId || y.originDriver === x.driverId)
                            .length > 0 ? (
                            <Text styleName={'caption2'} color={'RG02'}>
                              {'- 분'}
                            </Text>
                          ) : (
                            <Text styleName={'caption2'} color={'RG02'}>
                              {hour ? `${hour} 시간 ` : ''}
                              {`${isNaN(min) ? '0' : min < 10 ? `0${min}` : min} 분`}
                            </Text>
                          )}
                          {x.hasLunch &&
                            !(
                              movedOrderIds.filter(y => y.targetDriver === x.driverId || y.originDriver === x.driverId)
                                .length > 0
                            ) && <IcHasLunch width={14} height={14} />}
                        </Stack>

                        {movedOrderIds.filter(y => y.targetDriver === x.driverId || y.originDriver === x.driverId)
                          .length > 0 ? (
                          <Text styleName={'caption2'} color={'RG02'}>
                            {'- km'}
                          </Text>
                        ) : (
                          <Text styleName={'caption2'} color={'RG02'}>
                            {getDriverSummary(x).d.replace('.0', '')} km
                          </Text>
                        )}
                      </Stack>
                    </DriverItem>
                  </DroppableWrapper>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </ScrollableStack>
      <Stack name="button-area" sx={{ marginBottom: '28px', paddingTop: '18px' }} direction={'row'} spacing={12}>
        {movedOrderIds.length > 0 && modiAlarmPopupIsOpen && (
          <SpeechBubble
            isOpen={modiAlarmPopupIsOpen}
            setIsOpen={setModiAlarmPopupIsOpen}
            icon={<IconWarning width={20} height={20} />}
            sx={{ bottom: '58px', cursor: 'pointer' }}
          >
            <Text styleName="caption1" color="RG00">
              {strings.수정된주문} {movedOrderIds.length.toString().padStart(2, '0')} 개가 있습니다.
              <br />
              재계산을 실행해주세요.
            </Text>
          </SpeechBubble>
        )}

        <Button
          // variant={movedOrderIds.length > 0 ? 'third' : 'second'}
          variant={'seventh'}
          type={'button'}
          sx={{ width: '80px' }}
          height={32}
          onClick={() => {
            if (movedOrderIds.length > 0) {
              if (Object.keys(window.sessionStorage).length >= 5) {
                setNoMoreRoute(true);
                console.log('sibal');
              } else {
                setModiAlarmPopupIsOpen(false);
                runRecalculation();
              }
            } else {
              alert(`재계산은 ${strings.드라이버}간 ${strings.주문} 이동이 있을때만 가능합니다.`);
            }
          }}
        >
          <Text
            styleName={'subheadline3'}
            //  color={movedOrderIds.length > 0 ? 'RC02' : 'RG03'}
          >
            재계산
          </Text>
        </Button>
        <Button
          variant={'default'}
          type={'submit'}
          height={32}
          color="RG00"
          styleName="subheadline2"
          sx={{ flex: 1 }}
          onClick={() => {
            if (movedOrderIds.length > 0 && modiAlarmPopupIsOpen && Object.keys(window.sessionStorage).length < 5) {
              setIsModiContent(true);
            } else onRegisterRoute();
          }}
          disabled={mutateRegisterRouteIsLoading}
        >
          경로 확정
        </Button>
      </Stack>
      <Modal width={503} padding={24} isModalOpen={noMoreRoute} setIsModalOpen={setNoMoreRoute}>
        <Stack>
          <Stack spacing={10} sx={{ padding: '40px 0' }}>
            <Text styleName="title2" color="RG02">
              더 이상 경로 생성이 불가합니다.
            </Text>
            <Stack>
              <Text styleName="subheadline2" color="RC04">
                임시 결과 저장은 최대 5개까지 저장됩니다.
              </Text>
              <Text styleName="subheadline2" color="RG03">
                임시 결과 중에 1개를 선택해 ‘경로 확정'을 해주세요.
              </Text>
            </Stack>
          </Stack>
          <Button
            styleName="body2"
            color="RG03"
            variant={'seventh'}
            type={'button'}
            fullWidth
            height={40}
            onClick={() => setNoMoreRoute(false)}
          >
            확인
          </Button>
        </Stack>
      </Modal>
      <RunOptimizeModal
        info={{
          date: temporaryOptimizeData?.data.performedDate ?? '',
          totalDriver: temporaryOptimizeData?.data.driverList.filter(x => x.driverId).length ?? 0,
          name: temporaryOptimizeData?.name ?? '',
          totalOrder:
            temporaryOptimizeData?.data?.driverList
              .map(x => x.orderList.filter(d => d.type === 'order').length)
              .reduce((x, a) => x + a, 0) ?? 0,
        }}
        completedPercent={completedPercent}
        runEqualOptimizeProgressModal={runReCalcProgressModal}
        setRunEqualOptimizeProgressModal={setRunReCalcProgressModal}
      />
      <Modal width={503} isModalOpen={isModiContent} setIsModalOpen={setIsModiContent} padding={24}>
        <Stack>
          <Stack spacing={10} sx={{ padding: '40px 0' }}>
            <Text styleName="title2" color="RG02">
              수정사항이 있습니다.
            </Text>
            <Stack>
              <Text styleName="subheadline2" color="RC04">
                아직 수정사항이 반영되지 않았습니다.
                <br />
                <Highlight color="RG02">배차에 수정을 반영하고 경로를 재계산할까요?</Highlight>
              </Text>
            </Stack>
          </Stack>
          <Stack name="is-modi-content-modal-footer" spacing={16} direction="row" align="center">
            <Button
              variant="seventh"
              type="button"
              height={40}
              fullWidth
              styleName="body2"
              color="RG03"
              onClick={() => {
                setIsModiContent(false);
                onRegisterRoute();
              }}
            >
              수정 전 경로로 확정
            </Button>
            <Button
              variant="default"
              type="button"
              fullWidth
              height={40}
              styleName="body1"
              color="RG00"
              onClick={() => {
                setModiAlarmPopupIsOpen(false);
                setIsModiContent(false);
                runRecalculation();
              }}
            >
              수정사항 계산
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal width={503} isModalOpen={noRegister} setIsModalOpen={setNoRegister} padding={24}>
        <Stack>
          <Stack spacing={10} sx={{ padding: '40px 0' }}>
            <Text styleName="title2" color="RG02">
              경로가 확정되지 않았습니다.
            </Text>
            <Stack>
              <Text styleName="subheadline2" color="RC04">
                최적 경로를 확정하지 않고
                <br />
                페이지를 이동하실 경우 임시 경로 결과가 모두 삭제됩니다.
                <br />
                <Highlight color="RG02">
                  (이후 {strings.주문}들은 ‘{strings.배차대기}' 상태로 유지됩니다.)
                </Highlight>
              </Text>
            </Stack>
          </Stack>
          <Stack name="is-modi-content-modal-footer" spacing={16} direction="row" align="center">
            <Button
              variant="seventh"
              type="button"
              height={40}
              fullWidth
              styleName="body2"
              color="RG03"
              onClick={() => {
                setNoRegister(false);
              }}
            >
              확정하지않고 나가기
            </Button>
            <Button
              variant="default"
              type="button"
              fullWidth
              height={40}
              styleName="body1"
              color="RG00"
              onClick={() => {
                setNoRegister(false);
              }}
            >
              경로 확정하기
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default RoutePlanConfirmNav;
