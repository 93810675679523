import {
  createTable,
  PaginationState,
  useTableInstance,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import * as S from './style';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { getOrderList } from 'api';
import { IGetOrderListResponses, IOrder } from 'constants/types';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useStore } from 'store';
import strings from 'util/Localization';
import { validateOrderStatus } from 'util/validateStatus';
import TableComponent from '.';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import { Text } from 'components/Typography';
import { Tag } from 'components/Tag';
import dayjs from 'dayjs';
import OrderDetailModal from 'components/Modal/OrderDetailModal';
import { shipment_type } from 'constants/commons';
import TableSelectManager from './util/TableSelectManager';

let orderManagementTable = createTable().setRowType<IOrder>();

function OrderManagementTable({
  selectedOrder,
  setSelectedOrder,
  rowSelection,
  setRowSelection,
  setDeleteOrderModalOpen,
  setDelTarget,
}: {
  selectedOrder: Array<any>;
  setSelectedOrder: Dispatch<SetStateAction<Array<object>>>;
  rowSelection: {};
  setRowSelection: Dispatch<SetStateAction<{}>>;

  setDeleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  setDelTarget: Dispatch<SetStateAction<Array<number>>>;
}) {
  const domain = window.localStorage.getItem('serviceDomain');

  const { getOrderListProps, setGetOrderListProps } = useStore();

  const {
    data,
    isLoading: getOrderListResponsesIsLoading,
    isSuccess: getOrderListResponsesIsSuccess,
    isFetching: getOrderListResponsesIsFetching,
    refetch: refetchOrderList,
  } = useQuery<IGetOrderListResponses>(['getOrderList', getOrderListProps], () => getOrderList(getOrderListProps), {
    keepPreviousData: true,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
  });

  const [detailSelectBoxOpen, setDetailSelectBoxOpen] = useState<{ [key: string]: boolean }>({});
  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState<boolean>(false);
  const [target, setTarget] = useState<number>();

  const [isHover, setIsHover] = useState<
    {
      id: string;
      value: boolean;
    }[]
  >([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({
    shipmentType: domain === 'roouty',
  });

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    let t: any = {};
    selectedOrder.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.order_id === x.order_id);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [selectedOrder, setRowSelection]);

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedOrder.filter(z => z.order_id === y.order_id).length === 0);
      setSelectedOrder([...selectedOrder, ...orders]);
    } else {
      let orders: Array<any> = selectedOrder;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.order_id !== s.original?.order_id);
      });
      setSelectedOrder(orders);
    }
  }

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      orderManagementTable.createDisplayColumn({
        id: 'select',
        header: ({ instance }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: instance.getIsAllRowsSelected(),
              indeterminate: instance.getIsSomeRowsSelected(),
              onChange: instance.getToggleAllRowsSelectedHandler(),

              instance,
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
      }),
      orderManagementTable.createDataColumn(row => row.status, {
        id: 'status',
        header: () => strings.상태,
        cell: info => {
          let { key, name } = validateOrderStatus(
            info.getValue() as
              | 'unassigned'
              | 'scheduled'
              | 'processing'
              | 'arrived'
              | 'completed'
              | 'skipped'
              | 'canceled'
              | 'deleted'
          ) ?? { key: 'key', name: 'name' };

          return (
            <Menu
              menuButton={
                <div style={{ cursor: 'pointer' }} onClick={e => e.stopPropagation()}>
                  <Tag
                    active={isHover.find(d => d.id === info.row.id)?.value}
                    styleName="caption2"
                    status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
                    sx={{ width: '56px' }}
                  >
                    {name}
                  </Tag>
                </div>
              }
              offsetY={10}
              menuClassName={'table-menu'}
            >
              <MenuItem>
                <S.DetailSelectBoxButton
                  onClick={e => {
                    e.stopPropagation();

                    setDelTarget([info.row.original!.order_id]);
                    setDeleteOrderModalOpen(true);
                  }}
                >
                  취소
                </S.DetailSelectBoxButton>
              </MenuItem>
            </Menu>
          );
        },
      }),
      orderManagementTable.createDataColumn(
        row => (row.received_date ? dayjs(row.received_date).format('YYYY.MM.DD') : '-'),
        {
          id: 'received_date',
          // header: () => strings.주문접수일,
          header: '접수일',
          footer: info => info.column.id,
        }
      ),
      orderManagementTable.createDataColumn(
        row => (row.desired_date ? dayjs(row.desired_date).format('YYYY.MM.DD') : '-'),
        {
          id: 'desired_date',
          // header: () => strings.작업희망일,
          header: '희망일',
          footer: info => info.column.id,
        }
      ),
      orderManagementTable.createDataColumn(
        row => (row.completed_date ? dayjs(row.completed_date).format('YYYY.MM.DD') : '-'),
        {
          id: 'completed_date',
          header: () => strings.완료일,
          footer: info => info.column.id,
        }
      ),
      orderManagementTable.createDataColumn(row => (row.driver_name ? row.driver_name : '-'), {
        id: 'driver_name',
        header: () => strings.드라이버,
        footer: info => info.column.id,
      }),
      orderManagementTable.createDataColumn(row => row.shipment_type, {
        id: 'shipmentType',
        header: '유형',
        cell: info => {
          let rowShipmentType: string = info.getValue();
          return React.createElement(
            shipment_type.find(d => d.value === rowShipmentType)?.icon[
              isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
            ] ?? 'div',
            {
              width: 20,
              height: 20,
            }
          );
        },
        footer: info => info.column.id,
      }),
      orderManagementTable.createDataColumn(row => row.consignee_name, {
        id: 'consignee_name',
        header: () => strings.고객명,
        footer: info => info.column.id,
      }),
      orderManagementTable.createDataColumn(
        row => row.address + (row?.detail_address ? ', ' + row.detail_address : ''),
        {
          id: 'address',
          header: '주소',
          footer: info => info.column.id,
        }
      ),
      orderManagementTable.createDataColumn(
        row => {
          if (row.product_name) {
            if (row.product_name.length >= 2) return `${row.product_name[0] ?? ''} 외 ${row.product_name.length - 1}건`;
            else return `${row.product_name[0] ?? ''}`;
          }
          return '-';
        },
        {
          id: 'product_name',
          header: () => strings.상품,
          footer: info => info.column.id,
        }
      ),
      orderManagementTable.createDataColumn(row => row.order_id, {
        id: 'detail',
        header: () => strings.주문상세,
        cell: info => (
          <S.TableDeatilButton
            onClick={(e: any) => {
              setTarget(info.row.original!.order_id);
              setOrderDetailModalOpen(true);
            }}
          >
            ︙
          </S.TableDeatilButton>
        ),
        enableSorting: false,
      }),
    ],
    [anchorIsOpen, isHover, setDelTarget, setDeleteOrderModalOpen]
  );

  const instance = useTableInstance(orderManagementTable, {
    data: data?.orderList ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      pagination,
    },
    // manualPagination: true,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    // pageCount: Math.ceil((data?.orderList.length ?? 1) / pagination.pageSize),

    onSortingChange: setSorting,
    onRowSelectionChange: customSetRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  // RT-749
  // useEffect(() => {
  //   refetch();
  // }, [orderDetailModalOpen]);

  // RT-724
  useEffect(() => {
    setSelectedOrder([]);
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [data]);

  return (
    <React.Fragment>
      <TableComponent
        table={instance}
        pagination={pagination}
        setPagination={setPagination}
        onRowClick={(e, row) => {
          // if (e.target !== e.currentTarget) return;

          setTarget(row.original?.order_id as number);
          setOrderDetailModalOpen(true);
        }}
        setIsHover={setIsHover}
        placeholder="주문 추가를 눌러 주문을 추가해주세요"
        isDataFetching={getOrderListResponsesIsFetching}
      />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}

      {target && <OrderDetailModal {...{ target, isOpen: orderDetailModalOpen, setIsOpen: setOrderDetailModalOpen }} />}
    </React.Fragment>
  );
}

export default OrderManagementTable;
