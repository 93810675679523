import React, { Dispatch, SetStateAction, useState } from 'react';
import { PaginationState, Row, TableGenerics } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import * as S from './style';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import Pagination from 'components/Pagination';
import DraggableRow from './util/DraggableRow';
import strings from '../../util/Localization';
import Selector from 'components/Selector';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ReactComponent as IconSortDefault } from 'constants/icon/ic_arrow_double_faced.svg';
import { ReactComponent as IconSortDesc } from 'constants/icon/btn_table_sort_down.svg';
import { ReactComponent as IconSortAsc } from 'constants/icon/btn_table_sort_up.svg';
import { ReactComponent as IconCellDragHandle } from 'constants/icon/ic_drag_handle.svg';

import theme from 'constants/theme';
import Divider from 'components/Divider';

const TableComponent = <T extends TableGenerics>({
  table,
  isDataFetching,
  pagination,
  setPagination,
  placeholder,
  draggableRow,

  onRowClick,

  disableOptions = {
    totalCount: false,
    selectCount: false,
    pagnation: false,
    pageSizeSelector: false,
  },
  sortDrag,
  setIsHover,

  report,
}: {
  report?: boolean;
  table: any;
  isDataFetching: boolean;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  placeholder?: React.ReactNode | string;
  draggableRow?: boolean;

  disableOptions?: {
    totalCount?: boolean;
    selectCount?: boolean;
    pagnation?: boolean;
    pageSizeSelector?: boolean;
  };

  onRowClick?: (e: React.MouseEvent<HTMLElement>, row: Row<T>) => void;
  sortDrag?: {
    dragFunc: {
      handleDragStart?: () => void;
      handleDragEnd: (result: any) => void;
    };
  };
  setIsHover?: React.Dispatch<
    SetStateAction<
      {
        id: string;
        value: boolean;
      }[]
    >
  >;
}) => {
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: i => rows[i].index,
    overscan: pagination.pageSize,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  const hasSelectColumn = () => {
    return Boolean(
      table.getAllColumns().find((x: any) => {
        if (x.columnDefType === 'group') {
          return x.columns.find((d: any) => d.id === 'select');
        } else return x.id === 'select';
      })
    );
  };

  return (
    <React.Fragment>
      <S.TableWrapper height={686}>
        {hasSelectColumn() && !disableOptions.selectCount && !report && (
          <Text styleName={'subheadline2'} color={'RG03'} sx={{ marginBottom: '-4px', minWidth: 'fit-content' }}>
            {strings.선택된주문}&nbsp;:&nbsp;
            {isDataFetching
              ? '0'.padStart(2, '0')
              : table.getSelectedRowModel().rows.length.toString().padStart(2, '0')}
            &nbsp;개
          </Text>
        )}

        <S.TableScrollable
          ref={tableContainerRef}
          isEmpty={table.getCoreRowModel().rows.length === 0 || isDataFetching}
        >
          <S.CTable>
            <thead>
              {table.getHeaderGroups().map((headerGroup: any) => {
                console.log(headerGroup);

                // header로 grouping 할 때 header id에 "group_inv_" 를 추가하면 invisible로 셋
                return (
                  <S.CtrColumn
                    key={headerGroup.id}
                    style={
                      headerGroup.headers?.find((d: any) => d.id?.includes('group_inv_')) !== undefined
                        ? {
                            display: 'none',
                          }
                        : {}
                    }
                  >
                    {sortDrag && <S.Cth key="drag-handle-header" />}
                    {headerGroup.headers.map((header: any, index: number) => {
                      console.log(header.column.parent);

                      let headerType: string = header.column.parent?.id?.split('_')?.at(-1) ?? 'undifined';

                      const colorShift: { [key: string]: string } = {
                        dimension: theme.colors.RC10_2,
                        metrics: theme.colors.RC03_1,
                        undifined: 'none',
                      };

                      return (
                        <S.Cth
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ backgroundColor: colorShift[headerType] }}
                        >
                          {header.isPlaceholder ? null : (
                            <S.SortContainer
                              justify="start"
                              {...{
                                className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {header.renderHeader()}
                              {header.column.getCanSort() && (
                                <S.SortBox>
                                  {{
                                    asc: <IconSortAsc width={10} height={10} />,
                                    desc: <IconSortDesc width={10} height={10} />,
                                  }[header.column.getIsSorted() as string] ?? (
                                    <IconSortDefault fill={theme.colors.RG04} width={10} height={10} />
                                  )}
                                </S.SortBox>
                              )}
                            </S.SortContainer>
                          )}
                        </S.Cth>
                      );
                    })}
                  </S.CtrColumn>
                );
              })}
            </thead>
            {!isDataFetching && (
              <DragDropContext onDragEnd={sortDrag ? sortDrag.dragFunc.handleDragEnd : () => {}}>
                <Droppable droppableId="table-body">
                  {(provided, snapshot) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {paddingTop > 0 && (
                        <S.CtrRow>
                          <S.Ctd style={{ height: `${paddingTop}px` }} />
                        </S.CtrRow>
                      )}
                      {rowVirtualizer.getVirtualItems().map(virtualRow => {
                        console.log('v-row', table.getRowModel().rows[virtualRow.index]);

                        if (draggableRow)
                          return (
                            <DraggableRow
                              key={table.getRowModel().rows[virtualRow.index].id}
                              id={`${table.getRowModel().rows[virtualRow.index].original?.orderId || '-1'}`}
                              onMouseEnter={() => {
                                setIsHover &&
                                  setIsHover(prev => [
                                    { id: table.getRowModel().rows[virtualRow.index].id, value: true },
                                  ]);
                              }}
                              onMouseLeave={() => {
                                setIsHover &&
                                  setIsHover(prev => [
                                    { id: table.getRowModel().rows[virtualRow.index].id, value: false },
                                  ]);
                              }}
                            >
                              {table
                                .getRowModel()
                                .rows[virtualRow.index].getVisibleCells()
                                .map((cell: any) => {
                                  return <S.Ctd key={cell.id}>{cell.renderCell()}</S.Ctd>;
                                })}
                            </DraggableRow>
                          );
                        else
                          return (
                            <Draggable
                              draggableId={table.getRowModel().rows[virtualRow.index].id}
                              key={table.getRowModel().rows[virtualRow.index].id}
                              index={parseInt(table.getRowModel().rows[virtualRow.index].id)}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <S.CtrRow
                                    ref={provided.innerRef}
                                    isDragging={snapshot.isDragging}
                                    key={table.getRowModel().rows[virtualRow.index].id}
                                    style={onRowClick ? { cursor: 'pointer' } : {}}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                      onRowClick && onRowClick(e, table.getRowModel().rows[virtualRow.index]);
                                    }}
                                    // onMouseOver={table.getRowModel().rows[virtualRow.index]}
                                    isSelectedRow={table.getRowModel().rows[virtualRow.index].getIsSelected()}
                                    onMouseEnter={() => {
                                      setIsHover &&
                                        setIsHover(prev => [
                                          { id: table.getRowModel().rows[virtualRow.index].id, value: true },
                                        ]);
                                    }}
                                    onMouseLeave={() => {
                                      setIsHover &&
                                        setIsHover(prev => [
                                          { id: table.getRowModel().rows[virtualRow.index].id, value: false },
                                        ]);
                                    }}
                                    {...provided.draggableProps}
                                  >
                                    {sortDrag && (
                                      <S.Ctd key={'drag-handle'} {...provided.dragHandleProps}>
                                        <IconCellDragHandle />
                                      </S.Ctd>
                                    )}
                                    {table
                                      .getRowModel()
                                      .rows[virtualRow.index].getVisibleCells()
                                      .map((cell: any) => {
                                        return <S.Ctd key={cell.id}>{cell.renderCell()}</S.Ctd>;
                                      })}
                                  </S.CtrRow>
                                );
                              }}
                            </Draggable>
                          );
                      })}
                      {provided.placeholder}
                      {paddingBottom > 0 && (
                        <S.CtrRow>
                          <S.Ctd style={{ height: `${paddingBottom}px` }} />
                        </S.CtrRow>
                      )}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </S.CTable>
        </S.TableScrollable>
        {isDataFetching && (
          <S.TableStateBody align="center">
            <Text styleName="title2" color="RG05">
              테이블 정보를 불러오고 있습니다...
            </Text>
          </S.TableStateBody>
        )}
        {!isDataFetching && table.getCoreRowModel().rows.length === 0 && (
          <S.TableStateBody align="center">
            {placeholder ? (
              typeof placeholder === 'string' ? (
                <Text styleName="title2" color="RG05">
                  {placeholder}
                </Text>
              ) : (
                placeholder
              )
            ) : (
              <Text styleName="title2" color="RG05">
                테이블 정보가 존재하지 않습니다.
              </Text>
            )}
          </S.TableStateBody>
        )}

        <Stack direction="row" spacing={20}>
          {report ? (
            <Stack
              spacing={10}
              direction="row"
              divider={<Divider vertical color="RG06" style={{ height: '12px' }} />}
              align="center"
              sx={{
                width: 'fit-content',

                padding: '5px 10px',
                borderRadius: '8px',

                backgroundColor: theme.colors.RG08,
              }}
            >
              {hasSelectColumn() && !disableOptions.selectCount && (
                <Text styleName={'subheadline2'} color={'RG03'} sx={{ minWidth: 'fit-content' }}>
                  선택된 정보&nbsp;:&nbsp;
                  {isDataFetching
                    ? '0'.padStart(2, '0')
                    : table.getSelectedRowModel().rows.length.toString().padStart(2, '0')}
                  &nbsp;개
                </Text>
              )}
              {!disableOptions.totalCount && (
                <Text styleName="subheadline2" color={'RG03'}>
                  총 정보&nbsp;:&nbsp;
                  {`${
                    isDataFetching
                      ? '00'
                      : table
                          .getCoreRowModel()
                          .rows.filter(
                            (d: any) =>
                              d.original?.type !== 'end' && d.original?.type !== 'start' && d.original?.type !== 'break'
                          )
                          .length.toString()
                          .padStart(2, '0') ?? '00'
                  }`}
                  &nbsp;개
                </Text>
              )}
            </Stack>
          ) : (
            !disableOptions.totalCount && (
              <Text styleName="subheadline2" color={'RG03'}>
                {strings.총주문} :{' '}
                {`${
                  isDataFetching
                    ? '00'
                    : table
                        .getCoreRowModel()
                        .rows.filter(
                          (d: any) =>
                            d.original?.type !== 'end' && d.original?.type !== 'start' && d.original?.type !== 'break'
                        )
                        .length.toString()
                        .padStart(2, '0') ?? '00'
                }`}
                &nbsp;개
              </Text>
            )
          )}

          <Stack direction="row" spacing={30} sx={{ flexBasis: 'content' }} name="pagination-area">
            {!disableOptions.pageSizeSelector && (
              <Selector
                anchor="top"
                defaultValue={pagination.pageSize}
                options={[
                  { key: '10개씩 보기', value: 10 },
                  { key: '20개씩 보기', value: 20 },
                  { key: '30개씩 보기', value: 30 },
                  { key: '40개씩 보기', value: 40 },
                  { key: '50개씩 보기', value: 50 },
                ]}
                onOptionsClick={item =>
                  setPagination(prev => {
                    return { ...prev, pageIndex: 0, pageSize: item.value };
                  })
                }
              />
            )}
            {!disableOptions.pagnation && (
              <Pagination
                range={Array.from({ length: table.getPageCount() }, (v, i) => i)}
                current={table.getState().pagination.pageIndex}
                {...{ setPagination }}
              />
            )}
          </Stack>
        </Stack>
      </S.TableWrapper>
    </React.Fragment>
  );
};

export default TableComponent;
