import React, { useEffect, useState } from 'react';
import { ImanualExcelValidateResponses, ImanualExcelValidateVehicleList } from 'constants/types';

import Modal from 'components/Modal';
import { Stack } from '../components/Stack';
import XlsxLoader from 'components/Modal/XlsxLoader';
import { BoxContainer } from 'components/BoxContainer';
import Sidebar from 'components/SidebarLayout/Sidebar';
import { Text } from '../components/Typography';
import PageContentWrapper from 'components/PageContentWrapper';
import ManualRouteTable from 'components/Table/ManualRouteTable';
import ManualRoutingNav from 'components/SidebarLayout/ManualRoutingNav';
import { ManualRoutingMap } from 'components/MapContainer/ManualRoutingMap';

const ManualRoutingPage = () => {
  const [manualExcelValidatedData, setManualExcelValidatedData] = useState<Array<ImanualExcelValidateVehicleList>>([]);

  const [targetId, setTargetId] = useState<number>(-1);
  const [tabIsOpen, setTabIsOpen] = useState<boolean>(false);
  const [isXlsxError, setIsXlsxError] = useState<boolean>(false);
  const [xlsxFormIsOpen, setXlsxFormIsOpen] = useState<boolean>(true);
  const [isDownloadedErrorFile, setIsDownloadedErrorFile] = useState<boolean>(false);

  useEffect(() => {
    if (manualExcelValidatedData.length > 0) return;

    setXlsxFormIsOpen(true);
  }, [manualExcelValidatedData]);

  return (
    <React.Fragment>
      <Stack align={'flex-start'} direction={'row'}>
        <Sidebar bg="RG08" width="288px" ds="strong" index={2} sx={{ minWidth: '288px' }} padding={'28px 0 30px 0'}>
          <ManualRoutingNav
            {...{ tabIsOpen, setTabIsOpen, setXlsxFormIsOpen, manualExcelValidatedData, targetId, setTargetId }}
          />
        </Sidebar>

        <PageContentWrapper>
          <Stack
            useVerticalScroll
            align={'start'}
            sx={{
              maxWidth: `${tabIsOpen ? '1138px' : '0px'}`,
              minWidth: `${tabIsOpen ? '545px' : '0px'}`,

              margin: '48px 0',

              width: '100%',

              transition: 'all .2s',
            }}
          >
            {tabIsOpen && (
              <BoxContainer align={'start'} justify={'start'} sx={{ height: '100%', padding: '0 20px 0' }}>
                <Stack spacing={48} sx={{ height: '100%' }}>
                  <Stack name="header-area" spacing={40} align={'start'}>
                    <Text styleName={'title1'} color={'RG03'}>
                      {manualExcelValidatedData.find(d => d.vehicle.vehicleId === targetId)?.vehicle.licenseNumber}
                    </Text>
                  </Stack>

                  <Stack name="table-area">
                    <ManualRouteTable data={manualExcelValidatedData} {...{ setManualExcelValidatedData, targetId }} />
                  </Stack>
                </Stack>
              </BoxContainer>
            )}
          </Stack>

          <Stack sx={{ minWidth: '1px', height: '100%', flex: 1 }}>
            <ManualRoutingMap
              orderList={
                manualExcelValidatedData.find(d => d.vehicle.vehicleId === targetId)?.orderList ??
                manualExcelValidatedData
                  .map(d => d.orderList)
                  .reduce((x, a) => {
                    return x.concat(a);
                  }, [])
              }
            />
          </Stack>
        </PageContentWrapper>
      </Stack>

      <Modal padding={24} width={503} isModalOpen={xlsxFormIsOpen} setIsModalOpen={setXlsxFormIsOpen}>
        <XlsxLoader
          manual
          {...{
            setXlsxFormIsOpen,
            updateFunc: (res: ImanualExcelValidateResponses) => {
              setManualExcelValidatedData(res.vehicleList);
            },
            setIsDownloadedErrorFile,
            isXlsxError,
            setIsXlsxError,
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

export default ManualRoutingPage;
