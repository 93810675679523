import React, { ReactNode } from 'react';
import * as S from './style';

export interface ButtonProps {
  children: ReactNode;

  fullWidth?: boolean;
  disabled?: boolean;

  width?: number;
  height?: number;

  variant:
    | 'default'
    | 'icon'
    | 'second'
    | 'third'
    | 'fourth'
    | 'fifth'
    | 'sixth'
    | 'seventh'
    | 'eighth'
    | 'RTB'
    | 'MRTB';
  type: 'button' | 'submit' | 'reset';

  color?: string;
  bgColor?: string;

  sx?: React.CSSProperties;

  ds?: 'weak' | 'normal' | 'strong';
  onClick?: () => void;

  styleName?:
    | 'headline1'
    | 'headline2'
    | 'headline3'
    | 'title1'
    | 'title2'
    | 'title3'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'subheadline1'
    | 'subheadline2'
    | 'subheadline3'
    | 'caption1'
    | 'caption2'
    | 'caption3'
    | 'tooltip1'
    | 'tooltip2'
    | 'number1'
    | 'number2'
    | 'number3'
    | 'number4';
}

const MappedStyled = {
  default: S.Button,
  icon: S.IconButton,
  second: S.SecondButton,
  third: S.ThirdButton,
  fourth: S.FourthButton,
  fifth: S.FifthButton,
  sixth: S.SixthButton,
  seventh: S.SeventhButton,
  eighth: S.EighthButton,
  RTB: S.RTButton,
  MRTB: S.MRTButton,
};

export const Button = ({ sx, children, ...props }: ButtonProps): React.ReactElement => {
  const MappedStyledComponent = MappedStyled[props.variant];

  if (Object.keys(MappedStyled).includes(props.variant))
    return (
      <MappedStyledComponent style={sx} {...props}>
        {children}
      </MappedStyledComponent>
    );
  else return <React.Fragment />;
};
