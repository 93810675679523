import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Stack } from 'components/Stack';
import { Stack as StackStyle } from 'components/Stack/style';
import { Highlight, Text } from 'components/Typography';
import { Button } from '../Button';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { ReactComponent as IcArrowRight } from '../../constants/icon/ic_arrowright20.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';

import { useNavigate, useParams } from 'react-router-dom';
import { getRouteDetail, getRouteOrderListDownload } from 'api';
import { useMutation, useQuery } from 'react-query';
import { IParams } from 'constants/types';
import Divider from 'components/Divider';

import xlsxDownloader from 'util/xlsxDownloader';
import { ColorPalette } from '../../constants/colorPalette';
import strings from '../../util/Localization';
import { DriverItem } from './style';
import { getTotalReqTime } from 'util/getDriverSummary';

dayjs.locale('ko');
interface RoutePlanConfirmNavProps {
  driverTableIsOpen: boolean;
  setDriverTableIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  target: number | undefined;
  setTarget: React.Dispatch<React.SetStateAction<number | undefined>>;
}
const RouteHistoryNav = ({ driverTableIsOpen, setDriverTableIsOpen, target, setTarget }: RoutePlanConfirmNavProps) => {
  let params = useParams<IParams>() as IParams;
  let routeId = params.id;
  let navigate = useNavigate();

  const {
    data: routeDetail,
    isLoading: routeDetailIsLoading,
    isSuccess: routeDetailIsSuccess,
    failureCount,
  } = useQuery(['getRouteDetail', routeId], () => getRouteDetail(routeId), {
    enabled: Boolean(routeId),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
  // RT-1003 / RT-1019
  useEffect(() => {
    if (failureCount > 0) navigate('/manage/route/history', { replace: true });
  }, [failureCount]);

  const { mutate: mutateGetRouteOrderListDownload } = useMutation(getRouteOrderListDownload, {
    onSuccess: res => {
      xlsxDownloader(res, 'route');
    },
    onSettled: (data, error, variables, context) => {
      console.log('settled');
    },
  });

  if (routeDetailIsLoading) return <></>;

  if (routeDetailIsSuccess)
    return (
      <Stack sx={{ height: '100%', padding: '20px 20px 0 30px' }}>
        <ScrollableStack>
          <Stack spacing={30}>
            <Stack align={'start'} spacing={10}>
              <Text styleName="body2" color="RG04">
                {dayjs(routeDetail.performedDate).isAfter(dayjs()) ? strings.주행예정일 : strings.주행일자} :{' '}
                {routeDetail.performedDate}
              </Text>
              <Text styleName="body2" color="RG02">
                {routeDetail.name}
              </Text>
              <Text styleName="subheadline2" color="RG04">
                경로 ID : {routeDetail.routeId}
              </Text>
            </Stack>
            <Stack spacing={10} align="start">
              <Text styleName="body2">{`${strings.대상주문} ${strings.배차} 결과`}</Text>
              <Stack
                direction="row"
                sx={{ margin: '1px 0 0 0' }}
                spacing={8}
                divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
              >
                <Stack spacing={6} direction={'row'} sx={{ width: 'fit-content' }}>
                  <Text styleName={'caption2'} color={'RG03'}>
                    {strings.배차주문}
                  </Text>
                  <Text styleName={'caption2'} color={'RG04'}>
                    <Highlight styleName={'caption2'} color={'RC02'}>
                      {routeDetail.driverList
                        .map(d => d.orderList.filter(d => d.type === 'order').length)
                        .reduce((prev, current) => prev + current, 0)}
                    </Highlight>
                    &nbsp;/{' '}
                    {routeDetail.driverList
                      .map(d => d.orderList.filter(d => d.type === 'order').length)
                      .reduce((prev, current) => prev + current, 0)}
                  </Text>
                </Stack>
                <Stack spacing={6} direction={'row'} sx={{ width: 'fit-content' }}>
                  <Text styleName={'caption2'} color={'RG03'}>
                    {strings.모달_드라이버}
                  </Text>
                  <Text styleName={'caption2'} color={'RG04'}>
                    <Highlight styleName={'caption2'} color={'RC02'}>
                      {routeDetail.driverList.length}
                    </Highlight>
                    &nbsp;/ {routeDetail.driverList.length}명
                  </Text>
                </Stack>
              </Stack>

              {routeDetail.driverList.map(x => {
                const { routeStartTime, totalReqTime } = getTotalReqTime({
                  performedDate: routeDetail.performedDate,
                  workStartTime: x.workStartTime,
                  lastOrderETA: x.orderList.at(-1)?.route.eta ?? '00:00',
                });
                let hour = Math.floor(totalReqTime / 60 / 60);
                let min = Math.floor((totalReqTime / 60) % 60);

                // let hour = Math.floor(
                //   Math.floor(
                //     x.orderList.map(d => d.route.requiredTime ?? 0).reduce((prev, current) => prev + current, 0) / 60
                //   ) / 60
                // );
                // let min = Math.floor(
                //   Math.floor(
                //     x.orderList.map(d => d.route.requiredTime ?? 0).reduce((prev, current) => prev + current, 0) / 60
                //   ) % 60
                // );

                let distance = (
                  x.orderList.map(d => d.route.requiredDistance ?? 0).reduce((prev, current) => prev + current, 0) /
                  1000
                ).toFixed(1);

                return (
                  <DriverItem
                    key={`${x.orderList.length}-${x.driverId ?? 'none'}-RouteHistoryNav`}
                    spacing={10}
                    padding={10}
                    isActive={target === x.driverId && driverTableIsOpen}
                    onClick={() => {
                      if (target !== x.driverId) {
                        setTarget(x.driverId);
                        setDriverTableIsOpen(true);
                      } else setDriverTableIsOpen(prev => !prev);
                    }}
                  >
                    <Stack direction={'row'}>
                      <Stack sx={{ flex: 1 }} align={'center'} spacing={4} direction={'row'}>
                        {x.driverId && <ColorChip style={{ backgroundColor: ColorPalette.getColor(x.driverId) }} />}
                        <Text styleName={'subheadline1'} color={'RG02'}>
                          {x.name}
                        </Text>
                      </Stack>
                      <IcArrowRight />
                    </Stack>
                    <Stack
                      direction={'row'}
                      spacing={6}
                      divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                    >
                      <Text styleName={'caption2'} color={'RG03'}>
                        {strings.주문}&nbsp;{' '}
                        <Highlight styleName={'caption2'} color={'RC02'}>
                          {x.orderList.filter(d => d.type === 'order').length}
                        </Highlight>{' '}
                        개
                      </Text>

                      <Stack spacing={6} direction="row" align="center" sx={{ width: 'fit-content' }}>
                        <Text styleName={'caption2'} color={'RG03'}>
                          {hour ? `${hour} 시간 ` : ''}
                          {`${min < 10 ? `0${min}` : min} 분`}
                        </Text>
                        {x.hasLunch && <IcHasLunch />}
                      </Stack>

                      <Text styleName={'caption2'} color={'RG03'}>
                        {distance}km
                      </Text>
                    </Stack>
                  </DriverItem>
                );
              })}
            </Stack>
          </Stack>
        </ScrollableStack>
        <Stack name="button-area" sx={{ marginBottom: '28px', paddingTop: '18px' }}>
          <Button
            variant={'default'}
            ds={'normal'}
            type={'submit'}
            fullWidth
            height={32}
            color="RG00"
            styleName="subheadline2"
            onClick={() => {
              mutateGetRouteOrderListDownload(routeDetail.routeId);
            }}
          >
            경로 다운로드
          </Button>
        </Stack>
      </Stack>
    );
  return <></>;
};

export default RouteHistoryNav;

const ScrollableStack = styled(StackStyle)`
  ${({ theme }) => theme.modalScrollStyle.vertical}
  flex: 1;
  margin-right: -24px;
  width: calc(100% + 24px);
`;

const ColorChip = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
`;
