import React from 'react';
import { BoxContainer } from 'components/BoxContainer';
import { Stack } from 'components/Stack/style';
import { IField } from 'constants/types';
import styled from 'styled-components';
import Field from '..';

const FieldForm = ({ FieldProps }: { FieldProps: Array<IField> }) => {
  return (
    <BoxContainer
      useBothScroll={true}
      justify={'start'}
      sx={{
        padding: '48px 0 180px 0',
      }}
      align={'start'}
    >
      <FieldFormWrapper spacing={60}>
        {FieldProps.map(data => {
          return <Field {...data} key={data.title} />;
        })}
      </FieldFormWrapper>
    </BoxContainer>
  );
};

export default FieldForm;

export const FieldFormWrapper = styled(Stack)`
  min-width: 500px;
  width: 500px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: content-box;
`;
