import styled from 'styled-components';
import { Stack } from 'components/Stack/style';
import { SelectItem } from 'components/Input';

export const Editing = styled.div`
  background: ${({ theme }) => theme.colors.RC08};
  border-radius: 6px;
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AbsoluteSvg = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;

export const ScrollableStack = styled(Stack)`
  ${({ theme }) => theme.modalScrollStyle.vertical}
  flex: 1;
  margin-right: -24px;
  width: calc(100% + 24px);
`;

export const SelectBox = styled.div<{ isActive?: boolean; disabled?: boolean }>`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  border-radius: 8px;
  width: 100%;
  height: 32px;
  padding: 10px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  & > span {
    width: calc(100% - 25px);
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
  ${({ disabled, theme }) =>
    disabled
      ? {
          cursor: 'not-allowed',
        }
      : {
          ':hover': {
            background: theme.colors.RC03_1,
            border: `1px solid ${theme.colors.RC03}`,
          },
        }}

  ${({ isActive, disabled, theme }) =>
    !disabled &&
    isActive && {
      background: theme.colors.RC03_1,
      border: `1px solid ${theme.colors.RC03}`,
    }}
`;

export const ColorChip = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
`;

export const DriverItem = styled(Stack)<{ isActive: boolean }>`
  padding: 10px 8px;

  background: ${({ theme, isActive }) => (isActive ? theme.colors.RC03_1 : theme.colors.RG00)};
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.RC03 : theme.colors.RG06)};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};
  }
`;

export const DriverItemDisabled = styled(Stack)<{ selected: boolean }>`
  background: ${({ theme, selected }) => (selected ? theme.colors.RC03_1 : theme.colors.RG07)};
  border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.RC03 : theme.colors.RG06)};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.RC03};
    background: ${({ theme }) => theme.colors.RC03_1};
  }
`;

export const DriverPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  width: 238px;
  position: absolute;
  top: 41px;
  left: 0;
  max-height: 192px;
  ${({ theme }) => theme.scrollStyle.vertical};
`;

export const DriverPickerItem = styled(SelectItem)`
  cursor: pointer;
`;
