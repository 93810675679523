import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { SelectContainer, SelectItem } from 'components/Input';

import { ReactComponent as IconDragHandle } from 'constants/icon/ic_drag_handle.svg';
import { ReactComponent as IconFileUploadCircle } from 'constants/icon/ic_success.svg';

import theme from 'constants/theme';

export type TDragablePickerOptions = Array<{
  name: string;
  key: string | number;
  isSelected: boolean;
  default?: boolean;
}>;

interface DragablePickerProps {
  parent: React.ReactNode;
  dragable?: boolean;

  top?: string;
  width?: string;
  height?: string;

  options: TDragablePickerOptions;
  setOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DragablePicker = ({
  parent,
  dragable,

  top,
  width = '238px',
  height,

  options,
  setOptions,
  isOpen,
  setIsOpen,
}: DragablePickerProps) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: any) => {
    console.log(result);
    const { source, destination } = result;
    if (!destination) return;

    setOptions(reorder(options, source.index, destination.index));
  };

  return (
    <Stack ref={targetRef} align="start" sx={{ width: 'fit-content', position: 'relative' }}>
      {parent}

      {isOpen && (
        <DragableSelectContainer {...{ width, top, height }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                >
                  {options.map((item, index) => (
                    <Draggable key={item.key} draggableId={`${item.key}`} index={index}>
                      {(provided, snapshot) => (
                        <DargableSelectItem
                          key={`${item.name}-driver-picker-item`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          onClick={() => {
                            const data = Array.from(options);
                            let target = data.find(d => d.key === item.key);
                            if (!target) return;

                            target.isSelected = !item.isSelected;

                            setOptions(data);
                          }}
                          isDragging={snapshot.isDragging}
                        >
                          <Stack spacing={8} direction={'row'}>
                            {dragable && (
                              <div {...provided.dragHandleProps}>
                                <IconDragHandle width={12} height={12} />
                              </div>
                            )}
                            <IconFileUploadCircle
                              fill={item.isSelected ? theme.colors.RC02 : theme.colors.RG05}
                              width={14}
                              height={14}
                            />
                            <Text styleName={item.isSelected ? 'caption1' : 'caption2'} color={'RG02'}>
                              {item.name}
                            </Text>
                          </Stack>
                        </DargableSelectItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DragableSelectContainer>
      )}
    </Stack>
  );
};

export default DragablePicker;

const DragableSelectContainer = styled(SelectContainer)``;

export const DargableSelectItem = styled(SelectItem)<{ isDragging: boolean }>`
  justify-content: start;
  padding: 0 20px 0 12px;
  background: ${({ theme, isDragging }) => isDragging && theme.colors.RC03_1} !important;
`;
