import React, { useState } from 'react';

function usePostcode() {
  const [postcodeModalIsOpen, setPostcodeModalIsOpen] = useState<boolean>(false);

  const handlePostcodeComplete = (data: any, methods: any, name: string) => {
    let address = data.address || data.jibunAddress;
    methods.setValue(name, address, { shouldValidate: true, shouldDirty: true });
    setPostcodeModalIsOpen(false);
  };

  return { postcodeModalIsOpen, setPostcodeModalIsOpen, handlePostcodeComplete };
}

export default usePostcode;
