import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import GNB from './components/GNB';
import NoMatch from 'pages/NoMatch';

import Member from 'pages/setting/general/Member';
import Account from 'pages/setting/general/Account';
import Company from 'pages/setting/general/Company';
import Vehicle from 'pages/setting/general/Vehicle';

import ReportNav from 'components/SidebarLayout/ReportNav';
import SettingNav from 'components/SidebarLayout/SettingNav';
import OrderManageNav from './components/SidebarLayout/OrderManageNav';

import TestPage from './pages/TestPage';

import LoginPage from 'pages/LoginPage';
import SignUpPage from 'pages/SignUpPage';

import RouteHistory from 'pages/RouteHistory';
import PasswordSetting from 'pages/PasswordSetting';
import RouteControlPage from 'pages/RouteControlPage';
import RoutePlanConfirm from './pages/RoutePlanConfirm';
import RoutePlanSetupPage from 'pages/RoutePlanSetupPage';
import RoutePlanHistoryPage from 'pages/RoutePlanHistoryPage';

import GradeAuthorization from 'util/GradeAuthorization';
import ManualRoutingPage from 'pages/ManualRoutingPage';
import Driving from 'pages/Report/Driving';
import strings from 'util/Localization';
import Order from 'pages/Report/Order';
import ImgViewer from 'pages/ImgViewer';

const ConfigRouters = () => {
  strings.setLanguage((window.localStorage.getItem('serviceDomain') as string) ?? 'roouty');

  let authToken = localStorage.getItem('accessToken');

  const element = useRoutes([
    {
      path: '/',
      element: authToken ? <GNB /> : <Navigate to={'/login'} />,
      children: [
        {
          path: 'manage',
          children: [
            {
              path: 'route',
              element: <Outlet />,
              children: [
                {
                  path: 'history',
                  element: <RoutePlanHistoryPage />,
                },
                { path: 'history/:id', element: <RouteHistory /> },
                {
                  path: 'plan',
                  element: <RoutePlanSetupPage />,
                },
                {
                  path: 'manual',
                  element: <ManualRoutingPage />,
                },
                {
                  path: 'confirm/:key',
                  element: <RoutePlanConfirm />,
                },

                { path: '', element: <Navigate to={'history'} /> },
              ],
            },
            {
              path: 'control',
              element: (
                <GradeAuthorization level={2}>
                  <RouteControlPage />
                </GradeAuthorization>
              ),
            },
            {
              path: 'order',
              element: <OrderManageNav />,
            },
            {
              path: 'report',
              element: (
                <GradeAuthorization level={2}>
                  <ReportNav />
                </GradeAuthorization>
              ),
              children: [
                {
                  path: 'driving',
                  element: <Driving />,
                },
                {
                  path: 'order',
                  element: <Order />,
                },
                // {
                //   path: 'driving-route',
                //   element: <div>지옥의 드라이빙 경로</div>,
                // },
                // {
                //   path: 'usage',
                //   element: <div>지옥의 이용 내역</div>,
                // },

                { path: '', element: <Navigate to="driving" /> },
              ],
            },
            // { path: 'authority', element: <div>4</div> },
            // { path: 'customer-notification', element: <div>5</div> },

            { path: '', element: <Navigate to={'order'} /> },
          ],
        },
        { path: 'mypage', element: <Navigate to={'/'} /> },
        { path: '/', element: <Navigate to={'manage/order'} /> },

        {
          path: 'setting',

          element: (
            <GradeAuthorization level={2} pass={[4]}>
              <SettingNav />
            </GradeAuthorization>
          ),
          children: [
            {
              path: 'account',
              element: (
                <GradeAuthorization level={2} pass={[4]}>
                  <Account />
                </GradeAuthorization>
              ),
            },
            {
              path: 'company',
              element: (
                <GradeAuthorization level={2} pass={[4]}>
                  <Company />
                </GradeAuthorization>
              ),
            },
            {
              path: 'members',
              element: (
                <GradeAuthorization level={2} pass={[4]}>
                  <Member />
                </GradeAuthorization>
              ),
            },
            {
              path: 'vehicles',
              element: (
                <GradeAuthorization level={2}>
                  <Vehicle />
                </GradeAuthorization>
              ),
            },

            { path: '', element: <Navigate to="account" /> },
          ],
        },
      ],
    },

    { path: '/imgViewer', element: <ImgViewer /> },

    { path: '/login', element: authToken ? <Navigate to={'/'} /> : <LoginPage /> },

    // FIXME RT-965
    // { path: '/signup', element: <SignUpPage /> },

    { path: '/ps/:urlKey', element: <PasswordSetting /> },
    { path: '*', element: <NoMatch /> },
    JSON.parse(process.env.REACT_APP_TB_ONLY as string) && { path: 'test', element: <TestPage /> },
  ]);
  return element;
};

export default ConfigRouters;
