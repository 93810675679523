import React, { SetStateAction, useEffect, useState } from 'react';
import { useForm, UseFormProps, UseFormReturn, useFormState } from 'react-hook-form';

import { Button } from 'components/Button';
import { Input, Select } from 'components/Input';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { IFieldContent } from 'constants/types';

import getDirtyValues from 'util/getDirtyValues';
import PopupYIndexFixer from '../Popup/PopupYIndexFixer';
import Modal from 'components/Modal';
import DaumPostcodeEmbed from 'react-daum-postcode';
import usePostcode from 'hooks/usePostcode';

interface FieldProps {
  title: string;
  desc?: string;

  fieldContents: Array<IFieldContent>;

  //Right Top Button
  RTB?: string;
  RBB?: string;

  onSubmit: any;
  subFunction?: any;

  isModi?: boolean;
  setIsModi?: React.Dispatch<SetStateAction<boolean>>;
  complex?: boolean;

  useFormProps: UseFormProps;

  setIsDirty?: React.Dispatch<SetStateAction<boolean>>;
}

const Field = ({
  title,
  desc,
  fieldContents,
  RTB,
  onSubmit,
  subFunction,
  useFormProps,
  RBB,
  isModi,
  setIsModi,
  complex,
  setIsDirty,
}: FieldProps) => {
  const methods = useForm(useFormProps);
  const { control } = methods;
  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    if (setIsDirty) {
      let dirtyField = methods.formState.dirtyFields;
      setIsDirty(dirtyField && Object.keys(dirtyField).length !== 0 && dirtyField.constructor === Object);
    }
  }, [methods.formState.dirtyFields, setIsDirty]);

  const [targetName, setTargetName] = useState<string>('');
  const { postcodeModalIsOpen, setPostcodeModalIsOpen, handlePostcodeComplete } = usePostcode();

  function ValidateChildType(childType: string) {
    if (childType === 'Select') return true;
    if (childType === 'Input') return false;
  }

  function ValidatePhoneField(name: string) {
    if (name === 'contact' || name === 'phone') return true;
    else return false;
  }

  return (
    <form
      onSubmit={methods.handleSubmit(data => {
        if (complex) {
          if (subFunction) subFunction();
          // FIXME subFunction

          let res: any = getDirtyValues(dirtyFields, data);
          // if (Object.keys(res).length === 0) return;
          onSubmit(res);

          methods.reset({ dirtyFields: true });

          return;
        } else {
          if (subFunction)
            // FIXME subFunction
            subFunction();

          let res: any = getDirtyValues(dirtyFields, data);
          if (Object.keys(res).length === 0) return;

          onSubmit(res);
          methods.reset({ dirtyFields: true });
          return;
        }
      })}
    >
      <Stack spacing={30} align="end">
        <Stack name="Header" justify="space-between" direction="row" align="baseline">
          <Stack name="Header-info-container" align="flex-start" spacing={11}>
            <Text color="RG03" styleName="title1">
              {title}
            </Text>
            <Text color="RG04" styleName="caption2">
              {desc}
            </Text>
          </Stack>
          {RTB && (
            <Stack direction="row" justify="end" spacing={20}>
              {isModi && setIsModi && (
                <Button
                  variant={'fourth'}
                  height={40}
                  styleName="subheadline3"
                  color="RG04"
                  type={'button'}
                  sx={{ padding: '9px 20.5px' }}
                  onClick={() => {
                    methods.reset(useFormProps.defaultValues);
                    setIsModi && setIsModi(false);
                  }}
                >
                  취소
                </Button>
              )}
              <Button variant={isModi ? 'MRTB' : 'RTB'} type={'submit'}>
                {RTB}
              </Button>
            </Stack>
          )}
        </Stack>
        {fieldContents.map((child: any) => {
          return ValidateChildType(child.type) ? (
            React.createElement(Select, {
              ...{
                ...child,
                register: methods.register,
                key: child.name,
                watch: methods.watch,
                reset: methods.reset,
                setValue: methods.setValue,
                defaultValue: methods.watch(child.name),
                field: true,
                height: 40,
              },
            })
          ) : (
            <div key={child.name} style={{ width: '100%', position: 'relative' }}>
              {React.createElement(Input, {
                ...{
                  ...child,
                  register: methods.register,
                  key: child.name,
                  watch: methods.watch,
                  reset: methods.reset,
                  hasError: methods.formState?.errors[child.name],
                  errors: methods.formState?.errors[child.name],
                  defaultValue: methods.watch(child.name),
                  onClick: () => {
                    if (child?.postcode) {
                      setTargetName(child.name);
                      setPostcodeModalIsOpen(true);
                    } else child.onClick();
                  },
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    //RT-758
                    if (ValidatePhoneField(child.name))
                      methods.setValue(
                        child.name,
                        e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
                        { shouldValidate: true, shouldDirty: true }
                      );
                    else if (child.name === 'businessNumber') {
                      methods.setValue(
                        child.name,
                        e.target.value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3'),
                        { shouldValidate: true, shouldDirty: true }
                      );
                    } else methods.setValue(child.name, e.target.value, { shouldValidate: true, shouldDirty: true });
                  },

                  field: true,
                  height: 40,
                },
              })}
              {child?.timePicker && child?.timePicker.open && (
                <PopupYIndexFixer>
                  <MiniTimePicker
                    isOpen={child.timePicker.open}
                    setIsOpen={child.timePicker.setOpen}
                    setValue={methods.setValue}
                    name={child.name}
                  />
                </PopupYIndexFixer>
              )}
            </div>
          );
        })}
        {RBB && (
          <Button variant={'RTB'} type={'submit'} sx={{ margin: '0 0 48px 0' }}>
            {RBB}
          </Button>
        )}
      </Stack>

      <Modal
        isModalOpen={postcodeModalIsOpen}
        setIsModalOpen={setPostcodeModalIsOpen}
        title={'우편번호찾기'}
        width={532}
        ms={30}
        padding={20}
      >
        <DaumPostcodeEmbed
          onComplete={data => handlePostcodeComplete(data, methods, targetName)}
          style={{ width: 'calc(100% + 40px)', height: '500px', marginBottom: '-20px' }}
        />
      </Modal>
    </form>
  );
};

export default Field;
