import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import styled from 'styled-components';
import { Stack } from 'components/Stack/style';

import { ReactComponent as IcTooltipArrow } from 'constants/icon/arrow_tooltip.svg';

interface SpeechBubbleProps {
  children: ReactNode | string;
  icon?: React.SVGProps<any>;
  sx?: React.CSSProperties;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const SpeechBubble = ({ children, sx, isOpen, setIsOpen, icon }: SpeechBubbleProps) => {
  return (
    <Container
      align="start"
      style={sx}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <Bubble direction="row" spacing={10} padding={10}>
        <>
          {icon && icon}
          {children}
        </>
      </Bubble>
      <IcTooltipArrow style={{ marginLeft: '30px' }} />
    </Container>
  );
};

export default SpeechBubble;

export const Container = styled(Stack)`
  width: fit-content;

  position: absolute;
`;

export const Bubble = styled(Stack)`
  color: ${({ theme }) => theme.colors.RG00};

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.RG02};
`;
