import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Modal from '.';

import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Input } from '../Input';
import { useForm } from 'react-hook-form';
import WorkTimePopup from '../Popup/WorkTime';
import DaumPostcodeEmbed from 'react-daum-postcode';
import dayjs from 'dayjs';
import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { addSingleOrder, getVehicleInfo } from '../../api';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import DatePickerPopup from 'components/Popup/DatePicker';
import { OrderDetailModalBody, OrderDetailModalFooter, OrderDetailModalHeader } from './style';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import dateConverter from 'util/dateConverter';
import theme from 'constants/theme';
import _ from 'lodash';
import { SkillPicker } from 'components/Popup/SkillPicker';
import strings from '../../util/Localization';
import { DetailModiForm } from './OrderDetailModal';
import Divider from 'components/Divider';
import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';
import { Swap } from 'components/Swap';
import { shipment_type } from 'constants/commons';

interface SingleOrderFormProps {
  singleOrderFormIsOpen: boolean;
  setSingleOrderFormIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateFunc?: UseMutateFunction;
}

interface genLabelProps {
  text: string;
  isRequired?: boolean;
  isOption?: boolean;

  subText?: string;
}

export function genLabel(props: genLabelProps) {
  return (
    <Stack spacing={4} align={'start'} direction={'row'}>
      <Text styleName={'caption2'} color={'RG03'}>
        {props.text}
      </Text>
      {props.isRequired && (
        <Text styleName={'caption3'} color={'RC04'}>
          필수
        </Text>
      )}
      {props.isOption && (
        <Text styleName={'caption3'} color={'RG05'}>
          옵션
        </Text>
      )}
      {props.subText && (
        <Text styleName={'caption3'} color={'RG04'}>
          {props.subText}
        </Text>
      )}
    </Stack>
  );
}

function SingleOrderForm({ singleOrderFormIsOpen, setSingleOrderFormIsOpen, updateFunc }: SingleOrderFormProps) {
  const methods = useForm({ reValidateMode: 'onChange', shouldFocusError: true });

  // Set Default Values

  const [startTimePickerPopupIsOpen, setStartTimePickerPopupIsOpen] = useState<boolean>(false);
  const [endTimePickerPopupIsOpen, setEndTimePickerPopupIsOpen] = useState<boolean>(false);
  const [workTimePopupIsOpen, setWorkTimePopupIsOpen] = useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = useState<boolean>(false);
  const [desiredDatePopupIsOpen, setDesiredDatePopupIsOpen] = useState<boolean>(false);
  const [receivedDatePopupIsOpen, setReceivedDatePopupIsOpen] = useState<boolean>(false);
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);

  const [product, setProduct] = useState<Array<any>>([{ quantity: 1 }]);

  const [skillSearchValue, setSkillSearchValue] = useState<string>('');

  const handlePostcodeComplete = (data: any) => {
    let address = data.address || data.jibunAddress;
    methods.setValue('address', address, { shouldValidate: true, shouldDirty: true });
    setAddressPopupIsOpen(false);
  };

  const {
    mutate: mutateAddSingleOrder,
    isLoading: addSingleOrderIsLoading,
    isSuccess: addSingleOrderIsSuccess,

    status: addSingleOrderStatus,
    reset: addSingleOrderReset,
  } = useMutation(addSingleOrder, {
    onSuccess: () => {
      if (updateFunc) updateFunc();
    },
    onError: err => console.log(err),
  });

  const {
    data: vehicles,
    isSuccess: getVehicleListIsSuccess,
    refetch: refetchVehicle,
  } = useQuery(['vehicles'], getVehicleInfo, {});

  const handleFormComplete = (data: any) => {
    if (data.desiredDate) data.desiredDate = dateConverter(data.desiredDate, 'YYYY-MM-DD');
    // 주문 접수일
    data.receivedDate = data.receivedDate
      ? dateConverter(data.receivedDate, 'YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD');

    // data.product.quantity = 1; 트버 버전은 항상 1로 고정  /  RT-974 에서 수정됨
    // RT-630 service_time은 옵션값, 값이 없을 시 60으로 고정
    data.serviceTime = data?.serviceTime ? parseInt(data.serviceTime.replace(/[^0-9]/g, '')) * 60 : 60;

    // data.product.capacity = parseInt(data.product.capacity);
    data.packaging = parseInt(data.packaging) || 1;
    // after 혹은 before 둘 중 하나라도 있으면 반영해야함
    if (data.desiredTimeStart !== '' || data.desiredTimeEnd !== '') {
      if (data?.desiredTimeStart) {
        let key = data.desiredTimeStart?.split(' ')[0];
        let value = data.desiredTimeStart?.split(' ')[1];
        data.desiredTimeStart =
          key === '익일오전' ? `${parseInt(value.split(':')[0]) + 24}:${value.split(':')[1]}` : value;
      }
      if (data?.desiredTimeEnd) {
        let key = data.desiredTimeEnd?.split(' ')[0];
        let value = data.desiredTimeEnd?.split(' ')[1];

        data.desiredTimeEnd =
          key === '익일오전' ? `${parseInt(value.split(':')[0]) + 24}:${value.split(':')[1]}` : value;
      }
    } else {
      delete data.desiredHour;
    }

    if (
      (data.desiredTimeStart || methods.getValues('desiredTimeStart')) &&
      (data.desiredTimeEnd || methods.getValues('desiredTimeEnd'))
    ) {
      let start = dayjs(
        new Date(
          0,
          0,
          0,
          Number((data.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[0]),
          Number((data.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[1]),
          0
        )
      ).format('YYYY/MM/DD HH:mm');

      let end = dayjs(
        new Date(
          0,
          0,
          0,
          Number((data.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[0]),
          Number((data.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[1]),
          0
        )
      ).format('YYYY/MM/DD HH:mm');
      if (dayjs(start).isAfter(end)) {
        return methods.setError(
          'desiredTimeStart',
          {
            type: 'invalidDateRange',
            message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
          },
          { shouldFocus: true }
        );
      }
    }

    // 공백 삭제 FIXME
    if (data.receivedDate === '') delete data.receivedDate;
    if (data.consigneePhone === '') delete data.consigneePhone;
    else {
      data.consigneePhone = data.consigneePhone.replace(/[^0-9]/g, '');
    }
    if (data.consigneeNote === '') delete data.consigneeNote;
    if (data.address === '') delete data.address;
    if (data.detailAddress === '') delete data.detailAddress;

    if (data.desiredDate === '') delete data.desiredDate;
    if (data.desiredTimeStart === '') delete data.desiredTimeStart;
    if (data.desiredTimeEnd === '') delete data.desiredTimeEnd;
    if (data.serviceTime === '') delete data.serviceTime;
    if (data.skill === '') delete data.skill;

    data.product.forEach((d: any, i: number) => {
      if (d.name === '') delete data.product[i].name;
      if (d.code === '') delete data.product[i].code;
    });
    delete data.packaging;
    if (data.note) {
      let isEmpty: any = [];
      data.note.forEach((d: any) => {
        d === '' ? isEmpty.push(true) : isEmpty.push(false);
      });

      if (isEmpty.includes(true)) delete data.note;
    }

    delete data.completedAt;
    delete data.driverName;

    // data.product = [data.product];

    console.log(data);

    mutateAddSingleOrder({ props: data });
  };

  const serviceTimeOnCahnge = useCallback(
    _.debounce(function (e) {
      if (e.target.value !== '' && /[0-9]/g.test(e.target.value))
        methods.setValue('task.service_time', e.target.value.replaceAll(/\D/g, '').concat('분'), {
          shouldDirty: true,
          shouldValidate: true,
        });
      else
        methods.setValue('task.service_time', '', {
          shouldDirty: true,
          shouldValidate: true,
        });
    }, 500),
    []
  );

  React.useEffect(() => {
    setSkillSearchValue('');
    setProduct([{ quantity: 1 }]);
    methods.reset();

    methods.setValue(`product[${0}].quantity`, 1, {
      shouldDirty: true,
      shouldValidate: true,
    });
    methods.setValue(`capacity`, 1, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [singleOrderFormIsOpen]);

  React.useEffect(() => {
    methods.setValue(`product[${product.length - 1}].quantity`, 1, {
      shouldDirty: true,
      shouldValidate: true,
    });

    methods.setValue('shipmentType', shipment_type[0].value, {
      shouldDirty: true,
    });
  }, [product, methods]);

  const domain = window.localStorage.getItem('serviceDomain');

  return (
    <Modal
      title={strings.주문추가}
      isModalOpen={singleOrderFormIsOpen}
      setIsModalOpen={setSingleOrderFormIsOpen}
      isLoadingModal={addSingleOrderIsLoading}
      width={addSingleOrderIsLoading || addSingleOrderIsSuccess ? 504 : 496}
      height={'80vh'}
      nomt
      plain
    >
      {!addSingleOrderIsLoading &&
        (addSingleOrderIsSuccess ? (
          // RT-577/RT-606 추가
          <Stack padding={24} align="end">
            <IconClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSingleOrderFormIsOpen(false);
                addSingleOrderReset();
              }}
            />
            <Stack spacing={40}>
              <Stack spacing={30}>
                <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
                <Text styleName="subheadline3" color="RG04">
                  입력하신 {strings.모달_주문}이 이상 없이 추가 되었습니다.
                </Text>
              </Stack>
              <Button
                variant="default"
                type="reset"
                styleName="body2"
                color="RG00"
                fullWidth
                height={40}
                onClick={() => {
                  setSingleOrderFormIsOpen(false);
                  addSingleOrderReset();
                }}
              >
                닫기
              </Button>
            </Stack>
          </Stack>
        ) : (
          <React.Fragment>
            <OrderDetailModalHeader>
              <Stack direction="row" justify="space-between">
                <Text styleName="subheadline2" color="RG03">
                  {strings.주문추가}
                </Text>
                <IconClose
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSingleOrderFormIsOpen(false);
                    addSingleOrderReset();
                  }}
                />
              </Stack>
            </OrderDetailModalHeader>
            {addSingleOrderStatus === 'idle' && <Divider color="RG06" />}
            <OrderDetailModalBody useVerticalScroll>
              <Stack
                sx={{
                  maxHeight: 686,
                }}
              >
                <form>
                  <Stack name="content-area">
                    <DetailModiForm title="고객 정보">
                      <Stack sx={{ position: 'relative' }}>
                        {receivedDatePopupIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setReceivedDatePopupIsOpen}
                            setValue={methods.setValue}
                            initDate={dayjs().toString()}
                            name={'receivedDate'}
                            format="YYYY.MM.DD"
                            top="80px"
                          />
                        )}
                        <Input
                          label={genLabel({ text: strings.주문접수일 })}
                          name="receivedDate"
                          placeholder={`${dayjs().format('YYYY.MM.DD')}`}
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          readOnly={true}
                          onClick={() => {
                            setReceivedDatePopupIsOpen(true);
                          }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={16}>
                        <Input
                          label={genLabel({ text: strings.고객명, isRequired: true })}
                          name="consigneeName"
                          placeholder="예시) 홍길동"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{ required: true }}
                          hasError={methods.formState?.errors?.consigneeName}
                        />
                        <Input
                          name="consigneePhone"
                          label={genLabel({ text: '연락처', isRequired: !(domain === 'survey') })}
                          placeholder="000 - 0000 - 0000"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{
                            required: !(domain === 'survey'),
                            pattern: {
                              value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
                              message: '휴대폰 형식에 맞춰주세요.',
                            },
                            maxLength: {
                              value: 13,
                              message: '휴대폰 형식에 맞춰주세요.',
                            },
                          }}
                          hasError={methods.formState?.errors?.consigneePhone}
                          errors={methods.formState?.errors?.consigneePhone}
                        />
                      </Stack>
                      <Input
                        name="address"
                        label={genLabel({ text: '주소', isRequired: true })}
                        placeholder="받는 분 주소를 입력하세요"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                        validation={{ required: true }}
                        readOnly={true}
                        onFocus={() => {
                          setAddressPopupIsOpen(true);
                        }}
                        onClick={() => {
                          setAddressPopupIsOpen(true);
                        }}
                        hasError={methods.formState?.errors?.address}
                      />
                      <Input
                        name="detailAddress"
                        label={genLabel({ text: '상세주소' })}
                        placeholder="예시) 000동 000호"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                      />
                      <Input
                        name="consigneeNote"
                        label={genLabel({ text: strings.고객전달사항 })}
                        placeholder="예시) 문 앞에 전달"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                      />
                    </DetailModiForm>
                    <Divider color="RG06" />
                    <DetailModiForm title={strings.주문정보}>
                      {domain === 'roouty' ? (
                        <Stack align="end">
                          <Stack>
                            <Stack direction="row" spacing={16} sx={{ padding: '0 0 24px' }}>
                              <Input
                                name="capacity"
                                label={genLabel({ text: '총 용적량', isRequired: true })}
                                placeholder="1"
                                register={methods.register}
                                watch={methods.watch}
                                reset={methods.reset}
                                field
                                type="text"
                                height={40}
                                validation={{
                                  required: true,
                                  pattern: {
                                    value: /^[0-9]/,
                                    message: '숫자만 입력 가능합니다.',
                                  },
                                }}
                                hasError={methods.formState?.errors?.capacity}
                                errors={methods.formState?.errors?.capacity}
                                sx={{ flex: 1 }}
                              />
                              <Swap
                                name="shipmentType"
                                methods={methods}
                                label={genLabel({
                                  text: '주문 유형',
                                })}
                                sx={{ flex: 2 }}
                                maxWidth={286}
                                options={shipment_type.filter(d => d.visibleType)}
                              />
                            </Stack>
                            <Divider color="RG06" style={{ width: `calc(100% + 48px)` }} />
                          </Stack>

                          <Stack divider={<Divider color="RG06" style={{ width: `calc(100% + 48px)` }} />}>
                            {product?.map((d, i) => {
                              return (
                                <Stack spacing={12} key={`${d.name}-${i}-product-setting`} sx={{ padding: '24px 0' }}>
                                  <Input
                                    name={`product[${i}].name`}
                                    label={genLabel({ text: strings.상품명 })}
                                    placeholder={strings.상품명 + '을 입력해주세요.'}
                                    register={methods.register}
                                    watch={methods.watch}
                                    reset={methods.reset}
                                    field
                                    height={40}
                                  />
                                  <Stack direction="row" spacing={16}>
                                    <Input
                                      name={`product[${i}].quantity`}
                                      label={genLabel({ text: '수량', isRequired: true })}
                                      placeholder="1"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      type="text"
                                      height={40}
                                      validation={{ required: true }}
                                      hasError={methods.formState?.errors?.consignee?.quantity}
                                      sx={{ flex: 2 }}
                                    />
                                    <Input
                                      name={`product[${i}].code`}
                                      label={genLabel({ text: strings.상품코드 })}
                                      placeholder="예시) RT-123"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      height={40}
                                      sx={{ flex: 7 }}
                                    />
                                  </Stack>
                                </Stack>
                              );
                            })}
                          </Stack>
                          <Button
                            variant={'RTB'}
                            type={'button'}
                            styleName="caption2"
                            width={100}
                            height={26}
                            disabled={product.length >= 10}
                            onClick={() => setProduct(prev => [...prev].concat({ quantity: 1 }))}
                          >
                            + 아이템 추가
                          </Button>
                        </Stack>
                      ) : (
                        <Stack spacing={16} align="end">
                          <Stack direction="row" spacing={16} sx={{ display: 'none' }}>
                            <Input
                              name="capacity"
                              label={genLabel({ text: '총 용적량', isRequired: true })}
                              placeholder="1"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              field
                              type="text"
                              height={40}
                              validation={{
                                required: true,
                                pattern: {
                                  value: /^[0-9]/,
                                  message: '숫자만 입력 가능합니다.',
                                },
                              }}
                              hasError={methods.formState?.errors?.capacity}
                              errors={methods.formState?.errors?.capacity}
                              sx={{ flex: 1 }}
                            />
                            <Swap
                              name="shipmentType"
                              methods={methods}
                              label={genLabel({
                                text: '주문 유형',
                              })}
                              sx={{ flex: 2 }}
                              maxWidth={286}
                              options={shipment_type.filter(d => d.visibleType)}
                            />
                          </Stack>
                          {product?.map((d, i) => {
                            return (
                              <React.Fragment key={`${d.name}-${i}-product-setting-fragment`}>
                                <Stack direction="row" spacing={16} key={`${d.name}-${i}-product-setting`}>
                                  <Input
                                    name={`product[${i}].name`}
                                    label={genLabel({ text: strings.상품명 })}
                                    placeholder={strings.상품명 + '을 입력해주세요.'}
                                    register={methods.register}
                                    watch={methods.watch}
                                    reset={methods.reset}
                                    field
                                    height={40}
                                  />
                                  <Stack direction="row" spacing={16}>
                                    <Input
                                      name={`product[${i}].code`}
                                      label={genLabel({ text: strings.상품코드 })}
                                      placeholder="예시) RT-123"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      height={40}
                                    />
                                    <Input
                                      name={`product[${i}].quantity`}
                                      label={genLabel({ text: '수량', isRequired: true })}
                                      placeholder="1"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      type="text"
                                      height={40}
                                      validation={{ required: true }}
                                      hasError={methods.formState?.errors?.consignee?.quantity}
                                      sx={{ display: 'none' }}
                                    />
                                  </Stack>
                                </Stack>
                              </React.Fragment>
                            );
                          })}
                        </Stack>
                      )}
                    </DetailModiForm>
                    <Divider color="RG06" />
                    <DetailModiForm title={strings.배차정보}>
                      <Stack sx={{ position: 'relative' }}>
                        {desiredDatePopupIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setDesiredDatePopupIsOpen}
                            setValue={methods.setValue}
                            initDate={dayjs().toString()}
                            name={'desiredDate'}
                            banPast
                            top="80px"
                            format="YYYY.MM.DD"
                          />
                        )}
                        <Input
                          name="desiredDate"
                          label={genLabel({ text: strings.작업희망일 })}
                          placeholder="YYYY.MM.DD"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          height={40}
                          readOnly={true}
                          onClick={() => {
                            setDesiredDatePopupIsOpen(true);
                          }}
                        />
                      </Stack>

                      <Stack spacing={6}>
                        <Stack direction={'row'} align={'end'}>
                          <Stack sx={{ position: 'relative', marginRight: '16px' }}>
                            {startTimePickerPopupIsOpen && (
                              <PopupYIndexFixer>
                                <MiniTimePicker
                                  isOpen={startTimePickerPopupIsOpen}
                                  setIsOpen={setStartTimePickerPopupIsOpen}
                                  setValue={methods.setValue}
                                  name={'desiredTimeStart'}
                                  desired
                                />
                              </PopupYIndexFixer>
                            )}
                            <Input
                              label={genLabel({ text: strings.희망시간이후 })}
                              placeholder="00 : 00 (몇 시 부터)"
                              type="text"
                              name="desiredTimeStart"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              height={40}
                              readOnly={true}
                              onClick={() => {
                                setEndTimePickerPopupIsOpen(false);
                                setStartTimePickerPopupIsOpen(true);
                              }}
                              field
                              hasError={methods.formState.errors?.desiredTimeStart}
                            />
                          </Stack>
                          <Stack sx={{ position: 'relative' }}>
                            {endTimePickerPopupIsOpen && (
                              <PopupYIndexFixer>
                                <MiniTimePicker
                                  isOpen={endTimePickerPopupIsOpen}
                                  setIsOpen={setEndTimePickerPopupIsOpen}
                                  setValue={methods.setValue}
                                  name={'desiredTimeEnd'}
                                  rSide
                                  desired
                                />
                              </PopupYIndexFixer>
                            )}
                            <Input
                              label={genLabel({ text: strings.희망시간이전 })}
                              placeholder="00 : 00 (몇 시 까지)"
                              type="text"
                              name="desiredTimeEnd"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              height={40}
                              readOnly={true}
                              onClick={() => {
                                setStartTimePickerPopupIsOpen(false);
                                setEndTimePickerPopupIsOpen(true);
                              }}
                              field
                              hasError={methods.formState.errors?.desiredTimeStart}
                            />
                          </Stack>
                        </Stack>
                        {methods.formState.errors?.desiredTimeStart && (
                          <Text styleName="caption3" color="RC04">
                            {methods.formState.errors?.desiredTimeStart.message}
                          </Text>
                        )}
                      </Stack>

                      <Stack direction="row" spacing={16} align={'end'} sx={{ position: 'relative' }}>
                        <Input
                          name="serviceTime"
                          label={genLabel({ text: strings.작업소요시간분 })}
                          placeholder="숫자만 입력"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          height={40}
                          onChange={(e: any) => {
                            setWorkTimePopupIsOpen(false);
                            serviceTimeOnCahnge(e);
                          }}
                          onClick={() => {
                            setWorkTimePopupIsOpen(true);
                          }}
                        />
                        <Stack sx={{ position: 'relative' }}>
                          <Input
                            name="skill"
                            label={genLabel({ text: strings.특정차량지정 })}
                            placeholder={`${strings.특정차량} 지정`}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            height={40}
                            field
                            validation={{
                              validate: (res: any) =>
                                !Boolean(res) ||
                                (vehicles && vehicles.vehicleList.filter(d => d.licenseNumber === res).length > 0) ||
                                '존재하지 않는 차량입니다.',
                            }}
                            onChange={(e: any) => {
                              setSkillPopupIsOpen(true);
                              setSkillSearchValue(e.target.value);
                              methods.setValue('task.skill', e.target.value, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                            onClick={() => {
                              setSkillPopupIsOpen(true);
                            }}
                            hasError={methods.formState.errors.skill}
                          />
                          {skillPopupIsOpen && (
                            <SkillPicker
                              setIsOpen={setSkillPopupIsOpen}
                              methods={methods}
                              name={'skill'}
                              searchValue={skillSearchValue}
                            />
                          )}
                        </Stack>
                        {workTimePopupIsOpen && (
                          <WorkTimePopup
                            setIsOpen={setWorkTimePopupIsOpen}
                            setValue={methods.setValue}
                            name={'serviceTime'}
                          />
                        )}
                      </Stack>

                      {methods.formState.errors?.skill && (
                        <Stack align="end">
                          <Text styleName="caption3" color="RC04">
                            {methods.formState.errors?.skill.message ?? ''}
                          </Text>
                        </Stack>
                      )}

                      <Stack direction="row" spacing={16}>
                        <Input
                          name="driverName"
                          label={genLabel({ text: strings.모달_드라이버 })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                        <Input
                          name="completedAt"
                          label={genLabel({ text: strings.완료일 })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                      </Stack>
                    </DetailModiForm>
                    <Divider color="RG06" />
                    <DetailModiForm title="기타 정보">
                      {['', '', '', '', ''].map((d: any, index: any) => {
                        return (
                          <Input
                            key={`note${index}`}
                            name={`note[${index}]`}
                            label={genLabel({ text: `비고${index + 1}` })}
                            placeholder={'-'}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            height={40}
                          />
                        );
                      })}
                    </DetailModiForm>
                    <Divider color="RG06" />
                  </Stack>
                </form>
              </Stack>
            </OrderDetailModalBody>

            <OrderDetailModalFooter>
              <Button
                variant="fourth"
                type="button"
                height={40}
                width={124}
                styleName="body2"
                color="RG04"
                onClick={() => {
                  setSingleOrderFormIsOpen(false);
                }}
              >
                닫기
              </Button>
              <Button
                variant="default"
                type="submit"
                height={40}
                width={222}
                styleName="body2"
                color="RG00"
                onClick={methods.handleSubmit(handleFormComplete)}
              >
                추가하기
              </Button>
            </OrderDetailModalFooter>
          </React.Fragment>
        ))}

      <Modal
        isModalOpen={addressPopupIsOpen}
        setIsModalOpen={setAddressPopupIsOpen}
        title={'우편번호찾기'}
        width={532}
        ms={30}
        padding={20}
      >
        <DaumPostcodeEmbed
          onComplete={handlePostcodeComplete}
          style={{ width: '100%', height: '500px', marginBottom: '0' }}
        />
      </Modal>
    </Modal>
  );
  // useMemo(
  //   () =>
  //
  //   ,
  //   [
  //     singleOrderFormIsOpen,
  //     addressPopupIsOpen,
  //     workTimePopupIsOpen,
  //     startTimePickerPopupIsOpen,
  //     endTimePickerPopupIsOpen,
  //     desiredDatePopupIsOpen,
  //   ]
  // );
}

export default SingleOrderForm;
