import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import WeekDay from 'dayjs/plugin/weekday';
import { useForm, UseFormReturn } from 'react-hook-form';

import Divider from 'components/Divider';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import { DateSelector } from 'components/DateSelector';

import { ComplexDoubleviewDateselectorContainer, Summary } from './style';

dayjs.extend(WeekDay);
const dateFormat = 'YYYY.MM.DD';
const selectedTemplateButtonInit = 'defaults';

export type Tdate = Date | null | undefined | [Date | null, Date | null];
export interface ComplexDoubleviewDateselectorProps {
  initDate?: Tdate;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  methods: UseFormReturn<any>;

  setPerformedDate: Dispatch<SetStateAction<string>>;
}

const ComplexDoubleviewDateselector = ({
  initDate,
  isOpen,
  setIsOpen,
  methods,
  setPerformedDate,
}: ComplexDoubleviewDateselectorProps) => {
  const [date, setDate] = useState<Tdate>(initDate ?? null);
  const [selectedTemplateButton, setSelectedTemplateButton] = useState<string>(selectedTemplateButtonInit);

  const templates: Array<{ title: string; func: Function }> = [
    {
      title: '오늘',
      func: () => {
        setDate(dayjs().toDate());
      },
    },
    {
      title: '어제',
      func: () => {
        setDate(dayjs().subtract(1, 'day').toDate());
      },
    },
    {
      title: '이번 주',
      func: () => {
        let this_sunday = dayjs().weekday(-7).add(7, 'day').toDate();
        let this_saturday = dayjs(this_sunday).add(6, 'day').toDate();
        setDate([this_sunday, this_saturday]);
      },
    },
    {
      title: '지난 주',
      func: () => {
        let last_sunday = dayjs().weekday(-7).toDate();
        let last_saturday = dayjs(last_sunday).add(6, 'day').toDate();
        setDate([last_sunday, last_saturday]);
      },
    },
    {
      title: '지난 달',
      func: () => {
        let last_month_start = dayjs()
          .subtract(1, 'month')
          .subtract(dayjs().date() - 1, 'day')
          .toDate();

        let last_month_end = dayjs(last_month_start)
          .add(dayjs().daysInMonth() - 2, 'day')
          .toDate();
        setDate([last_month_start, last_month_end]);
      },
    },
  ];

  const CDDReset = () => {
    setDate([
      dayjs()
        .subtract(dayjs().daysInMonth() - 2, 'day')
        .toDate(),
      dayjs().toDate(),
    ]);

    setSelectedTemplateButton(selectedTemplateButtonInit);
  };

  const onSubmit = (formData: unknown) => {
    if (formData && typeof formData === 'object') formData = Object.values(formData);

    setPerformedDate(`${methods.getValues('startDate')}-${methods.getValues('endDate')}`.replaceAll('.', ''));
    setIsOpen(false);
  };

  React.useEffect(() => {
    methods.setValue(
      'startDate',
      date ? (Array.isArray(date) ? dayjs(date[0]).format(dateFormat) : dayjs(date).format(dateFormat)) : '',
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    methods.setValue(
      'endDate',
      date ? (Array.isArray(date) ? dayjs(date[1] ?? date[0]).format(dateFormat) : dayjs(date).format(dateFormat)) : '',
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  }, [date, methods, setPerformedDate]);

  return (
    <React.Fragment>
      {isOpen && (
        <ComplexDoubleviewDateselectorContainer>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack name="detailCalendar-body" align="start" spacing={14} padding={20}>
              <Summary hasError={Boolean(methods.formState.errors.startDate || methods.formState.errors.endDate)}>
                <input
                  {...methods.register('startDate', {
                    minLength: {
                      value: 10,
                      message: '',
                    },
                    maxLength: {
                      value: 10,
                      message: '',
                    },
                    pattern: {
                      value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                      message: '',
                    },
                  })}
                  autoComplete="off"
                />
                <Text styleName="caption2" color="RG02">
                  -
                </Text>
                <input
                  {...methods.register('endDate', {
                    minLength: {
                      value: 10,
                      message: '',
                    },
                    maxLength: {
                      value: 10,
                      message: '',
                    },
                    pattern: {
                      value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                      message: '',
                    },
                  })}
                  autoComplete="off"
                />
              </Summary>

              <Stack spacing={30} direction="row">
                <Stack name="template-buttons" spacing={13} sx={{ width: '84px' }}>
                  {templates.map(d => {
                    return (
                      <Button
                        key={d.title}
                        fullWidth
                        variant={selectedTemplateButton === d.title ? 'third' : 'second'}
                        type={'button'}
                        styleName="caption2"
                        sx={{ padding: '7px 10px' }}
                        onClick={() => {
                          d.func();
                          setSelectedTemplateButton(d.title);
                        }}
                      >
                        {d.title}
                      </Button>
                    );
                  })}
                </Stack>
                <DateSelector
                  showDoubleView
                  selectRange
                  allowPastDate
                  allowPartialRange
                  returnValue="range"
                  showFixedNumberOfWeeks={false}
                  customWidth="430px"
                  {...{ date: initDate, setDate }}
                />
              </Stack>
            </Stack>
            <Divider color="RG06" />
            <Stack name="detailCalendar-footer" direction="row" justify="space-between" padding={20}>
              <Button
                variant={'second'}
                type={'button'}
                styleName="caption2"
                color="RG03"
                sx={{ padding: '3px 6px' }}
                onClick={CDDReset}
              >
                초기화
              </Button>

              <Stack direction="row" spacing={20} sx={{ width: '320px' }}>
                <Button
                  variant="second"
                  type="button"
                  styleName="caption2"
                  color="RG03"
                  height={26}
                  sx={{ flex: 1 }}
                  onClick={() => setIsOpen(false)}
                >
                  닫기
                </Button>
                <Button
                  variant="default"
                  type="submit"
                  styleName="caption1"
                  color="RG00"
                  height={26}
                  sx={{ flex: 2 }}
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  적용하기
                </Button>
              </Stack>
            </Stack>
          </form>
        </ComplexDoubleviewDateselectorContainer>
      )}
    </React.Fragment>
  );
};

export default ComplexDoubleviewDateselector;
