export interface TemporaryOptimizedList {
  key: string;
  name: string;
}

export function getTemporaryOptimizeDataList() {
  let list: TemporaryOptimizedList[] = [];
  for (let i = 0; i < sessionStorage.length; i++) {
    let key = sessionStorage.key(i);
    if (key && key.startsWith('temp_'))
      list.push({
        key,
        name: JSON.parse(sessionStorage.getItem(key)!).name,
      });
  }
  list.sort((a, b) => {
    return parseInt(b.key.replace('temp_', '')) - parseInt(a.key.replace('temp_', ''));
  });
  return list;
}

export function removeTemporaryOptimizeDataList(dateKey: string) {
  for (let i = 0; i < sessionStorage.length; i++) {
    let key = sessionStorage.key(i);
    if (key && key.startsWith(`temp_${dateKey}`)) sessionStorage.removeItem(key);
  }
}
export function removeAllTemporaryOptimizeDataList() {
  for (let i = 0; i < sessionStorage.length; i++) {
    let key = sessionStorage.key(i);
    if (key && key.startsWith('temp_')) sessionStorage.removeItem(key);
  }
}
