import React, { useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { ImanualExcelValidateOrderList } from 'constants/types';
import './style.css';
import { MapConfigDefaultProps, MapConfigKeys, MapConfigOptions } from './MapConfig';
import { GoogleMapsOverlay } from './DeckGL/GoogleMapsOverlay';
import { DynamicMarker } from './DeckGL/DynamicMarker';
import { DynamicSvgPresets } from '../DynamicSvg/Presets';

interface RoutePlanSetupMapProps {
  orderList?: ImanualExcelValidateOrderList[];
}

export function ManualRoutingMap({ orderList }: RoutePlanSetupMapProps) {
  const [mapInstance, setMapInstance] = React.useState<any>(null);
  const [mapsInstance, setMapsInstance] = React.useState<any>(null);
  const [overlayInstance, setOverlayInstance] = React.useState<GoogleMapsOverlay | null>(null);

  useEffect(() => {
    if (mapsInstance && mapInstance) {
      let bounds = new mapsInstance.LatLngBounds();
      orderList?.forEach(x => {
        x.coordinate && bounds.extend({ lat: x.coordinate.coordinates[1], lng: x.coordinate.coordinates[0] });
      });
      mapInstance.fitBounds(bounds, 120);
    }
  }, [mapsInstance, mapInstance, orderList]);

  function onMapLoaded(map: any, maps: any) {
    if (!mapInstance) setMapInstance(map);
    if (!mapsInstance) setMapsInstance(maps);
    if (!overlayInstance) {
      let overlay = new GoogleMapsOverlay({});
      setOverlayInstance(overlay);
      overlay.setMap(map);
    }
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={MapConfigKeys}
      options={MapConfigOptions}
      defaultCenter={MapConfigDefaultProps.center}
      defaultZoom={MapConfigDefaultProps.zoom}
      onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
    >
      {orderList && overlayInstance && (
        <DynamicMarker
          overlay={overlayInstance}
          marker={orderList
            .filter(x => x.coordinate && x.coordinate.coordinates && x.coordinate.coordinates.length > 1)
            .map(x => {
              let info =
                // 셀렉 안됨
                false ? DynamicSvgPresets.waitingSelected : DynamicSvgPresets.waiting;
              info = JSON.parse(JSON.stringify(info));
              info.chip = {
                time: !!(x.desiredTimeStart || x.desiredTimeEnd),
                pickup: x.shipmentType === 'pickup',
                skill: !!x.skill,
              };
              return {
                info,
                coordinates: [x.coordinate.coordinates[0], x.coordinate.coordinates[1]],
                onClick: () => {
                  console.log(x);
                },
              };
            })}
        />
      )}
    </GoogleMapReact>
  );
}
