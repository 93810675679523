import { Menu, MenuItem } from '@szhsin/react-menu';
import {
  ColumnOrderState,
  createTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useTableInstance,
} from '@tanstack/react-table';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { Stack } from 'components/Stack';
import { Tag } from 'components/Tag';
import { shipment_type } from 'constants/commons';
import { IOrderReportDimensionRoute, IOrderReportMetrics, IReportResponsesList } from 'constants/types';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { validateOrderStatus } from 'util/validateStatus';
import TableComponent from '.';
import { RowDirectionContainer } from './style';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';

let orderReportTable =
  createTable().setRowType<IReportResponsesList<IOrderReportDimensionRoute, IOrderReportMetrics>>();

const OrderReportTable = ({
  isFetching,
  reportData,

  orderReportDimensionMetrics,
  rowSelection,
  setRowSelection,
}: {
  isFetching: boolean;
  reportData: Array<IReportResponsesList<IOrderReportDimensionRoute, IOrderReportMetrics>> | undefined;

  orderReportDimensionMetrics: TDragablePickerOptions;
  rowSelection: {};
  setRowSelection: Dispatch<SetStateAction<{}>>;
}) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]); // column id 를 원하는 순서대로 배열하면됨

  const [isHover, setIsHover] = useState<
    {
      id: string;
      value: boolean;
    }[]
  >([]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      orderReportTable.createDisplayColumn({
        id: 'select',
        header: ({ instance }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: instance.getIsAllPageRowsSelected(),
              indeterminate: instance.getIsSomePageRowsSelected(),
              onChange: instance.getToggleAllPageRowsSelectedHandler(),

              instance,
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
        enableSorting: false,
      }),
      orderReportTable.createDataColumn(row => row.dimension.route?.id, {
        id: 'route_id',
        header: '경로 ID',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.dimension.driver?.name, {
        id: 'driver',
        header: '드라이버',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.dimension.performedDate, {
        id: 'performedDate',
        header: '주행일',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.dimension.route?.name, {
        id: 'route_name',
        header: '주행 이름',
        footer: info => info.column.id,
      }),

      orderReportTable.createDataColumn(row => row.metrics.orderId, {
        id: 'orderCode',
        header: '주문 ID',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.status, {
        id: 'status',
        header: '상태',
        cell: info => {
          let { key, name } = validateOrderStatus(
            info.getValue() as
              | 'unassigned'
              | 'scheduled'
              | 'processing'
              | 'arrived'
              | 'completed'
              | 'skipped'
              | 'canceled'
              | 'deleted'
          ) ?? { key: 'key', name: 'name' };

          return (
            <Tag
              active={isHover.find(d => d.id === info.row.id)?.value}
              styleName="caption2"
              status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
              sx={{ width: '56px' }}
            >
              {name}
            </Tag>
          );
        },
      }),
      orderReportTable.createDataColumn(row => row.metrics.consigneeName, {
        id: 'consigneeName',
        header: '고객명',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.address, {
        id: 'address',
        header: '주소',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.detailAddress, {
        id: 'detailAddress',
        header: '상세주소',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.capacity, {
        id: 'capacity',
        header: '총 용적량',
        footer: info => info.column.id,
      }),

      // default_invisible columns
      orderReportTable.createDataColumn(row => row.metrics.serviceTime, {
        id: 'serviceTime',
        cell: info => `${Math.round(info.getValue() / 60)} 분`,
        header: '작업 소요 시간 (분)',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.shipmentType, {
        id: 'shipmentType',
        header: '주문 유형',
        cell: info => {
          let rowShipmentType = info.getValue();
          return (
            <Stack direction="row" sx={{ margin: '0 -9px' }}>
              <React.Fragment>
                <RowDirectionContainer>
                  {React.createElement(
                    shipment_type.find(d => d.value === rowShipmentType?.replace('Rotation', ''))?.icon[
                      isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
                    ] ?? 'div',
                    {
                      width: 20,
                      height: 20,
                    }
                  )}
                </RowDirectionContainer>

                {rowShipmentType?.includes('Rotation') && (
                  <RowDirectionContainer>
                    {React.createElement(
                      shipment_type.find(d => d.value === 'rotation')?.icon[
                        isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
                      ] ?? 'div',
                      {
                        width: 20,
                        height: 20,
                      }
                    )}
                  </RowDirectionContainer>
                )}
              </React.Fragment>
            </Stack>
          );
        },
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.createdAt, {
        id: 'createdAt',
        header: '주문 추가 일자',
        cell: info => dayjs(info.getValue()).format('YYYY-MM-DD'),
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.historyComment, {
        id: 'historyComment',
        header: '완료/보류 사유',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.podUrl, {
        id: 'podUrl',
        header: 'PoD 이미지 (url)',
        cell: info => {
          return (
            info.getValue().length > 0 && (
              <Link
                to={{ pathname: '/imgViewer' }}
                onClick={() => localStorage.setItem('img_referrer', info.getValue()[0])}
                target="_blank"
              >
                이미지 확인하기
              </Link>
            )
          );
        },
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.podCreatedAt, {
        id: 'podCreatedAt',
        header: 'PoD 접수 시간 (시:분)',
        cell: info => info.getValue() && dayjs(info.getValue()).format('HH:mm'),
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.actualCapacity, {
        id: 'actualCapacity',
        header: '실 용적량',
        footer: info => info.column.id,
      }),
      orderReportTable.createDataColumn(row => row.metrics.capacityComment, {
        id: 'capacityComment',
        header: '실 용적량 차이 사유',
        footer: info => info.column.id,
      }),
    ],
    []
  );

  const instance = useTableInstance(orderReportTable, {
    data: reportData ?? [],
    columns,
    state: {
      columnOrder,
      pagination,
      rowSelection,
      columnVisibility,
    },
    onColumnOrderChange: setColumnOrder,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, []);

  useEffect(() => {
    let columnVisible: { [key: string]: boolean } = {};

    orderReportDimensionMetrics.forEach(d => {
      columnVisible[`${d.key}`] = d.isSelected;
    });

    setColumnVisibility(columnVisible);
    setColumnOrder(['select'].concat(orderReportDimensionMetrics.map(d => `${d.key}`)));
  }, [orderReportDimensionMetrics]);

  return (
    <React.Fragment>
      <TableComponent
        report
        table={instance}
        setIsHover={setIsHover}
        isDataFetching={isFetching}
        {...{ pagination, setPagination }}
        placeholder="불러올 주문 정보 리포트가 없습니다."
      />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}
    </React.Fragment>
  );
};

export default OrderReportTable;
