import React from 'react';
import { useForm } from 'react-hook-form';
import * as S from './style';

export interface FormProps {
  mode?: 'onChange' | 'onBlur' | 'onSubmit' | 'onTouched' | 'all';
  defaultValues?: any;
  children: any;

  onSubmit: any;
  onError?: any;
}

export const Form = ({ mode, defaultValues, children, onSubmit, onError }: FormProps) => {
  const methods = useForm({ mode, defaultValues });
  const { handleSubmit } = methods;

  return (
    <S.Form onSubmit={handleSubmit(onSubmit, onError)}>
      {React.Children.map(children, child => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
                watch: methods.watch,
                reset: methods.reset,
              },
            })
          : child;
      })}
    </S.Form>
  );
};
