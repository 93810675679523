import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IVehicleList } from 'constants/types';
import {
  createTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useTableInstance,
} from '@tanstack/react-table';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import DeactiveModal from 'components/Modal/DeactiveModal';
import TableComponent from '.';
import strings from 'util/Localization';
import { getVehicleInfo } from 'api';
import { useQuery } from 'react-query';

let vehicleManagementTable = createTable().setRowType<IVehicleList>();

function VehicleManagementTable({
  mutateDeleteVehicleInfo,
  setVehicleModalStatus,
  setVehicleModalIsOpen,
  setCurrentValues,
}: {
  mutateDeleteVehicleInfo: Function;
  setVehicleModalStatus: Dispatch<SetStateAction<'add' | 'modi'>>;
  setVehicleModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setCurrentValues: Dispatch<SetStateAction<any>>;
}) {
  const { data: vehicles, isFetching: getVehicleListIsFetching } = useQuery(['vehicles'], getVehicleInfo, {});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100000,
  });

  const [targetVehicle, setTargetVehicle] = useState(null);

  const [targetVehicleId, setTargetVehicleId] = useState(null);
  const [deleteVehicleModal, setDeleteVehicleModalOpen] = useState<boolean>(false);

  const columns = useMemo(
    () => [
      vehicleManagementTable.createDataColumn(row => row.vehicleId, {
        id: 'vehicleId',
        header: () => '차량ID',
        enableSorting: true,
        enableMultiSort: true,
      }),
      vehicleManagementTable.createDataColumn(row => row.licenseNumber, {
        id: 'license_number',
        header: () => '차량번호',
        enableSorting: true,
        enableMultiSort: true,
      }),
      vehicleManagementTable.createDataColumn(row => row.model.name, {
        id: 'model_name',
        header: () => '차량 타입(중량)',
        enableSorting: false,
      }),
      vehicleManagementTable.createDataColumn(row => row.maxCapacity, {
        id: 'model_max_capacity',
        header: () => '최대 용적 수량',
        enableSorting: true,
        enableMultiSort: true,
      }),
      vehicleManagementTable.createDataColumn(row => (row?.memo ? row.memo : '-'), {
        id: 'memo',
        header: () => '차량 메모',
        enableSorting: false,
      }),
      vehicleManagementTable.createDataColumn(row => row.startAddress, {
        id: 'det4ails',
        header: () => '출발지 주소',
        enableSorting: false,
      }),
      vehicleManagementTable.createDataColumn(row => (row?.endAddress ? row.endAddress : '-'), {
        id: 'det6ails',
        header: () => '도착지 주소',
        enableSorting: false,
      }),

      vehicleManagementTable.createDisplayColumn({
        id: 'modify',
        header: () => '수정',
        cell: ({ row }) => (
          <Button
            variant={'second'}
            type={'button'}
            sx={{ padding: '3px 6px' }}
            onClick={() => {
              setVehicleModalStatus('modi');
              setCurrentValues(row.original);
              setVehicleModalIsOpen(true);
            }}
          >
            <Text styleName="caption2" color="RG04">
              수정
            </Text>
          </Button>
        ),
        enableSorting: false,
      }),
      vehicleManagementTable.createDisplayColumn({
        id: 'manage',
        header: () => '관리',
        cell: ({ row }) => (
          <Button
            variant={'second'}
            type={'button'}
            sx={{ padding: '3px 6px' }}
            onClick={() => {
              setTargetVehicle(row.getValue('license_number'));
              setTargetVehicleId(row.getValue('vehicleId'));
              setDeleteVehicleModalOpen(true);
            }}
          >
            <Text styleName="caption2" color="RG04">
              삭제
            </Text>
          </Button>
        ),
        enableSorting: false,
      }),
    ],
    []
  );

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [vehicles?.vehicleList]);

  const instance = useTableInstance(vehicleManagementTable, {
    data: vehicles?.vehicleList ?? [],
    columns,
    state: {
      sorting,
      columnVisibility: {
        vehicleId: false,
      },
    },
    initialState: {
      sorting: [
        {
          id: 'license_number',
          desc: false,
        },
        {
          id: 'model_max_capacity',
          desc: true,
        },
      ],
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <React.Fragment>
      <TableComponent
        table={instance}
        isDataFetching={getVehicleListIsFetching}
        disableOptions={{
          totalCount: true,
          selectCount: true,
          pagnation: true,
          pageSizeSelector: true,
        }}
        {...{ pagination, setPagination }}
      />

      <DeactiveModal
        isOpen={deleteVehicleModal}
        setIsOpen={setDeleteVehicleModalOpen}
        target={`${targetVehicle}`}
        text={'선택하신 차량을 삭제하시겠습니까?'}
        highlight={
          <span>
            이후 해당 차량 배차가 불가하며,
            <br />
            일부 {strings.드라이버}의 차량 재설정이 필요합니다.
          </span>
        }
        returnFunc={() => mutateDeleteVehicleInfo({ target: targetVehicleId })}
        RBT={'차량 삭제'}
        LBT={'취소 (차량 유지)'}
      />
    </React.Fragment>
  );
}

export default VehicleManagementTable;
