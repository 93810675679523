import React from 'react';
import { useDroppable } from '@dnd-kit/core';

function DroppableWrapper({ children, id, sx }: { children: React.ReactNode; id: string; sx?: React.CSSProperties }) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div ref={setNodeRef} style={sx}>
      {children}
    </div>
  );
}

export default DroppableWrapper;
