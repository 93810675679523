import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IcRoouty } from 'roouty_logo_white.svg';

const PlainHeader = () => {
  let navigate = useNavigate();
  return (
    <Header>
      <Container>
        <IcRoouty
          onClick={() => navigate('/')}
          style={{
            cursor: 'pointer',
            width: '104px',
            height: '24px',
            margin: '0 0 0 30px',
          }}
        />
      </Container>
    </Header>
  );
};

export default PlainHeader;

const Header = styled.div`
  width: 100%;

  height: 72px;
  padding: 24px 30px;

  background-color: ${({ theme }) => theme.colors.RG02};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;
