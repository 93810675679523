import axios from 'axios';
import {
  ILoginResponses,
  ILoginSubmitForm,
  IGetUnassignedOrdersResponses,
  IGetMemberList,
  IAddOrder,
  IDeleteOrder,
  IGetMyInfoResponses,
  ISetMyInfo,
  IgetDriverInfoResponses,
  ISetDriverInfo,
  IDeactivateMember,
  IGetAdminInfoResponses,
  ISetAdminInfo,
  IGetCompanyInfoResponses,
  ISetCompanyInfo,
  IGetVehicleInfo,
  IAddVehicle,
  IDeleteVehicleInfo,
  ISetVehicleInfo,
  IDeleteUnassignedOrder,
  IGetDownloadOrderList,
  IGetDownloadOrderListResponses,
  IGetOrderListResponses,
  IGetOrderList,
  IGetOrderDetail,
  IGetOrderDetailResponses,
  ISetOrderDetail,
  IAddSingleOrder,
  IGetUnassignedOrders,
  IGetRouteListResponses,
  IEqualOptimize,
  IEqualOptimizeResponses,
  IGetRouteDetailResponses,
  ICancelRouteOrder,
  IGetVehicleModelResponses,
  IInviteMember,
  IRunRecalculateOptimize,
  IRunRecalculateOptimizeResponses,
  IRegisterRoute,
  IOrderListDownload,
  IOrderListDownloadResponses,
  IUpsertOrder,
  ISignupForm,
  IGetControlRouteResponses,
  IGetControlRouteListByDateResponses,
  IsetBatchOrders,
  IGetInvestigatorListResponses,
  ISetInvestigatorProps,
  IGetLocationTopicList,
  IHistoryList,
  IAddSingleOrderAttr,
  ImanualExcelValidateResponses,
  IReportResponses,
  IOrderReportMetrics,
  IReportQuery,
  IRouteReportMetrics,
  IOrderReportDimensionRoute,
  IRouteReportDimensionRoute,
  ImanualRouting,
} from 'constants/types';
import { baseAPI, request } from './instance';

export const csAlert = (
  // - historyId: error response에 담겨있던 historyId
  // - uri: error가 발생했던 요청 uri
  // - type: 유형
  //     - algorithmError
  props: { historyId: string; uri: string; type: string }
) => {
  return request({ url: '/api/cs', params: { ...props } });
};

export const getServiceDomain = (): Promise<{ type: string }> => {
  return request({ url: '/company/authority' });
};

// auth API Func
export const login = async (props: ILoginSubmitForm): Promise<ILoginResponses> => {
  const { data } = await baseAPI.post('/auth/signin', { ...props, loginType: 'pc' });
  return data;
};

export const logout = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.reload();
  return request({ url: '/auth/signout', method: 'PUT' });
};

export const signup = (props: ISignupForm) => {
  return request({ url: '/auth/signup', method: 'POST', data: { ...props } });
};
export const checkSignupPsLink = (urlKey: string) => {
  return request({ url: `/auth/signup/check/${urlKey}` });
};
export const signupPs = (urlKey: string, password: string) => {
  return request({ url: `/auth/signup/${urlKey}`, method: 'PUT', data: { password } });
};
export const resendSignupPsLink = (loginAccount: string) => {
  return request({ url: '/auth/resend', method: 'POST', data: { loginAccount } });
};

export const postPsLink = (userId: number) => {
  return request({ url: `/member/password/reset/${userId}`, method: 'POST' });
};
export const checkPsLink = (urlKey: string) => {
  return request({ url: `/member/password/check/${urlKey}` });
};
export const resetPassword = (urlKey: string, password: string) => {
  return request({ url: `/member/password/change/${urlKey}`, method: 'PUT', data: { password } });
};

//
// orders API Func
export const getUnassignedOrders = (props: IGetUnassignedOrders): Promise<IGetUnassignedOrdersResponses> => {
  return request({ url: '/order/list/optimize', headers: { 'Content-Type': 'url-query' }, params: { ...props } });
};

export const getMemberList = (props: IGetMemberList) => {
  return request({
    url: `/member/list?grade=${props.grade}`,
    headers: { 'Content-Type': 'url-query' },
  });
};

export const addOrder = ({
  config,
  props,
}: {
  config: {
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    onDownloadProgress: (progressEvent: ProgressEvent) => void;
  };
  props: IAddOrder;
}) => {
  return request({
    url: '/order',
    method: 'POST',
    data: { ...props },
    ...config,
  });
};

export const deleteOrder = (props: IDeleteOrder) => {
  return request({ url: '/order/delete', method: 'PUT', data: { ...props } });
};

export const deleteUnassignedOrder = (props: IDeleteUnassignedOrder) => {
  return request({ url: '/order/delete', method: 'PUT', data: { ...props } });
};

export const getDownloadOrderList = (props: IGetDownloadOrderList): Promise<IGetDownloadOrderListResponses> => {
  return request({
    url: '/order/list/download',
    method: 'GET',
    headers: { 'Content-Type': 'url-query' },
    params: { ...props },
  });
};

export const getOrderListDownload = (props: IOrderListDownload): Promise<IOrderListDownloadResponses> => {
  return request({ url: '/order/list/download', method: 'POST', data: { ...props } });
};

export const getOrderList = async (props: IGetOrderList): Promise<IGetOrderListResponses> => {
  return await request({
    url: '/order/list',
    method: 'GET',
    headers: { 'Content-Type': 'url-query' },
    params: { ...props },
  });
};

export const getOrderDetail = ({ target }: IGetOrderDetail): Promise<IGetOrderDetailResponses> => {
  return request({ url: `/order/detail/${target}` });
};

export const setOrderDetail = ({ target, ...props }: ISetOrderDetail) => {
  return request({ url: `/order/${target}`, method: 'PUT', data: { ...props } });
};

export const addSingleOrder = ({
  props,
  config,
}: {
  props: IAddSingleOrderAttr;
  config?: {
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    onDownloadProgress: (progressEvent: ProgressEvent) => void;
  };
}) => {
  return request({
    url: '/order',
    method: 'POST',
    data: { ...props },
    ...config,
  });
};

// export const upsertOrder = ({
//   props,
//   config,
// }: {
//   props: IUpsertOrder;
//   config?: {
//     onUploadProgress: (progressEvent: ProgressEvent) => void;
//     onDownloadProgress: (progressEvent: ProgressEvent) => void;
//   };
// }) => {
//   return request({ url: '/order/upsert', method: 'POST', data: { ...props }, ...config });
// };

export const uploadExcel = ({
  file,
  config,
}: {
  file: any;
  config?: {
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    onDownloadProgress: (progressEvent: ProgressEvent) => void;
  };
}): Promise<{ erroredExcel?: string; failureCount: number; successCount: number }> => {
  return request({
    url: '/order/excel',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
    ...config,
  });
};

export const manualExcelValidate = ({
  file,
  config,
}: {
  file: any;
  config?: {
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    onDownloadProgress: (progressEvent: ProgressEvent) => void;
  };
}): Promise<ImanualExcelValidateResponses> => {
  return request({
    url: '/manual/validate',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
    ...config,
  });
};

export const manualRouting = (props: ImanualRouting): Promise<{ routeId: number }> => {
  return request({ url: '/manual/route', method: 'POST', data: { ...props } });
};

export const setBatchOrders = (props: IsetBatchOrders) => {
  return request({ url: '/order/batch', method: 'PUT', data: { ...props } });
};

export const skipOrders = (orderList: number[]) => {
  return request({ url: '/order/skip', method: 'PUT', data: { orderList, comment: '관리자 요청으로 보류' } });
};

//
// member / info API Funcs
export const getMyInfo = (): Promise<IGetMyInfoResponses> => {
  return request({ url: '/member/profile/my' });
};

export const setMyInfo = (props: ISetMyInfo) => {
  return request({ url: '/member/profile/my', method: 'PUT', data: { ...props } });
};

export const getDriverInfo = (): Promise<IgetDriverInfoResponses> => {
  return request({ url: '/member/driver/list' });
};

export const setDriverInfo = ({ target, ...props }: ISetDriverInfo) => {
  return request({ url: `/member/driver/${target}`, method: 'PUT', data: { ...props } });
};

// export const deactivateMember = ({ target }: IDeactivateMember) => {
//   return request({ url: `/member/deactivate/${target}`, method: 'DELETE' });
// };

export const getAdminInfo = (): Promise<IGetAdminInfoResponses> => {
  return request({ url: `/member/admin/list` });
};

export const setAdminInfo = ({ target, ...props }: ISetAdminInfo) => {
  return request({ url: `/member/admin/${target}`, method: 'PUT', data: { ...props } });
};

export const getCompanyInfo = (): Promise<IGetCompanyInfoResponses> => {
  return request({ url: `/company` });
};

export const setCompanyInfo = (props: ISetCompanyInfo) => {
  return request({ url: `/company`, method: 'PUT', data: { ...props } });
};

export const addVehicle = (props: IAddVehicle) => {
  return request({ url: `/vehicle`, method: 'POST', data: { ...props } });
};

export const getVehicleInfo = (): Promise<IGetVehicleInfo> => {
  return request({ url: `/vehicle/list` });
};

export const setVehicleInfo = ({ target, ...props }: ISetVehicleInfo) => {
  return request({ url: `/vehicle/${target}`, method: 'PUT', data: { ...props } });
};

export const deleteVehicleInfo = ({ target }: IDeleteVehicleInfo) => {
  return request({ url: `/vehicle/${target}`, method: 'DELETE' });
};

export const getVehicleModel = (): Promise<IGetVehicleModelResponses> => {
  return request({ url: '/vehicle/model/list' });
};

export const activateMember = (userId: number) => {
  return request({ url: `/member/activate/${userId}`, method: 'PUT' });
};
export const deactivateMember = ({ target }: IDeactivateMember) => {
  return request({ url: `/member/deactivate/${target}`, method: 'DELETE' });
};

export const getInvestigatorList = (): Promise<IGetInvestigatorListResponses> => {
  return request({ url: '/member/investigator/list' });
};
export const setInvestigator = ({ target, ...props }: ISetInvestigatorProps) => {
  return request({ url: `/member/investigator/${target}`, method: 'PUT', data: { ...props } });
};

//
// route API Funcs
export const getRouteList = (): Promise<IGetRouteListResponses> => {
  return request({ url: '/route/list' });
};

export const cancelRoute = (routeId: number) => {
  return request({ url: `/route/cancel/${routeId}`, method: 'DELETE' });
};

export const runEqualOptimize = ({
  config,
  props,
}: {
  config: any;
  props: IEqualOptimize;
}): Promise<IEqualOptimizeResponses> => {
  return request({ url: '/route/optimize/equal', method: 'POST', data: { ...props }, ...config });
};

export const runRecalculateOptimize = (props: IRunRecalculateOptimize): Promise<IRunRecalculateOptimizeResponses> => {
  return request({ url: '/route/optimize/edit', method: 'POST', data: { ...props } });
};

export const getRouteDetail = (routeId: number | string): Promise<IGetRouteDetailResponses> => {
  return request({ url: `/route/detail/${routeId}` });
};

export const cancelRouteOrder = ({ routeId, props }: { routeId: number | string; props: ICancelRouteOrder }) => {
  return request({ url: `/order/cancel/${routeId}`, method: 'PUT', data: { ...props } });
};

export const registerRoute = (props: IRegisterRoute) => {
  return request({
    url: '/route/register',
    method: 'POST',
    data: { ...props },
  });
};

export const getRouteOrderListDownload = (routeId: number) => {
  return request({ url: `/route/download/${routeId}` });
};

export const getControlRoute = (routeId: number): Promise<IGetControlRouteResponses> => {
  return request({ url: `/route/control/${routeId}` });
};
export const getControlRouteListByDate = (performedDate: string): Promise<IGetControlRouteListByDateResponses> => {
  // performedDate YYYY-MM-DD
  return request({ url: '/route/when', params: { performedDate } });
};

export const getControlRouteHistory = (routeId: number): Promise<{ historyList: IHistoryList[] }> => {
  return request({ url: `/route/history/${routeId}` });
};

//
// invite API Funcs
export const inviteMember = (props: IInviteMember) => {
  return request({ url: '/member/invite', method: 'POST', data: { ...props } });
};

export const inviteCheck = (urlKey: string) => {
  return request({ url: `/member/invite/check/${urlKey}` });
};

export const inviteAccept = (urlKey: string, password: string) => {
  return request({ url: `/member/invite/accept/${urlKey}`, method: 'PUT', data: { password } });
};

// export const getLocationTopicList = (): Promise<IGetLocationTopicList> => {
export const getLocationTopicList = (): Promise<IGetLocationTopicList> => {
  return axios
    .get(`${process.env.REACT_APP_LOCATION_SERVER_URL}/location/topics/`, {
      headers: { token: window.localStorage.getItem('accessToken') || '' },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
    });
};

// Reporte APIs
export const getRouteReport = (
  props: IReportQuery
): Promise<IReportResponses<IRouteReportDimensionRoute, IRouteReportMetrics>> => {
  return request({ url: '/report/route', params: { ...props } });
};
export const getOrderReport = (
  props: IReportQuery
): Promise<IReportResponses<IOrderReportDimensionRoute, Array<IOrderReportMetrics>>> => {
  return request({ url: '/report/order', params: { ...props } });
};
