import React, { useEffect } from 'react';
import { BoxContainer } from 'components/BoxContainer';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import VehicleManagementTable from 'components/Table/VehicleManagementTable';
import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { addVehicle, deleteVehicleInfo, getVehicleInfo, getVehicleModel, setVehicleInfo } from 'api';
import { IAddVehicle, IVehicleList } from 'constants/types';
import Modal from 'components/Modal';
import { Form } from 'components/Form';
import { Input, Select } from 'components/Input';
import { useForm, useFormState } from 'react-hook-form';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { genLabel } from 'components/Modal/SingleOrderForm';
import getDirtyValues from 'util/getDirtyValues';
import { VehiclePicker } from 'components/Popup/VehiclePicker';

const Vehicle = () => {
  const [vehicleModalIsOpen, setVehicleModalIsOpen] = React.useState<boolean>(false);
  const [vehicleModalStatus, setVehicleModalStatus] = React.useState<'add' | 'modi'>('add');
  const [currentValues, setCurrentValues] = React.useState<any>({});

  const { refetch: refetchVehicle } = useQuery(['vehicles'], getVehicleInfo, {});

  const { mutate: mutateAddVehicle } = useMutation(addVehicle, {
    onSettled(data, error, variables, context) {
      refetchVehicle();
    },
  });

  const { mutate: mutateSetVehicle } = useMutation(setVehicleInfo, {
    onSettled(data, error, variables, context) {
      refetchVehicle();
    },
  });
  const { mutate: mutateDeleteVehicleInfo } = useMutation(deleteVehicleInfo, {
    onSettled(data, error, variables, context) {
      refetchVehicle();
    },
  });

  const mutations = {
    add: mutateAddVehicle,
    modi: mutateSetVehicle,
  };

  return (
    <BoxContainer sx={{ padding: '48px 20px' }} align={'start'} justify={'start'} useVerticalScroll={true}>
      <Stack sx={{ maxWidth: '1024px', margin: '0 auto' }} spacing={40}>
        <Stack name="header_area" spacing={4}>
          <Stack direction="row" justify="space-between" align="flex-start">
            <Text styleName="title1" color="RG03">
              차량 관리
            </Text>
            <Button
              variant={'default'}
              type={'button'}
              sx={{ padding: '9px 28px' }}
              onClick={() => {
                setVehicleModalStatus('add');
                setVehicleModalIsOpen(true);
              }}
            >
              <Text styleName="subheadline2" color="RG00">
                + 신규 차량 등록
              </Text>
            </Button>
          </Stack>

          <Stack spacing={4} align="start">
            <Text styleName="caption3" color="RG03">
              • 드라이버가 사용할 차량 정보를 등록해주세요.
            </Text>
            <Text styleName="caption3" color="RG03">
              • 최대 용적 수량, 차고지 등록 등 차량 별 정보 등록이 가능합니다.
            </Text>
            <Text styleName="caption3" color="RG03">
              • 사용 중인 플랜에 따라 최대 등록 가능 차량이 제한될 수 있습니다.
            </Text>
          </Stack>
        </Stack>

        <VehicleModal
          status={vehicleModalStatus}
          mutate={mutations[vehicleModalStatus]}
          isOpen={vehicleModalIsOpen}
          setIsOpen={setVehicleModalIsOpen}
          currentValues={currentValues}
          setCurrentValues={setCurrentValues}
        />
        <VehicleManagementTable
          {...{ mutateDeleteVehicleInfo, setVehicleModalStatus, setVehicleModalIsOpen, setCurrentValues }}
        />
      </Stack>
    </BoxContainer>
  );
};

export default Vehicle;

export const VehicleModal = ({
  mutate,
  isOpen,
  setIsOpen,
  status,
  currentValues,
  setCurrentValues,
}: {
  mutate: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  status: 'add' | 'modi';
  currentValues: IVehicleList;
  setCurrentValues: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, control } = methods;
  const { dirtyFields } = useFormState({ control });

  const [selectedModel, setSelectedModel] = React.useState<number>(1);

  const [targetInput, setTargetInput] = React.useState<string>('');
  const [vehiclePickerIsOpen, setVehiclePickerIsOpen] = React.useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = React.useState<boolean>(false);

  // RT-857
  useEffect(() => {
    methods.reset();
  }, [status, isOpen]);

  useEffect(() => {
    if (!validateStatus()) {
      methods.setValue('licenseNumber', currentValues?.licenseNumber, { shouldValidate: true });
      methods.setValue('vehicleModelId', currentValues?.model.name, { shouldValidate: true });
      methods.setValue('maxCapacity', currentValues?.maxCapacity, { shouldValidate: true });
      methods.setValue('memo', currentValues?.memo, { shouldValidate: true });
      methods.setValue('startAddress', currentValues?.startAddress, { shouldValidate: true });
      methods.setValue('endAddress', currentValues?.endAddress, { shouldValidate: true });
    }
  }, [currentValues]);

  function validateStatus() {
    if (status === 'add') return true;
    else return false;
  }

  const onSubmit = (data: any) => {
    if (Object.keys(dirtyFields).length > 0) {
      data = getDirtyValues(dirtyFields, data);
      if (data?.vehicleModelId) data.vehicleModelId = selectedModel as number;

      if (!validateStatus()) mutate({ target: currentValues.vehicleId, ...data });
      else mutate(data);
    }
    setIsOpen(false);
  };

  const handlePostcodeComplete = (data: any) => {
    let address = data.address || data.jibunAddress;
    methods.setValue(targetInput, address, { shouldValidate: true, shouldDirty: true });
    setAddressPopupIsOpen(false);
  };

  const onClickAddressInput = (e: React.MouseEvent<HTMLInputElement>) => {
    setTargetInput(e.currentTarget.name);
    setAddressPopupIsOpen(true);
  };
  return (
    <React.Fragment>
      <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} width={550} padding={24}>
        <Stack spacing={30} align="start">
          <Text styleName="title1" color="RG03">
            {validateStatus() ? '등록할 차량 정보를 입력해주세요.' : '수정할 차량 정보를 입력해주세요.'}
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={30}>
              <Stack spacing={20} direction="row">
                <Input
                  name="licenseNumber"
                  placeholder="51가4885"
                  label={genLabel({
                    text: '차량 번호',
                    isRequired: true,
                  })}
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  validation={{ required: { value: true, message: '' } }}
                  field
                  errors={methods.formState.errors.licenseNumber}
                  hasError={methods.formState.errors.licenseNumber}
                />
                <Stack sx={{ position: 'relative' }}>
                  <Input
                    name="vehicleModelId"
                    placeholder="차종을 선택해주세요"
                    label={genLabel({
                      text: '차종',
                      isRequired: true,
                    })}
                    readOnly={true}
                    onClick={() => {
                      setVehiclePickerIsOpen(true);
                    }}
                    field
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    validation={{ required: { value: true, message: '' } }}
                    errors={methods.formState.errors.vehicleModelId}
                    hasError={methods.formState.errors.vehicleModelId}
                  />
                  {vehiclePickerIsOpen && (
                    <VehiclePicker
                      name="vehicleModelId"
                      methods={methods}
                      setSelectedModel={setSelectedModel}
                      isOpen={vehiclePickerIsOpen}
                      setIsOpen={setVehiclePickerIsOpen}
                      setIsHovering={() => {}}
                    />
                  )}
                </Stack>
              </Stack>

              <Input
                name="maxCapacity"
                placeholder="숫자만 입력해주세요."
                label={genLabel({
                  text: '최대 용적수량',
                  isRequired: true,
                })}
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                validation={{
                  required: { value: true, message: '' },
                  pattern: {
                    value: /^[0-9]/,
                    message: '숫자만 입력 가능합니다.',
                  },
                }}
                field
                errors={methods.formState.errors.maxCapacity}
                hasError={methods.formState.errors.maxCapacity}
              />
              <Input
                name="memo"
                placeholder="차량 메모를 남겨주세요. ex. 윙탑"
                label="차량 메모"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
              />
              <Input
                name="startAddress"
                placeholder="주소검색"
                label={genLabel({
                  text: '출발지 주소',
                  isRequired: true,
                })}
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                validation={{ required: { value: true, message: '' } }}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  onClickAddressInput(e);
                }}
                field
                errors={methods.formState.errors.startAddress}
                hasError={methods.formState.errors.startAddress}
              />
              <Input
                name="endAddress"
                placeholder="주소검색"
                label="도착지 주소"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  onClickAddressInput(e);
                }}
              />

              <Stack align="end" sx={{ width: '100%' }}>
                <Button variant={'default'} type={'submit'} sx={{ padding: '9px 37.5px' }}>
                  <Text styleName="subheadline2" color="RG00">
                    {validateStatus() ? '차량 등록하기' : '수정 완료'}
                  </Text>
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Modal>

      <Modal
        isModalOpen={addressPopupIsOpen}
        setIsModalOpen={setAddressPopupIsOpen}
        title={'우편번호찾기'}
        width={532}
        ms={30}
        padding={20}
      >
        <DaumPostcodeEmbed
          onComplete={handlePostcodeComplete}
          style={{ width: '100%', height: '500px', marginBottom: '0' }}
        />
      </Modal>
    </React.Fragment>
  );
};
