import React, { SetStateAction } from 'react';

import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import { UseFormReturn } from 'react-hook-form';

const GridButtonFilter = ({
  name,
  content,
  status,
  setStatus,
  methods,
}: {
  name: string;
  content: Array<{ [key: string]: any }>;
  status: boolean[];
  setStatus: React.Dispatch<SetStateAction<boolean[]>>;

  methods: UseFormReturn<any>;
}) => {
  const updateStatus = (index: number) => {
    let items = [...status];

    if (index === 0) {
      items[0]
        ? (items = [false, true, ...new Array(content.length - 2).fill(false)])
        : (items = [true, ...new Array(content.length - 1).fill(false)]);

      // ? (items = [false, ...new Array(content.length - 1).fill(false)])
      // : (items = [true, ...new Array(content.length - 1).fill(true)]);

      // items = [false, true, ...new Array(content.length - 2).fill(true)];
      setStatus(items);
    } else {
      items[index] = !items[index];

      let activated = items.slice(1, items.length).filter(x => x).length;
      items[0] = activated === babis.length;

      if (items[0]) {
        for (let i = 1; i < items.length; i++) items = [true, ...new Array(content.length - 1).fill(false)];
      }

      setStatus(items);
    }

    let statusArray = '';
    items.forEach((x, index) => {
      if (x && statusArray === '') {
        statusArray = statusArray + content[index].id;
      } else if (x) {
        statusArray = statusArray + ',' + content[index].id;
      }
    });
    methods.setValue(name, statusArray, { shouldDirty: true, shouldValidate: true });

    // 배송중/배송완료 옵션 추가됐을때 위에지우고 주석풀면됨
    // if (index === 0) {
    //   setStatus([false, true, ...new Array(content.length - 2).fill(false)]);
    //   return;
    // }

    // items[index] = !items[index];

    // let isAll = true;
    // for (let i = 1; i < items.length; i++) if (!items[i]) isAll = false;
    // items[0] = isAll;

    // if (isAll) setStatus([false, true, ...new Array(content.length - 2).fill(false)]);
    // else setStatus(items);
  };

  let babis = content.filter(d => d.id !== 'all');
  const contentNodes = Array.from({ length: Math.floor(babis.length / 2) + (babis.length % 2) }, (v, i) => i + 1);

  return (
    <React.Fragment>
      {/* <Stack direction={'row'} spacing={12}>
    <Button
      variant={status[0] ? 'sixth' : 'second'}
      type={'button'}
      fullWidth={true}
      height={32}
      onClick={() => {
        updateStatus(0);
      }}
    >
      <Text styleName={'caption2'} color={status[0] ? 'RC04' : 'RG02'}>
        {content[0].name}
      </Text>
    </Button>
    </Stack> */}

      {contentNodes.map(x => {
        let lIndex = x * 2 - 1,
          rIndex = x * 2;
        return (
          <Stack direction={'row'} spacing={12} key={`omnav_${x}`}>
            <Button
              variant={status[lIndex] ? 'third' : 'second'}
              type={'button'}
              fullWidth={true}
              height={32}
              // disabled={content[lIndex]?.disabled ? content[lIndex].disabled : false}
              // sx={content[lIndex]?.disabled ? { background: '#FFF' } : {}}
              onClick={() => {
                updateStatus(lIndex);
              }}
            >
              <Text styleName={'caption2'} color={status[lIndex] ? 'RC02' : 'RG02'}>
                {content[lIndex].name}
              </Text>
            </Button>
            <Button
              variant={status[rIndex] ? 'third' : 'second'}
              type={'button'}
              fullWidth={true}
              height={32}
              // disabled={content[rIndex]?.disabled ? content[rIndex].disabled : false}
              // sx={content[rIndex]?.disabled ? { background: '#FFF' } : {}}
              onClick={() => {
                updateStatus(rIndex);
              }}
            >
              <Text styleName={'caption2'} color={status[rIndex] ? 'RC02' : 'RG02'}>
                {content[rIndex].name}
              </Text>
            </Button>
          </Stack>
        );
      })}

      <Stack direction={'row'} spacing={12}>
        <Button
          variant={status[0] ? 'third' : 'second'}
          type={'button'}
          fullWidth={true}
          height={32}
          onClick={() => {
            updateStatus(0);
          }}
          sx={{ flex: 1 }}
        >
          <Text styleName={'caption2'} color={status[0] ? 'RC02' : 'RG02'}>
            {content[0].name}
          </Text>
        </Button>
        <div style={{ flex: 1 }}>{''}</div>
      </Stack>
    </React.Fragment>
  );
};

export default GridButtonFilter;
