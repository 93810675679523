import strings from 'util/Localization';

import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { ReactComponent as IcDeliveryFF } from 'constants/icon/ic_delivery_face_fill.svg';
import { ReactComponent as IcDeliveryPF } from 'constants/icon/ic_delivery_path_fill.svg';
import { ReactComponent as IcPickupFF } from 'constants/icon/ic_pickup_face_fill.svg';
import { ReactComponent as IcPickupPF } from 'constants/icon/ic_pickup_path_fill.svg';
import { ReactComponent as IcrotationFF } from 'constants/icon/ic_rotation_face_fill.svg';
import { ReactComponent as IcrotationPF } from 'constants/icon/ic_rotation_path_fill.svg';

strings.setLanguage(localStorage.getItem('serviceDomain') ?? 'roouty');

const shipment_type = [
  {
    label: '배달',
    value: 'delivery',
    icon: {
      pf: IcDeliveryPF,
      ff: IcDeliveryFF,
    },
    visibleType: true,
  },
  {
    label: '수거',
    value: 'pickup',
    icon: {
      pf: IcPickupPF,
      ff: IcPickupFF,
    },
    visibleType: true,
  },

  {
    label: '회차',
    value: 'rotation',
    icon: {
      pf: IcrotationPF,
      ff: IcrotationFF,
    },
    visibleType: false,
  },
];

const statusFilter = [
  {
    name: '전체',
    id: 'all',
  },
  {
    name: strings.배차대기,
    id: 'unassigned',
  },
  {
    name: strings.배차완료,
    id: 'scheduled',
  },
  {
    name: strings.배송중,
    id: 'processing',
    disabled: true,
  },
  {
    name: strings.배송완료,
    id: 'completed',
    disabled: true,
  },
  {
    name: '보류',
    id: 'skipped',
    disabled: true,
  },
  {
    name: '취소',
    id: 'canceled',
  },
];

const shipmentFilter = [
  {
    name: '전체',
    id: 'all',
  },
  {
    name: shipment_type[0].label,
    id: shipment_type[0].value,
  },
  {
    name: shipment_type[1].label,
    id: shipment_type[1].value,
  },
];

const dimension_options_default: TDragablePickerOptions = [
  {
    name: '주행일',
    key: 'performedDate',
    isSelected: true,
  },
  {
    name: '드라이버',
    key: 'driver',
    isSelected: true,
  },
  {
    name: '경로 ID',
    key: 'route_id',
    isSelected: true,
  },
  {
    name: '주행 이름',
    key: 'route_name',
    isSelected: true,
  },
];

const metrics_options: {
  route: TDragablePickerOptions;
  order: TDragablePickerOptions;
} = {
  route: [
    {
      name: '예상 이동 거리 (km)',
      key: 'estimatedDistance',
      isSelected: true,
      default: true,
    },
    {
      name: '실제 이동 거리 (km)',
      key: 'actualDistance',
      isSelected: true,
      default: true,
    },
    {
      name: '예상 이동 시간 (분)',
      key: 'estimatedTime',
      isSelected: true,
      default: true,
    },
    {
      name: '실제 이동 시간 (분)',
      key: 'actualTime',
      isSelected: true,
      default: true,
    },
    {
      name: '총 용적량',
      key: 'totalCapacity',
      isSelected: true,
      default: true,
    },
    {
      name: '실 용적량',
      key: 'actualCapacity',
      isSelected: true,
      default: true,
    },
    {
      name: '주문 집계(개)',
      key: 'totalOrder',
      isSelected: true,
      default: true,
    },

    {
      name: '완료 주문 집계(개)',
      key: 'completedOrder',
      isSelected: true,
    },
    {
      name: '보류 주문 집계(개)',
      key: 'skippedOrder',
      isSelected: false,
    },
    {
      name: 'PoD 집계(개)',
      key: 'podUploaded',
      isSelected: false,
    },

    {
      name: '회차 (횟수)',
      key: 'rotation',
      isSelected: false,
    },
  ],
  order: [
    {
      name: '주문ID',
      key: 'orderCode',
      isSelected: true,
      default: true,
    },

    {
      name: '주문 추가 일자',
      key: 'createdAt',
      isSelected: false,
    },
    {
      name: '주문 유형',
      key: 'shipmentType',
      isSelected: false,
    },

    {
      name: '주소',
      key: 'address',
      isSelected: true,
      default: true,
    },

    {
      name: '상세주소',
      key: 'detailAddress',
      isSelected: true,
      default: true,
    },

    {
      name: '상태',
      key: 'status',
      isSelected: true,
      default: true,
    },

    {
      name: '완료/보류 사유',
      key: 'historyComment',
      isSelected: false,
    },
    {
      name: '고객명',
      key: 'consigneeName',
      isSelected: true,
      default: true,
    },
    {
      name: '작업 소요 시간 (분)',
      key: 'serviceTime',
      isSelected: false,
    },
    {
      name: '총 용적량',
      key: 'capacity',
      isSelected: true,
      default: true,
    },
    {
      name: '실 용적량',
      key: 'actualCapacity',
      isSelected: false,
      default: true,
    },
    {
      name: '실 용적량 차이 사유',
      key: 'capacityComment',
      isSelected: false,
    },
    {
      name: 'PoD 접수 시간 (시:분)',
      key: 'podCreatedAt',
      isSelected: false,
      default: true,
    },
    { name: 'PoD 이미지 (url)', key: 'podUrl', isSelected: false, default: true },
  ],
};
export { shipment_type, statusFilter, shipmentFilter, dimension_options_default, metrics_options };
