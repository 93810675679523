import React, { useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { DriverIdUnSelected, IGetRouteDetailResponses, IGetRouteDetailResponsesOrderList } from 'constants/types';
import { Polyline } from './DeckGL/Polyline';
import './style.css';
import { OrderMarker, StartEndMarker } from './components/CustomMarker';
import { MapConfigDefaultProps, MapConfigKeys, MapConfigOptions } from './MapConfig';
import { initDrawingManager } from './components/DrawingManager';
import { GoogleMapsOverlay } from './DeckGL/GoogleMapsOverlay';

interface RouteHistoryMapProps {
  routeDetail?: IGetRouteDetailResponses;
  selectedDriver?: number;
  selectedOrder: any[];
  setSelectedOrder: React.Dispatch<React.SetStateAction<any[]>>;
}

export function RouteHistoryMap({
  routeDetail,
  selectedOrder,
  setSelectedOrder,
  selectedDriver,
}: RouteHistoryMapProps) {
  const [mapInstance, setMapInstance] = React.useState<any>(null);
  const [mapsInstance, setMapsInstance] = React.useState<any>(null);

  const [overlayInstance, setOverlayInstance] = React.useState<GoogleMapsOverlay | null>(null);
  const [drawingInstance, setDrawingInstance] = React.useState<any>(null);
  const [draggedBound, setDraggedBound] = React.useState<any>(null);

  useEffect(() => {
    if (mapsInstance && mapInstance && routeDetail) {
      let bounds = new mapsInstance.LatLngBounds();

      routeDetail?.driverList.forEach(x => {
        if (selectedDriver === x.driverId || !selectedDriver) {
          x.vehicle &&
            x.vehicle.startCoordinate &&
            bounds.extend({
              lat: x.vehicle.startCoordinate.coordinates[1],
              lng: x.vehicle.startCoordinate.coordinates[0],
            });
          x.vehicle &&
            x.vehicle.endCoordinate &&
            bounds.extend({
              lat: x.vehicle.endCoordinate.coordinates[1],
              lng: x.vehicle.endCoordinate.coordinates[0],
            });

          x.orderList.forEach(o => {
            o.coordinate &&
              bounds.extend({
                lat: o.coordinate?.coordinates[1],
                lng: o.coordinate?.coordinates[0],
              });
          });
        }
      });
      mapInstance.fitBounds(bounds, 120);
    }
  }, [mapsInstance, mapInstance, routeDetail, selectedDriver]);

  useEffect(() => {
    if (draggedBound == null) return;
    let order: IGetRouteDetailResponsesOrderList[] = [];
    routeDetail?.driverList.forEach(y => {
      y.driverId === selectedDriver &&
        y.orderList.forEach(x => {
          if (x.coordinate) {
            let position = { lat: x.coordinate.coordinates[1], lng: x.coordinate.coordinates[0] };
            if (draggedBound.contains(position)) {
              if (selectedOrder.filter(d => d.orderId === x.orderId).length === 0) order.push(x);
            }
          }
        });
    });

    setSelectedOrder([...selectedOrder, ...order]);
    setDraggedBound(null);
  }, [draggedBound]);

  const onDrawingEventCalled = (bound: any) => {
    setDraggedBound(bound);
  };

  function onMapLoaded(map: any, maps: any) {
    if (!mapInstance) setMapInstance(map);
    if (!mapsInstance) setMapsInstance(maps);
    if (!drawingInstance) {
      let drawingManager = initDrawingManager({ map, maps, onEvent: onDrawingEventCalled });
      setDrawingInstance(drawingManager);
    }
    if (!overlayInstance) {
      let overlay = new GoogleMapsOverlay({});
      setOverlayInstance(overlay);
      overlay.setMap(map);
    }
  }

  function selectOrderByMarkerSelect(orderId: number) {
    if (selectedOrder.filter(x => x.orderId === orderId).length > 0) {
      setSelectedOrder(selectedOrder.filter(x => x.orderId !== orderId));
      return;
    }
    let order: IGetRouteDetailResponsesOrderList[] = [];

    routeDetail?.driverList.forEach(y => {
      if (y.driverId === selectedDriver)
        y.orderList.forEach(x => {
          if (x.orderId === orderId) order.push(x);
        });
    });
    setSelectedOrder([...selectedOrder, ...order]);
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={MapConfigKeys}
      options={MapConfigOptions}
      defaultCenter={MapConfigDefaultProps.center}
      defaultZoom={MapConfigDefaultProps.zoom}
      onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
    >
      {routeDetail?.driverList && overlayInstance && (
        <Polyline
          overlay={overlayInstance}
          optimizeDrivers={[]}
          responseDrivers={routeDetail?.driverList}
          selectedDriverId={selectedDriver}
        />
      )}

      {(routeDetail?.driverList || []).map((x, index) => {
        let markers = [];
        x.vehicle &&
          x.vehicle.startCoordinate &&
          markers.push(
            <StartEndMarker
              {...{
                lat: x.vehicle.startCoordinate?.coordinates[1],
                lng: x.vehicle.startCoordinate?.coordinates[0],
                displayType: 'start',
                content: '시작',
                driverName: x.name,
                colorIndex: x.driverId,
                zIndex: -2002,
                alpha:
                  (selectedDriver || DriverIdUnSelected) > DriverIdUnSelected
                    ? selectedDriver === x.driverId
                      ? 1
                      : 0.3
                    : 1,
              }}
            />
          );
        x.vehicle &&
          x.vehicle.endCoordinate &&
          markers.push(
            <StartEndMarker
              {...{
                lat: x.vehicle.endCoordinate?.coordinates[1],
                lng: x.vehicle.endCoordinate?.coordinates[0],
                displayType: 'end',
                content: '종료',
                zIndex: -2003,
                alpha:
                  (selectedDriver || DriverIdUnSelected) > DriverIdUnSelected
                    ? selectedDriver === x.driverId
                      ? 1
                      : 0.3
                    : 1,
              }}
            />
          );

        x.orderList.map(o => {
          o.coordinate &&
            markers.push(
              <OrderMarker
                onClick={() => {
                  selectOrderByMarkerSelect(o.orderId || 0);
                }}
                {...{
                  lat: o.coordinate?.coordinates[1],
                  lng: o.coordinate?.coordinates[0],
                  displayType: 'number',
                  colorIndex: x.driverId,
                  isSelected: selectedOrder.filter(g => g.orderId === o.orderId).length !== 0,
                  zIndex:
                    (selectedDriver || DriverIdUnSelected) > DriverIdUnSelected
                      ? selectedDriver === x.driverId
                        ? 1000
                        : o.route?.displayIndex ?? 1
                      : 1000,
                  content: `${o.route?.displayIndex ?? ''}`,
                  chips: {
                    truck: !!o.skill,
                    time: !!(o.desiredTimeEnd || o.desiredTimeStart),
                  },
                  alpha:
                    (selectedDriver || DriverIdUnSelected) > DriverIdUnSelected
                      ? selectedDriver === x.driverId
                        ? 1
                        : 0.3
                      : 1,
                }}
              />
            );
        });

        return markers;
      })}
    </GoogleMapReact>
  );
}
