import React, { ReactNode, useState } from 'react';
import Calendar, { CalendarProps } from 'react-calendar';
import * as S from './style';

import { ReactComponent as ArrowLeft } from '../../constants/icon/ic_arrowleft20.svg';
import { ReactComponent as ArrowRight } from '../../constants/icon/ic_arrowright20.svg';
import dayjs from 'dayjs';

export interface DateSelectorProps extends Partial<CalendarProps> {
  date: Date | null | undefined | [Date | null, Date | null];
  setDate: React.Dispatch<React.SetStateAction<any>>;
  customWidth?: string;
  allowPastDate?: boolean;
  allowUncheck?: boolean;
}

const currentDate = dayjs();
const leftPad = (value: number) => (value < 10 ? `0${value}` : value.toString());

export function DateSelector({ customWidth, ...props }: DateSelectorProps): React.ReactElement {
  const DateSelectorConfiguration: CalendarProps = {
    ...props,
    locale: 'ko-KR',
    next2Label: null,
    nextLabel: <ArrowRight />,
    prev2Label: null,
    prevLabel: <ArrowLeft />,
    formatDay: (_locale, date) => date.getDate().toString(),
    formatMonthYear: (_locale, date) => `${date.getFullYear()}.${leftPad(date.getMonth() + 1)}`,
    minDetail: 'month',
    calendarType: 'US',
    // onChange: (e: any) => console.log(e),

    onChange: (v: any, e: any) => {
      // RT-816
      let message = '3개월 이내로 검색해주세요.';
      if (dayjs(v).isAfter(dayjs().add(3, 'month'))) return alert(message);
      else if (dayjs(v).isBefore(dayjs().subtract(3, 'month'))) return alert(message);
      else if (dayjs(v).isSame(dayjs(props.date?.toString()))) {
        if (props.allowUncheck) props.setDate(null);
      } else props.setDate(v);
    },
  };

  console.log(props.date);

  return (
    <S.DateSelectorStyle customWidth={customWidth}>
      <Calendar
        {...DateSelectorConfiguration}
        value={props.date}
        minDate={props.allowPastDate ? undefined : currentDate.toDate()}
      />
    </S.DateSelectorStyle>
  );
}
