import {
  PaginationState,
  createTable,
  useTableInstance,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import OrderDetailModal from 'components/Modal/OrderDetailModal';
import { Stack } from 'components/Stack';
import { IUnassignedOrdersList } from 'constants/types';
import React, { SetStateAction, useEffect, useState } from 'react';
import TableComponent from '.';
import { RowDirectionContainer, TableDeatilButton } from './style';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';

import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';
import { ReactComponent as IconPerson } from 'constants/icon/ic_person.svg';
import dayjs from 'dayjs';
import strings from 'util/Localization';
import { shipment_type } from 'constants/commons';
import TableSelectManager from './util/TableSelectManager';

let routePlanManagementTable = createTable().setRowType<IUnassignedOrdersList>();

function RoutePlanManagementTable({
  data,
  isFetching,
  selectedOrder,
  setSelectedOrder,
  rowSelection,
  setRowSelection,
}: {
  data: IUnassignedOrdersList[];
  isFetching: boolean;
  selectedOrder: Array<any>;
  setSelectedOrder: React.Dispatch<SetStateAction<Array<object>>>;
  rowSelection: {};
  setRowSelection: React.Dispatch<React.SetStateAction<{}>>;
}) {
  let domain = window.localStorage.getItem('serviceDomain');

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [target, setTarget] = React.useState<number>();
  const [orderDetailModalOpen, setOrderDetailModalOpen] = React.useState<boolean>(false);

  useEffect(() => {
    let t: any = {};
    selectedOrder.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.order_id === x.order_id);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [selectedOrder, setRowSelection]);

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedOrder.filter(z => z.order_id === y.order_id).length === 0);
      setSelectedOrder([...selectedOrder, ...orders]);
    } else {
      let orders: Array<any> = selectedOrder;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.order_id !== s.original?.order_id);
      });
      setSelectedOrder(orders);
    }
  }

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = React.useMemo(
    () => [
      routePlanManagementTable.createDisplayColumn({
        id: 'select',
        header: ({ instance }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: instance.getIsAllRowsSelected(),
              indeterminate: instance.getIsSomeRowsSelected(),
              onChange: instance.getToggleAllRowsSelectedHandler(),

              instance,
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        enableSorting: false,
      }),
      routePlanManagementTable.createDataColumn(row => dayjs(row.received_date).format('YYYY.MM.DD'), {
        id: 'received_date',
        header: '접수일',
        footer: info => info.column.id,
      }),
      routePlanManagementTable.createDataColumn(
        row => (row.desired_date ? dayjs(row.desired_date).format('YYYY.MM.DD') : '-'),
        {
          id: 'desired_date',
          header: '희망일',
          footer: info => info.column.id,
        }
      ),
      routePlanManagementTable.createDataColumn(row => 1, {
        id: 'types',
        header: '유형',
        cell: info => {
          let rowShipmentType = info.row.original?.shipment_type;

          return (
            <Stack direction="row" sx={{ margin: '0 -9px' }}>
              {info.column
                .getFacetedRowModel()
                .rows.find(d => Boolean(d.original?.desired_time_start) || Boolean(d.original?.desired_time_end)) && (
                <RowDirectionContainer>
                  {(info.row.original?.desired_time_start || info.row.original?.desired_time_end) && (
                    <IconDesiredTime width={20} height={20} />
                  )}
                </RowDirectionContainer>
              )}

              {info.column.getFacetedRowModel().rows.find(d => d.original?.skill) && (
                <RowDirectionContainer>
                  {info.row.original?.skill &&
                    (domain === 'roouty' ? (
                      <IconTruck width={20} height={20} />
                    ) : (
                      <IconPerson width={20} height={20} />
                    ))}
                </RowDirectionContainer>
              )}

              {domain === 'roouty' && (
                <RowDirectionContainer>
                  {React.createElement(
                    shipment_type.find(d => d.value === rowShipmentType)?.icon[
                      // isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
                      'pf'
                    ] ?? 'div',
                    {
                      width: 20,
                      height: 20,
                    }
                  )}
                </RowDirectionContainer>
              )}
            </Stack>
          );
        },
        footer: info => info.column.id,
      }),
      routePlanManagementTable.createDataColumn(row => row.consignee_name, {
        id: 'consigneeName',
        header: '고객명',
        footer: info => info.column.id,
      }),
      routePlanManagementTable.createDataColumn(
        row =>
          (row.address.length > 20 ? row.address.substring(0, 20) + '...' : row.address) +
          (row?.detail_address ? ', ' + row.detail_address : ''),
        {
          id: 'consigneeAddress',
          header: '주소',
          footer: info => info.column.id,
        }
      ),
      routePlanManagementTable.createDisplayColumn({
        id: 'details',
        header: ({ instance }) => strings.주문상세,
        cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableDeatilButton
              onClick={() => {
                row.original?.order_id && setTarget(row.original.order_id);
                setOrderDetailModalOpen(true);
              }}
            >
              ︙
            </TableDeatilButton>
          </div>
        ),
        enableSorting: false,
      }),
    ],
    [anchorIsOpen, domain]
  );

  const instance = useTableInstance(routePlanManagementTable, {
    data,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    onRowSelectionChange: customSetRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [data]);

  return (
    <React.Fragment>
      <TableComponent table={instance} isDataFetching={isFetching} {...{ pagination, setPagination }} />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}
      {target && <OrderDetailModal {...{ target, isOpen: orderDetailModalOpen, setIsOpen: setOrderDetailModalOpen }} />}
    </React.Fragment>
  );
}

export default RoutePlanManagementTable;
