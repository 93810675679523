import React, { useEffect } from 'react';

import { BoxContainer } from 'components/BoxContainer';
import { Highlight, Text } from '../components/Typography';
import { Stack } from '../components/Stack';
import Sidebar from 'components/SidebarLayout/Sidebar';
import PageContentWrapper from 'components/PageContentWrapper';
import RoutePlanManagementTable from 'components/Table/RoutePlanManagementTable';
import dayjs from 'dayjs';
import RoutePlanSetupNav from 'components/SidebarLayout/RoutePlanSetupNav';
import { useQuery } from 'react-query';
import { getUnassignedOrders } from 'api';
import { IGetUnassignedOrders } from 'constants/types';
import { RoutePlanSetupMap } from '../components/MapContainer/RoutePlanSetupMap';
import strings from '../util/Localization';

const RoutePlanSetupPage = () => {
  const {
    data: unassignedOrderList,
    refetch: refetchUnassignedOrderList,
    isFetching: unassignedOrderListIsFetching,
  } = useQuery('getUnassignedOrders', () => getUnassignedOrders({ ...filter }), {
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const [selectedOrder, setSelectedOrder] = React.useState<Array<any>>([]);
  const [filter, setFilter] = React.useState<IGetUnassignedOrders>({
    // RT-815
    received_date: `${dayjs().subtract(1, 'week').format('YYYYMMDD')}-${dayjs().format('YYYYMMDD')}`,
    allow_desired_date_null: false,
  });
  const [targetOrdersId, setTargetOrdersId] = React.useState<Array<number>>([]);
  const [rowSelection, setRowSelection] = React.useState({});

  useEffect(() => {
    setSelectedOrder([]);
    refetchUnassignedOrderList();
  }, [filter]);

  useEffect(() => {
    let res = selectedOrder.map(d => d.order_id);
    setTargetOrdersId(res);
  }, [selectedOrder]);

  return (
    <React.Fragment>
      <Stack align={'flex-start'} direction={'row'}>
        <Sidebar bg="RG08" width="288px" ds="strong" index={2} sx={{ minWidth: '288px' }} padding={'28px 0 30px 0'}>
          <RoutePlanSetupNav {...{ filter, setFilter, targetOrdersId }} />
        </Sidebar>

        <PageContentWrapper>
          <Stack
            useVerticalScroll
            align={'start'}
            sx={{ minWidth: '545px', maxWidth: '881px', margin: '48px 0', width: '45vw' }}
          >
            <BoxContainer align={'start'} justify={'start'} sx={{ height: '100%', padding: '0 20px 0' }}>
              <Stack spacing={48} sx={{ height: '100%' }}>
                <Stack name="header-area" spacing={40} align={'start'}>
                  <Text styleName={'title1'} color={'RG03'}>
                    {strings.배차대기주문}
                  </Text>
                </Stack>
                <Stack name="table-area">
                  <RoutePlanManagementTable
                    data={unassignedOrderList?.order_list ?? []}
                    isFetching={unassignedOrderListIsFetching}
                    {...{ rowSelection, setRowSelection, selectedOrder, setSelectedOrder }}
                  />
                </Stack>
              </Stack>
            </BoxContainer>
          </Stack>

          <Stack sx={{ minWidth: '1px', height: '100%', flex: 1 }}>
            <RoutePlanSetupMap
              unassignedOrderList={unassignedOrderList?.order_list}
              setSelectedOrder={setSelectedOrder}
              selectedOrder={selectedOrder}
            />
          </Stack>
        </PageContentWrapper>
      </Stack>
    </React.Fragment>
  );
};

export default RoutePlanSetupPage;
