// @ts-ignore
import { RGBAColor } from 'deck.gl';

let driverIdsArray: any = {};

export const ColorPalette = {
  default: [
    '#2182BF',
    '#A79CE1',
    '#F69327',
    '#63A212',
    '#6B3C82',
    '#A55283',
    '#435990',
    '#9F872E',
    '#6D85B8',
    '#E44590',
    '#F06D24',
    '#28CAED',
    '#BD63C4',
    '#566D38',
    '#42D86C',
    '#FF70BD',
    '#1C5843',
    '#8266BE',
    '#B17477',
    '#E796BC',
  ],
  startColor: '#1C48CD',
  endColor: '#25282F',
  newColor: '#769DFF',
  selectedNewColor: '#406EE2',
  getColor: function (index: number) {
    // DriverId를 기본 키로 사용하지 않고, 각 DriverId마다 다른 색상값 순차적으로 부여
    if (!driverIdsArray[index]) {
      let currentIndex = Object.keys(driverIdsArray).length;
      driverIdsArray[index] = ColorPalette.default[currentIndex % ColorPalette.default.length];
    }
    return driverIdsArray[index];
  },
};

export function hexToRgb(hex: string, alpha: number = 255): RGBAColor {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), alpha] : [0, 0, 0, alpha];
}
