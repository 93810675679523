import { IEqualOptimizeResponsesDriver } from '../constants/types';
import _ from 'lodash';
import dayjs from 'dayjs';

export const getDriverSummary = (driver: IEqualOptimizeResponsesDriver) => {
  let filteredDriver = _.cloneDeep(driver);

  // RT-1025 리스트에 점심시간과 도착지 까지의 정보를 포함하도록 변경 예정
  // filteredDriver.orderList = filteredDriver.orderList.filter(x => x.type === 'order');
  return {
    h: Math.floor(
      Math.floor(
        filteredDriver.orderList
          .map(d => d.route?.external?.requiredTime ?? 0)
          .reduce((prev, current) => prev + current, 0) / 60
      ) / 60
    ),
    m: Math.floor(
      Math.floor(
        filteredDriver.orderList
          .map(d => d.route?.external?.requiredTime ?? 0)
          .reduce((prev, current) => prev + current, 0) / 60
      ) % 60
    ),
    d: (
      filteredDriver.orderList
        .map(d => d.route?.external?.requiredDistance ?? 0)
        .reduce((prev, current) => prev + current, 0) / 1000
    ).toFixed(1),
  };
};

export const getTotalReqTime = ({
  performedDate,
  workStartTime,
  lastOrderETA,
}: {
  performedDate: string;
  workStartTime: string;
  lastOrderETA: string;
}) => {
  const separator = performedDate.replace(/[0-9]/g, '').charAt(0);

  let routeStartTime = dayjs(
    new Date(
      Number(performedDate?.split(separator)[0]),
      Number(performedDate?.split(separator)[1]) - 1,
      Number(performedDate?.split(separator)[2]),
      Number(workStartTime?.split(':')[0]),
      Number(workStartTime?.split(':')[1]),
      0
    )
  ).utcOffset('+0900');

  let totalReqTime = Math.abs(routeStartTime.diff(dayjs(lastOrderETA), 's'));

  return { routeStartTime, totalReqTime };
};
