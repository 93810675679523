import React, { SetStateAction, useCallback, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useForm, useFormState } from 'react-hook-form';

import { useStore } from 'store';
import { getOrderList, getVehicleInfo, setBatchOrders } from 'api';
import { IGetOrderListResponses, IsetBatchOrdersProps } from 'constants/types';

import Modal from '.';
import { Swap } from 'components/Swap';
import Divider from 'components/Divider';
import { Input } from 'components/Input';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Highlight, Text } from 'components/Typography';
import { DetailModiForm } from './OrderDetailModal';

import { genLabel } from './SingleOrderForm';

import dayjs from 'dayjs';
import getDirtyValues from 'util/getDirtyValues';
import { SkillPicker } from 'components/Popup/SkillPicker';
import _ from 'lodash';
import WorkTimeSelectPopup from 'components/Popup/WorkTimeSelect';
import dateConverter from 'util/dateConverter';
import DatePickerPopup from 'components/Popup/DatePicker';
import strings from '../../util/Localization';
import {
  BatchOrdersDetailTable,
  BatchOrdersDetailTableContainer,
  BatchOrdersDetailTd,
  BatchOrdersDetailTh,
  OrderDetailModalBody,
  OrderDetailModalFooter,
  OrderDetailModalHeader,
} from './style';

import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';
import { shipment_type } from 'constants/commons';

const BatchOrdersModifyingModal = ({
  targetOrderList,
  batchOrdersModifyingModalIsOpen,
  setBatchOrdersModifyingModalIsOpen,
}: {
  targetOrderList: Array<number>;
  batchOrdersModifyingModalIsOpen: boolean;
  setBatchOrdersModifyingModalIsOpen: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const domain = window.localStorage.getItem('serviceDomain');

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: { shipmentType: '', serviceTime: '', desiredDate: '', skill: '' },
  });
  const { handleSubmit, control } = methods;
  const { dirtyFields } = useFormState({
    control,
  });
  const { getOrderListProps } = useStore();

  const [modifyingConfirm, setModifyingConfirm] = useState<boolean>(false);
  const [modifiedContents, setModifiedContents] = useState<any[]>([]);

  const [skillSearchValue, setSkillSearchValue] = useState<string>('');
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [workTimePopupIsOpen, setWorkTimePopupIsOpen] = useState<boolean>(false);
  const [desiredDatePopupIsOpen, setDesiredDatePopupIsOpen] = useState<boolean>(false);

  const { refetch: refetchOrderList } = useQuery<IGetOrderListResponses>(
    ['getOrderList', getOrderListProps],
    () => getOrderList(getOrderListProps),
    {
      keepPreviousData: true,
      refetchInterval: 300000,
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: vehicles,
    isSuccess: getVehicleListIsSuccess,
    refetch: refetchVehicle,
  } = useQuery(['vehicles'], getVehicleInfo, {});

  const { mutate: mutateSetBatchOrders } = useMutation(setBatchOrders, {
    onSuccess: () => {
      refetchOrderList();
    },
  });

  const onSubmit = (data: IsetBatchOrdersProps) => {
    let res = getDirtyValues(dirtyFields, data) as IsetBatchOrdersProps;

    if (Object.keys(res).length)
      mutateSetBatchOrders({
        ...res,
        serviceTime: res.serviceTime && parseInt((res.serviceTime as string).replace(/\D/g, '')) * 60,
        desiredDate: res.desiredDate && dateConverter(res?.desiredDate, 'YYYY-MM-DD'),
        orderList: targetOrderList,
      });

    setBatchOrdersModifyingModalIsOpen(false);
  };

  function valueConverter(value: string) {
    if (value === 'skill') return strings.특정차량지정;
    if (value === 'desiredDate') return strings.작업희망일;
    if (value === 'serviceTime') return strings.작업소요시간분;
    if (value === 'shipmentType') return strings.주문 + '유형';
    else return value;
  }

  const serviceTimeOnCahnge = useCallback(
    _.debounce(function (e) {
      if (e.target.value !== '' && /[0-9]/g.test(e.target.value))
        methods.setValue('serviceTime', e.target.value.replaceAll(/\D/g, '').concat('분'), {
          shouldDirty: true,
          shouldValidate: true,
        });
      else
        methods.setValue('serviceTime', '', {
          shouldDirty: true,
          shouldValidate: true,
        });
    }, 500),
    []
  );

  const onClickHandler = (data: any) => {
    let dirtyValues: any = getDirtyValues(dirtyFields, { ...data });

    if (Object.values(dirtyValues).filter(d => Boolean(d)).length > 0) {
      let keys = Object.keys(dirtyValues).map(d => {
        return valueConverter(d);
      });

      if (Object.keys(dirtyValues).includes('note')) {
        dirtyValues.note
          .filter((d: any) => Boolean(d))
          .forEach((d: any, index: number) => keys.push(`비고 ${index + 1}`));
      }

      if (dirtyValues?.desiredDate)
        if (dayjs(dateConverter(dirtyValues.desiredDate, 'YYYY-MM-DD')).isBefore(dayjs().subtract(1, 'day'))) {
          return methods.setError(
            'desiredDate',
            {
              type: 'invalidDateRange',
              message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
            },
            { shouldFocus: true }
          );
        }

      if (dirtyValues?.shipmentType) {
        dirtyValues.shipmentType = shipment_type.find(d => d.value === dirtyValues?.shipmentType)?.label;
      }

      console.log(dirtyValues);

      let res = Object.entries(dirtyValues)
        .filter(d => Boolean(d[1]))
        .map(d => {
          let r = { [valueConverter(d[0])]: d[1] };
          return r;
        });

      setModifiedContents(res);

      setModifyingConfirm(true);
    } else setBatchOrdersModifyingModalIsOpen(false);
  };

  useEffect(() => {
    methods.reset();

    if (domain === 'roouty')
      methods.setValue('shipmentType', shipment_type[0].value, {
        shouldDirty: true,
      });
    setModifyingConfirm(false);
  }, [methods, batchOrdersModifyingModalIsOpen]);

  return (
    <Modal
      isModalOpen={batchOrdersModifyingModalIsOpen}
      setIsModalOpen={setBatchOrdersModifyingModalIsOpen}
      plain
      width={modifyingConfirm ? 503 : 496}
      ms={0}
      // ms={modifyingConfirm ? 0 : 30}
    >
      {modifyingConfirm ? (
        <React.Fragment>
          <OrderDetailModalHeader>
            <Stack direction="row" justify="end">
              <IconClose
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setBatchOrdersModifyingModalIsOpen(false);
                }}
              />
            </Stack>
          </OrderDetailModalHeader>
          <Stack spacing={16} align="start" sx={{ padding: '20px 24px' }}>
            <Text
              styleName="subheadline3"
              color="RG03"
              sx={{ flexDirection: 'column', textAlign: 'start', alignItems: 'flex-start' }}
            >
              선택하신 {strings.모달_주문} {targetOrderList.length} 건을 일괄수정 하시겠습니까?
              <br />
              <Highlight styleName="subheadline3" color="RC04">
                일괄 변경에서 선택한 {strings.모달_주문} 정보가 모두 아래와 같이 변경됩니다.
                <br />
                일괄 변경 이후에는 변경 전 정보로 되돌릴 수 없습니다.
              </Highlight>
            </Text>

            <BatchOrdersDetailTableContainer>
              <BatchOrdersDetailTable>
                <thead>
                  <tr>
                    <BatchOrdersDetailTh colSpan={125}>변경항목</BatchOrdersDetailTh>
                    <BatchOrdersDetailTh colSpan={331}>변경내용</BatchOrdersDetailTh>
                  </tr>
                </thead>
                <tbody>
                  {modifiedContents.map((d, index) => {
                    console.log(Object.entries(d)[0]);

                    if (Object.entries(d)[0][0] === 'note') {
                      return (Object.entries(d)[0][1] as Array<string>).map((c: string, index: number) => {
                        return Boolean(c) ? (
                          <tr>
                            <BatchOrdersDetailTd disabled colSpan={125}>
                              비고{index + 1}
                            </BatchOrdersDetailTd>
                            <BatchOrdersDetailTd disabled colSpan={331}>
                              {c}
                            </BatchOrdersDetailTd>
                          </tr>
                        ) : null;
                      });
                    } else
                      return (
                        <tr>
                          <BatchOrdersDetailTd disabled colSpan={125}>
                            {`${Object.entries(d)[0][0]}`}
                          </BatchOrdersDetailTd>
                          <BatchOrdersDetailTd disabled colSpan={331}>
                            {`${Object.entries(d)[0][1]}`}
                          </BatchOrdersDetailTd>
                        </tr>
                      );
                  })}
                </tbody>
              </BatchOrdersDetailTable>
            </BatchOrdersDetailTableContainer>

            <Text styleName="subheadline3" color="RG03" sx={{ margin: '30px 0 0 0' }}>
              변경하려는 내용이 맞으면 확인을 눌러주세요.
            </Text>
          </Stack>

          <OrderDetailModalFooter>
            <Stack direction="row" spacing={16}>
              <Button
                variant={'second'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  setModifyingConfirm(false);
                }}
              >
                <Text styleName="body2" color="RG04">
                  취소
                </Text>
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={handleSubmit(onSubmit)}
              >
                <Text styleName="body2" color="RG00">
                  확인
                </Text>
              </Button>
            </Stack>
          </OrderDetailModalFooter>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <OrderDetailModalHeader>
            <Stack direction="row" justify="space-between">
              <Text styleName="subheadline2" color="RG03">
                {strings.주문정보일괄수정}
              </Text>
              <IconClose
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setBatchOrdersModifyingModalIsOpen(false);
                }}
              />
            </Stack>
          </OrderDetailModalHeader>
          <Divider color="RG06" />
          <OrderDetailModalBody useVerticalScroll sx={{ width: '100%' }}>
            <Stack
              sx={{
                maxHeight: 558,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack name="content-area">
                  <DetailModiForm title={strings.배차정보}>
                    <Stack sx={{ position: 'relative' }}>
                      <Input
                        label={strings.작업희망일}
                        name="desiredDate"
                        placeholder={dayjs().format('YYMMDD')}
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                        readOnly
                        onClick={() => {
                          setDesiredDatePopupIsOpen(true);
                        }}
                        onChange={() => {
                          setDesiredDatePopupIsOpen(false);
                        }}
                        errors={methods.formState.errors.desiredDate}
                        hasError={methods.formState.errors.desiredDate}
                      />
                      {desiredDatePopupIsOpen && (
                        <DatePickerPopup
                          setIsOpen={setDesiredDatePopupIsOpen}
                          setValue={methods.setValue}
                          initDate={dayjs().toString()}
                          name={'desiredDate'}
                          banPast
                          top="80px"
                          format="YYMMDD"
                        />
                      )}
                    </Stack>
                    <Stack spacing={16} direction="row">
                      <Stack sx={{ position: 'relative' }}>
                        <Input
                          label={strings.작업소요시간분}
                          name="serviceTime"
                          placeholder="숫자만 입력"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          onChange={(e: any) => {
                            setWorkTimePopupIsOpen(false);
                            serviceTimeOnCahnge(e);
                          }}
                          onClick={() => {
                            setWorkTimePopupIsOpen(true);
                          }}
                        />
                        {workTimePopupIsOpen && (
                          <WorkTimeSelectPopup
                            setIsOpen={setWorkTimePopupIsOpen}
                            setValue={methods.setValue}
                            name={'serviceTime'}
                          />
                        )}
                      </Stack>
                      <Stack sx={{ position: 'relative' }}>
                        <Input
                          name="skill"
                          label={strings.특정차량지정}
                          placeholder={strings.차량번호입력}
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{
                            validate: (res: any) =>
                              !Boolean(res) ||
                              (vehicles && vehicles.vehicleList.filter(d => d.licenseNumber === res).length > 0) ||
                              '존재하지 않는 차량입니다.',
                          }}
                          onChange={(e: any) => {
                            setSkillPopupIsOpen(true);
                            setSkillSearchValue(e.target.value);
                            methods.setValue('skill', e.target.value, { shouldDirty: true, shouldValidate: true });
                          }}
                          onClick={() => {
                            setSkillPopupIsOpen(true);
                          }}
                          hasError={methods.formState.errors.skill}
                        />

                        {skillPopupIsOpen && (
                          <SkillPicker
                            setIsOpen={setSkillPopupIsOpen}
                            methods={methods}
                            name={'skill'}
                            searchValue={skillSearchValue}
                          />
                        )}
                      </Stack>
                    </Stack>

                    {methods.formState.errors?.skill && (
                      <Stack align="end">
                        <Text styleName="caption3" color="RC04">
                          {methods.formState.errors?.skill.message ?? ''}
                        </Text>
                      </Stack>
                    )}
                  </DetailModiForm>
                  <Divider color="RG06" />
                  {domain === 'roouty' && (
                    <React.Fragment>
                      <DetailModiForm title={strings.주문정보}>
                        <Swap
                          name="shipmentType"
                          methods={methods}
                          label={genLabel({
                            text: '주문 유형',
                            subText: `${strings.주문} 1개당 배달  또는 수거만 설정 가능`,
                          })}
                          sx={{ flex: 2 }}
                          maxWidth={446}
                          options={shipment_type.filter(d => d.visibleType)}
                        />
                      </DetailModiForm>
                      <Divider color="RG06" />
                    </React.Fragment>
                  )}
                  <DetailModiForm title="기타 정보">
                    <Stack spacing={10}>
                      {['', '', '', '', ''].map((d: any, index: any) => {
                        return (
                          <Input
                            key={`note[${index}]`}
                            name={`note[${index}]`}
                            label={`비고${index + 1}`}
                            placeholder={'-'}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            height={40}
                          />
                        );
                      })}
                    </Stack>
                  </DetailModiForm>
                  <Divider color="RG06" />
                </Stack>
              </form>
            </Stack>
          </OrderDetailModalBody>
          <OrderDetailModalFooter>
            <Stack
              name="batch-orders-modifying-modal-footer"
              spacing={16}
              direction="row"
              align="center"
              sx={{ padding: '2px 0 0 0' }}
            >
              <Button
                variant="fourth"
                type="button"
                height={40}
                width={124}
                styleName="body2"
                color="RG04"
                onClick={() => {
                  setBatchOrdersModifyingModalIsOpen(false);
                }}
              >
                취소
              </Button>
              <Button
                variant="default"
                type="button"
                fullWidth
                height={40}
                styleName="body2"
                color="RG00"
                onClick={handleSubmit(onClickHandler)}
              >
                수정 완료
              </Button>
            </Stack>
          </OrderDetailModalFooter>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default BatchOrdersModifyingModal;
