export function initDrawingManager({map, maps, onEvent}: {map: any, maps: any, onEvent: (bound: any) => void}): any {
    const drawingManager = new maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
            position: maps.ControlPosition.TOP_CENTER,
            drawingModes: [
                maps.drawing.OverlayType.CIRCLE,
                maps.drawing.OverlayType.POLYGON,
                maps.drawing.OverlayType.RECTANGLE,
            ],
        },
        circleOptions: {
            fillColor: "#769DFF",
            fillOpacity: 0.1,
            strokeWeight: 2,
            strokeColor: "#769DFF",
            clickable: false,
            editable: false,
            zIndex: 1,
        },
        polygonOptions: {
            fillColor: "#769DFF",
            fillOpacity: 0.1,
            strokeWeight: 2,
            strokeColor: "#769DFF",
            clickable: false,
            editable: false,
            zIndex: 1,
        },
        rectangleOptions: {
            fillColor: "#769DFF",
            fillOpacity: 0.1,
            strokeWeight: 2,
            strokeColor: "#769DFF",
            clickable: false,
            editable: false,
            zIndex: 1,
        },
    });
    drawingManager.setMap(map);


    maps.Circle.prototype.contains = function(latLng: any) {
        return this.getBounds().contains(latLng) && maps.geometry.spherical.computeDistanceBetween(this.getCenter(), latLng) <= this.getRadius();
    }
    maps.Rectangle.prototype.contains = function(latLng: any) {
        return this.getBounds().contains(latLng)
    }
    maps.Polygon.prototype.contains = function(latLng: any) {
        return maps.geometry.poly.containsLocation(latLng, this)
    }

    maps.event.addListener(drawingManager, 'circlecomplete', function(circle: any) {
        circle.setMap(null)
        onEvent(circle)
    });

    maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon: any) {
        polygon.setMap(null)
        onEvent(polygon)
    });

    maps.event.addListener(drawingManager, 'rectanglecomplete', function(rectangle: any) {
        rectangle.setMap(null)
        onEvent(rectangle)
    });
    return drawingManager
}