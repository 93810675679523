import styled, { css } from 'styled-components';
import { ButtonProps } from 'components/Button';

const ButtonBaseStyle = css<ButtonProps>`
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 8px;

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme, width, height, fullWidth, ds }) => ({
    width: fullWidth ? '100%' : width ? `${width}px` : 'auto',
    height: `${height}px`,

    boxShadow: ds && theme.shadows[ds],
    backgroundColor: theme.colors.RC03,

    ':hover': {
      backgroundColor: theme.colors.RC02,
    },
    ':disabled': {
      cursor: 'not-allowed',
      color: theme.colors.RG04,
      background: theme.colors.RG06,
    },
  })}

  color: ${({ theme, color }) => color && theme.colors[color]};
  ${({ styleName, theme }) => styleName && theme.fontStyle[styleName]}
`;

export const Button = styled.button`
  ${ButtonBaseStyle}
`;

export const IconButton = styled.button`
  ${ButtonBaseStyle}
`;

export const SecondButton = styled.button<ButtonProps>`
  transition: all 0.3s;
  cursor: pointer;

  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme, width, height, fullWidth }) => ({
    width: fullWidth ? '100%' : width ? `${width}px` : 'auto',
    height: `${height}px`,

    border: `1px solid ${theme.colors.RG06}`,
    backgroundColor: theme.colors.RG00,
    ':hover': {
      border: `1px solid ${theme.colors.RG05_1}`,
      backgroundColor: theme.colors.RG05_1,
    },
    ':disabled': {
      cursor: 'not-allowed',
      background: theme.colors.RG06,
    },
  })}

  color: ${({ theme, color }) => color && theme.colors[color]};
  ${({ styleName, theme }) => styleName && theme.fontStyle[styleName]}
`;

export const ThirdButton = styled.button`
  ${ButtonBaseStyle}

  ${({ theme }) => ({
    color: theme.colors.RC03,
    border: `1px solid ${theme.colors.RC03}`,
    backgroundColor: theme.colors.RC03_1,
    ':hover': {
      border: `1px solid ${theme.colors.RC03}`,
      backgroundColor: theme.colors.RC03_1,
    },
  })}
`;

export const FourthButton = styled.button`
  ${ButtonBaseStyle}

  ${({ theme }) => ({
    color: theme.colors.RG04,
    border: `1px solid ${theme.colors.RG06}`,
    backgroundColor: theme.colors.RG08,
    ':hover': {
      border: `1px solid ${theme.colors.RG06}`,
      backgroundColor: theme.colors.RG08,
    },
    ':disabled': {
      border: `1px solid ${theme.colors.RG06}`,
      backgroundColor: theme.colors.RG08,
      cursor: 'not-allowed',
    },
  })}
`;

export const FifthButton = styled.button`
  ${ButtonBaseStyle}

  ${({ theme }) => ({
    color: theme.colors.RC02,
    border: `1px solid ${theme.colors.RC03}`,
    backgroundColor: theme.colors.RC03_1,
    ':hover': {
      border: `1px solid ${theme.colors.RC03}`,
      backgroundColor: theme.colors.RC03_1,
    },
  })}
`;

export const SixthButton = styled.button`
  ${ButtonBaseStyle}

  ${({ theme }) => ({
    color: theme.colors.RC04,
    border: `1px solid ${theme.colors.RC04}`,
    backgroundColor: theme.colors.RC04_2,
    ':hover': {
      border: `1px solid ${theme.colors.RC04}`,
      backgroundColor: theme.colors.RC04_1,
    },
  })}
`;

export const SeventhButton = styled.button<ButtonProps>`
  transition: all 0.3s;
  cursor: pointer;

  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme, width, height, fullWidth }) => ({
    width: fullWidth ? '100%' : width ? `${width}px` : 'auto',
    height: `${height}px`,

    color: theme.colors.RG03,

    border: `1px solid ${theme.colors.RG06}`,
    backgroundColor: theme.colors.RG00,
    ':hover': {
      color: theme.colors.RG04,
      border: `1px solid ${theme.colors.RG06}`,
      backgroundColor: theme.colors.RG07,
    },
    ':disabled': {
      cursor: 'not-allowed',
      background: theme.colors.RG06,
    },
  })}

  color: ${({ theme, color }) => color && theme.colors[color]};
  ${({ styleName, theme }) => styleName && theme.fontStyle[styleName]}
`;

export const RTButton = styled.button`
  ${ButtonBaseStyle}
  ${({ theme }) => theme.fontStyle.subheadline2}
  white-space: nowrap;

  ${({ theme, bgColor }) => ({
    padding: '9px 20.5px',

    color: bgColor ? theme.colors[bgColor] : theme.colors.RC02,
    backgroundColor: bgColor ? theme.colors[bgColor + '_2'] : theme.colors.RC03_1,

    '& > * > svg > path': {
      fill: bgColor ? theme.colors[bgColor] : theme.colors.RC02,
    },

    ':hover': {
      color: theme.colors.RG00,
      backgroundColor: bgColor ? theme.colors[bgColor] : theme.colors.RC03,

      '& > * > svg > path': {
        fill: theme.colors.RG00,
      },
    },
    ':disabled': {
      cursor: 'not-allowed',

      color: theme.colors.RG04,
      background: theme.colors.RG06,
      ':hover': {
        color: theme.colors.RG04,
        background: theme.colors.RG06,
      },
    },
  })}

  ${({ styleName, theme }) => styleName && theme.fontStyle[styleName]}
`;

export const MRTButton = styled.button`
  ${ButtonBaseStyle}
  ${({ theme }) => theme.fontStyle.subheadline2}
  white-space: nowrap;

  ${({ theme }) => ({
    padding: '9px 20.5px',

    color: theme.colors.RG00,
    backgroundColor: theme.colors.RC02,

    ':disabled': {
      cursor: 'not-allowed',
    },
  })}
`;

export const EighthButton = styled.button`
  ${ButtonBaseStyle}
  ${({ theme }) => theme.fontStyle.subheadline2}
  white-space: nowrap;

  ${({ theme }) => ({
    padding: '6px 10px',
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,

    ':hover': {
      color: theme.colors.RG00,
      backgroundColor: theme.colors.RC03,
      border: `1px solid ${theme.colors.RC03}`,
    },
    ':disabled': {
      cursor: 'not-allowed',

      color: theme.colors.RG04,
      background: theme.colors.RG06,
      border: 'none',
      ':hover': {
        color: theme.colors.RG04,
        background: theme.colors.RG06,
        border: 'none',
      },
    },
  })}

  ${({ styleName, theme }) => styleName && theme.fontStyle[styleName]}
`;
