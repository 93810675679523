import React, { useEffect, useMemo, useRef, useState } from 'react';

import { BoxContainer } from 'components/BoxContainer';
import { Highlight, Text } from '../components/Typography';
import { Stack } from '../components/Stack';
import Sidebar from 'components/SidebarLayout/Sidebar';
import PageContentWrapper from 'components/PageContentWrapper';
import RoutePlanConfirmTable from 'components/Table/RoutePlanConfirmTable';
import RoutePlanConfirmNav from '../components/SidebarLayout/RoutePlanConfirmNav';
import styled from 'styled-components';
import { useStore } from '../store';
import { useParams } from 'react-router-dom';
import {
  DriverIdUnSelected,
  IEqualOptimizeResponsesDriversOrderList,
  UnBatchedDriverId,
  UnBatchedRouteIndex,
} from '../constants/types';
import { RoutePlanConfirmMap } from '../components/MapContainer/RoutePlanConfirmMap';
import { useDndMonitor } from '@dnd-kit/core';
import _ from 'lodash';
import { getDriverSummary, getTotalReqTime } from '../util/getDriverSummary';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import Divider from 'components/Divider';
import dayjs from 'dayjs';
import strings from '../util/Localization';
import { useLeave } from 'hooks/usePrompt';
import { removeAllTemporaryOptimizeDataList } from 'util/sessionStorageManager';

import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import { ReactComponent as IcClose } from '../constants/icon/ic_close.svg';
import theme from 'constants/theme';
import Modal from 'components/Modal';
import { Button } from 'components/Button';

export interface MovedOrder {
  id: number;
  targetDriver: number;
  fromDriver: number;
  originDriver: number;
}

const RoutePlanConfirm = () => {
  const params = useParams();

  useLeave(removeAllTemporaryOptimizeDataList);

  const { temporaryOptimizeData, loadTemporaryOptimizeData, setSelectedOrderIds } = useStore();

  const [driverTableIsOpen, setDriverTableIsOpen] = useState<boolean>(false);
  const [selectedDriverId, setSelectedDriverId] = useState<number>(DriverIdUnSelected);
  const [movedOrderIds, setMovedOrderIds] = useState<Array<MovedOrder>>([]);
  const [rowSelectionBatched, setRowSelectionBatched] = useState({});
  const [rowSelectionUnBatched, setRowSelectionUnBatched] = useState({});
  const [selectedOrder, setSelectedOrder] = useState<Array<any>>([]);

  const selectedDriver = useMemo(() => {
    let sid: number | null = selectedDriverId;
    if (sid === UnBatchedDriverId) sid = null;
    let s = temporaryOptimizeData?.data.driverList.filter(y => y.driverId === sid);
    if (s && s.length > 0) return s[0];
    return null;
  }, [temporaryOptimizeData, selectedDriverId]);

  const currentOrders = useMemo(() => {
    let orders: IEqualOptimizeResponsesDriversOrderList[] = [];

    selectedDriver?.orderList.forEach(order => {
      if (movedOrderIds.filter(x => x.id === order.orderId).length === 0) orders.push(order);
    });
    return orders;
  }, [movedOrderIds, selectedDriver]);

  const editingOrders = useMemo(() => {
    let orders: IEqualOptimizeResponsesDriversOrderList[] = [];
    temporaryOptimizeData?.data.driverList.forEach(drivers => {
      drivers.orderList.forEach(order => {
        if (movedOrderIds.filter(x => x.id === order.orderId && selectedDriverId === x.targetDriver).length > 0) {
          if (order.route && order.route.external && order.route.external.routeIndex)
            order.route.external.routeIndex = UnBatchedRouteIndex;
          orders.push(order);
        }
      });
    });
    return orders;
  }, [movedOrderIds, temporaryOptimizeData, selectedDriverId]);

  //MARK : 글로벌 State (드래그시 보일 주문 개수 업데이트)
  useEffect(() => {
    setSelectedOrderIds(selectedOrder.map(x => x.orderId));
  }, [selectedOrder, setSelectedOrderIds]);

  // MARK : 드라이버 선택시 테이블 Selection 모두 날리기
  useEffect(() => {
    setSelectedOrder([]);
  }, [selectedDriverId]);

  // MARK : Route 이동시 Temporary 데이터 업데이트 및 State 초기화
  useEffect(() => {
    if (params.key)
      loadTemporaryOptimizeData({
        dateKey: params.key,
      });

    return () => {
      setMovedOrderIds([]);
      setDriverTableIsOpen(false);
      setSelectedDriverId(DriverIdUnSelected);
    };
  }, [loadTemporaryOptimizeData, params]);

  useDndMonitor({
    onDragStart(event) {
      let isAlreadySelected = selectedOrder.filter(x => `${x.orderId}` === `${event.active.id}`).length !== 0;
      if (selectedDriver && !isAlreadySelected) {
        let orders: any[] = [];
        temporaryOptimizeData?.data.driverList.forEach(x => {
          x.orderList.forEach(y => {
            `${y.orderId}` === `${event.active.id}` && orders.push(y);
          });
        });
        setSelectedOrder([...selectedOrder, ...orders]);
      }
    },
    onDragEnd(event) {
      if (event.over) {
        if (event.over.id === `driver_${selectedDriverId}`) {
          alert(
            `같은 ${strings.드라이버}에게 할당할 수 없습니다.\n다른 ${strings.드라이버}에게 ${strings.모달_주문}을 할당해주세요.`
          );
        } else if (selectedOrder.length === 0) {
          alert(`${strings.모달_주문}이 선택되지 않았습니다. \n1개 이상 선택해주세요.`);
        } else {
          let targetDriverId = parseInt((`${event.over?.id}` || '0').replace('driver_', ''));

          // 선택한 주문 중 스킬ID가 지정된 주문이 있으면
          if (selectedOrder.filter(x => x.skill).length > 0) {
            if (targetDriverId === -1) {
              // 미배차로 옮기는 경우
            } else {
              // 미배차에서 다른 Driver 혹은 Driver to Driver로 옮기는 경우
              let targetDriver = temporaryOptimizeData?.data.driverList.filter(x => x.driverId === targetDriverId);
              if (targetDriver && targetDriver.length > 0) {
                let isAllowed = true;
                selectedOrder
                  .filter(x => x.skill)
                  .forEach(order => {
                    if (order.skill !== targetDriver![0].name) isAllowed = false;
                  });

                if (!isAllowed) {
                  alert(`${strings.특정차량}이 지정된 주문은\n다른 ${strings.드라이버}에게 할당 할 수 없습니다.`);
                  return;
                }
              }
            }
          }
          let ids = selectedOrder
            .map(x => x.orderId)
            .map(x => {
              let movedOrder: MovedOrder = {
                id: x,
                targetDriver: targetDriverId,
                fromDriver: selectedDriverId,
                originDriver:
                  movedOrderIds.filter(y => y.id === x).length > 0
                    ? movedOrderIds.filter(y => y.id === x)[0].originDriver
                    : selectedDriverId,
              };
              return movedOrder;
            });

          setMovedOrderIds(_.unionBy(ids, movedOrderIds, 'id'));
          setSelectedOrder([]);
        }
      }
    },
  });

  const counter = useRef<number>(0);
  const [unBatchedInfoModalIsOpen, setUnBatchedInfoModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!(selectedDriverId > 0) && driverTableIsOpen && currentOrders.length > 0 && counter.current < 1) {
      counter.current += 1;
      setUnBatchedInfoModalIsOpen(true);
    }
  }, [currentOrders.length, driverTableIsOpen, selectedDriverId]);

  const { routeStartTime, totalReqTime } = getTotalReqTime({
    performedDate: temporaryOptimizeData?.data.performedDate ?? dayjs().format('YYYY-MM-DD'),
    workStartTime: selectedDriver?.workStartTime ?? '09:00',
    lastOrderETA: currentOrders.at(-1)?.route?.external?.eta ?? '00:00',
  });

  let hour = Math.floor(totalReqTime / 60 / 60);
  let min = Math.floor((totalReqTime / 60) % 60);

  return (
    <React.Fragment>
      <Stack align={'flex-start'} direction={'row'}>
        <Sidebar bg="RG08" width="288px" ds="strong" index={2} sx={{ minWidth: '288px' }} padding={'28px 0 0 0'}>
          <RoutePlanConfirmNav
            driverTableIsOpen={driverTableIsOpen}
            setDriverTableIsOpen={setDriverTableIsOpen}
            selectedDriverId={selectedDriverId}
            setSelectedDriverId={setSelectedDriverId}
            movedOrderIds={movedOrderIds}
          />
        </Sidebar>

        <PageContentWrapper>
          <DriverStack
            align={'start'}
            sx={{
              maxWidth: `${driverTableIsOpen ? '881px' : '0px'}`,
              minWidth: `${driverTableIsOpen ? '400px' : '0px'}`,
              padding: `${driverTableIsOpen ? '18px 0' : '0px'}`,
            }}
          >
            <Stack align="end">
              <IcClose
                width={12}
                height={12}
                onClick={() => {
                  setDriverTableIsOpen(false);
                }}
                style={{ cursor: 'pointer', padding: '0 18px' }}
              />
            </Stack>
            <BoxContainer
              useVerticalScroll
              align={'start'}
              justify={'start'}
              sx={{
                padding: '0 18px',
                margin: '18px 0 30px',
              }}
            >
              <Stack spacing={48}>
                {editingOrders.length > 0 && (
                  <Stack spacing={40} sx={{ maxHeight: '70%' }} name="table-area" align={'start'}>
                    <Text styleName={'title1'} color={'RG02'}>
                      수정중인 {strings.주문}
                    </Text>
                    <RoutePlanConfirmTable
                      data={editingOrders.filter(x => x.orderId)}
                      rowSelection={rowSelectionUnBatched}
                      setRowSelection={setRowSelectionUnBatched}
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      disableOptions={{
                        pagnation: true,
                        pageSizeSelector: true,
                      }}
                    />
                  </Stack>
                )}

                {selectedDriver && (
                  <Stack>
                    <Stack name="header-area" spacing={8} align={'start'}>
                      <Text styleName={'title1'} color={'RG03'}>
                        {selectedDriverId > 0 ? selectedDriver.name : strings.미배차주문}
                      </Text>
                      {selectedDriverId > 0 ? (
                        <Stack
                          direction={'row'}
                          spacing={8}
                          divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                        >
                          <Text styleName={'subheadline2'} color={'RG03'}>
                            {strings.주문}&nbsp;{' '}
                            <Highlight styleName={'subheadline2'} color={'RC02'}>
                              {currentOrders.filter(x => x.orderId).length +
                                editingOrders.filter(x => x.orderId).length}
                            </Highlight>{' '}
                            개
                          </Text>
                          {movedOrderIds.filter(
                            z => z.targetDriver === selectedDriverId || z.originDriver === selectedDriverId
                          ).length > 0
                            ? '~'
                            : Boolean(selectedDriver.orderList.length) &&
                              selectedDriverId > 0 && (
                                <Text styleName={'subheadline2'} color={'RG03'}>
                                  {routeStartTime.format('HH:mm')} ~{' '}
                                  {routeStartTime.add(totalReqTime, 's').format('HH:mm')}
                                </Text>
                              )}

                          {movedOrderIds.filter(
                            z => z.targetDriver === selectedDriverId || z.originDriver === selectedDriverId
                          ).length > 0 ? (
                            <Text styleName={'subheadline2'} color={'RG03'}>
                              {'- 분'}
                            </Text>
                          ) : (
                            <Text styleName={'subheadline2'} color={'RG03'}>
                              {hour ? `${hour} 시간 ` : ''}
                              {`${isNaN(min) ? '0' : min < 10 ? `0${min}` : min} 분`}
                            </Text>
                          )}

                          {selectedDriver.hasLunch &&
                            !(
                              movedOrderIds.filter(
                                z => z.targetDriver === selectedDriverId || z.originDriver === selectedDriverId
                              ).length > 0
                            ) && (
                              <Stack spacing={8} direction="row" align="center" sx={{ width: 'fit-content' }}>
                                <IcHasLunch />
                                <Text styleName={'subheadline2'} color={'RG03'}>
                                  1 시간
                                </Text>
                              </Stack>
                            )}

                          {movedOrderIds.filter(
                            z => z.targetDriver === selectedDriverId || z.originDriver === selectedDriverId
                          ).length > 0 ? (
                            <Text styleName={'subheadline2'} color={'RG03'}>
                              {'- km'}
                            </Text>
                          ) : (
                            <Text styleName={'subheadline2'} color={'RG03'}>
                              {getDriverSummary(selectedDriver).d} km
                            </Text>
                          )}
                        </Stack>
                      ) : (
                        <Stack
                          direction={'row'}
                          spacing={8}
                          divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                        >
                          <Text styleName={'subheadline2'} color={'RG03'}>
                            {strings.주문}&nbsp;{' '}
                            <Highlight styleName={'subheadline2'} color={'RC02'}>
                              {currentOrders.filter(x => x.orderId).length +
                                editingOrders.filter(x => x.orderId).length}
                            </Highlight>{' '}
                            개
                          </Text>
                          <Stack
                            direction="row"
                            spacing={8}
                            sx={{ width: 'fit-content', cursor: 'pointer' }}
                            onClick={() => {
                              setUnBatchedInfoModalIsOpen(true);
                            }}
                          >
                            <Text styleName="subheadline2" color="RC02">
                              미배차는 희망시간 / 업무 시간 초과 등의 이유로 배정되지 않을 수 있습니다.
                            </Text>
                            <IcInfo fill={theme.colors.RC02} width={16} height={16} />
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      sx={{ flex: 1, marginTop: '16px', overflowY: editingOrders.length > 0 ? 'scroll' : 'hidden' }}
                    >
                      <Stack name="table-area" sx={{ maxHeight: editingOrders.length > 0 ? '70%' : '100%' }}>
                        <RoutePlanConfirmTable
                          // data={currentOrders.filter(x => x.orderId)}
                          data={currentOrders}
                          rowSelection={rowSelectionBatched}
                          setRowSelection={setRowSelectionBatched}
                          selectedOrder={selectedOrder}
                          setSelectedOrder={setSelectedOrder}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </BoxContainer>
          </DriverStack>

          <Stack sx={{ minWidth: '1px', height: '100%', flex: 1 }}>
            <RoutePlanConfirmMap
              optimizeEqualData={temporaryOptimizeData?.data}
              selectedDriverId={selectedDriverId}
              movedOrderIds={movedOrderIds}
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              currentOrders={currentOrders}
              editingOrders={editingOrders}
            />
          </Stack>
        </PageContentWrapper>
      </Stack>

      <Modal
        isModalOpen={unBatchedInfoModalIsOpen}
        setIsModalOpen={setUnBatchedInfoModalIsOpen}
        width={503}
        padding={24}
      >
        <Stack spacing={40}>
          <Stack spacing={10} sx={{ padding: '30px 0 0' }}>
            <Text styleName="title2" color="RG02">
              이러한 경우 미배차가 될 수 있습니다.
            </Text>
            <Text styleName="subheadline2" color="RG03">
              미배차를 해결하기 위해선
              <br />
              아래 사유를 확인하고 수정을 진행해주세요.
            </Text>
          </Stack>
          <Stack
            padding={16}
            spacing={16}
            align="start"
            sx={{
              background: theme.colors.RC04_1,
              borderRadius: 8,
            }}
          >
            <Text styleName="subheadline2" color="RG02" align="start">
              미배차가 발생하는 사유는 아래와 같습니다.
            </Text>

            <Text styleName="subheadline2" color="RG02" align="start">
              1. 특정 차량 지정된 경로 생성 시 차량이 선택되지 않는 경우
              <br />
              2. 작업 불가능한 시간을 작업 희망으로 설정한 경우
              <br />
              3. 주문의 총 용적량이 차량의 최대 용적량을 초과한 경우
              <br />
            </Text>
          </Stack>
          <Button
            type="button"
            styleName="body2"
            color="RG03"
            variant="seventh"
            fullWidth
            height={40}
            onClick={() => {
              setUnBatchedInfoModalIsOpen(false);
            }}
          >
            확인
          </Button>
        </Stack>
      </Modal>
    </React.Fragment>
  );
};

export default RoutePlanConfirm;
const DriverStack = styled(Stack)`
  width: 45vw;
  transition: all 0.2s ease-in-out;
`;
