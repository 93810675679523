import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';

const DeactiveModal = ({
  isOpen,
  setIsOpen,
  target,
  targetName,
  text,
  highlight,
  returnFunc,
  RBT,
  LBT,
  ms,
  reverse,
  single,
  headline,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  targetName?: string;
  target?: string;
  headline?: string;
  text: string | ReactNode;
  highlight: string | ReactNode;
  returnFunc: any;
  RBT: string;
  LBT?: string;
  ms?: number;
  reverse?: boolean;
  single?: boolean;
}) => {
  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} width={503} padding={24} ms={ms ? ms : 5}>
      <Stack name="delete-vehicle-modal">
        <Stack name="content-area" spacing={16} sx={{ padding: '40px 0' }}>
          <Stack spacing={10}>
            {headline && (
              <Text styleName="title2" color="RG02">
                {headline}
              </Text>
            )}
            <Stack name="text-area" direction={reverse ? 'column-reverse' : 'column'}>
              <Text styleName="subheadline3" color="RG03" sx={{ whiteSpace: 'pre-wrap' }}>
                {text}
              </Text>
              <Text styleName="subheadline3" color="RC04" sx={{ whiteSpace: 'pre-wrap' }}>
                {highlight}
              </Text>
            </Stack>
          </Stack>
          {targetName ? (
            <Text styleName="subheadline3" color="RG04">
              {targetName}
            </Text>
          ) : (
            target && (
              <Text styleName="subheadline3" color="RG04">
                {target}
              </Text>
            )
          )}
        </Stack>
        {single ? (
          <Stack>
            <Button
              variant={'default'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                returnFunc({ ...{ target } });

                setIsOpen(false);
              }}
            >
              <Text styleName="body2" color="RG00">
                {RBT}
              </Text>
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={16}>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Text styleName="body2" color="RG04">
                {LBT ? LBT : '취소'}
              </Text>
            </Button>
            <Button
              variant={'default'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                returnFunc({ ...{ target } });
                setIsOpen(false);
              }}
            >
              <Text styleName="body2" color="RG00">
                {RBT}
              </Text>
            </Button>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default DeactiveModal;
