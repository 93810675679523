import React, { useMemo, useState, SetStateAction, Dispatch } from 'react';
import { createTable, getCoreRowModel, useTableInstance, PaginationState } from '@tanstack/react-table';
import { ImanualExcelValidateOrderList, ImanualExcelValidateVehicleList } from 'constants/types';

import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';
import { ReactComponent as IconPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';

import TableComponent from '.';
import { Stack } from 'components/Stack';
import { RowDirectionContainer } from './style';
import { shipment_type } from 'constants/commons';

let manualRouteTable = createTable().setRowType<ImanualExcelValidateOrderList>();

const ManualRouteTable = ({
  data,
  setManualExcelValidatedData,
  targetId,
}: {
  data: Array<ImanualExcelValidateVehicleList>;
  setManualExcelValidatedData: Dispatch<SetStateAction<Array<ImanualExcelValidateVehicleList>>>;
  targetId: number;
}) => {
  const domain = window.localStorage.getItem('serviceDomain');

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100000000,
  });

  const [columnVisibility, setColumnVisibility] = useState({});

  const [isHover, setIsHover] = useState<
    {
      id: string;
      value: boolean;
    }[]
  >([]);

  const columns = useMemo(
    () => [
      // 작업 희망/ 소요시간 추가
      // manualRouteTable.createDataColumn(row => row., {
      //   id: 'serviceTime',
      //   header: '작업소요시간',
      //   footer: info => info.column.id,
      // }),
      manualRouteTable.createDataColumn(row => 1, {
        id: 'index',
        header: '순서',
        cell: info => info.row.index + 1,
        footer: info => info.column.id,
        enableSorting: false,
      }),
      manualRouteTable.createDataColumn(row => 1, {
        id: 'types',
        header: '유형',
        cell: info => {
          let rowShipmentType = info.row.original?.shipmentType;
          return (
            <Stack direction="row" sx={{ margin: '0 -9px' }}>
              <React.Fragment>
                {info.column
                  .getFacetedRowModel()
                  .rows.find(d => Boolean(d.original?.desiredTimeStart) || Boolean(d.original?.desiredTimeEnd)) && (
                  <RowDirectionContainer>
                    {(info.row.original?.desiredTimeStart || info.row.original?.desiredTimeEnd) && (
                      <IconDesiredTime width={20} height={20} />
                    )}
                  </RowDirectionContainer>
                )}

                {info.column.getFacetedRowModel().rows.find(d => d.original?.skill) && (
                  <RowDirectionContainer>
                    {info.row.original?.skill &&
                      (domain === 'roouty' ? (
                        <IconTruck width={20} height={20} />
                      ) : (
                        <IconPerson width={20} height={20} />
                      ))}
                  </RowDirectionContainer>
                )}

                {domain === 'roouty' && (
                  <RowDirectionContainer>
                    {React.createElement(
                      shipment_type.find(d => d.value === rowShipmentType?.replace('Rotation', ''))?.icon[
                        isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
                      ] ?? 'div',
                      {
                        width: 20,
                        height: 20,
                      }
                    )}
                  </RowDirectionContainer>
                )}

                {domain === 'roouty' && (
                  <RowDirectionContainer>
                    {React.createElement(
                      rowShipmentType?.includes('Rotation')
                        ? shipment_type.find(d => d.value === 'rotation')?.icon[
                            isHover.find(d => d.id === info.row.id)?.value ? 'ff' : 'pf'
                          ] ?? 'div'
                        : 'div',
                      {
                        width: 20,
                        height: 20,
                      }
                    )}
                  </RowDirectionContainer>
                )}
              </React.Fragment>
            </Stack>
          );
        },
        footer: info => info.column.id,
        enableSorting: false,
      }),
      manualRouteTable.createDataColumn(row => row.consigneeName, {
        id: 'consigneeName',
        header: '고객명',
        footer: info => info.column.id,
        enableSorting: false,
      }),
      manualRouteTable.createDataColumn(
        row => (row?.address ? row.address + (row?.detailAddress ? ', ' + row?.detailAddress : '') : '-'),
        {
          id: 'address',
          header: () => '주소',
          footer: info => info.column.id,
          enableSorting: false,
        }
      ),
      manualRouteTable.createDataColumn(row => row.capacity, {
        id: 'capacity',
        header: '용적량',
        footer: info => info.column.id,
        enableSorting: false,
      }),
      manualRouteTable.createDataColumn(
        row => {
          if (row.product) {
            if (row.product.length >= 2) return `${row.product[0].name ?? ''} 외 ${row.product.length - 1}건`;
            else return `${row.product[0].name ?? ''}`;
          }
          return '-';
        },
        {
          id: 'product',
          header: '아이템',
          footer: info => info.column.id,
          enableSorting: false,
        }
      ),
    ],
    [domain, isHover]
  );

  const instance = useTableInstance(manualRouteTable, {
    data: Array.from(data.find(d => d.vehicle.vehicleId === targetId)?.orderList ?? []),
    columns,
    state: {
      columnVisibility,
    },

    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const reorderData = (startIndex: number, endIndex: number) => {
    const newData = [...data];
    const [movedRow] = newData.find(d => d.vehicle.vehicleId === targetId)!.orderList.splice(startIndex, 1);
    newData.find(d => d.vehicle.vehicleId === targetId)!.orderList.splice(endIndex, 0, movedRow);

    console.log(movedRow);
    console.log(newData);

    setManualExcelValidatedData([...newData]);
  };

  return (
    <React.Fragment>
      <TableComponent
        sortDrag={{
          dragFunc: {
            handleDragEnd: result => {
              console.log(result);
              const { source, destination } = result;
              if (!destination) return;
              reorderData(source.index, destination.index);
            },
          },
        }}
        table={instance}
        isDataFetching={false}
        disableOptions={{ selectCount: true, pagnation: true, pageSizeSelector: true }}
        {...{ pagination, setPagination, setIsHover }}
      />
    </React.Fragment>
  );
};

export default ManualRouteTable;
