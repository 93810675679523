import React, { useEffect } from 'react';
import { IOrder, IUnassignedOrdersList } from 'constants/types';
import { renderToString } from 'react-dom/server';
import { GoogleMapsOverlay } from './GoogleMapsOverlay';
import GL from '@luma.gl/constants';
import { IconLayer } from '@deck.gl/layers/typed';
import { DynamicSvg, MarkerInfo } from '../../DynamicSvg';
import { DynamicSvgPresets } from '../../DynamicSvg/Presets';

function svgToDataURL(svg: string) {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

export function DynamicMarker({
  overlay,
  marker,
}: {
  overlay: GoogleMapsOverlay;
  marker: {
    info: MarkerInfo;
    coordinates: [number, number];
    onClick?: any;
  }[];
}) {
  useEffect(() => {
    if (!overlay) return;

    const MULTIPLIER = 3;
    const CHUNKSIZE = 100;

    let layers: IconLayer[] = [];
    let data: any[] = [];

    marker.forEach((x, index) => {
      let icon = svgToDataURL(renderToString(<DynamicSvg info={x.info} />));
      data.push({
        index,
        icon,
        large: x.info.markerSize === 'large',
        coordinates: x.coordinates,
        onClick: x.onClick,
      });
    });

    for (let i = 0; i < data.length; i += CHUNKSIZE) {
      const chunk = data.slice(i, i + CHUNKSIZE);

      layers.push(
        new IconLayer({
          data: chunk,
          getIcon: d => ({
            url: d.icon,
            width: 80 * MULTIPLIER,
            height: (d.large ? 80 : 65) * MULTIPLIER,
            anchorY: (d.large ? 80 : 65) * MULTIPLIER,
            anchorX: (d.large ? 22 : 16.5) * MULTIPLIER,
          }),
          loadOptions: {
            imagebitmap: {
              resizeWidth: 300,
              resizeHeight: 300,
            },
          },
          sizeScale: 1,
          getSize: d => (d.large ? 80 : 65),
          textureParameters: {
            [GL.TEXTURE_MIN_FILTER]: GL.NEAREST,
            [GL.TEXTURE_MAG_FILTER]: GL.NEAREST,
            [GL.TEXTURE_WRAP_S]: GL.CLAMP_TO_EDGE,
            [GL.TEXTURE_WRAP_T]: GL.CLAMP_TO_EDGE,
          },
          pickable: true,
          getPosition: (d: any) => d.coordinates,
          onClick: (info, event) => {
            info.object.onClick();
          },
        })
      );
    }

    overlay.setProps({ layers });
  }, [overlay, marker]);
  return null;
}
