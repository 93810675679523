import React, { HTMLProps, SetStateAction, useEffect, useRef } from 'react';
import { TableInstance } from '@tanstack/react-table';

import { Stack } from 'components/Stack';

import { ReactComponent as IconArrowDown } from 'constants/icon/ic_arrow_count_down.svg';

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...props
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !props.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input className={'extended-checkbox'} type="checkbox" ref={ref} {...props} />;
}

export function HeaderIndeterminateCheckbox({
  indeterminate,
  instance,
  anchorIsOpen,
  setAnchorIsOpen,
  setAnchorPoint,
  className = '',
  ...props
}: {
  indeterminate?: boolean;
  instance: TableInstance<any>;
  anchorIsOpen: boolean;
  setAnchorIsOpen: React.Dispatch<SetStateAction<boolean>>;
  setAnchorPoint: React.Dispatch<SetStateAction<DOMRect | undefined>>;
} & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !props.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <Stack direction="row" spacing={4}>
      <input className={'extended-checkbox'} type="checkbox" ref={ref} {...props} />

      <IconArrowDown
        style={anchorIsOpen ? { transform: 'rotate(0.5turn)' } : {}}
        onClickCapture={e => {
          setAnchorIsOpen(prev => !prev);
          setAnchorPoint(e.currentTarget.getBoundingClientRect());
        }}
      />
    </Stack>
  );
}

export default IndeterminateCheckbox;
