import strings from './Localization';

export function validateOrderStatus(
  data: 'unassigned' | 'scheduled' | 'processing' | 'arrived' | 'completed' | 'skipped' | 'canceled' | 'deleted'
) {
  const status = {
    unassigned: { key: 'todo', name: strings.배차대기 },
    scheduled: { key: 'done', name: strings.배차완료 },
    processing: { key: 'moving', name: strings.배송중 },
    arrived: { key: 'moving', name: strings.배송중 },
    completed: { key: 'purple', name: strings.배송완료 },
    skipped: { key: 'skipped', name: '보류' },
    canceled: { key: 'cancel', name: '취소' },
    deleted: { key: 'cancel', name: '취소' },
  };

  return status[data];
}

export function validateAccountStatus(data: 'invited' | 'accepted' | 'activated' | 'deactivated') {
  const status = {
    invited: { key: 'todo', name: '초대됨' },
    accepted: { key: 'moving', name: '초대완료' },
    activated: { key: 'done', name: '활성' },
    deactivated: { key: 'skipped', name: '정지' },
  };

  return status[data];
}

export function validateControlDriverStatus(
  data: 'start' | 'processing' | 'arrived' | 'completed' | 'end' | 'skipped'
) {
  const status = {
    start: '근무 시작',
    processing: strings.배송중,
    arrived: '도착',
    completed: strings.배송완료,
    end: '근무 종료',
    skipped: '보류',
  };

  return status[data];
}
