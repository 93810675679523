import React, { useEffect } from 'react';

import GoogleMapReact from 'google-map-react';
import { MapConfigDefaultProps, MapConfigKeys, MapConfigOptions } from 'components/MapContainer/MapConfig';
import { IGetControlRouteResponses, ILocationMqOnStore } from '../../constants/types';

import { ReactComponent as MyLocation } from '../../constants/icon/ic_mylocation.svg';
import { GoogleMapsOverlay } from './DeckGL/GoogleMapsOverlay';
import { RouteControlPolyline } from './DeckGL/RouteControlPolyline';
import { useStore } from '../../store';
import { OrderMarker, StartEndMarker, MyLocationMarker } from './components/CustomMarker';
import { AIOMarker } from './components/AIOMarker';

interface RouteControlMapProps {
  data?: IGetControlRouteResponses;
  isRefetching: boolean;
  locationMqResponse?: ILocationMqOnStore[];
  selectedDriver?: number;
}

const RouteControlMap = ({ data, locationMqResponse, selectedDriver, isRefetching }: RouteControlMapProps) => {
  const [mapInstance, setMapInstance] = React.useState<any>(null);
  const [mapsInstance, setMapsInstance] = React.useState<any>(null);
  const [overlayInstance, setOverlayInstance] = React.useState<GoogleMapsOverlay | null>(null);

  function onMapLoaded(map: any, maps: any) {
    if (!mapInstance) setMapInstance(map);
    if (!mapsInstance) setMapsInstance(maps);
    if (!overlayInstance) {
      let overlay = new GoogleMapsOverlay({});
      setOverlayInstance(overlay);
      overlay.setMap(map);
    }
  }

  useEffect(() => {
    if (mapsInstance && mapInstance && data && !isRefetching) {
      let bounds = new mapsInstance.LatLngBounds();
      let boundEdited = false;
      data?.driverList.forEach(x => {
        if (selectedDriver === x.driverId || !selectedDriver || selectedDriver === -1) {
          if (x.vehicle && x.vehicle.startCoordinate) {
            bounds.extend({
              lat: x.vehicle.startCoordinate.coordinates[1],
              lng: x.vehicle.startCoordinate.coordinates[0],
            });
            boundEdited = true;
          }
          if (x.vehicle && x.vehicle.endCoordinate) {
            bounds.extend({
              lat: x.vehicle.endCoordinate.coordinates[1],
              lng: x.vehicle.endCoordinate.coordinates[0],
            });
            boundEdited = true;
          }

          x.orderList.forEach(o => {
            if (o.coordinate) {
              bounds.extend({
                lat: o.coordinate?.coordinates[1],
                lng: o.coordinate?.coordinates[0],
              });
              boundEdited = true;
            }
          });
        }
      });
      if (boundEdited) {
        setTimeout(() => {
          mapInstance.fitBounds(bounds, 120);
        }, 40);
      }
    }
  }, [mapsInstance, mapInstance, data, selectedDriver]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={MapConfigKeys}
      options={MapConfigOptions}
      defaultCenter={MapConfigDefaultProps.center}
      defaultZoom={MapConfigDefaultProps.zoom}
      onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
    >
      {overlayInstance && (
        <RouteControlPolyline
          overlay={overlayInstance}
          responseDrivers={data?.driverList}
          selectedDriverId={selectedDriver}
        />
      )}

      {(locationMqResponse || []).map(x => {
        return (
          <MyLocationMarker
            {...{
              lat: x.locationInfo.location.coordinates[1],
              lng: x.locationInfo.location.coordinates[0],
              driverName: data?.driverList.find(y => y.driverId.toString() === x.driverId.toString())?.name,
              colorIndex: parseInt(x.driverId),
            }}
          />
        );
      })}

      {(data?.driverList || []).map((x, index) => {
        let markers = [];
        x.vehicle &&
          x.vehicle.startCoordinate &&
          markers.push(
            <StartEndMarker
              {...{
                lat: x.vehicle.startCoordinate?.coordinates[1],
                lng: x.vehicle.startCoordinate?.coordinates[0],
                displayType: 'start',
                showNameChip: !Boolean(locationMqResponse?.find(y => y.driverId.toString() === x.driverId.toString())),
                content: '시작',
                driverName: x.name,
                colorIndex: x.driverId,
                zIndex: -2002,
                alpha: (selectedDriver || -1) > -1 ? (selectedDriver === x.driverId ? 1 : 0.3) : 1,
              }}
            />
          );
        x.vehicle &&
          x.vehicle.endCoordinate &&
          markers.push(
            <StartEndMarker
              {...{
                lat: x.vehicle.endCoordinate?.coordinates[1],
                lng: x.vehicle.endCoordinate?.coordinates[0],
                displayType: 'end',
                content: '종료',
                zIndex: -2003,
                alpha: (selectedDriver || -1) > -1 ? (selectedDriver === x.driverId ? 1 : 0.3) : 1,
              }}
            />
          );

        x.orderList.map(o => {
          o.coordinate &&
            markers.push(
              <AIOMarker
                {...{
                  lat: o.coordinate?.coordinates[1],
                  lng: o.coordinate?.coordinates[0],
                  type: o.status === 'completed' ? 'order_end' : 'order',
                  color: x.driverId,
                  text: `${o.route?.routeIndex ?? ''}`,
                  chip: {
                    complete: o.status === 'completed',
                    skill: !!o.skill,
                    time: !!(o.desiredTimeEnd || o.desiredTimeStart),
                    pickup: o.shipmentType === 'pickup',
                    // delivery: o.shipmentType === 'delivery',
                  },
                  zIndex: (selectedDriver || -1) > -1 ? (selectedDriver === x.driverId ? 1000 : 1) : 100,
                  alpha: (selectedDriver || -1) > -1 ? (selectedDriver === x.driverId ? 1 : 0.3) : 1,
                }}
              />
            );
        });

        return markers;
      })}
    </GoogleMapReact>
  );
};

export default RouteControlMap;
