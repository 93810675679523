import React, { Dispatch, SetStateAction } from 'react';
import { canceler } from 'api/instance';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Highlight, Text } from 'components/Typography';
import ProgressBar from 'components/ProgressBar';
import { usePrompt } from 'hooks/usePrompt';
import strings from '../../util/Localization';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';

interface IRunOptimizeEqualModalProps {
  info:
    | {
        date: string;
        totalDriver: number;
        name: string;
        totalOrder: number;
      }
    | undefined;
  completedPercent: number;
  runEqualOptimizeProgressModal: boolean;
  setRunEqualOptimizeProgressModal: Dispatch<SetStateAction<boolean>>;
}

const RunOptimizeModal = ({
  info,
  completedPercent,
  runEqualOptimizeProgressModal,
  setRunEqualOptimizeProgressModal,
}: IRunOptimizeEqualModalProps) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(runEqualOptimizeProgressModal);

  return (
    <Modal
      isModalOpen={runEqualOptimizeProgressModal}
      setIsModalOpen={setRunEqualOptimizeProgressModal}
      plain
      sx={{ padding: '24px 36px' }}
    >
      <Stack sx={{ width: '100%', minWidth: '428px' }}>
        <Stack spacing={12} sx={{ padding: '20px 0' }}>
          {showPrompt && <IconWarning width={24} height={24} />}
          <Text styleName="title2" color="RG02" sx={{ justifyContent: 'center' }}>
            최적화 계산 중
          </Text>
        </Stack>
        {showPrompt ? (
          <Stack sx={{ maxWidth: '350px' }}>
            <div style={{ width: '100%' }}>
              <ProgressBar progress={completedPercent} spacing={8} />
            </div>

            <Stack name="content-area" align={'center'} sx={{ padding: '30px 0 40px' }}>
              <Text styleName="caption3" color="RC04">
                다른 페이지로 이동할 경우 경로 계산이 중단됩니다.
              </Text>
              <Text styleName="caption3" color="RG03">
                이후 처음부터 다시 계산됩니다.
              </Text>
            </Stack>

            <Stack direction="row" spacing={18} sx={{ minWidth: '438px' }}>
              <Button
                variant={'fourth'}
                type={'button'}
                fullWidth
                height={40}
                styleName={'subheadline3'}
                color={'RG04'}
                onClick={() => {
                  canceler();
                  setRunEqualOptimizeProgressModal(false);
                  confirmNavigation();
                }}
              >
                계산 취소
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                fullWidth
                height={40}
                styleName={'subheadline2'}
                color={'RG00'}
                onClick={() => {
                  cancelNavigation();
                }}
              >
                이어서 계산하기
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={30} sx={{ padding: '0 44px' }}>
            <div style={{ width: '100%' }}>
              <ProgressBar progress={completedPercent} spacing={8} />
            </div>

            <Stack name="content-area" align={'start'}>
              <Text styleName="caption3" color="RG03" sx={{ textAlign: 'start' }}>
                {info?.name}
                <br />
                {info?.date}
                <br />
              </Text>
              <Stack align="start">
                <Text styleName="caption3" color="RG03" sx={{ textAlign: 'start' }}>
                  {strings.모달_드라이버}&nbsp;<Highlight styleName="caption2">{info?.totalDriver}</Highlight>&nbsp;명{' '}
                  <br />
                </Text>
                <Text styleName="caption3" color="RG03" sx={{ textAlign: 'start' }}>
                  {strings.모달_주문}&nbsp;<Highlight styleName="caption2">{info?.totalOrder}</Highlight>&nbsp;개 <br />
                </Text>
              </Stack>
              <br />
              <Text styleName="caption3" color="RG03" sx={{ textAlign: 'start' }}>
                최대 5분까지 소요될 수 있습니다.
              </Text>
              <Text styleName="caption3" color="RC04">
                새로고침 또는 메뉴 이동 시 계산 취소되오니 주의해주세요.
              </Text>
            </Stack>

            <Button
              variant={'fourth'}
              type={'button'}
              fullWidth
              height={40}
              onClick={() => {
                canceler();
                setRunEqualOptimizeProgressModal(false);
              }}
            >
              계산 취소
            </Button>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default RunOptimizeModal;
