import React, { useEffect } from 'react';
import FieldForm from 'components/Field/FieldForm';
import { getCompanyInfo, setCompanyInfo } from 'api';
import { useMutation, useQuery } from 'react-query';
import { ISetCompanyInfo } from 'constants/types';
import dayjs from 'dayjs';
import Modal from 'components/Modal';
import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { useStore } from 'store';
import strings from '../../../util/Localization';

const Company = () => {
  const { userGrade } = useStore();

  const {
    isLoading: isLoadingCompanyInfo,
    data: companyInfo,
    refetch,
  } = useQuery('companyInfo', getCompanyInfo, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
  const { mutate: mutateSetCompanyInfo, isLoading: isLoadingSetCompanyInfo } = useMutation(setCompanyInfo, {
    onSuccess: () => {
      alert('성공정으로 수정 완료되었습니다.');
      refetch();
    },
  });

  const [paymentIsModi, setPaymentIsModi] = React.useState<boolean>(false);
  const [settingIsModi, setSettingIsModi] = React.useState<boolean>(false);

  const [planeModalOpen, setPlaneModalOpen] = React.useState<boolean>(false);
  const [timePickerPopupOpen, setTimePickerPopupOpen] = React.useState<boolean>(false);

  const company = [
    {
      title: '회사 정보',
      desc: '계정이 소속된 회사에 대한 기본 정보입니다.',
      RTB: userGrade < 2 ? (paymentIsModi ? '저장' : '수정') : '',

      fieldContents: [
        {
          type: 'Input',
          name: 'companyName',
          label: '회사명',
          validation: {
            required: { value: true, message: '해당 필드는 필수값 입니다.' },
          },
          readOnly: !paymentIsModi,
        },
        {
          type: 'Input',
          name: 'businessNumber',
          label: '사업자번호',
          validation: {
            pattern: {
              value: /^(\d{3})-?(\d{2})-?(\d{5})/,
              message: '사업자번호 형식에 맞춰주세요.',
            },
            maxLength: { value: 12, message: '사업자번호 형식에 맞춰주세요.' },
          },
          readOnly: !paymentIsModi,
        },
        {
          type: 'Input',
          name: 'name',
          label: '대표자명',
          validation: {},
          readOnly: !paymentIsModi,
        },
        {
          type: 'Input',
          name: 'contact',
          label: '회사 연락처',
          validation: {
            pattern: {
              value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
              message: '휴대폰 형식에 맞춰주세요.',
            },
            maxLength: {
              value: 13,
              message: '휴대폰 형식에 맞춰주세요.',
            },
          },
          readOnly: !paymentIsModi,
        },
        {
          type: 'Input',
          name: 'email',
          label: '회사 이메일',

          validation: {
            pattern: {
              value: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g,
              message: '이메일 형식에 맞춰주세요',
            },
          },

          readOnly: !paymentIsModi,
        },
        {
          type: 'Input',
          name: 'taxEmail',
          //RT-759
          label: '세금계산서 수신 이메일',

          validation: {
            pattern: {
              value: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g,
              message: '이메일 형식에 맞춰주세요',
            },
          },

          readOnly: !paymentIsModi,
        },
      ],
      useFormProps: {
        mode: 'onSubmit',
        defaultValues: {
          companyName: companyInfo?.name,
          businessNumber: companyInfo?.payment?.businessNumber?.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3'),
          name: companyInfo?.payment?.name,
          contact: companyInfo?.payment?.contact
            ?.replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
          email: companyInfo?.payment?.email,
          taxEmail: companyInfo?.payment?.taxEmail,
        },
      },
      onSubmit: (data: any) => {
        let companyName = '';

        if (paymentIsModi && Boolean(Object.keys(data).length)) {
          if (data?.payment?.businessNumber)
            data.payment.businessNumber = data.payment.businessNumber?.replace(/[^0-9]/g, '');
          if (data?.payment?.contact) data.payment.contact = data.payment.contact?.replace(/[^0-9]/g, '');

          if (data?.companyName) {
            companyName = data.companyName;
            delete data.companyName;
          }

          console.log(data);

          const restData: any = {
            name: companyName,
            payment: {
              ...data,
            },
          };
          // NullKiller(restData);
          if (restData.name === '') delete restData.name;
          if (Object.keys(restData.payment).length < 1) delete restData.payment;
          mutateSetCompanyInfo(restData);
        }

        setPaymentIsModi(prev => !prev);
      },

      isModi: paymentIsModi,
      setIsModi: setPaymentIsModi,
      complex: true,
    },

    {
      title: '루티 이용 현황',
      desc: '현재 이용 중인 플랜 정보입니다.',
      RTB: '플랜 업데이트',

      fieldContents: [
        { type: 'Input', name: 'service_plan_name', label: '이용중인 플랜', disabled: true },
        { type: 'Input', name: 'service_plan_use', label: '이용 기간', disabled: true },
      ],
      useFormProps: {
        mode: 'onSubmit',
        defaultValues: {
          service_plan_name: companyInfo?.pricing.name,

          // RT-864
          service_plan_use: `${
            companyInfo?.pricing.startedAt ? dayjs(companyInfo?.pricing.startedAt).format('YYYY.MM.DD') : ''
          } - ${companyInfo?.pricing.expiredAt ? dayjs(companyInfo?.pricing.expiredAt).format('YYYY.MM.DD') : ''}`,
        },
      },

      onSubmit: () => {},

      // FIXME subFunction
      subFunction: () => {
        setPlaneModalOpen(true);
      },
    },

    {
      title: window.localStorage.getItem('serviceDomain') === 'roouty' ? `배차 기본 정보` : `조사원 경로 설정`,
      desc: `회사의 ${strings.배차}에 대한 기본 정보입니다.`,
      RTB: userGrade < 2 ? (settingIsModi ? '저장' : '수정') : '',

      fieldContents:
        window.localStorage.getItem('serviceDomain') === 'roouty'
          ? [
              { type: 'Input', name: 'vehicleCount', label: '등록 차량 수 (현재 / 최대)', disabled: true },
              {
                type: 'Input',
                name: 'driverCount',
                label: `등록 ${strings.드라이버} 수 (현재 / 최대)`,
                disabled: true,
              },
              {
                type: 'Input',
                name: 'workStartTime',
                label: '업무 시작 시각',
                timePicker: {
                  open: timePickerPopupOpen,
                  setOpen: setTimePickerPopupOpen,
                },
                onClick: () => settingIsModi && setTimePickerPopupOpen(true),
                onFocus: (e: React.FocusEvent<HTMLInputElement>) => e.target.blur(),

                validation: {
                  required: { value: true, message: '해당 필드는 필수값 입니다.' },
                },
              },
              {
                type: 'Select',
                name: 'hasLunch',
                label: `${strings.드라이버} 점심시간`,
                options: [
                  { value: true, key: '설정 (1시간)' },
                  { value: false, key: '미설정 (점심시간 없음)' },
                ],

                validation: {
                  required: { value: true, message: '해당 필드는 필수값 입니다.' },
                },
                disabled: !settingIsModi,
              },
              { type: 'Input', name: 'unit', label: '단위 용적(1unit)', readOnly: !settingIsModi },
            ]
          : [
              {
                type: 'Input',
                name: 'driverCount',
                label: `등록 조사원 수 (현재 / 최대)`,
                disabled: true,
              },
              {
                type: 'Input',
                name: 'workStartTime',
                label: '조사원 업무 시작 시간',
                timePicker: {
                  open: timePickerPopupOpen,
                  setOpen: setTimePickerPopupOpen,
                },
                onClick: () => settingIsModi && setTimePickerPopupOpen(true),
                onFocus: (e: React.FocusEvent<HTMLInputElement>) => e.target.blur(),

                validation: {
                  required: { value: true, message: '해당 필드는 필수값 입니다.' },
                },
              },
              {
                type: 'Select',
                name: 'hasLunch',
                label: `${strings.드라이버} 점심시간`,
                options: [
                  { value: true, key: '설정 (1시간)' },
                  { value: false, key: '미설정 (점심시간 없음)' },
                ],

                validation: {
                  required: { value: true, message: '해당 필드는 필수값 입니다.' },
                },
                disabled: !settingIsModi,
              },
            ],
      useFormProps: {
        mode: 'onSubmit',
        defaultValues: {
          vehicleCount: `${companyInfo?.pricing.vehicleCount} / ${companyInfo?.pricing.maxVehicleCount}`,
          driverCount: `${companyInfo?.pricing.driverCount} / ${companyInfo?.pricing.maxDriverCount}`,
          workStartTime:
            companyInfo?.setting?.workStartTime && parseInt(companyInfo?.setting?.workStartTime?.split(':')[0]) < 12
              ? `오전 ${companyInfo?.setting?.workStartTime}`
              : `오후 ${companyInfo?.setting?.workStartTime}`,
          hasLunch: Boolean(companyInfo?.setting?.hasLunch),
          unit: companyInfo?.setting?.unit,
        },
      },
      onSubmit: (data: any) => {
        if (settingIsModi && Boolean(Object.keys(data).length)) {
          if (data?.workStartTime) data.workStartTime = data.workStartTime?.split(' ')[1];
          if (data?.hasLunch) data.hasLunch = JSON.parse(data.hasLunch);

          const restData: ISetCompanyInfo = {
            setting: {
              ...data,
            },
          };
          mutateSetCompanyInfo(restData);
        }

        setSettingIsModi(prev => !prev);
      },

      isModi: settingIsModi,
      setIsModi: setSettingIsModi,
      complex: true,
    },
  ];

  return (
    <React.Fragment>
      {isLoadingCompanyInfo ? <></> : <FieldForm FieldProps={company} />}
      <Modal
        isModalOpen={planeModalOpen}
        setIsModalOpen={setPlaneModalOpen}
        width={502}
        padding={24}
        ms={12}
        bd
        ds="strong"
      >
        <Stack align="start" spacing={37} sx={{ textAlign: 'start', width: '100%' }}>
          <Text styleName="subheadline3" color="RG03">
            루티 Roouty 이용 중이신 플랜을 변경하고 싶으신가요?
            <br />
            담당자 또는 아래 메일로 문의주시면 빠르게 연락드리겠습니다.
            <br />
            support@wemeetmobility.com
            <br />
            감사합니다.
          </Text>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
            <Button
              variant={'fourth'}
              type={'button'}
              width={128}
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                setPlaneModalOpen(false);
              }}
            >
              확인
            </Button>
          </div>
        </Stack>
      </Modal>
    </React.Fragment>
  );
};

export default Company;
