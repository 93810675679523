import {
  PaginationState,
  createTable,
  useTableInstance,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
} from '@tanstack/react-table';
import { getRouteList } from 'api';
import { IRouteList } from 'constants/types';
import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import strings from 'util/Localization';
import TableComponent from '.';
import { Tag } from 'components/Tag';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';

let routeHistoryManagementTable = createTable().setRowType<IRouteList>();

function RouteHistoryManagementTable({
  setSelectedOrder,
}: {
  setSelectedOrder: React.Dispatch<SetStateAction<Array<object>>>;
}) {
  let navigate = useNavigate();

  const [isHover, setIsHover] = useState<
    {
      id: string;
      value: boolean;
    }[]
  >([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100000,
  });
  const { data: routes, isFetching: getRouteListIsFetching } = useQuery(['getRouteList'], getRouteList, {});

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    routeId: false,
  });
  const [sorting, setSorting] = useState<SortingState>([
    // { id: 'createdAt', desc: false },
    // { id: 'performedDate', desc: false },
    // { id: 'name', desc: false },
    // { id: 'totalOrderCount', desc: false },
  ]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      routeHistoryManagementTable.createDisplayColumn({
        id: 'select',
        header: ({ instance }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: instance.getIsAllPageRowsSelected(),
              indeterminate: instance.getIsSomePageRowsSelected(),
              onChange: instance.getToggleAllPageRowsSelectedHandler(),

              instance,
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
        enableSorting: false,
      }),
      routeHistoryManagementTable.createDataColumn(row => row.status, {
        id: 'status',
        header: () => strings.상태,
        cell: info => {
          return (
            <Tag
              active={isHover.find(d => d.id === info.row.id)?.value}
              styleName="caption2"
              status={'done'}
              sx={{ width: '56px' }}
            >
              {strings.배차완료}
            </Tag>
          );
        },
        enableSorting: false,
      }),
      routeHistoryManagementTable.createDataColumn(row => dayjs(row.createdAt).format('YY.MM.DD'), {
        id: 'createdAt',
        header: () => strings.배차완료일,
      }),
      routeHistoryManagementTable.createDataColumn(row => dayjs(row.performedDate).format('YY.MM.DD'), {
        id: 'performedDate',
        header: () => strings.주행예정일?.replaceAll(' ', ''),
      }),
      routeHistoryManagementTable.createDataColumn(row => row.name, {
        id: 'name',
        header: () => strings.주행이름,
      }),
      routeHistoryManagementTable.createDataColumn(row => row.totalOrderCount, {
        id: 'totalOrderCount',
        header: () => strings.총주문,
      }),
      routeHistoryManagementTable.createDataColumn(row => row.totalDriverCount, {
        id: 'totalDriverCount',
        header: () => strings.모달_드라이버,
        enableSorting: false,
      }),
      routeHistoryManagementTable.createDataColumn(row => row.createdBy, {
        id: 'createdBy',
        header: () => '담당자',
        enableSorting: false,
      }),
      routeHistoryManagementTable.createDataColumn(row => row.routeId, {
        id: 'routeId',
        header: () => '라우터ID식별자',
        enableSorting: false,
      }),
    ],
    [isHover]
  );

  const instance = useTableInstance(routeHistoryManagementTable, {
    data: routes?.routeList ?? [],
    columns,
    state: {
      pagination,
      rowSelection,
      columnVisibility,
      sorting,
    },
    initialState: {},
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setSelectedOrder(instance.getSelectedRowModel().flatRows);
  }, [rowSelection]);

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [routes?.routeList]);

  return (
    <React.Fragment>
      <TableComponent
        table={instance}
        isDataFetching={getRouteListIsFetching}
        onRowClick={(e, row) => {
          navigate(`${row.getValue('routeId')}`);
        }}
        disableOptions={{
          totalCount: true,
          selectCount: true,
          pagnation: true,
          pageSizeSelector: true,
        }}
        setIsHover={setIsHover}
        {...{ pagination, setPagination }}
      />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}
    </React.Fragment>
  );
}
export default RouteHistoryManagementTable;
