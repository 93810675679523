import React, { ReactNode, useEffect, useState } from 'react';
import { Stack } from '../Stack';
import dayjs from 'dayjs';
import { Input } from '../Input';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { ReactComponent as IcArrowDropDown } from '../../constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as IcClose } from '../../constants/icon/ic_close_tooltip.svg';
import styled from 'styled-components';
import DriverPickerPopup, { DriverListItem } from '../Popup/DriverPicker';
import { Text } from '../Typography';
import strings from 'util/Localization';
import { ArrowDropDownAbsolute, DriverChip, DriverChipAll, DriverChipEmpty, DriverWrapper } from './style';

export interface DriverSelectorProps {
  drivers: DriverListItem[];
  setDrivers: React.Dispatch<React.SetStateAction<DriverListItem[]>>;
  methods: UseFormReturn<FieldValues, any>;

  driverPickerIsOpen: boolean;
  setDriverPickerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MappedStyled = {
  default: DriverChip,
  empty: DriverChipEmpty,
};

export function DriverSelector({ ...props }: DriverSelectorProps): React.ReactElement {
  function removeChip(index: number) {
    let items = [...props.drivers];
    items[index].isSelected = false;
    props.setDrivers(items);
    props.setDriverPickerIsOpen(false);
    updateValue();
  }
  function removeAllChips() {
    let items = [...props.drivers];
    items.map(x => (x.isSelected = false));
    props.setDrivers(items);
    props.setDriverPickerIsOpen(false);
    updateValue();
  }

  function updateValue() {
    let items = props.drivers.filter(x => x.isSelected);
    props.methods.setValue(
      'driverList',
      items.map(x => {
        return { driverId: x.driverId, vehicleId: x.vehicleId };
      }),
      {
        shouldValidate: true,
      }
    );
  }

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Stack spacing={8}>
      {props.drivers.filter(x => x.isSelected).length > 0 && (
        <DriverWrapper>
          {props.drivers.filter(x => x.isSelected).length === props.drivers.length && (
            <DriverChipAll onClick={() => removeAllChips()}>
              <Stack spacing={4} direction={'row'}>
                <Text styleName={'tooltip1'} color={'RG02'}>
                  모두 선택
                </Text>
                <IcClose />
              </Stack>
            </DriverChipAll>
          )}
          {props.drivers.map((x, i) => {
            const MappedStyledComponent = MappedStyled[x.name === '지정되지 않음' ? 'empty' : 'default'];

            if (x.isSelected && props.drivers.filter(x => x.isSelected).length !== props.drivers.length)
            if (x.isSelected)
              return (
                <MappedStyledComponent key={`${x.name}-chip`} onClick={() => removeChip(i)}>
                  <Stack spacing={4} direction={'row'}>
                    <Text styleName={'tooltip1'} color={'RG02'}>
                      {x.name} {x?.licenseNumber && `(${x.licenseNumber})`}
                    </Text>
                    <IcClose />
                  </Stack>
                </MappedStyledComponent>
              );
            else return null;
          })}
        </DriverWrapper>
      )}
      <Stack direction={'row'} sx={{ position: 'relative' }}>
        <Input
          variant={'third'}
          type="text"
          name="driverList"
          value={''}
          placeholder={`${strings.기사를} 선택하세요`}
          register={props.methods.register}
          watch={props.methods.watch}
          reset={props.methods.reset}
          height={32}
          readOnly={true}
          onBlur={() => {
            !isHovering && props.setDriverPickerIsOpen(false);
          }}
          onClick={() => {
            props.setDriverPickerIsOpen(prev => !prev);
          }}
          errors={props.methods.formState.errors.driverList}
          hasError={props.methods.formState.errors.driverList}
        />
        <ArrowDropDownAbsolute>
          <IcArrowDropDown />
        </ArrowDropDownAbsolute>

        {props.driverPickerIsOpen && (
          <DriverPickerPopup
            drivers={props.drivers}
            setDrivers={props.setDrivers}
            setIsOpen={props.setDriverPickerIsOpen}
            setValue={props.methods.setValue}
            name={'driverList'}
            setIsHovering={setIsHovering}
          />
        )}
      </Stack>
    </Stack>
  );
}
