import React, { forwardRef, ReactNode } from 'react';
import * as S from './style';

export interface TypographyProps {
  /**
   * 텍스트 색  */
  color?: string;
  ws?: string;
  /**
   * 텍스트 스타일 */
  styleName:
    | 'headline1'
    | 'headline2'
    | 'headline3'
    | 'title1'
    | 'title2'
    | 'title3'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'subheadline1'
    | 'subheadline2'
    | 'subheadline3'
    | 'caption1'
    | 'caption2'
    | 'caption3'
    | 'tooltip1'
    | 'tooltip2'
    | 'number1'
    | 'number2'
    | 'number3'
    | 'number4'
    | 'number5';

  align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'justify-all' | 'match-parent';
  /**
   * 내용  */
  children: string | ReactNode;

  decoration?: 'underline';
  sx?: React.CSSProperties;
}
export interface IHighlightProps {
  color?: string;
  children: string | ReactNode;

  sx?: React.CSSProperties;

  styleName?:
    | 'headline1'
    | 'headline2'
    | 'headline3'
    | 'title1'
    | 'title2'
    | 'title3'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'subheadline1'
    | 'subheadline2'
    | 'subheadline3'
    | 'caption1'
    | 'caption2'
    | 'caption3'
    | 'tooltip1'
    | 'tooltip2'
    | 'number1'
    | 'number2'
    | 'number3'
    | 'number4'
    | 'number5';
}

export const MappedStyled = {
  headline1: S.Headline1,
  headline2: S.Headline2,
  headline3: S.Headline3,
  title1: S.Title1,
  title2: S.Title2,
  title3: S.Title3,
  body1: S.Body1,
  body2: S.Body2,
  body3: S.Body3,
  subheadline1: S.SubHeadline1,
  subheadline2: S.SubHeadline2,
  subheadline3: S.SubHeadline3,
  caption1: S.Caption1,
  caption2: S.Caption2,
  caption3: S.Caption3,
  tooltip1: S.Tooltip1,
  tooltip2: S.Tooltip2,
  number1: S.Number1,
  number2: S.Number2,
  number3: S.Number3,
  number4: S.Number4,
  number5: S.Number5,
};

export const Text = forwardRef<any, TypographyProps>(({ children, sx, ...props }, ref): React.ReactElement => {
  const MappedStyledComponent = MappedStyled[props.styleName];

  if (Object.keys(MappedStyled).includes(props.styleName))
    return (
      <MappedStyledComponent {...props} style={sx} ref={ref}>
        {children}
      </MappedStyledComponent>
    );
  else return <React.Fragment />;
});

export const Highlight = ({ sx, children, ...props }: IHighlightProps) => {
  return (
    <S.Highlight style={sx} {...props}>
      {children}
    </S.Highlight>
  );
};
