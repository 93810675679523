import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueries, useQuery } from 'react-query';
import { checkPsLink, checkSignupPsLink, inviteAccept, inviteCheck, resetPassword, signupPs } from 'api';

import { Input } from 'components/Input';
import { useForm } from 'react-hook-form';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import PlainHeader from 'components/GNB/PlainHeader';

import theme from 'constants/theme';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';

const PasswordSetting = () => {
  let navigate = useNavigate();
  let urlKey = useParams().urlKey;
  const methods = useForm({
    reValidateMode: 'onChange',
  });

  const [isValidated, setIsValidated] = useState<boolean>(false);

  const queriesResponse = useQueries([
    {
      queryKey: ['inv', urlKey],
      queryFn: () => inviteCheck(urlKey as string),
      retry: false,
      suspense: true,
      enabled: Boolean(urlKey),
    },

    {
      queryKey: ['ps', urlKey],
      queryFn: () => checkPsLink(urlKey as string),
      retry: false,
      suspense: true,
      enabled: Boolean(urlKey),
    },

    {
      queryKey: ['signup', urlKey],
      queryFn: () => checkSignupPsLink(urlKey as string),
      retry: false,
      suspense: true,
      enabled: Boolean(urlKey),
    },
  ]);

  useEffect(() => {
    if (queriesResponse.filter(d => d.isStale === true).length === queriesResponse.length) {
      if (queriesResponse.filter(d => d.isError === true).length === queriesResponse.length)
        return navigate('/login', { replace: true });
      else setIsValidated(true);
    }
    return () => {};
  }, [queriesResponse]);

  const { mutate: mutateInviteAccept } = useMutation((password: string) => inviteAccept(urlKey as string, password), {
    onSuccess: () => {
      setIsDone(true);
    },
  });

  const { mutate: mutateResetPassword } = useMutation((password: string) => resetPassword(urlKey as string, password), {
    onSuccess: () => {
      setIsDone(true);
    },
  });

  const { mutate: mutateSignup } = useMutation((password: string) => signupPs(urlKey as string, password), {
    onSuccess: () => {
      setIsDone(true);
    },
  });

  const { watch } = methods;

  const onSubmit = (data: any) => {
    let password = data.password.replace(/(\s*)/g, '');
    let passwordConfirm = data.passwordConfirm.replace(/(\s*)/g, '');

    if (/[\{\}\[\]\/?.,;:|\)~`\-_+<>$%&\\\=\(\'\"]/gi.test(password)) {
      return methods.setError(
        'password',
        {
          type: 'not allowed char',
          message: '영문 / 숫자 / 특수문자 ( ! @ # ^ * ) 사용 가능합니다.',
        },
        { shouldFocus: true }
      );
    }

    if (password !== passwordConfirm)
      return methods.setError(
        'passwordConfirm',
        { type: 'mismatch', message: '비밀번호가 일치하지 않습니다. 다시 확인해주세요.' },
        { shouldFocus: true }
      );

    // 0 이 inv 1이 ps
    if (queriesResponse[0].isSuccess) return mutateInviteAccept(password);
    if (queriesResponse[1].isSuccess) return mutateResetPassword(password);
    if (queriesResponse[2].isSuccess) return mutateSignup(password);
  };

  const [isDone, setIsDone] = useState<boolean>(false);
  const [isEntered, setIsEntered] = useState<boolean>(false);

  useEffect(() => {
    const subscription = watch(value => {
      if (value.password && value.passwordConfirm) setIsEntered(true);
      else setIsEntered(false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  if (isValidated)
    return (
      <PageWrapper>
        <PlainHeader />
        <Container>
          <Body>
            <Card>
              {isDone ? (
                <DoneForm>
                  <IcSuccess fill={theme.colors.RC02} />
                  <Text styleName="body3" color="RG03" sx={{ textAlign: 'center' }}>
                    비밀번호 설정이 완료되었습니다.
                    <br />
                    새로운 비밀번호로 로그인해주세요.
                  </Text>

                  <Button
                    styleName="body2"
                    color="RG00"
                    variant={'default'}
                    type={'button'}
                    height={48}
                    fullWidth
                    onClick={() => navigate('/login')}
                  >
                    로그인하러 가기
                  </Button>
                </DoneForm>
              ) : (
                <React.Fragment>
                  <TnD>
                    <Text styleName="title1" color="RG03">
                      비밀번호 설정하기
                    </Text>
                    <Text styleName="body3" color="RG03">
                      사용하실 비밀번호를 입력해주세요.
                    </Text>
                  </TnD>
                  <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Input
                      name="password"
                      label="비밀번호"
                      bottomLabel="8자 이상 입력. 영문 / 숫자 / 특수문자 사용 가능합니다."
                      placeholder="비밀번호를 입력해주세요"
                      type="password"
                      variant="fourth"
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      validation={{
                        required: { value: true, message: '비밀번호를 입력해주세요.' },
                        minLength: {
                          value: 8,
                          message: '8자 이상 입력. 영문 / 숫자 / 특수문자 사용 가능합니다.',
                          shouldFocus: true,
                        },
                      }}
                      errors={methods.formState.errors.password}
                      hasError={methods.formState.errors.password}
                      sx={{ gap: '8px' }}
                    />
                    <Input
                      name="passwordConfirm"
                      label="비밀번호 확인"
                      bottomLabel="비밀번호를 다시 입력해주세요."
                      placeholder="비밀번호 확인"
                      type="password"
                      variant="fourth"
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      validation={{
                        required: { value: true, message: '비밀번호를 확인을 입력해주세요.' },
                      }}
                      errors={methods.formState.errors.passwordConfirm}
                      hasError={methods.formState.errors.passwordConfirm}
                      sx={{ gap: '8px' }}
                    />
                    <Button
                      styleName="body2"
                      color="RG00"
                      variant={'default'}
                      type={'submit'}
                      height={48}
                      disabled={!isEntered}
                    >
                      설정 완료
                    </Button>
                  </Form>
                </React.Fragment>
              )}
            </Card>
          </Body>
        </Container>
      </PageWrapper>
    );

  return <>유효토큰을 확인중입니다...</>;
};

export default PasswordSetting;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;

  flex-direction: column;

  overflow-y: scroll;

  /* gap: 0; */
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

const Body = styled.div`
  display: flex;

  height: 100%;

  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  padding: 0 20px;

  width: 100%;
  max-width: 360px;
`;

const TnD = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 8px;

  margin: 0 0 60px 0;
`;

const Form = styled.form`
  display: flex;

  flex-direction: column;
  gap: 30px;
`;

const DoneForm = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 40px;
`;
