export interface IErrorResponse {
  number:
    | 1101
    | 1102
    | 1103
    | 1104
    | 1105
    | 1106
    | 1107
    | 1108
    | 1109
    | 1110
    | 1111
    | 1201
    | 1202
    | 1203
    | 1204
    | 1004
    | 1005
    | 1006
    | 1007
    | 1008
    | 1009;
  message: string;

  advice?: unknown;
  name?: string;
  statusCode?: number;
}
export type IDomainTypes = 'roouty' | 'survey';

export const UnBatchedDriverId = -1;
export const DriverIdUnSelected = -3;
export const UnBatchedRouteIndex = -2;

export interface IParams {
  [key: string]: string;
}

export interface ILoginSubmitForm {
  loginAccount: string;
  password: string;
}

export interface ILoginQuery extends ILoginSubmitForm {
  loginType: 'pc' | 'mobile';
}

export interface ILoginResponses {
  refreshToken: string;
  accessToken: string;
}

export interface ISignupForm {
  email: string; // 생성하려는 owner 계정의 이메일
  companyName: string; // 생성하려는 회사의 이름
}

export interface IGetMyAuthInfoResponses {
  _id: string;
  email: string;
  name: string;
  contact: string;
  createdAt: string;
}

export interface IGetAccessTokenResponses {
  accessToken: string;
}

export interface IAddOrder {
  orderList: [
    {
      clientOrderId: string;
      consignee: {
        name: string;
        address: string;
        detail: string;
        phone: string;
        note: string;
      };
      task: {
        desiredDate: Date;
        desiredHour: {
          before: string;
          after: string;
        };
        serviceTime: number;
        skillId: string;
      };
      product: [
        {
          code: string;
          name: string;
          quantity: number;
          capacity: number;
          packaging: number;
        }
      ];
      option: [string];
      note: [string];
      orderDate: Date;
    }
  ];
}

export interface IUnassignedOrdersList {
  order_id: number;
  received_date: string;
  desired_date?: string;
  desired_time_start?: string;
  desired_time_end?: string;
  consignee_name: string;
  shipment_type: string;
  skill?: string;
  address: string;
  detail_address?: string;
  coordinate: {
    type: 'Point';
    coordinates: [number, number];
  };
}

export interface IGetUnassignedOrdersResponses {
  order_list: IUnassignedOrdersList[];
}

export interface IOrderList {
  _id: string;
  clientOrderId: string;
  consignee: {
    name: string;
    address: string;
    detail: string;
    phone: string;
    note: string;
  };
  location: {
    coordinates: [number, number];
  };
  task: {
    desiredDate: Date;
    desiredHour: {
      before: string;
      after: string;
    };
    serviceTime: number;
    skillId: string;
  };
  product: [
    {
      code: string;
      name: string;
      quantity: number;
      capacity: number;
      packaging: number;
    }
  ];
  orderDate: Date;
  createdAt: Date;
}

export interface IGetMemberList {
  grade: number | string;
}

export interface IDriverListResponse {
  driverId?: number;
  name?: string;
  workStartTime: string;
  hasLunch: boolean;
  vehicle: {
    vehicleId?: number;
    startAddress?: string;
    endAddress?: string;
    startCoordinate: {
      type: string;
      coordinates: [number, number];
    };
    endCoordinate: {
      type: string;
      coordinates: [number, number];
    };
  };
  orderList: {
    orderId?: number;
    type: string;
    receivedDate?: string;
    address?: string;
    coordinate?: {
      type: string;
      coordinates: [number, number];
    };
    desiredDate?: string;
    desiredTimeStart?: string;
    desiredTimeEnd?: string;
    serviceTime?: number;
    skill?: string;
    route: {
      internal?: {
        routeIndex?: number;
        requiredDistance?: number;
        requiredTime?: number;
        eta?: string;
        polyline?: string;
      };
      external?: {
        routeIndex?: number;
        requiredDistance?: number;
        requiredTime?: number;
        eta?: string;
        polyline?: string;
      };
    };
  }[];
}

export interface IOptimizedOrderList {
  _id: string;
  clientOrderId: string;
  consignee: {
    name: string;
    address: string;
    detail: string;
    phone: string;
    note: string;
  };
  location: {
    coordinates: [number, number];
  };
  task: {
    desiredDate: Date;
    desiredHour: {
      before: string;
      after: string;
    };
    serviceTime: number;
    skillId: string;
  };
  product: [
    {
      code: string;
      name: string;
      capacity: number;
      quantity: number;
      packaging: number;
    }
  ];
  orderDate: Date;
  transport: {
    driverId: string;
    driverName: string;
    routeIndex: number;
    internal: {
      requiredDistance: number;
      requiredTime: number;
      eta: string;
      polyline: string;
    };
    external: {
      requiredDistance: number;
      requiredTime: number;
      eta: string;
      polyline: string;
    };
  };
  type: string;
}

export interface IRunOptimize {
  date: string;
  orderList: any;
  driverList: any;
}

export interface IRunOptimizeEqualResponses {
  performedDate: string;
  driverList: IEqualOptimizeResponsesDriver[];
}

export interface IDeleteOrder {
  orderList: string[];
}

export interface IFieldContent {
  //common props
  name: string;
  type: string;
  sx?: React.CSSProperties;
  required?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  validation?: object;
  onClick?: () => void;

  //input props
  height?: number;
  placeholder?: string;
  label?: string | React.ReactNode;
  rightArea?: {
    rightContent: string | React.ReactNode;
    rightFunc: () => void;
  };

  //select props
  options?: { key: string; value: string | boolean | number }[];
}

export interface IField {
  title: string;
  RTB?: string;

  fieldContents: IFieldContent[];

  useFormProps: any;
  onSubmit: any;

  // FIXME : subFunction

  subFunction?: any;

  isModi?: boolean;
  setIsModi?: any;
  complex?: boolean;
}

export interface IGetMyInfoResponses {
  userId: number;

  email?: string; // 유저 이메일
  name: string; // 유저 이름
  phone?: string; // 유저 전화번호

  roleId: 1 | 2 | 3 | 4;

  driverInfo?: {
    workStartTime: string;
    vehicle?: {
      licenseNumber: string;
      startAddress: string;
      endAddress?: string;
    };
  };
}

export interface ISetMyInfo {
  name?: string; // 유저 이름
  phone?: string; // -를 제외한 string 전화번호
}

export interface IDriverUserList {
  driverId: number; // 드라이버 id
  userId: number;
  name: string; // 유저 이름
  status: string; // 해당 계정의 상태
  phone?: string; // 유저 전화번호
  workStartTime: string;
  vehicle?: {
    vehicleId?: number; // 차량 id
    licenseNumber?: string; // 차량번호
  };
  invitedAt: Date; // 초대 날짜
  acceptedAt?: Date; //초대 수락 날짜

  hasLunch: boolean; // 점심시간 설정 여부
}

export interface IgetDriverInfoResponses {
  memberList: IDriverUserList[];
}

export interface ISetDriverInfo {
  target: number; // 드라이버의 유저 id

  name?: string; // 드라이버 이름
  vehicleId?: number; // 차량 id
  workStartTime?: string; // 근무(작업) 시작 시간
  hasLunch?: boolean; // 점심시간 설정 여부
}

export interface IDeactivateMember {
  target: number; //비활성화 시킬 멤버의 유저 id
}

export interface IAdminUserList {
  userId: number; // 유저 id
  name: string; // 유저 이름
  email?: string; // 이메일
  phone?: string; // 연락처
  status: string; // 해당 계정의 상태
  invitedAt: Date; // 초대 날짜
  acceptedAt?: Date; // 초대 수락 날짜
}

export interface IGetAdminInfoResponses {
  userList: IAdminUserList[];
}

export interface ISetAdminInfo {
  target: number; // 관리자의 유저 id

  name?: string; // 유저 이름
  phone?: string; // -를 제외한 string 전화번호
}

export interface IGetCompanyInfoResponses {
  companyId: number;
  name: string;
  payment: {
    businessNumber?: string;
    name?: string;
    contact?: string;
    email?: string;
    taxEmail?: string;
  };
  pricing: {
    name: string;
    vehicleCount: number;
    maxVehicleCount: number;
    driverCount: number;
    maxDriverCount: number;
    startedAt: string;
    expiredAt: string;
  };
  setting: {
    workStartTime: string;
    hasLunch: number;
    lunchPossibleStart: string;
    lunchPossibleEnd: string;
    lunchDuration: number;
    unit?: string;
    startAddress?: string;
    endAddress?: string;
  };
}

export interface ISetCompanyInfo {
  name?: string;
  payment?: {
    businessNumber?: string;
    name?: string;
    contact?: string;
    email?: string;
    taxEmail?: string;
  };
  setting?: {
    workStartTime?: string;
    hasLunch?: boolean;
    lunchPossibleStart?: string;
    lunchPossibleEnd?: string;
    lunchDuration?: number;
    unit?: string;
    startAddress?: string;
    endAddress?: string;
  };
}

export interface IGetVehicleModelResponses {
  vehicleModelList: {
    vehicleModelId: number;
    name: string;
  }[];
}
export interface IVehicleList {
  vehicleId: number; // 차량 id
  licenseNumber: string; // 차량번호
  maxCapacity: number;
  model: {
    // 차종정보
    name?: string; // 차종

    // model_id?: number; // 차종 id
    // max_capacity?: number; // 최대 용적
  };
  memo?: string; // 메모
  startAddress: string; // 출발지 주소
  endAddress?: string; // 도착지 주소
}
export interface IAddVehicle {
  licenseNumber: string; // 차량번호
  vehicleModelId: number; // 차종 ID
  maxCapacity: number; // 최대 용적수량
  memo?: string; // 메모
  startAddress: string; // 출발지 주소
  endAddress?: string; // 도착지 주소
}

export interface IGetVehicleInfo {
  vehicleList: IVehicleList[];
}

export interface ISetVehicleInfo {
  target: string; // 차량 id

  licenseNumber?: string; // 차량번호
  vehicleModelId?: number;
  maxCapacity?: number; // 최대 용적수량
  memo?: string; // 메모
  startAddress?: string; // 출발지 주소
  endAddress?: string; // 도착지 주소
}

export interface IDeleteVehicleInfo {
  target: string; // 차량 id
}

export interface IDeleteUnassignedOrder {
  orderList: number[]; // 취소하려는 미배차 주문의 order_id
}

// TODO: IGetDownloadOrderList 배차 관제 이후 수정필요
export interface IGetDownloadOrderList {
  order_id?: string; // 주문 id를 ,(콤마) 로 구분하여 전송
  status: string; //all (전체, default 값) | unassigned (배차 대기, 미배차) | scheduled (배차 완료, 배송 대기) | processing (배송 중) | completed (배송 완료) | canceled (취소)
  received_date: string; // 주문 접수일 YYYMMDD-YYYYMMDD
  desired_date?: string; //작업 희망일 YYYYMMDD-YYYYMMDD
  completed_date?: string; // 배송 완료일 YYYYMMDD-YYYYMMDD
  driver_id: string; // 드라이버 id를 ,(콤마) 로 구분하여 전송
  keyword?: string; // 검색할 고객명에 대한 키워드
  sort_by?: string; //
  sort_method?: string; // desc, asc
}

export interface IGetDownloadOrderListResponses {
  orderList: [
    {
      status: string;
      received_date: string;
      consignee_name: string;
      consignee_phone: string;
      consignee_note: string;
      address: string;
      detail_address: string;
      product: [
        {
          code: string;
          name: string;
          capacity: number;
          quantity: number;
        }
      ];
      shipment_type: string;
      desired_date: string;
      desired_time_start: string;
      desired_time_end: string;
      service_time: number;
      skill: string;
      note: string[];
      route: {
        driver_name: string;
        performed_date: string;
        completed_date: string;
        route_index: number;
        eta: string;
        required_time: number;
      };
    }
  ];
}

export interface IGetOrderList {
  status?: string;
  received_date: string;
  desired_date?: string;
  allow_desired_date_null: boolean;
  completed_date?: string;
  driverId?: string;
  keyword?: string;
  sort_by?: string;
  sort_method?: string;
  page_per_count?: number;
  page?: number;
}

export interface IOrder {
  order_id: number;
  status: string;
  received_date: string;
  desired_date?: string;
  desired_time_start?: string;
  desired_time_end?: string;
  skill?: string;
  completed_date?: string;
  driver_name?: string;
  consignee_name: string;
  address: string;
  detail_address?: string;
  shipment_type: string;
  product_name?: string[];
  coordinate?: {
    type: 'Point';
    coordinates: [number, number];
  };
}
// export interface IOrder {
//   orderId: number;
//   status: string;
//   receivedDate: string;
//   desiredDate?: string;
//   desiredTimeStart?: string;
//   desiredTimeEnd?: string;
//   skill?: string;
//   completedDate?: string;
//   driverName?: string;
//   consigneeName: string;
//   address: string;
//   productName?: string[];
//   coordinate?: {
//     type: 'Point';
//     coordinates: [number, number];
//   };
// }

export interface IGetOrderListResponses {
  // totalCount: number;
  // page: number;
  // pagePerCount: number;
  orderList: IOrder[];
}

export interface IGetOrderDetail {
  target: number; // order_id
}

export interface IGetOrderDetailResponses {
  order_id: number;
  order_code: string;
  order_short_code: string;
  status: string;
  received_date: string;
  consignee_name: string;
  consignee_phone?: string;
  consignee_note?: string;
  address: string;
  detail_address?: string;
  product: [
    {
      product_id?: number;
      code?: string;
      name?: string;
      quantity: number;
    }
  ];
  capacity: number;
  desired_date?: string;
  desired_time_start?: string;
  desired_time_end?: string;
  service_time: number;
  shipment_type: string;
  skill?: string;
  note?: string[];
  completed_at?: string;
  route?: {
    performedDate: string; // YYYY-MM-DD
    driverId: number;
    driverName: string;
    routeId: number;
    routeName: string;
  };
  pod?: { capacity: number; comment: null | string; podFiles: Array<unknown> };
}

export interface ISetOrderDetail {
  target: number; // order_id

  clientKey?: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  shipmentType?: string;
  skill?: string;
  serviceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: string[];
  product: [
    {
      code?: string;
      name?: string;
      quantity?: number;
    }
  ];
}

export interface IAddSingleOrder {
  orderList: IAddSingleOrderAttr[];
}

export interface IAddSingleOrderAttr {
  clientKey?: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  shipmentType?: string;
  skill?: string;
  serviceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: string[];
  product: {
    code?: string;
    name?: string;
    quantity?: number;
  }[];
}

export interface IGetUnassignedOrders {
  received_date: string;
  desired_date?: string;
  shipment_type?: string;
  allow_desired_date_null: boolean;
}

export interface IRouteList {
  routeId: number;
  name: string;
  status: string;
  performedDate: string;
  totalOrderCount: number;
  totalDriverCount: number;
  createdBy: string;
  createdAt: string;
}
export interface IGetRouteListResponses {
  routeList: IRouteList[];
}

export interface IEqualOptimize {
  performedDate: string;
  orderList: [number];
  driverList: [
    {
      driverId: number;
      vehicleId: number;
    }
  ];
}
export interface IEqualOptimizeResponsesOnStore {
  key: string;
  name: string;
  originName: string;
  data: IEqualOptimizeResponses;
}

export interface ILocationMqOnStore {
  userId: string;
  driverId: string;
  locationInfo: {
    os: string;
    collectedTime: string;
    userId: string;
    driverId: string;
    location: {
      type: string;
      coordinates: [number, number];
    };
  };
}

export interface IEqualOptimizeResponsesDriversOrderList {
  orderId?: number;
  type: string;
  receivedDate?: string;
  address?: string;
  detailAddress?: string;
  shipmentType?: string;
  coordinate?: {
    type: 'Point';
    coordinates: [number, number];
  };
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  skill?: string;
  route?: {
    internal?: {
      routeIndex?: number;
      displayIndex?: number;
      requiredDistance?: number;
      requiredTime?: number;
      eta?: string;
      polyline?: string;
    };
    external?: {
      routeIndex?: number;
      displayIndex?: number;
      requiredDistance?: number;
      requiredTime?: number;
      eta?: string;
      polyline?: string;
    };
  };
}

export interface IEqualOptimizeResponsesDriver {
  driverId?: number | null;
  name?: string | null;
  workStartTime: string;
  hasLunch: boolean;
  vehicle?: {
    vehicleId?: number;
    startAddress?: string;
    endAddress?: string;
    startCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
    endCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
  };
  orderList: IEqualOptimizeResponsesDriversOrderList[];
}

// export interface IEqualOptimizeResponses {
//   performedDate: string;
//   driverList: IEqualOptimizeResponsesDriver[];
// }
export interface IEqualOptimizeResponses extends IRunOptimizeEqualResponses {}

export interface IGetRouteDetailResponsesOrderList {
  orderId?: number;
  type: string;
  address?: string;
  detailAddress?: string;
  shipmentType: string;
  coordinate?: {
    type: 'Point';
    coordinates: [number, number];
  };
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  skill?: string;
  route: {
    displayIndex?: number;
    routeIndex?: number;
    requiredDistance?: number;
    requiredTime?: number;
    eta?: string;
    polyline?: string;
  };
}

export interface IGetRouteDetailResponsesDriver {
  driverId?: number;
  name?: string;
  workStartTime: string;
  hasLunch: boolean;
  vehicle?: {
    vehicleId?: number;
    startAddress?: string;
    endAddress?: string;
    startCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
    endCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
  };
  orderList: IGetRouteDetailResponsesOrderList[];
}
export interface IGetRouteDetailResponses {
  routeId: number;
  name: string;
  performedDate: string;
  driverList: IGetRouteDetailResponsesDriver[];
}

export interface ICancelRouteOrder {
  driverId: number;
  orderList: number[];
}

export interface IInviteMember {
  loginAccount: string;
  name: string;
}

export interface IRunRecalculateOptimize {
  performedDate: string;
  driverList: [
    {
      driverId: number;
      vehicle: {
        vehicleId: number;
      };
      orderList: [
        {
          orderId: number;
        }
      ];
    }
  ];
}

export interface IRunRecalculateOptimizeResponses {
  performedDate: string;
  driverList: [
    {
      driverId?: number;
      name?: string;
      workStartTime: string;
      hasLunch: boolean;
      vehicle: {
        vehicleId?: number;
        startAddress?: string;
        endAddress?: string;
        startCoordinate?: {
          type: 'Point';
          coordinates: [number, number];
        };
        endCoordinate?: {
          type: 'Point';
          coordinates: [number, number];
        };
      };
      orderList: IEqualOptimizeResponsesDriversOrderList[];
    }
  ];
}

export interface IRegisterDriverList {
  driverId: number;
  vehicle: {
    vehicleId: number;
  };
  orderList: [
    {
      orderId?: number;
      type: string;
      route: {
        internal: {
          routeIndex: number;
          requiredDistance?: number;
          requiredTime: number;
          eta?: string;
          polyline?: string;
        };
        external: {
          routeIndex: number;
          requiredDistance?: number;
          requiredTime: number;
          eta?: string;
          polyline?: string;
        };
      };
    }
  ];
}

export interface IRegisterRoute {
  name: string;
  performedDate: string;
  driverList: IRegisterDriverList[];
}

export interface IOrderListDownload {
  orderList: number[]; // 다운로드 하려는 orderId 의 배열
  sortBy?: string; // 정렬기준 // status: 상태 (default) | received_date: 주문 접수일 | desired_date: 작업 희망일 | completed_date: 배송 완료일 | driver_id: 드라이버 id | consignee_name: 고객명 | address: 주소 | product_name: 상품명
  sortMethod?: string; // 정렬 기준 // desc | asc (default)
}

export interface IRouteOrderListFormat {
  name: string;
  createdAt: string;
  orderList: [
    {
      orderId?: number;
      shipmentType: string;
      receivedDate?: string;
      consigneeName?: string;
      consigneePhone?: string;
      consigneeNote?: string;
      address?: string;
      detailAddress?: string;
      skill?: string;
      desiredDate?: string;
      capacity: number;
      serviceTime?: number;
      desiredTimeStart?: string;
      desiredTimeEnd?: string;
      note?: string[];
      product?: {
        code?: string;
        name?: string;
        quantity?: number;
      }[];
      driverName: string;
      performedDate: string;
      routeIndex: number | '-';
      eta?: string;
      requiredTime?: number;
    }
  ];
}

export interface IOrderListFormat {
  orderId?: number;

  shipmentType: string;
  clientKey: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  serviceTime: number;
  skill?: string;
  note?: string[];
  status: string;
  product: [
    {
      code?: string;
      name?: string;
      quantity: number;
    }
  ];
}

export interface IOrderListDownloadResponses {
  orderList: IOrderListFormat[];
}

export interface IUpsertOrder {
  orderList: IOrderListFormat[];
}

export interface IControlRouteList {
  routeId: number;
  name: string;
  status: string; // route status
}

export interface IControlRouteDriverList {
  driverId: number;
  name: string;
  workStartTime: string;
  status: string;
  vehicle: {
    vehicleId: number;
    startAddress: string;
    endAddress?: string;
    startCoordinate: {
      type: 'Point';
      coordinates: [number, number];
    };
    endCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
  };
  orderList: IControlRouteDriverOrderList[];
}
export interface IControlRouteDriverOrderList {
  orderId?: number;
  status: string;
  type: string;
  consigneeName?: string;
  address?: string;
  detailAddress?: string;
  shipmentType?: string;
  coordinate?: {
    type: 'Point';
    coordinates: [number, number];
  };
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  completedAt?: string;
  skill?: string;
  route: {
    routeIndex: number;
    displayIndex: number;
    requiredTime?: number;
    requiredDistance: number;
    eta?: string;
    polyline?: string;
  };
}
export interface IGetControlRouteResponses {
  routeId: number;
  routeStatus: string;
  historyList: [
    {
      orderId?: number;
      driverId?: number;
      comment?: string;
      // displayIndex: number;
      type: string;
      createdBy: string;
      createdAt: string;
    }
  ];
  orderStatus: {
    completed: number;
    scheduled: number;
    skipped: number;
    totalOrder: number;
  };
  driverList: IControlRouteDriverList[];
}

export interface IHistoryList {
  orderId?: number;
  orderAddress: string;
  driverId?: number;
  routeIndex: number;
  createdBy?: string;
  driverName: string;
  comment?: string;
  type: string;
  createdAt: string;
}

export interface IGetControlRouteListByDateResponses {
  routeList: IControlRouteList[];
}

export interface IsetBatchOrders extends IsetBatchOrdersProps {
  // 배차 대기 상태 주문만 수정이 가능합니다.
  // only unassigned order can be modified
  orderList: number[]; // 주문 ID 목록
}

export interface IsetBatchOrdersProps {
  skill?: string;
  desiredDate?: string;
  serviceTime?: number | string;
  note?: string[];
}

export interface IGetInvestigatorListResponses {
  memberList: IInvestigator[];
}

export interface IInvestigator {
  driverId: number;
  name: string;
  status: string;
  phone?: string;
  workStartTime: string;
  vehicle?: {
    vehicleId: number;
    startAddress: string;
    endAddress: string;
  };
  userId: number;
  hasLunch: boolean;
  invitedAt: Date;
  acceptedAt?: Date;
}

export interface ISetInvestigatorProps {
  target: string | number;
  name?: string;
  workStartTime?: string;
  startAddress?: string;
  endAddress?: string;
  hasLunch?: boolean;
}
export interface IGetLocationTopicList {
  topicList: string[];
}

export interface ImanualExcelValidateResponses {
  vehicleList: ImanualExcelValidateVehicleList[];
}

export interface ImanualExcelValidateVehicleList {
  vehicle: {
    vehicleId: number;
    licenseNumber: string;
  };
  orderList: ImanualExcelValidateOrderList[];
}

export interface ImanualExcelValidateOrderList {
  clientKey?: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  coordinate: {
    type: 'Point';
    coordinates: [number, number];
  };
  address: string;
  detailAddress?: string;
  capacity: number;
  shipmentType?: string;
  skill?: string;
  serviceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: [string];
  product: [
    {
      code?: string;
      name?: string;
      quantity?: number;
    }
  ];
}

export interface ImanualRouting extends ImanualExcelValidateResponses {
  performedDate: string;
  name: string;
}

export interface IReportQuery {
  performedDate: string; // (YYYYMMDD-YYYYMMDD)
  dimension: string; // (로우 정렬 기준) - route: 경로 - driver: 드라이버 - performedDate: 최적화 일자
  metrics: string;
}

export type IReportResponses<DimensionRouteType, MetricsType> = {
  [key in 'routeList' | 'orderList']: IReportResponsesList<DimensionRouteType, MetricsType>[];
};

export interface IReportResponsesList<DimensionRouteType, MetricsType> {
  dimension: {
    performedDate: string;
    driver: {
      id: number;
      name: string;
    };
    route: DimensionRouteType;
  };
  metrics: MetricsType;
}

export interface IOrderReportDimensionRoute {
  id: number;
  name: string;
}
export interface IRouteReportDimensionRoute extends IOrderReportDimensionRoute {
  routeCode: string;
}
export interface IRouteReportMetrics {
  estimatedDistance: number;
  actualDistance: number;
  estimatedTime: number;
  actualTime: number;
  totalCapacity: number;
  actualCapacity: number;
  totalOrder: number;
  completedOrder: number;
  skippedOrder: number;
  podUploaded: number;
  rotation: number;
}
export interface IOrderReportMetrics {
  orderId: number;
  orderCode: string;
  routeIndex: number;
  address: string;
  detailAddress: string;
  status: string;
  consigneeName: string;
  capacity: number;
  serviceTime: number;
  shipmentType: string;
  createdAt: Date;
  historyComment: string;
  podUrl: string[];
  podCreatedAt: Date;
  actualCapacity: number;
  capacityComment: string;
}
