import React, { useEffect } from 'react';

import { useMutation, useQuery } from 'react-query';
import { IGetOrderListResponses } from 'constants/types';
import { getOrderList, skipOrders } from 'api';

import { useStore } from 'store';
import theme from 'constants/theme';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Highlight, Text } from 'components/Typography';

import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import strings from 'util/Localization';

interface SkipModalProps {
  deleteOrderModalOpen: boolean;
  setDeleteOrderModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  delTarget: Array<number>;
  setDelTarget: React.Dispatch<React.SetStateAction<Array<number>>>;

  noCancelTrigger: boolean;

  refetchControlRouteByIdData: Function;
}

const SkipModal = ({
  deleteOrderModalOpen,
  setDeleteOrderModalOpen,
  delTarget,
  setDelTarget,
  noCancelTrigger,
  refetchControlRouteByIdData,
}: SkipModalProps) => {
  let domain = window.localStorage.getItem('serviceDomain');

  const { getOrderListProps } = useStore();

  const { refetch: refetchOrderList } = useQuery<IGetOrderListResponses>(
    ['getOrderList', getOrderListProps],
    () => getOrderList(getOrderListProps),
    { keepPreviousData: true }
  );

  const {
    mutate: mutateSkipOrder,
    isLoading: deleteUnassignedOrderIsLoading,
    isSuccess: deleteUnassignedOrderIsSuccess,
    isError: deleteUnassignedOrderIsError,
    reset,
  } = useMutation(skipOrders, {
    onSuccess: () => {
      setDelTarget([]);
      refetchOrderList();
      refetchControlRouteByIdData();
    },
  });

  useEffect(() => {
    console.log(noCancelTrigger);

    reset();
  }, [deleteOrderModalOpen, reset]);

  return (
    <Modal
      // title="주문 상태 변경"
      isModalOpen={deleteOrderModalOpen}
      setIsModalOpen={() => {
        setDeleteOrderModalOpen(prev => !prev);
      }}
      padding={24}
      isLoadingModal={deleteUnassignedOrderIsLoading}
      width={503}
      // width={deleteUnassignedOrderIsLoading || deleteUnassignedOrderIsSuccess ? 424 : 976}
      ms={deleteUnassignedOrderIsSuccess ? 0 : 30}
    >
      {!deleteUnassignedOrderIsLoading &&
        (deleteUnassignedOrderIsSuccess ? (
          <React.Fragment>
            <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
            <Text styleName="subheadline3" color="RG04" sx={{ padding: '10px 0 20px 0' }}>
              {strings.주문상태}가 '보류'로 변경되었습니다.
            </Text>
            <Button
              variant={'default'}
              type={'reset'}
              styleName="body2"
              color="RG00"
              fullWidth
              sx={{ padding: '6px 0' }}
              onClick={() => {
                setDeleteOrderModalOpen(false);
              }}
            >
              닫기
            </Button>
          </React.Fragment>
        ) : deleteUnassignedOrderIsError || noCancelTrigger ? (
          <Stack name="delete-vehicle-modal" spacing={33}>
            <Stack spacing={10}>
              <Text styleName="subheadline2" color="RC04">
                {domain === 'survey' ? '조사원이' : '드라이버가'} 업무 중이면서 ‘배차완료’ 또는 ‘{strings.주행_조사}중’
                상태인 {strings.모달_주문}만<br />
                ‘보류’로 변경할 수 있습니다.
              </Text>
              <Text styleName="subheadline3" color="RG03">
                다른 상태의 {strings.모달_주문}을 제외하고 다시 시도해주세요.
              </Text>
            </Stack>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                setDeleteOrderModalOpen(false);
              }}
            >
              <Text styleName="body2" color="RG04">
                닫기
              </Text>
            </Button>
          </Stack>
        ) : (
          <Stack name="delete-vehicle-modal" spacing={33}>
            <Stack spacing={10}>
              <Text styleName="title2" color="RG02">
                {/* {`${strings.선택된주문} ${delTarget.length ? delTarget.length : 1}건의 ${strings.주문상태}를`} */}
                {strings.주문상태}를 ‘보류’로 변경하시겠습니까?
              </Text>
              <Text styleName="subheadline2" color="RG03">
                보류 상태가 되면 ‘배차대기’ 또는 ‘취소’로 변경할 수 있습니다.
                <br />
                상태 변경 시 담당 기사님께 업데이트 됩니다.
              </Text>
            </Stack>
            <Stack direction="row" spacing={16}>
              <Button
                variant={'second'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  setDeleteOrderModalOpen(false);
                }}
              >
                <Text styleName="body2" color="RG04">
                  취소
                </Text>
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  mutateSkipOrder(delTarget);
                }}
              >
                <Text styleName="body2" color="RG00">
                  변경하기
                </Text>
              </Button>
            </Stack>
          </Stack>
        ))}
    </Modal>
  );
};

export default SkipModal;
