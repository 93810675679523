import React, { useState, useEffect, SetStateAction, useRef } from 'react';

import mqtt from 'mqtt/dist/mqtt';
import { IClientOptions, MqttClient } from 'mqtt/types/lib/client';
import { ILocationMqOnStore } from '../constants/types';

function useMqtt({
  locationMqResponse,
  setLocationMqResponse,
  attributedDriverId,
}: {
  locationMqResponse: ILocationMqOnStore[];
  setLocationMqResponse: React.Dispatch<SetStateAction<ILocationMqOnStore[]>>;
  attributedDriverId: Array<number>;
}) {
  const locationMqResponseRef = useRef(locationMqResponse);
  const setLocationMqResponseRef = (data: ILocationMqOnStore[]) => {
    locationMqResponseRef.current = [...data];
    setLocationMqResponse([...data]);
  };

  const [client, setClient] = useState<MqttClient | null>(null);
  const [isSub, setIsSub] = useState<boolean>(false);
  const [connectStatus, setConnectStatus] = useState<string>('default');

  const mqttConnect = (host: string, mqttOption: IClientOptions) => {
    setConnectStatus('Connecting');
    setClient(mqtt.connect('mqtts://' + host, mqttOption));
  };

  const mqttSub = (subscription: { topic: string | string[]; qos: 0 | 1 | 2 }) => {
    if (client) {
      const { topic, qos } = subscription;
      client.subscribe(topic, { qos }, (error: Error) => {
        if (error) {
          console.log('Subscribe to topics error', error);
          return;
        }
        console.log('Subscribed');
        setIsSub(true);
      });
    }
  };

  const mqttUnSub = (subscription: { topic: string | string[] }) => {
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, (error: Error) => {
        if (error) {
          console.log('Unsubscribe error', error);
          return;
        }
        setLocationMqResponseRef([]);
        console.log('Unsubscribed');
        setIsSub(false);
      });
    }
  };

  const mqttPublish = (context: any) => {
    if (client) {
      const { topic, qos, payload } = context;
      client.publish(topic, payload, { qos }, (error: any) => {
        if (error) {
          console.log('Publish error: ', error);
        }
      });
    }
  };

  const mqttDisconnect = () => {
    if (client) {
      client.end();
    }
  };

  useEffect(() => {
    console.log('now is : ' + connectStatus);

    if (client) {
      client.on('connect', () => {
        setConnectStatus('Connected');
      });
      client.on('error', (err: Error) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        setConnectStatus('Reconnecting');
      });
      client.on('message', (topic: string | string[], message: any) => {
        const payload = { topic, message: message.toString() };
        // setPayload(payload);
        try {
          let json: ILocationMqOnStore = JSON.parse(payload.message);
          let mqResponses: ILocationMqOnStore[] = locationMqResponseRef.current;
          if (mqResponses.filter(x => x.userId === json.userId && x.driverId === json.driverId).length > 0) {
            mqResponses.forEach((x, index) => {
              if (x.userId === json.userId && x.driverId === json.driverId) {
                mqResponses[index] = json;
              }
            });
          } else mqResponses = mqResponses.concat(json);

          if (mqResponses.filter(d => attributedDriverId.includes(Number(d.driverId))).length > 0)
            setLocationMqResponseRef(mqResponses.filter(d => attributedDriverId.includes(Number(d.driverId))));
        } catch (e) {
          console.log(e);
        }

        console.log(payload);
      });
    }
  }, [client, connectStatus]);

  return {
    client,
    setClient,
    isSub,
    setIsSub,
    connectStatus,
    setConnectStatus,
    mqttConnect,
    mqttSub,
    mqttUnSub,
    mqttPublish,
    mqttDisconnect,
  };
}

export default useMqtt;
