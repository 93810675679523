import React from 'react';
import { useStore } from 'store';

const GradeSelector = () => {
  const { userGrade, setUserGrade } = useStore();

  return (
    <select
      defaultValue={userGrade}
      onChange={e => {
        let res = e.target.value;

        setUserGrade(Number(res) as 1 | 2 | 3 | 4);
      }}
      style={{ position: 'fixed', right: 0, bottom: 0, width: '150px', height: '30px' }}
    >
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
    </select>
  );
};

export default GradeSelector;
