import React, { Dispatch, FocusEvent, SetStateAction, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import Alt from 'components/Alt';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import DragablePicker, { TDragablePickerOptions } from 'components/Popup/DragablePicker';

import theme from 'constants/theme';

import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import { ReactComponent as IcDown } from 'constants/icon/ic_arrowdown.svg';
import { ReactComponent as IcCalendar } from 'constants/icon/ic_calendar.svg';
import { ReactComponent as IcArrowCountUp } from 'constants/icon/ic_arrow_count_up.svg';
import { ReactComponent as IcArrowCountDown } from 'constants/icon/ic_arrow_count_down.svg';
import { ReactComponent as IcMagnifyingGlass } from 'constants/icon/ic_magnifyingglass.svg';
import ComplexDoubleviewDateselector from 'components/ComplexDoubleviewDateselector';
import dayjs from 'dayjs';
import { IReportQuery } from 'constants/types';

const ReportFilterContent = ({
  title,
  altInfo,
  children,
}: {
  title?: string;
  altInfo?: string;
  children: React.ReactNode;
}) => {
  const titleRef = useRef<HTMLSpanElement>(null);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();
  const [alt, setAlt] = useState<{ visible: boolean; content: string }>({ visible: false, content: '' });

  return (
    <FilterContentBox spacing={4}>
      {title && altInfo && (
        <Stack name="filter-content-header" spacing={6} direction="row">
          <Text styleName="caption2" color="RG03" ref={titleRef}>
            {title}
          </Text>
          <IcInfo
            width={14}
            height={14}
            fill={theme.colors.RG05}
            onMouseOver={e => {
              setAnchorPoint(e.currentTarget.getBoundingClientRect());
              setAlt({ content: altInfo, visible: true });
            }}
            onMouseOut={() => {
              setAlt({ content: '', visible: false });
            }}
          />
        </Stack>
      )}
      {children}

      {alt.visible && (
        <Alt
          style={{
            top: anchorPoint ? anchorPoint.top - 110 : 0,
            left: anchorPoint ? anchorPoint.left - ((titleRef.current?.getBoundingClientRect().width ?? 0) + 6) : 0,
          }}
        >
          {alt.content}
        </Alt>
      )}
    </FilterContentBox>
  );
};

const ReportFilter = ({
  type,
  setFilter,
  setDownloadModalIsOpen,
  dimensionSelectorOptions,
  setDimensionSelectorOptions,
  metricsSelectorOptions,
  setMetricsSelectorOptions,

  orderReportDimensionMetrics,
  setOrderReportDimensionMetrics,
}: {
  type: 'route' | 'order';
  setFilter: Dispatch<SetStateAction<IReportQuery | undefined>>;
  setDownloadModalIsOpen?: React.Dispatch<SetStateAction<boolean>>;
  dimensionSelectorOptions: TDragablePickerOptions;
  setDimensionSelectorOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;
  metricsSelectorOptions: TDragablePickerOptions;
  setMetricsSelectorOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;

  orderReportDimensionMetrics?: TDragablePickerOptions;
  setOrderReportDimensionMetrics?: Dispatch<SetStateAction<TDragablePickerOptions>>;
}) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      keyword: '',
      startDate: dayjs()
        .subtract(dayjs().daysInMonth() - 2, 'day')
        .format('YYYY.MM.DD'),
      endDate: dayjs().format('YYYY.MM.DD'),
    },
  });

  const PeriodContent: Array<{
    key: string;
    name: string;
    setter: () => void;
  }> = [
    {
      key: 'daily',
      name: '일간',
      setter: () => {
        methods.setValue('startDate', dayjs().subtract(1, 'day').format('YYYY.MM.DD'), {
          shouldDirty: true,
          shouldValidate: true,
        });
        methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
      },
    },
    {
      key: 'weekly',
      name: '주간',
      setter: () => {
        methods.setValue('startDate', dayjs().subtract(7, 'day').format('YYYY.MM.DD'), {
          shouldDirty: true,
          shouldValidate: true,
        });
        methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
      },
    },
    {
      key: 'monthly',
      name: '월간',
      setter: () => {
        methods.setValue(
          'startDate',
          dayjs()
            .subtract(dayjs().daysInMonth() - 2, 'day')
            .format('YYYY.MM.DD'),
          { shouldDirty: true, shouldValidate: true }
        );
        methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
      },
    },
  ];
  const [period, setPeriod] = useState<string>('monthly');
  const [CDDIsOpen, setCDDIsOpen] = useState<boolean>(false);
  const [performedDate, setPerformedDate] = useState<string>(
    `${methods.getValues('startDate').replaceAll('.', '')}-${methods.getValues('endDate').replaceAll('.', '')}`
  );

  const [metricsSelectorIsOpen, setMetricsSelectorIsOpen] = useState<boolean>(false);
  const [dimensionSelectorIsOpen, setDimensionSelectorIsOpen] = useState<boolean>(false);

  const onSubmit = (res: any) => {
    console.log(res);
  };

  const CalendarInputOnFocus = (e: FocusEvent) => {
    setCDDIsOpen(true);
  };

  useEffect(() => {
    let dimension = new Set<string>();
    Object.values(dimensionSelectorOptions.filter(d => d.isSelected)).forEach(d => {
      if (`${d.key}`.includes('route')) dimension.add('route');
      else dimension.add(`${d.key}`);
    });

    setFilter({
      performedDate: performedDate,
      // `${methods.getValues('startDate').replaceAll('.', '')}-${methods
      //   .getValues('endDate')
      //   .replaceAll('.', '')}`,
      dimension: Array.from(dimension).sort().join(','),
      metrics: Object.values(metricsSelectorOptions.filter(d => d.isSelected))
        .map(d => d.key)
        .sort()
        .toString(),
    });
  }, [dimensionSelectorOptions, methods, metricsSelectorOptions, performedDate, setFilter]);

  return (
    <React.Fragment>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack align="start" spacing={16}>
          {/* 
              RP-410 searchbar is TBD content
          <FilterSearchbar spacing={8} direction="row">
            <input
              {...methods.register('keyword', {})}
              autoComplete="off"
              placeholder="주문번호, 고객 이름, 담당자 등을 검색해서 찾을 수 있습니다"
            />
            <IcMagnifyingGlass />
          </FilterSearchbar> 
          */}

          <GrayBox justify="center" align="start">
            <Stack justify="start" align="flex-end" spacing={12} direction="row">
              {/* <ReportFilterContent
                title="데이터 주기 설정"
                altInfo={`${type === 'route' ? '주행' : '주문'}기록에 대해 데이터 주기를 설정합니다.`}
              >
                <PeriodWrapper direction={'row'}>
                  {PeriodContent.map(d => (
                    <PeriodContainer
                      key={d.name}
                      selected={period === d.key}
                      onClick={() => {
                        setPeriod(d.key);
                        PeriodContent.find(x => x.key === d.key)?.setter();
                      }}
                      len={PeriodContent.length}
                    >
                      <Text styleName={'caption3'} color={period === d.key ? 'RC02' : 'RG02'}>
                        {d.name}
                      </Text>
                    </PeriodContainer>
                  ))}
                </PeriodWrapper>
              </ReportFilterContent> */}
              <ReportFilterContent title="날짜 설정" altInfo="측정할 날짜를 설정합니다.">
                <Stack sx={{ position: 'relative' }}>
                  <CalendarInputField isActive={CDDIsOpen} hasError={false}>
                    <CalendarIconContainer isActive={CDDIsOpen} hasError={false}>
                      <IcCalendar />
                    </CalendarIconContainer>
                    <input
                      {...methods.register('startDate', {
                        minLength: {
                          value: 10,
                          message: '',
                        },
                        maxLength: {
                          value: 10,
                          message: '',
                        },
                        pattern: {
                          value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                          message: '',
                        },
                      })}
                      autoComplete="off"
                      onFocus={CalendarInputOnFocus}
                    />
                    <Text styleName="caption2" color="RG02">
                      -
                    </Text>
                    <input
                      {...methods.register('endDate', {
                        minLength: {
                          value: 10,
                          message: '',
                        },
                        maxLength: {
                          value: 10,
                          message: '',
                        },
                        pattern: {
                          value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                          message: '',
                        },
                      })}
                      autoComplete="off"
                      onFocus={CalendarInputOnFocus}
                    />
                  </CalendarInputField>

                  <ComplexDoubleviewDateselector
                    {...{
                      initDate: [
                        dayjs(methods.getValues('startDate')).toDate(),
                        dayjs(methods.getValues('endDate')).toDate(),
                      ],
                      methods,
                      setPerformedDate,
                      isOpen: CDDIsOpen,
                      setIsOpen: setCDDIsOpen,
                    }}
                  />
                </Stack>
              </ReportFilterContent>
              <ReportFilterContent title="칼럼 설정" altInfo="측정하고자 하는 데이터를 설정합니다.">
                {type === 'order' ? (
                  <Stack spacing={10} direction="row">
                    <DragablePicker
                      dragable
                      top="42px" // parent height + 10
                      height="607px"
                      parent={
                        <Button
                          variant={'RTB'}
                          type={'button'}
                          height={32}
                          onClick={() => setDimensionSelectorIsOpen(prev => !prev)}
                        >
                          <Stack spacing={8} direction="row">
                            항목 선택
                            {dimensionSelectorIsOpen ? <IcArrowCountUp /> : <IcArrowCountDown />}
                          </Stack>
                        </Button>
                      }
                      {...{
                        options: orderReportDimensionMetrics ?? [],
                        setOptions: setOrderReportDimensionMetrics ?? setDimensionSelectorOptions,
                        isOpen: dimensionSelectorIsOpen,
                        setIsOpen: setDimensionSelectorIsOpen,
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack spacing={10} direction="row">
                    <DragablePicker
                      dragable
                      top="42px" // parent height + 10
                      parent={
                        <Button
                          variant={'RTB'}
                          type={'button'}
                          bgColor={'RC10'}
                          height={32}
                          onClick={() => setDimensionSelectorIsOpen(prev => !prev)}
                        >
                          <Stack spacing={8} direction="row">
                            측정 기준 선택
                            {dimensionSelectorIsOpen ? <IcArrowCountUp /> : <IcArrowCountDown />}
                          </Stack>
                        </Button>
                      }
                      {...{
                        options: dimensionSelectorOptions,
                        setOptions: setDimensionSelectorOptions,
                        isOpen: dimensionSelectorIsOpen,
                        setIsOpen: setDimensionSelectorIsOpen,
                      }}
                    />

                    <DragablePicker
                      dragable
                      top="42px" // parent height + 10
                      height="607px"
                      parent={
                        <Button
                          variant={'RTB'}
                          type={'button'}
                          height={32}
                          onClick={() => setMetricsSelectorIsOpen(prev => !prev)}
                        >
                          <Stack spacing={8} direction="row">
                            측정 항목 선택
                            {metricsSelectorIsOpen ? <IcArrowCountUp /> : <IcArrowCountDown />}
                          </Stack>
                        </Button>
                      }
                      {...{
                        options: metricsSelectorOptions,
                        setOptions: setMetricsSelectorOptions,
                        isOpen: metricsSelectorIsOpen,
                        setIsOpen: setMetricsSelectorIsOpen,
                      }}
                    />
                  </Stack>
                )}
              </ReportFilterContent>
              <ReportFilterContent>
                <Button
                  variant={'second'}
                  type={'button'}
                  color={'RG03'}
                  styleName={'caption2'}
                  height={32}
                  onClick={() => {
                    setDownloadModalIsOpen && setDownloadModalIsOpen(true);
                  }}
                  sx={{ padding: '10px 16px' }}
                >
                  <IcDown style={{ marginRight: '10px' }} />
                  엑셀로 다운로드
                </Button>
              </ReportFilterContent>
            </Stack>
          </GrayBox>
        </Stack>
      </form>
    </React.Fragment>
  );
};

export default ReportFilter;

const GrayBox = styled(Stack)`
  padding: 12px 16px;

  min-height: 80px;
  border-radius: 8px;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG08,
  })}
`;

const FilterContentBox = styled(Stack)`
  width: fit-content;
`;

const PeriodWrapper = styled(Stack)`
  overflow: hidden;
  height: 32px;
  border-radius: 6px;
`;
const PeriodContainer = styled.div<{ len: number; selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ len }) => ({
    width: `calc(100% / ${len})`,
  })}
  height: 100%;

  padding: 6px 21px;
  white-space: nowrap;
  position: relative;
  ${({ selected }) =>
    selected
      ? {
          backgroundColor: theme.colors.RC03_1,
          border: `1px solid ${theme.colors.RC03}`,
          zIndex: 1,
        }
      : { backgroundColor: theme.colors.RG00, border: `1px solid ${theme.colors.RG06}` }}

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:not(:last-child) {
    margin-right: -1px;
  }
  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  cursor: pointer;
`;

const CalendarIconContainer = styled.div<{ isActive: boolean; hasError: boolean }>`
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, hasError, isActive }) =>
    hasError ? 'transparent' : isActive ? 'transparent' : theme.colors.RC03_1};
`;

const CalendarInputField = styled.div<{ isActive: boolean; hasError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 270px;
  height: 32px;

  border-radius: 6px;
  color: ${({ theme }) => theme.colors.RG02};
  background: ${({ theme, hasError, isActive }) =>
    hasError ? theme.colors.RC04_1 : isActive ? theme.colors.RC03_1 : theme.colors.RG00};

  border: 1px solid ${({ theme }) => theme.colors.RG06};
  ${({ theme }) => theme.fontStyle.caption2}
  & > input {
    width: 113px;
    padding: 6px 10px;

    background: none;
    text-align: center;
  }
`;

const FilterSearchbar = styled(Stack)`
  ${({ theme }) => theme.fontStyle.caption2}
  width: 345px;
  height: 38px;

  padding: 9px 16px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.RG06};

  & > input {
    width: 100%;
    ::placeholder {
      color: ${({ theme }) => theme.colors.RG05};
    }
  }
  & > svg {
    cursor: pointer;
  }
  & > svg > path {
    stroke: ${({ theme }) => theme.colors.RC03};
  }
`;
