import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { BoxContainer } from '../BoxContainer';
import { Button } from '../Button';
import { Input } from '../Input';
import DatePickerPopup from '../Popup/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import { useForm, useFormState } from 'react-hook-form';
import { DriverSelector } from '../DriverSelector';
import { DriverListItem } from '../Popup/DriverPicker';
import { IGetUnassignedOrders } from 'constants/types';
import getDirtyValues from 'util/getDirtyValues';
import { useLocation, useNavigate } from 'react-router-dom';
import { csAlert, getControlRouteListByDate, getDriverInfo, runEqualOptimize } from 'api';
import { useMutation, useQuery } from 'react-query';
import RunOptimizeModal from 'components/Modal/RunOptimizeModal';
import { useStore } from '../../store';
import { removeAllTemporaryOptimizeDataList } from '../../util/sessionStorageManager';
import useInterval from '../../hooks/useInterval';
import strings from '../../util/Localization';
import theme from 'constants/theme';

import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import useQueryTime from 'hooks/useQueryTime';
import GridButtonFilter from 'components/GridButtonFilter';
import { shipmentFilter } from 'constants/commons';

interface RoutePlanSetupNavProps {
  filter: IGetUnassignedOrders;
  setFilter: Dispatch<SetStateAction<IGetUnassignedOrders>>;
  targetOrdersId: Array<number>;
}

const RoutePlanSetupNav = ({ filter, setFilter, targetOrdersId }: RoutePlanSetupNavProps) => {
  let domain = window.localStorage.getItem('serviceDomain');
  const methods = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' });
  const { control } = methods;

  const navigate = useNavigate();
  const { setTemporaryOptimizeData } = useStore();

  const { dirtyFields } = useFormState({
    control,
  });

  const EXPECTED_CALC_MULTIPLIER = 2.5;

  useInterval(
    () => {
      let nextPercent = completedPercent;
      if (nextPercent < 20) nextPercent += Math.floor(Math.random() * 3);
      else if (nextPercent < 50) nextPercent += Math.floor(Math.random() * 2);
      else if (nextPercent < 90) nextPercent += Math.random() > 0.7 ? 1 : 0;
      else if (nextPercent < 100) nextPercent += Math.random() > 0.9 ? 1 : 0;
      setCompletedPercent(nextPercent > 100 ? 100 : nextPercent);
    },
    targetOrdersId.length / EXPECTED_CALC_MULTIPLIER > 40
      ? Math.floor(targetOrdersId.length / EXPECTED_CALC_MULTIPLIER)
      : 40
  );

  const { data: dailyRoutes, refetch: refetchDailyRoutes } = useQuery(
    ['dailyRoutes'],
    () =>
      getControlRouteListByDate(
        methods.getValues().date ? dayjs(methods.getValues().date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
      ),
    {}
  );

  const {
    data: mutateRunEqualOptimizeResponses,
    mutate: mutateRunEqualOptimize,
    status: mutateRunEqualOptimizeStatus,
  } = useMutation(runEqualOptimize, {
    mutationKey: 'runEqualOptimize',
    onMutate: data => {
      removeAllTemporaryOptimizeDataList();
      setCompletedPercent(0);
      setRunEqualOptimizeProgressModal(true);
    },
    onError: (e: any) => {
      if ((e.response?.status as number) >= 500) {
        let confirmAction = window.confirm('에러 정보를 개발팀에 공유?');
        if (confirmAction) {
          csAlert({
            historyId: e?.response?.data?.error?.advice,
            uri: e.response?.config.url,
            type: 'algorithmError',
          });
        } else {
          //
        }
      }
      // window.prompt(
      //   `균등 배차 에러 \n\n에러 historyId : ${e?.response?.data?.error?.advice}\n에러 코드 : ${e?.response?.data?.error?.number}\n\n\n 아래 historyId 를 복사하세요`,
      //   e?.response?.data?.error?.advice
      // );
    },
    onSuccess: res => {
      console.log('success');

      setRunEqualOptimizeProgressModal(false);
      setTimeout(() => {
        let key = `${Date.now()}`;
        setTemporaryOptimizeData({
          dateKey: key,
          data: res,
          name: methods.getValues('name'),
          originName: methods.getValues('name'),
        });

        navigate(`../confirm/${key}`, { replace: true });
      }, 50);
    },
    onSettled: (data, error, variables, context) => {
      setRunEqualOptimizeProgressModal(false);
    },
  });

  const init = useLocation().state as any;

  const resetForms = () => {
    // methods.resetField('receivedDate.start');
    methods.resetField('desiredDate.start');
    methods.resetField('desiredDate.end');
    methods.resetField('allow_desired_date_null');

    // RT-815
    methods.setValue('receivedDate.start', `${dayjs().subtract(1, 'week').format('YYYY.MM.DD')}`, {
      shouldValidate: true,
      shouldDirty: true,
    });
    methods.setValue('receivedDate.end', dayjs().format('YYYY.MM.DD'), { shouldValidate: true, shouldDirty: true });
    methods.setValue('allow_desired_date_null', false, { shouldValidate: true, shouldDirty: true });

    setFilter({
      // RT-815
      received_date: `${dayjs().subtract(1, 'week').format('YYYYMMDD')}-${dayjs().format('YYYYMMDD')}`,
      allow_desired_date_null: false,
    });
  };

  const handleSetRouteSetting = (data: any) => {
    // route 경로 생성할 때 stash에 저장
    delete data.desiredDate;
    delete data.receivedDate;

    if (!targetOrdersId.length) {
      return alert(`최소 1개 이상의 ${strings.모달_주문}을 선택 해주세요`);
    }

    if (!data.driverList?.length) {
      methods.setError(
        'driverList',
        { type: 'requiredField', message: '해당 필드는 필수값입니다.' },
        { shouldFocus: true }
      );
    }
    if (!data.name) {
      methods.setError('name', { type: 'requiredField', message: '해당 필드는 필수값입니다.' }, { shouldFocus: true });
    }
    if (!data.date) {
      methods.setError('date', { type: 'requiredField', message: '해당 필드는 필수값입니다.' }, { shouldFocus: true });
    }

    data.driverList = driverState
      .filter(x => x.isSelected)
      .map(x => {
        return {
          driverId: x.driverId,
          vehicleId: x.vehicleId,
        };
      });
    if (Object.keys(methods.formState.errors).length) return;

    let res = {
      orderList: targetOrdersId,
      performedDate: data.date.split(' ')[0].replaceAll('.', '-'),
      ...data,
    };

    setMutateRunEqualOptimizeProps({
      name: data.name,
      date: data.date,
      totalDriver: data.driverList?.length,
      totalOrder: targetOrdersId.length,
    });

    mutateRunEqualOptimize({
      props: {
        performedDate: res.performedDate,
        orderList: res.orderList,
        driverList: res.driverList,
      },
      config: {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          // const { loaded, total } = progressEvent;
          //
          // let completedPercent = Math.round((loaded / total) * 100);
          // console.log(completedPercent, 'completedPercent onUpload');
          // if (completedPercent >= 100) setCompletedPercent(20);
        },
        onDownloadProgress: (progressEvent: ProgressEvent) => {
          // const { loaded, total } = progressEvent;
          // let completedPercent = Math.round((loaded / total) * 100);
          // console.log(completedPercent, completedPercent >= 100, 'completedPercent onDownload');
          // if (completedPercent >= 100) setCompletedPercent(100);
        },
      },
    });
  };

  const handleSetFilter = (data: any) => {
    let values: any = getDirtyValues(dirtyFields, data);

    console.log(dirtyFields);

    console.log(values);

    // RT-814
    if (values.receivedDate?.start === '' || values.receivedDate?.end === '')
      return methods.setError('receivedDate.start', { type: 'required', message: '해당 필드는 필수값 입니다.' });
    if (dayjs(values.receivedDate?.start).isAfter(dayjs(values.receivedDate?.end)))
      return methods.setError('receivedDate.start', {
        type: 'invalidDateRange',
        message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
      });

    if (
      values.desiredDate?.end &&
      values.desiredDate?.start &&
      dayjs(values.desiredDate?.start).isAfter(dayjs(values.desiredDate?.end))
    )
      return methods.setError('desiredDate.start', {
        type: 'invalidDateRange',
        message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
      });

    let res = {
      received_date: `${values.receivedDate?.start ? values.receivedDate.start.replaceAll('.', '') : '19700101'}-${
        values?.receivedDate?.end ? values.receivedDate.end.replaceAll('.', '') : '21000101'
      }`,
      desired_date:
        values.desiredDate &&
        `${
          values.desiredDate.start && values.desiredDate?.start !== ''
            ? values.desiredDate?.start?.replaceAll('.', '')
            : '19700101'
        }-${
          values.desiredDate.end && values.desiredDate?.end !== ''
            ? values.desiredDate?.end?.replaceAll('.', '')
            : '21000101'
        }`,
      allow_desired_date_null: values.allow_desired_date_null,
      shipment_type: values.shipmentType,
    };

    if (res.shipment_type?.includes('all')) delete res.shipment_type;

    setFilter({ ...res });
  };

  useEffect(() => {
    resetForms();

    methods.setValue('date', init?.date, { shouldValidate: true, shouldDirty: true });
    methods.setValue('name', init?.name, { shouldValidate: true, shouldDirty: true });
  }, []);

  // useEffect(() => {
  //   const subscription = methods.watch((value, { name, type }) => {
  //     if (name === 'date') {
  //       methods.setValue(
  //         'name',
  //         `${value.date} ${domain === 'roouty' ? '배차' : '배정'}${
  //           window.localStorage.getItem('roleId') === '4' ? ` ${window.localStorage.getItem('name')}` : ''
  //         } #${(dailyRoutes?.routeList.length ?? 0) + 1} 배챠챳`
  //       );
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [methods.getValues().date, dailyRoutes]);

  useEffect(() => {
    refetchDailyRoutes();
    methods.setValue(
      'name',
      `${dayjs(methods.getValues().date).format('YYYY.MM.DD (ddd)')} ${domain === 'roouty' ? '배차' : '배정'}${
        window.localStorage.getItem('roleId') === '4' ? ` ${window.localStorage.getItem('name')}` : ''
      } #${(dailyRoutes?.routeList.length ?? 0) + 1}`
    );
  }, [methods.getValues().date, dailyRoutes, domain]);

  const [shipmentStatus, setShipmentStatus] = useState<boolean[]>([...new Array(shipmentFilter.length).fill(true)]);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState<boolean>(false);

  const [receivedStartDatePickerIsOpen, setReceivedStartDatePickerIsOpen] = useState(false);
  const [receivedEndDatePickerIsOpen, setReceivedEndDatePickerIsOpen] = useState(false);
  const [desiredStartDatePickerIsOpen, setDesiredStartDatePickerIsOpen] = useState(false);
  const [desiredEndDatePickerIsOpen, setDesiredEndDatePickerIsOpen] = useState(false);
  const [driverState, setDriverState] = useState<Array<DriverListItem>>([]);
  const [driverPickerIsOpen, setDriverPickerIsOpen] = useState<boolean>(false);

  const [mutateRunEqualOptimizeProps, setMutateRunEqualOptimizeProps] = useState<{
    date: string;
    totalDriver: number;
    name: string;
    totalOrder: number;
  }>();
  const [completedPercent, setCompletedPercent] = useState<number>(0);

  const [runEqualOptimizeProgressModal, setRunEqualOptimizeProgressModal] = useState<boolean>(false);

  const { data: drivers, isSuccess: getDriverListIsSuccess } = useQuery(['drivers'], getDriverInfo, {
    onSuccess: res => {
      // vehicleId 가 없는 드라이버 임의 배제
      // status가 activated인 드라이버만 노출

      let driverList = res?.memberList
        .filter(d => d.status === 'activated')
        .map(x => {
          return {
            name: x.name,
            driverId: x?.driverId,
            licenseNumber: x?.vehicle?.licenseNumber,
            vehicleId: x?.vehicle?.vehicleId,
            isSelected: true,
          };
        })
        .filter(d => Boolean(d.vehicleId));

      setDriverState(driverList);

      methods.setValue(
        'driverList',
        driverList.map(x => {
          return { driverId: x.driverId, vehicleId: x.vehicleId };
        }),
        {
          shouldValidate: true,
        }
      );
    },

    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const closeAllDatePickers = () => {
    setReceivedStartDatePickerIsOpen(false);
    setReceivedEndDatePickerIsOpen(false);
    setDesiredStartDatePickerIsOpen(false);
    setDesiredEndDatePickerIsOpen(false);
    setDatePickerIsOpen(false);
    setDriverPickerIsOpen(false);
  };

  return (
    <React.Fragment>
      <form style={{ height: '100%' }}>
        <Stack spacing={20} sx={{ height: '100%' }}>
          <Stack name="tnd" align="start" spacing={8} sx={{ padding: '0 20px 0 30px' }}>
            <BoxContainer justify="space-between">
              <Text styleName="body2" color="RG02">
                {strings.배차설정}
              </Text>
            </BoxContainer>
          </Stack>

          <Stack useVerticalScroll sx={{ height: 'fit-content' }}>
            <Stack spacing={20} sx={{ maxHeight: '560px', padding: '0 20px 0 30px' }}>
              <Stack spacing={20}>
                <Stack align="start" spacing={4}>
                  <Text styleName="caption2" color="RG03">
                    {strings.주행일자}
                  </Text>

                  <Stack direction={'row'} sx={{ position: 'relative' }}>
                    <Input
                      variant={'third'}
                      type="text"
                      name="date"
                      placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')}`}
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      height={32}
                      readOnly={true}
                      onFocus={() => {
                        closeAllDatePickers();
                        setDatePickerIsOpen(true);
                      }}
                      onClick={() => {
                        closeAllDatePickers();
                        setDatePickerIsOpen(true);
                      }}
                      errors={methods.formState.errors.date}
                      hasError={methods.formState.errors.date}
                    />

                    {datePickerIsOpen && (
                      <DatePickerPopup
                        banPast
                        allowUncheck={false}
                        setIsOpen={setDatePickerIsOpen}
                        setValue={methods.setValue}
                        name={'date'}
                        initDate={methods.getValues('date')}
                        format={'YYYY.MM.DD (ddd)'}
                      />
                    )}
                  </Stack>
                </Stack>

                <Stack align="start" spacing={4}>
                  <Text styleName="caption2" color="RG03">
                    {strings.주행이름}
                  </Text>
                  <Input
                    height={32}
                    name="name"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    variant={'third'}
                    placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')} 배차`}
                    errors={methods.formState.errors.name}
                    hasError={methods.formState.errors.name}
                  />
                </Stack>

                <Stack align="start" spacing={4}>
                  <Stack align={'start'} direction={'row'} spacing={12}>
                    <Text styleName="caption2" color="RG03">
                      {strings.드라이버}
                    </Text>
                    <Text styleName="caption2" color="RG04">
                      {driverState.filter(x => x.isSelected).length} 명 / {driverState.length} 명
                    </Text>
                  </Stack>
                  <DriverSelector
                    methods={methods}
                    drivers={driverState}
                    setDrivers={setDriverState}
                    driverPickerIsOpen={driverPickerIsOpen}
                    setDriverPickerIsOpen={setDriverPickerIsOpen}
                  />
                </Stack>
              </Stack>

              <Stack spacing={12}>
                <Stack name="tnd" align="start" spacing={8}>
                  <BoxContainer justify="space-between">
                    <Text styleName="body2" color="RG02">
                      {strings.주문필터링}
                    </Text>
                    <Button type={'button'} variant={'second'} sx={{ padding: '3px 6px' }} onClick={resetForms}>
                      <Text styleName={'caption2'} color={'RG04'}>
                        초기화
                      </Text>
                    </Button>
                  </BoxContainer>
                </Stack>

                <Stack align="start" spacing={4}>
                  <Text styleName="caption2" color="RG03">
                    접수일
                  </Text>
                  <Stack align="start">
                    <Stack direction={'row'} sx={{ position: 'relative' }}>
                      <Input
                        variant={'second'}
                        type="text"
                        name="receivedDate.start"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        height={32}
                        readOnly={true}
                        onClick={() => {
                          closeAllDatePickers();
                          setReceivedStartDatePickerIsOpen(true);
                        }}
                        // errors={methods.formState.errors?.receivedDate?.start}
                        hasError={methods.formState.errors?.receivedDate?.start}
                      />
                      <div>&nbsp;-&nbsp;</div>
                      <Input
                        variant={'second'}
                        type="text"
                        name="receivedDate.end"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        height={32}
                        readOnly={true}
                        onClick={() => {
                          closeAllDatePickers();
                          setReceivedEndDatePickerIsOpen(true);
                        }}
                        // errors={methods.formState.errors?.receivedDate?.start}
                        hasError={methods.formState.errors?.receivedDate?.start}
                      />
                      {receivedStartDatePickerIsOpen && (
                        <DatePickerPopup
                          fullWidth
                          setIsOpen={setReceivedStartDatePickerIsOpen}
                          setValue={methods.setValue}
                          name={'receivedDate.start'}
                          initDate={methods.getValues('receivedDate.start')}
                        />
                      )}
                      {receivedEndDatePickerIsOpen && (
                        <DatePickerPopup
                          fullWidth
                          setIsOpen={setReceivedEndDatePickerIsOpen}
                          setValue={methods.setValue}
                          name={'receivedDate.end'}
                          initDate={methods.getValues('receivedDate.end')}
                        />
                      )}
                    </Stack>
                    <Text styleName="tooltip2" color="RC04">
                      {methods.formState.errors?.receivedDate?.start?.message}
                    </Text>
                  </Stack>
                </Stack>

                <Stack align="start" spacing={4}>
                  <Stack
                    justify="space-between"
                    direction="row"
                    align="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      methods.setValue('allow_desired_date_null', !methods.getValues('allow_desired_date_null'), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  >
                    <Text styleName="caption2" color="RG03">
                      희망일
                    </Text>
                    <Stack spacing={4} direction="row" align="center" sx={{ width: 'fit-content' }}>
                      <IcSuccess
                        width={10}
                        height={10}
                        fill={theme.colors[methods.getValues('allow_desired_date_null') ? 'RC02' : 'RG05']}
                      />
                      <Text styleName="tooltip1" color={methods.getValues('allow_desired_date_null') ? 'RC02' : 'RG04'}>
                        희망일 없음 포함
                      </Text>
                      <Input
                        type="checkbox"
                        name="allow_desired_date_null"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        sx={{ display: 'none' }}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={'row'} sx={{ position: 'relative' }}>
                    <Input
                      variant={'second'}
                      type="text"
                      name="desiredDate.start"
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      height={32}
                      readOnly={true}
                      onClick={() => {
                        closeAllDatePickers();
                        setDesiredStartDatePickerIsOpen(true);
                      }}
                      hasError={methods.formState.errors?.desiredDate?.start}
                    />
                    <div>&nbsp;-&nbsp;</div>
                    <Input
                      variant={'second'}
                      type="text"
                      name="desiredDate.end"
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      height={32}
                      readOnly={true}
                      onClick={() => {
                        closeAllDatePickers();
                        setDesiredEndDatePickerIsOpen(true);
                      }}
                      hasError={methods.formState.errors?.desiredDate?.start}
                    />
                    {desiredStartDatePickerIsOpen && (
                      <DatePickerPopup
                        fullWidth
                        setIsOpen={setDesiredStartDatePickerIsOpen}
                        setValue={methods.setValue}
                        name={'desiredDate.start'}
                        initDate={methods.getValues('desiredDate.start')}
                      />
                    )}
                    {desiredEndDatePickerIsOpen && (
                      <DatePickerPopup
                        fullWidth
                        setIsOpen={setDesiredEndDatePickerIsOpen}
                        setValue={methods.setValue}
                        name={'desiredDate.end'}
                        initDate={methods.getValues('desiredDate.end')}
                      />
                    )}
                  </Stack>
                  <Text styleName="tooltip2" color="RC04">
                    {methods.formState.errors?.desiredDate?.start?.message}
                  </Text>
                </Stack>

                {domain === 'roouty' && (
                  <Stack align="start" spacing={4} sx={{ margin: '8px 0 0' }}>
                    <Text styleName="caption2" color="RG03">
                      {strings.주문 + ' 유형'}
                    </Text>
                    <GridButtonFilter
                      name="shipmentType"
                      content={shipmentFilter}
                      {...{ status: shipmentStatus, setStatus: setShipmentStatus, methods }}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack name="button-area" spacing={16} sx={{ padding: '0 20px 0 30px' }}>
            <Button
              variant={'seventh'}
              ds={'normal'}
              type={'button'}
              fullWidth
              height={32}
              color="RG03"
              styleName="caption1"
              onClick={methods.handleSubmit(data => handleSetFilter(data))}
            >
              {strings.주문필터적용}
            </Button>

            <Button
              variant={'default'}
              ds={'normal'}
              type={'submit'}
              fullWidth
              height={40}
              color="RG00"
              styleName="body1"
              onClick={methods.handleSubmit(data => handleSetRouteSetting(data))}
            >
              {strings.배차경로생성}
            </Button>
          </Stack>
        </Stack>
      </form>
      <RunOptimizeModal
        {...{
          info: mutateRunEqualOptimizeProps,
          completedPercent,
          runEqualOptimizeProgressModal,
          setRunEqualOptimizeProgressModal,
        }}
      />
    </React.Fragment>
  );
};

export default RoutePlanSetupNav;
