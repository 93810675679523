import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Stack } from 'components/Stack';
import Divider from 'components/Divider';
import { Text } from 'components/Typography';

import { ReactComponent as TEMP } from 'constants/icon/profile_none_photo.svg';
import { Link } from 'react-router-dom';
import { logout } from 'api';
import { useStore } from 'store';
import { commonUris } from 'constants/uris';

const MyPagePopup = forwardRef(({ setMyPagePopupIsOpen }: { setMyPagePopupIsOpen: any }, ref) => {
  const { userGrade } = useStore();

  return (
    <Container ref={ref as any}>
      <MyPageCard>
        <Stack spacing={118}>
          <Stack spacing={30}>
            <MyInfo>
              <TEMP />
              <NameTag styleName="title2" color="RG02">
                {window.localStorage.getItem('name')}
              </NameTag>
              <MailTag styleName="body3" color="RG04">
                {userGrade < 2
                  ? window.localStorage.getItem('email')
                  : window.localStorage.getItem('phone') || 'userid@wemeetmobility.com'}
              </MailTag>
            </MyInfo>
            <LogoutButton type={'button'} onClick={() => logout()}>
              로그아웃
            </LogoutButton>
          </Stack>
          <Stack
            justify="center"
            align="center"
            direction="row"
            divider={<Divider color="RG06" vertical />}
            spacing={10}
            sx={{ height: '12px' }}
            name="footer-info-area"
          >
            {[
              { value: '개인정보처리방침', path: commonUris.policy_privacy },
              { value: '서비스 이용약관', path: commonUris.tos },
            ].map(d => (
              <a key={`${d.value}-link`} href={d.path} target="_blank" rel="noreferrer">
                <Text styleName="caption3" color="RG04">
                  {d.value}
                </Text>
              </a>
            ))}
          </Stack>
        </Stack>
      </MyPageCard>
    </Container>
  );
});

export default MyPagePopup;

const Container = styled.div`
  position: absolute;
  top: 82px;
  right: 15px;

  z-index: 200;
`;

const MyPageCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  flex-direction: column;

  width: 360px;

  padding: 80px 20px 30px 20px;

  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.strong};
  background-color: ${({ theme }) => theme.colors.RG00};

  transition-property: width, height;
  transition: 0.3s;
`;

const LogoutButton = styled.button`
  ${({ theme }) => theme.fontStyle.body2}

  transition: background 0.3s;
  cursor: pointer;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  height: 48px;

  padding: 12px;

  ${({ theme }) => ({
    color: theme.colors.RG04,
    border: `1px solid ${theme.colors.RG06}`,
    backgroundColor: theme.colors.RG08,
    ':hover': {
      border: `1px solid ${theme.colors.RG05}`,
      backgroundColor: theme.colors.RG07,
    },
  })}
`;

const MyInfo = styled.div``;

const NameTag = styled(Text)`
  margin: 22.75px 0 11.25px 0;

  justify-content: center;
`;

const MailTag = styled(Text)`
  justify-content: center;
`;
