import { logout } from 'api';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IErrorResponse, IGetAccessTokenResponses } from 'constants/types';
import strings from 'util/Localization';

const CancelToken = axios.CancelToken;
export let canceler: any;

export const baseAPI = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

let isRefreshing = false;
let failedQueue: any = [];

// @ts-ignore
const processQueue = (error, token = null) => {
  // @ts-ignore
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const request = ({ ...options }: AxiosRequestConfig) => {
  baseAPI.defaults.headers.common.token = window.localStorage.getItem('accessToken') || '';
  const onSuccess = (response: AxiosResponse) => response.data;
  const onError = async (error: any) => {
    const originRequest = error.config;

    const getAccessToken: AxiosRequestConfig = {
      url: '/auth/getAccessToken',
      method: 'PUT',
      timeout: 300000,
      headers: {
        'Content-Type': 'application/json',
        token: `${window.localStorage.getItem('refreshToken')}`,
      },
    };

    if (error.response.status === 401 && !originRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originRequest.headers.token = token;
            return axios(originRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        baseAPI(getAccessToken)
          .then(({ data }) => {
            window.localStorage.setItem('accessToken', data.accessToken);

            baseAPI.defaults.headers.common.token = data.accessToken;
            originRequest.headers.common.token = data.accessToken;
            processQueue(null, data.token);
            resolve(axios(originRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      }).catch(err => {
        if (err.config.url === '/auth/getAccessToken') {
          // getAccessToken refresh 실패 시 강제 로그아웃
          logout();
          return Promise.reject(error);
        }
      });
    } else {
      let errResponse = error.response.data.error as IErrorResponse;

      let advice = errResponse.advice as any;

      const err = {
        1101: `업로드 하신 엑셀 ${(advice?.occuredIndex as Array<string>)?.map(d => parseInt(d) + 3)}행 ${
          errResponse.message
        }\n${strings.모달_주문}이 추가되지 않았으니 수정 후 다시 시도해주세요.`,

        1102: errResponse.message,
        1103: errResponse.message,
        1104: errResponse.message,
        1105: errResponse.message,
        1106: errResponse.message,
        1107: errResponse.message,
        1108: errResponse.message,
        1109: errResponse.message,
        1110: errResponse.message,

        // RT-1012 alert 대신 디자인 팝업 형식으로 변경됨
        1111: null,

        // RT-577/RT-591
        // 1111: `해당 상태로 변경할 수 없는 주문이 포함되어 있습니다.\n선택한 주문을 확인해주세요.\n\n배차 대기 가능 상태 = 배차완료 / 보류\n보류 가능 상태 = 배송중\n취소 가능 상태 = 배차 대기 / 배차 완료 / 보류`,
        1201: errResponse.message,
        1202: errResponse.message,
        1203: errResponse.message,
        1204: errResponse.message,
        1004: errResponse.message,
        1005: errResponse.message,
        1006: errResponse.message,
        1007: errResponse.message,
        1008: errResponse.message,
        1009: errResponse.message,
      };

      if (
        !(
          error.config.url.includes('/auth/signup/check/') ||
          error.config.url.includes('/member/password/check/') ||
          error.config.url.includes('/member/invite/check/') ||
          error.config.url.includes('/member/password/reset/') ||
          (error.response.data.error.name === 'DuplicatedUser' && error.config.url.includes('/auth/signup'))
        )
      )
        err[errResponse.number] && alert(err[errResponse.number]);
    }
    return Promise.reject(error);
  };

  return baseAPI({
    ...options,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      canceler = c;
    }),
  })
    .then(onSuccess)
    .catch(onError);
};
