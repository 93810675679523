import styled from 'styled-components';

export const ArrowDropDownAbsolute = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;

export const DriverWrapper = styled.div`
  padding: 10px;
  padding-bottom: 2px;
  border-radius: 8px;

  border: 1px solid ${({ theme }) => theme.colors.RG06};
  background: ${({ theme }) => theme.colors.RG00};
  max-height: 106px;
  width: 100%;
  ${({ theme }) => theme.scrollStyle.vertical};
`;

export const DriverChip = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 3px 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  background: ${({ theme }) => theme.colors.RC03_1};
  border-radius: 2px;
  &:hover {
    background: ${({ theme }) => theme.colors.RC03_3};
  }
  &:hover .close > path {
    stroke: ${({ theme }) => theme.colors.RG03};
  }
  &:hover span {
    color: ${({ theme }) => theme.colors.RG02} !important;
  }
`;
export const DriverChipAll = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 3px 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  background: ${({ theme }) => theme.colors.RC11_1};
  border-radius: 2px;
  &:hover {
    background: rgba(121, 117, 255, 0.3);
  }
  &:hover .close > path {
    stroke: ${({ theme }) => theme.colors.RG03};
  }
  &:hover span {
    color: ${({ theme }) => theme.colors.RG02} !important;
  }
`;

export const DriverChipEmpty = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 3px 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  background: ${({ theme }) => theme.colors.RC10_2};
  border-radius: 2px;
  &:hover {
    background: rgba(19, 192, 151, 0.3);
  }
  &:hover .close > path {
    stroke: ${({ theme }) => theme.colors.RG03};
  }
  &:hover span {
    color: ${({ theme }) => theme.colors.RG02} !important;
  }
`;
